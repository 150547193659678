<template>
  <div class="separator" :class="this.separatorType" :style="this.getStyle"/>
</template>

<script>

export default {
  name: 'Separator',
  computed: {
    getStyle() {
      if (this.size === 1) {
        return {};
      }
      return this.separatorType === 'vertical' ? {width: `${this.size}px`} : {};
    },
  },
  props: {
    size: {
      type: Number,
      default: 1,
    },
    separatorType: {
      validator: (prop) => ['vertical', 'horizontal'].includes(prop),
      default: 'horizontal',
    },
  },
};


</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

@separator__text__color: @super-light;
@separator__background-color: @super-light;

.separator {
  border: 0 solid transparent;
  color: @separator__text__color; /* Цвет линии для остальных браузеров */
  background-color: @separator__background-color; /* Цвет линии для браузера Firefox и Opera */
  align-self: stretch;

  &.horizontal {
    //width: 100%;
    height: 1px;
  }

  &.vertical {
    width: 1px;
    //height: 100%;
  }
}
</style>
