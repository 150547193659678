export default class AdminHelper {
  constructor() {
    this.status = null;

    this.struct = {
      create: {
        before: [],
        after: [
          {
            system_name: 'button_create',
            title: 'Создать',
            type: 'button',
            actions: [
              {type: 'sendFormAll__isCompressed__isOnlyChange__isWithRequired'},
              {type: 'closeModal__isAll__isWithBase'},
            ],
          },
        ],
      },
      change: {
        before: [
          {
            system_name: 'id',
            type: 'label',
            typeSettings: {
              is_required: true,
            },
            getValue: (item) => (item.id ? item.id : ''),
          },
        ],
        after: [
          {
            system_name: 'button_update',
            title: 'Сохранить',
            type: 'button',
            actions: [
              {type: 'sendFormAll__isCompressed__isOnlyChange__isWithRequired'},
              {type: 'closeModal__isAll__isWithBase'},
            ],
          },
          {
            system_name: 'button_update',
            title: 'Сохранить и продолжить редактирование',
            type: 'button',
            actions: [
              {type: 'sendFormAll__isCompressed__isOnlyChange__isWithRequired'},
            ],
          },
          {
            system_name: 'button_delete',
            type: 'button',
            title: 'Удалить',
            typeSettings: {
              elementStatusModifier: 'danger',
            },
            getValue: (item) => '',
            actions: [
              {
                type: 'showModal',
                form: {
                  system_name: 'modal_delete',
                  title: 'Удалить?',
                  type: 'form',
                  items: [
                    {
                      system_name: 'row',
                      type: 'row',
                      items: [
                        {
                          system_name: 'button_cancel',
                          type: 'button',
                          title: 'Отмена',
                          action: {
                            type: 'closeModal',
                          },
                        },
                        {
                          system_name: 'button_accept',
                          type: 'button',
                          title: 'Удалить',
                          typeSettings: {
                            elementStatusModifier: 'danger',
                          },
                          actions: [
                            {
                              // type: 'sendFormAll__isCompressed__isOnlyChange__isWithRequired',
                              type: 'sendFormAll__isJoin__isCompressed__isWithRequired',
                            },
                            {
                              type: 'closeModal__isAll__isWithBase',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    };
  }

  get Status() {
    return this.status;
  }
  set Status(value) {
    this.status = value;
  }

  get BeforeStruct() {
    if (this.status == null) return [];
    return this.struct[this.status].before;
  }

  get AfterStruct() {
    if (this.status == null) return [];
    return this.struct[this.status].after;
  }
}
