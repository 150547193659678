export default class TranslationsAdmin {
  constructor(baseUrl) {
    this.name = 'localization/translations';
    this.url = baseUrl + this.name;
  }

  get ModelName() {
    return this.name;
  }

  get Url() {
    return this.url;
  }

  get TableStruct() {
    return [
      {
        system_name: 'id',
        title: 'id',
        content_align: 'right',
        getValue: (item) => (item.id ? item.id : ''),
      },
      {
        system_name: 'msgid',
        title: 'Ключ',
        content_align: 'right',
        getValue: (item) => (item.msgid ? item.msgid : ''),
      },
      {
        system_name: 'msgstr',
        title: 'Перевод',
        content_align: 'right',
        getValue: (item) => (item.msgstr ? item.msgstr : ''),
      },
      {
        system_name: 'language_id',
        title: 'Язык',
        content_align: 'right',
        getValue: (item) => (item.language_id ? item.language_id : ''),
      },
    ];
  }

  get FormStruct() {
    return [
      {
        system_name: 'msgid',
        title: 'Ключ',
        getValue: (item) => (item.msgid ? item.msgid : ''),
      },
      {
        system_name: 'msgstr',
        title: 'Перевод',
        getValue: (item) => (item.msgstr ? item.msgstr : ''),
      },
      {
        system_name: 'language_id',
        title: 'Язык',
        getValue: (item) => (item.language_id ? item.language_id : ''),
      },
    ];
  }
}
