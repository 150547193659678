import {dataSource, ROOT_URL} from './dataSource.js';

const REQUEST_LINK = '/api/v1/__get_all_pages';

function profileDataSource(handler) {
  const ans = {};

  const curHandler = (data) => {
    if (data) {
      handler(data);
    } else {
      handler(null);
    }
  };

  ans.__proto__ = dataSource(ROOT_URL + REQUEST_LINK, curHandler); // родительский объект

  ans.makeRequest = () => {
    ans.__proto__.method = 'GET';

    // eslint-disable-next-line no-unused-vars
    ans.__proto__.makeRequest();
  };

  ans.makeRequest();
  return ans;
}

export default profileDataSource;
