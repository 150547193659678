import {ref} from 'vue';

export const dataSourceHook = (dataSourceNames) => {
  const curResources = ref({});
  const resourceHandlers = ref({});

  const newExternalData = (newData, compName, idx) => {
    // console.log(newData, compName);
    // this.$store.commit('user_obj_mut', newData);
  };

  const makeRequest = () => {
    let resourceName = dataSourceNames.value[0];

    let sendArgs = [];

    if (arguments.length > 0) {
      // eslint-disable-next-line prefer-rest-params
      resourceName = arguments[0];
      sendArgs = Array.prototype.slice.call(arguments, 1);
    }

    curResources.value[resourceName].makeRequest(...sendArgs);
  };

  const dataSources = require.context(
      // Относительный путь до каталога компонентов
      '../project_management/dataSources',
      // Обрабатывать или нет подкаталоги
      true,
      // Регулярное выражение для определения файлов базовых компонентов
      /[A-z]\w+\.(js)$/,
  );

  const findNames = dataSourceNames.value;

  if (!findNames) {
    return;
  }

  dataSources.keys().forEach((fileName) => {
    // Получение конфигурации компонента
    const componentConfig = dataSources(fileName);

    let compName = fileName.split('/');
    for (let i = compName.length - 1; i > -1; i--) {
      if (compName[i] === '.') {
        compName.splice(i, 1);
      }

      compName[i] = compName[i].replace(/\.\w+$/, '');
    }
    compName = compName.join('-');

    if (findNames.includes(compName)) {
      resourceHandlers.value[compName] = (newData, idx) => {
        newExternalData(newData, compName, idx);
      };
      curResources.value[compName] = componentConfig.default(resourceHandlers.value[compName]);
    }
  });

  return {
    newExternalData,
    makeRequest,
    curResources,
    resourceHandlers,
  };
};
