<template>
  <BarLayout :direct="(this.get_is_mobile_info) ? 'vertical' : 'horizontal'"
             gap="10"
             class="pickers-wrapper">

    <DatePicker :restrictions_start="range_restrictions_start"
                :restrictions_end="range_restrictions_end"
                :init_date="range_init_start"
                :top_label="this.loc('Начало')"
                @select_changed="range_change_first"
    />

    <SeparatorComponent :size="1" :separatorType="(!this.get_is_mobile_info) ? 'vertical' : 'horizontal'"/>

    <DatePicker :restrictions_start="range_restrictions_start"
                :restrictions_end="range_restrictions_end"
                :init_date="range_init_end"
                :top_label="this.loc('Конец')"
                @select_changed="range_change_second"
    />
  </BarLayout>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import DatePicker from './DatePicker';
import SeparatorComponent from './Separator';
import BarLayout from './BarLayout';
import ButtonComponent from './Button';

export default builder({
  name: 'DataRangePicker',
  components: {DatePicker, SeparatorComponent, BarLayout, ButtonComponent},
  props: {
    range_restrictions_start: {
      type: Date,
      default: new Date(-8640000000000000),
    },
    range_restrictions_end: {
      type: Date,
      default: new Date(8640000000000000),
    },
    range_init_start: {
      type: Date,
      default: new Date(),
    },
    range_init_end: {
      type: Date,
      default: new Date(),
    },
  },
  emits: ['range_changed', 'range_changed_by_click'],
  computed: {
    get_selected_range() {
      return {
        'from': this.date_first,
        'to': this.date_second,
      };
    },
  },
  data: function() {
    return {
      date_first: this.range_init_start,
      date_second: this.range_init_end,
    };
  },
  methods: {
    range_change_first(date) {
      this.date_first=date;
      this.$emit('range_changed', this.get_selected_range);
    },
    range_change_second(date) {
      this.date_second=date;
      this.$emit('range_changed', this.get_selected_range);
    },
    range_change_by_click() {
      this.$emit('range_changed_by_click', this.get_selected_range);
    },

  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

</style>
