import loaderAndModalsControllerHook from '@/assets/v1/js/hooks/loaderAndModalsControllerHook';

const simpleValidatorHook = () => {
  const {alertWarning} = loaderAndModalsControllerHook();

  const objIsValid = (obj) => {
    for (const key in obj) {
      if ((obj[key] == null) || (obj[key] === '') || (obj[key]['type'] === 'invalid')) {
        alertWarning('Проверяйте поля');
        return false;
      }
    }
    return true;
  };

  return {
    objIsValid,
  };
};

export default simpleValidatorHook;
