<template>
  <div class="report-preview">
    <linkTo :href="this.loc_data.url" :newTab="this.loc_data.open_new_tab === true" class="title-block">
      <LabelComponent class="title-block-text"
                      label_type='h3'
                      :label_text="this.loc(loc_data.name)"/>

      <IconComponent type="svg" name="caret-circle-down" size="14" class="title-block-icon"/>
    </linkTo>
    <NonResizableOnOverflow class="report-preview-content">
        <div class="content-slider">
          <div class="content">
            <component
                :is="this.loc_data.preview_template.template_type"
                :template_info="this.loc_data.preview_template"/>
          </div>
        </div>
    </NonResizableOnOverflow>
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import NonResizableOnOverflow from '../core/NonResizableOnOverflow';
import LabelComponent from '../unit/Label';
import IconComponent from '../unit/Icon';

export default builder({
  components: {
    IconComponent,
    LabelComponent,
    NonResizableOnOverflow,
  },
  props: ['loc_data'],
});


</script>

<style lang="less">

@import url('../../assets/v1/less/base.less');

@preview__content__background: @white;
@preview__title__text__color: @dark;
@preview__hover__title__text__color: @light-green;
@preview__hover__title__icon__color: @light-green;

.report-preview{
  .flex(column, flex-start, flex-start);
  position: relative;
  height: 300px;
  max-height: 300px;
  overflow: hidden;


  .report-preview-content{
    background: @preview__content__background;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(46, 50, 45, 0.05);
    margin-top: 10px;
  }

  .title-block{
    .flex(row, flex-start, center);
    max-width: 100%;
    color: @preview__title__text__color;
    transition: color 0.2s;

    &:hover{
      color: @preview__hover__title__text__color;
    }

    .title-block-text{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title-block-icon{
      .set_icon_color(@preview__hover__title__icon__color);
      transform: rotate(-90deg);
      margin-left: 8px;
    }
  }

  .wrapper{
    margin-top: 31px;
    border-radius: 10px 10px 0 0;

  }
}

//@media screen and (max-width: @transition-threshold-1) {
//  .report-preview{
//    min-width: 100%;
//    max-width: 100%;
//  }
//}
</style>
