<template>
  <UniInputFieldsPainter
        v-if="this.getDrawData != null"
        :input_fields="this.getDrawData"
        :download_additional_objects_src="this.getSrcData.download_additional_objects_src"
        @onFormFilled="this.formFilled"
      />
  <!-- <LoadingFrame v-else /> -->
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
// import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';
import UniInputFieldsPainter from '@/uni_page_components/uni_input_fields_painter';
// import LoadingFrame from '../../components/LoadingFrame';

export default builder({
  ignoreHistory: true,
  components: {
    UniInputFieldsPainter,
    // LoadingFrame,
  },
  data: () => ({
    // rawInputFields: null,
  }),
  // mixins: [uniViewMixin],
  mounted() {
    // this.rawInputFields = this.draw_data[0].input_fields;
  },
  computed: {
    getDrawInfo() {
      return this.cur_view.draw_data[0].next_type_data;
    },
    getSrcData() {
      return this.cur_view.draw_data[0].next_level[0];
    },
    getDrawData() {
      const baseFields = this.getSrcData.input_fields;

      const filteredFields = {};

      Object.keys(baseFields).forEach((key) => {
        if (baseFields[key].type == 'row') {
          Object.keys(baseFields[key].fields).forEach((rowKey) => {
            if (baseFields[key].fields[rowKey].type != 'button' ||
            (baseFields[key].fields[rowKey].require_inputs != null &&
             baseFields[key].fields[rowKey].require_inputs.length > 0)) {
              return;
            }

            if (filteredFields[key] === undefined) {
              filteredFields[key] = {
                'type': 'row',
                'fields': {},
              };
            }

            filteredFields[key].fields[rowKey] = baseFields[key].fields[rowKey];
          });
        }

        if (baseFields[key].type == 'button' &&
         (baseFields[key].require_inputs == null ||
          baseFields[key].require_inputs.length < 1)) {
          filteredFields[key] = baseFields[key];
        }
      });

      console.log(filteredFields);

      return filteredFields;
    },
  },
  watch: {

  },
  methods: {
    formFilled(filledForm) {
      this.sendFormToServer(filledForm);
    },
    sendFormToServer(formData) {
      const clearLines = true;

      if (clearLines) {
        Object.keys(formData).forEach((element) => {
          if (Array.isArray(formData[element])) {
            formData[element] = formData[element].filter((item) => item != null);
          }
        });
      }

      const sendSrcName = this.getSrcData.send_form_source;

      this.$store.commit('set_full_screen_loading', true);

      const selectedGuids = this.selected_items.map((elem) => {
        return elem.next_level[0].GUID;
      });

      const options={
        url: `${ROOT_URL}/api/v1/src/${sendSrcName}`,
        method: 'POST',
        body: {
          params: {
            'tasks': selectedGuids,
            'input_fields': formData,
          },
        },
      };
      dataRequester(options)
          .then((response) => {
            console.log(response);
            window.location.reload();
          })
          .catch((errData)=> {
            console.log(errData);
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
  emits: {
    // next_select: null,
    // close_view: null,
  },
  props: {
    cur_view: {default: null},
    action_data: {default: null},
    selected_items: {default: null},
  },
});
</script>

<style  lang="less">

</style>
