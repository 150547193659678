<template>
  <div class="navigation-sliders">
    <div class="slider-tiles-wrapper" v-if="get_list_reports_preview.length !== 0">
      <NavigationReportPreview v-for="(item, i) in get_list_reports_preview"
                     :key="i"
                     :loc_data="item"
                     class="slider-tile"/>
    </div>

    <div class="slider-tiles-wrapper" v-if="get_list_reports.length !== 0">
      <NavigationReportTile v-for="(item, i) in get_list_reports"
                  :key="i"
                  :loc_data="item"
                  class="slider-tile"/>
    </div>

    <div class="tiles-not-found" v-if="get_list_reports_preview.length === 0 && get_list_reports.length === 0">
      <LabelComponent label_type="body/large/regular 16" class="tiles-not-found-text">
        {{ this.loc('Нет доступных категорий для данного пользователя') }}
      </LabelComponent>
    </div>
  </div>
</template>

<script type="text/javascript">

import builder from '../../assets/v1/js/builder';
import NavigationReportPreview from './NavigationReportPreview';
import NavigationReportTile from './NavigationReportTile';
import LabelComponent from '../unit/Label';

export default builder({
  components: {
    LabelComponent,
    NavigationReportPreview,
    NavigationReportTile,
  },
  computed: {
    get_inner_structure() {
      const currentPage = this.$store.state.navigate_settings.current_page_info;
      return this.$store.getters.getSortedLayer(currentPage['id']);
    },
    get_list_reports() {
      return this.get_inner_structure.filter(function(item) {
        return !item.preview_template;
      });
    },
    get_list_reports_preview() {
      return this.get_inner_structure.filter(function(item) {
        return item.preview_template;
      });
    },
  },
  mounted() {
    const element = document.getElementById('content-slider');
    if (element) {
      element.classList.remove('content-wrapper-type-1__slider__content__back');
      element.classList.remove('content-wrapper-type-2__page__content__slider__back');
    }
  },
  props: ['structure'],
});


</script>

<style lang="less">

@import url('../../assets/v1/less/base.less');

//.page .content-block-type2 .content-slider{
//  background: transparent;
//  box-shadow: none;
//}

@gap_size: 15px;
@tiles-not-found__background-color: @white;

.navigation-sliders{
  .flex(column, flex-start, stretch);
  & > *{
    margin-bottom: 15px;
  }

  & > *:last-child{
    margin-bottom: 0;
  }
  padding-bottom: 20px;
}

.slider-tiles-wrapper {
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}

.tiles-not-found{
  background: @tiles-not-found__background-color;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(46, 50, 45, 0.03);
}

@media screen and (max-width: @transition-threshold-3) {
  .slider-tiles-wrapper{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: @transition-threshold-2) {
  .slider-tiles-wrapper{
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .scroll-wrapper{
    .slider{
      //margin: @margin-vertical-800 (@margin-horizontal-800 - 15px);
    }
  }
}

</style>
