<template>
  <table class="grid-root" :style="{'grid-template-columns': this.getColumnSizes}">
    <thead class="ignore-for_grid">
      <tr class="ignore-for_grid" v-for="sliceNum in this.getSlicesCount" :key="sliceNum">
        <th v-for="(column, name) in this.getSliceColumns(sliceNum-1)" :key="name"
            class="slice-table__header-cell">
          <LabelComponent label_type='caption/small/medium 10'
                          :label_text="this.loc(column.label)"/>
        </th>
      </tr>
    </thead>
    <tbody class="ignore-for_grid">
      <div v-for="row in this.getSlices" :key="row" class="row" style="display: contents;">
        <tr v-for="(slice, idx) in row" :key="idx"
            class="ignore-for_grid slice"
            :class="{'clickable': this.sliceIsClickable(slice)}"
            @click="this.sliceSelected(slice)">
          <td v-for="(column, name) in this.getSliceColumns(idx)" :key="name"
              class="slice-table__data-cell" :class="this.getColorClassForSlice(slice, name)">
            <LabelComponent :is_monospace="true"
                            :digits_precision="2"
                            :label_text="slice != null ? slice[name].value : '-'"
            />
          </td>
        </tr>
      </div>
    </tbody>
  </table>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import LabelComponent from '@/components/unit/Label';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';

function getSliceInfoFromObject(objData, columnsCount) {
  for (let curNum = 0; curNum < columnsCount; curNum++) {
    if (objData['columns_' + curNum] !== undefined) {
      const ans = {};
      Object.assign(ans, objData['columns_' + curNum]);
      ans.__slice_num__ = curNum;
      ans.__orig__ = objData;
      return ans;
    }
  }
}

export default builder({
  components: {
    LabelComponent,
  },
  data: () => ({
  }),
  mixins: [uniViewMixin],
  computed: {
    getColumnSizes() {
      let ans = '';

      for (let curNum = 0; curNum < this.getColumnsCount; curNum++) {
        ans += 'minmax(150px, 1.67fr) ';
      }

      return ans;
    },
    getAllColumns() {
      const ans = [];
      for (let curNum = 0; curNum < this.getSlicesCount; curNum++) {
        const curColumns = this.draw_info['columns_'+curNum];

        // eslint-disable-next-line guard-for-in
        for (const curColumnKey in curColumns) {
          const curColumn = {};
          Object.assign(curColumn, curColumns[curColumnKey]);
          curColumn.__key__ = curColumnKey;
          ans.push(curColumn);
        }
      }
      return ans;
    },
    getSlicesCount() {
      let ans = 0;
      for (const curKey in this.draw_info) {
        if (curKey.startsWith('columns_')) {
          ans++;
        }
      }
      return ans;
    },
    getColumnsCount() {
      let ans = 0;
      for (let curSlice = 0; curSlice < this.getSlicesCount; curSlice++) {
        ans+= Object.keys(this.draw_info['columns_'+curSlice]).length;
      }
      return ans;
    },
    getSlices() {
      const priorityObjects = {};

      // eslint-disable-next-line guard-for-in
      for (const idx in this.draw_data) {
        // const curLine = this.draw_data[idx];

        this.draw_data[idx].__orig_idx__ = idx;

        const column = getSliceInfoFromObject(this.draw_data[idx], this.getSlicesCount);
        if (priorityObjects[column.__priority__] === undefined) {
          priorityObjects[column.__priority__] = {
            priority: column.__priority__,
            slices: [],
          };
          for (let curSlice = 0; curSlice < this.getSlicesCount; curSlice++) {
            priorityObjects[column.__priority__].slices.push(null);
          }
        }

        priorityObjects[column.__priority__].slices[column.__slice_num__] = column;
      }

      // Create items array
      const items = Object.keys(priorityObjects).map(function(key) {
        return [priorityObjects[key].priority, priorityObjects[key].slices];
      });

      // Sort the array based on the second element
      items.sort(function(first, second) {
        return first[0] - second[0];
      });

      return items.map((item) => item[1]);
    },
  },
  watch: {

  },
  methods: {
    getColorClassForSlice(slice, columnName) {
      const ans = {};

      if (slice != null) {
        const workColor = slice[columnName].color ? slice[columnName].color : slice.__orig__.color;

        if (workColor) {
          ans[workColor + '__color'] = true;
        }
      }

      return ans;
    },
    getSliceColumns(sliceNum) {
      return this.draw_info['columns_' + sliceNum];
    },
    sliceIsClickable(slice) {
      if (slice == null) {
        return false;
      }

      return slice.__orig__.next_type_data != null;
    },
    getObjectFromQueryIdx(queryIdx) {
      const slice = getSliceInfoFromObject(this.draw_data[queryIdx], this.getSlicesCount);

      return {
        modalCaption: slice[Object.keys(slice)[0]].value,
        next_level: slice.__orig__.next_level,
        next_type_data: slice.__orig__.next_type_data,
      };
    },
    sliceSelected(slice) {
      if (!this.sliceIsClickable(slice)) {
        return;
      }
      // console.log('slice clicked:', slice);

      const modalText = slice[Object.keys(slice)[0]].value;

      this.$emit('next_select', this.draw_index, {
        modalCaption: modalText,
        next_level: slice.__orig__.next_level,
        next_type_data: slice.__orig__.next_type_data,
      }, slice.__orig__.__orig_idx__);
    },
  },
  emits: {
    next_select: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">
  @import url('../../assets/v1/less/base.less');
  @row__hover__background-color: @light-green-opacity-10;
  @slice__hover__background-color: @status-color-3;
  @cell__border__color: @super-light;
  @cell__text__color: @light-gray;
  @header__background-color: @white;

  @supports (display: grid) {
    .ignore-for_grid {
      display: contents;
    }

    .grid-root {
      display: grid;
      border-collapse: collapse;
      min-width: 100%;
    }
  }

  .row:hover td {
    background: @row__hover__background-color;
  }

  .clickable:hover td {
    background: @slice__hover__background-color;
    cursor: pointer;
  }

  .slice-table__header-cell {
    text-align: left;
    border-top: 1px solid @cell__border__color;
    border-bottom: 1px solid @cell__border__color;
    min-height: 29px;
    padding: 7px;
    color: @cell__text__color;
    background-color: @header__background-color;

    position: sticky;
    top: 0;
    z-index: 2;
  }

  .slice-table__data-cell {
    z-index: 1;
    border-top: 1px solid @cell__border__color;
    padding: 14px 7px;
    position: relative;
  }
</style>
