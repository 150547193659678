<template>
  <div class='mtom-wrapper'>
    <SeparatorComponent />
    <LabelComponent :is_monospace="true"
                    :label_text="this.formInitStruct.label"
                    style="margin: 7px; margin-left: 7px;"
    />
    <TableComponent
      :table-data="this.getTableData"
      :table-data-formatted="this.getTableData"
      :table-columns="this.getTableColumns"
      :is-xlsx-exporting="true"
      :is-selectable="false"
      :no-data-text="null"
      :withTotal="false"
    >
      <template v-slot="{row_id, column_id}">
        <ButtonComponent v-if="this.getTableColumns[column_id].system_name == '__remove_col__'"
                      :iconUrl="'/icons/close.svg'"
                      iconType="svg"
                      :elementType="'default'"
                      class="remove-sub-obj-button"
                      @click_to_button="this.removeElement(row_id)"
        />
        <!-- <LabelComponent v-else-if="this.getTableColumns[column_id].itype == 'link'" class="inline-form__field"
                        :label_text="this.getTableColumns[column_id].title"
                        /> -->
        <ForeignObject v-else-if="this.getTableColumns[column_id].itype == 'link'"
                   :is_extendable="false"
                   :is_filterable="true"
                   :is_multi_select="false"
                   :label_text="this.getTableColumns[column_id].title"
                   :object_type="this.getTableColumns[column_id].link_object"
                   :object_type_view="this.getTableColumns[column_id].link_object_view"
                   :selected_items="this.getTableData[row_id][column_id].val"
                   :input_field_status="this.getFieldStatus(row_id, column_id)"
                   :is_favoritable="true"
                   @input_change="(data) => this.setFormValue(row_id, column_id, data)"
        />
        <CheckBox v-else-if="this.getTableColumns[column_id].itype == 'boolean'" class="inline-form__field"
                    :label_text_left="[this.getTableColumns[column_id].title, undefined][1]"
                    :init_value="this.getTableData[row_id][column_id].val"
                    :check_box_field_status="this.getFieldStatus(row_id, column_id)"
                    :error_text="this.getTableData[row_id][column_id].error"
                    @input_check="(data) => this.setFormValue(row_id, column_id, data)"
                    />
        <InputField v-else class="inline-form__field"
                    :label_text="this.getTableColumns[column_id].title"
                    :set_values="this.getTableData[row_id][column_id].val"
                    :input_field_status="this.getFieldStatus(row_id, column_id)"
                    :error_text="this.getTableData[row_id][column_id].error"
                    autocomplete="off"
                    @input_change="(data) => this.setFormValue(row_id, column_id, data)"
                    :type="this.getTableColumns[column_id].itype"
                    />
      </template>
    </TableComponent>
    <ButtonComponent
                      :label="'Добавить элемент'"
                      :elementType="'default'"
                      class="add-new-sub-obj-button"
                      @click_to_button="this.addNewElement()"
    />
    <SeparatorComponent />
  </div>
</template>

<script type="text/javascript">
import TableComponent from '@/components/unit/Table';
import LabelComponent from '@/components/unit/Label';
import ButtonComponent from '@/components/unit/Button';
import SeparatorComponent from '@/components/unit/Separator';

import InputField from '@/components/unit/InputField';
// import DropDownInput from '@/units/forms/DropDownInput';
import CheckBox from '@/components/unit/CheckBox';
import ForeignObject from '@/components/unit/ForeignObject';


export default ({
  components: {
    TableComponent,
    LabelComponent,
    ButtonComponent,
    SeparatorComponent,
    InputField,
    // DropDownInput,
    CheckBox,
    ForeignObject,
  },
  emits: [
    'input_change',
  ],
  data: () => ({
    drawObjectsValues: [],
  }),
  mounted() {
    this.buildFromInit();
  },
  methods: {
    buildFromInit() {
      this.drawObjectsValues = this.formInitStruct.val || [];
    },
    getFieldStatus(rowId, colId) {
      return this.getTableData[rowId][colId].error != null ? 'error' : undefined;
    },
    setFormValue(rowId, colId, value) {
      this.getTableData[rowId][colId].val = value;
      this.callFormsChanged();
    },
    callFormsChanged() {
      this.$emit(
          'input_change',
          this.drawObjectsValues,
      );
    },
    addNewElement() {
      const newElem = {};

      Object.keys(this.formInitStruct.content_form).forEach((field) => {
        const newField = {};
        const curField = this.formInitStruct.content_form[field];
        Object.keys(curField).forEach((attr) => newField[attr] = curField[attr]);
        newElem[field] = newField;
      });

      this.drawObjectsValues.push(newElem);

      // console.log('this.drawObjectsValues', this.drawObjectsValues);

      this.callFormsChanged();
    },
    removeElement(removeIndex) {
      this.drawObjectsValues.splice(removeIndex, 1);

      this.callFormsChanged();
    },
  },
  computed: {
    getDrawValues() {
      return Object.fromEntries(Object.keys(this.formInitStruct.content_form).filter(
          (key) => !this.formInitStruct.content_form[key].hidden,
      ).map(
          (key) => [key, this.formInitStruct.content_form[key]],
      ));
    },
    getTableColumns() {
      const drawFields = this.getDrawValues;

      drawFields.__remove_col__ = {
        'label': 'Удалить',
      };

      const ans = Object.keys(drawFields).map((field, index) =>{
        return {
          'id': index,
          'title': drawFields[field].label,
          'itype': drawFields[field].itype,
          'link_object': drawFields[field].link_object,
          'link_object_view': drawFields[field].link_object_view,
          // 'ddVals': drawFields[field].ddVals,
          'system_name': field,
          // 'content_align': this.checkColumnIsNumber(item.__key__) ? 'right' : 'left',
          'width': '300px',
          // 'is_delete_if_empty': item.is_delete_if_empty,
          // 'is_with_sum': item.is_with_sum === true,
          // 'is_files': item.is_files === true,
          // 'resource': item.resource,
          'self_clickable': false,
          // 'number_round': item.number_round,
          // '__key__': item.__key__,
          // 'data': (item) => item['value'],
        };
      });

      // console.log('drawFields', drawFields, ans);

      return ans;
    },
    getTableData() {
      const allColumns = this.getDrawValues;

      const curAns = this.drawObjectsValues.map((drawObj, index) => {
        const ans = Object.assign(
            {
              id: index,
              // text_color: item.color,
              // icon: item.icon,
              // background_color: item.color_background,
              is_total: false,
              // is_remove_column: column.system_name == '__remove_col__',
              // mtomUid: drawObj['uid'],
            },
            Object.keys(allColumns).map((columnKey) => drawObj[Object.keys(drawObj).filter((key) => key == columnKey)[0]]),
        );

        return ans;
      });

      // console.log('curAns', curAns);

      return curAns;
    },
  },
  watch: {
    formInitStruct() {
      this.buildFromInit();
    },
  },
  props: {
    formInitStruct: {
      required: true,
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.mtom-wrapper {
  // padding: 14px;

  .table-data__header-row {
    position: static;
  }

  .table-data__main-cell {
    position: static;
  }

  .add-new-sub-obj-button {
    display: flex;
    margin: 9px;
  }
}
</style>
