<template>
  <BasePage :is_with_background="false">
    <LabelComponent label_type='button/large/regular 14' :label_text="this.loc('Данная система отключена по решению руководства компании')"/>
    <ListView
        v-if="false"
        :is_loading="this.data_loading"
        :search_hints="this.get_search_hints"
        :search_data="this.current_data_calc"
        @click_by_list_item="this.click_by_card">

      <template v-slot:additional_settings>
        <div class="card">
          <ButtonDateRange :init_range="this.date_range"
                           :label_text="this.loc('Задать период выборки данных')"
                           componentType="input_field"
                           @date_range_changed="this.set_date_range"
                           />

          <div class="button-panel">
            <ButtonComponent class="modal-button"
                             :label="this.loc('Применить')"
                             @click_to_button="this.renew_data()"/>

            <ButtonComponent :label="this.loc('Сбросить')"
                             elementStatusModifier="danger"
                             @click_to_button="this.set_defaults"/>
          </div>
        </div>
      </template>

      <template v-slot="{item}">
        <div class="card__header">
          <LabelComponent class="card__header__title"
                          label_type='button/large/regular 14'
                          :label_text="item['Document_ID'] || ''"/>

          <!-- <LabelComponent :label_type="(item['Tracking_Status']) ? 'body/large/regular 12':'button/large/regular 14'"
                          :label_color="
                              (item['Tracking_Status'] === 'Отслеживается') ? 'status-color-3':
                              (item['Tracking_Status'] === 'Запрос отклонен') ? 'status-color-5':
                              (item['Tracking_Status'] === 'Запрос отправлен') ? 'status-color-1': undefined"
                          :label_text="item['Tracking_Status'] || ''"/> -->
        </div>

        <SeparatorComponent v-if="item['Driver'] || item['Driver_Phone_Non_Validation'] ||
                                  item['Tracking_Status_Refuse_Reason']"/>

        <LabelComponent v-if="item['Driver']"
                        label_type='button/large/regular 14'
                        :label_text="item['Driver'] || ''"/>

        <LabelComponent v-if="item['Driver_Phone_Non_Validation']"
                        label_type='button/large/regular 14'
                        :label_text="item['Driver_Phone'] || item['Driver_Phone_Non_Validation'] || ''"/>

        <!-- <LabelComponent v-if="item['Tracking_Status_Refuse_Reason']" class="error_text"
                        label_type='body/large/regular 12'
                        :label_text="item['Tracking_Status_Refuse_Reason']"/> -->
      </template>
    </ListView>

    <template v-slot:modal>
      <ModalViewConductor ref="modalViewConductor">
        <template v-slot="{level}">
          <ModalView v-if="level === 1"
                     :open_level="1"
                     type_modal="internal"
                     :caption="`${this.loc('Документ №')} ${this.clicked_item_data['Document_ID']}`"
                     @click_close="this.closed_modal($event)">

            <BarLayout direct="vertical" gap="12">
              <LabelComponent v-if="this.tracking_form.success_text" class="success_text"
                              label_type='body/large/regular 12'
                              :label_text="this.tracking_form.success_text"/>

              <LabelComponent v-if="this.tracking_form.error_text" class="error_text"
                              label_type='body/large/regular 12'
                              :label_text="this.tracking_form.error_text"/>

              <NamedField :label="this.loc('ФИО')">
                <LabelComponent label_type='button/large/regular 14'
                                :label_text="this.clicked_item_data['Driver']"/>
              </NamedField>

              <SeparatorComponent/>

              <NamedField :label="this.loc('Статус')">
                <LabelComponent label_type='button/large/regular 14'
                                :label_text="this.loc(this.current_card_track_status)"/>
              </NamedField>

              <InputField type="tel"
                          ref="number_input"
                          :label_text="this.loc('Номер телефона')"
                          input_mask="+9 (999) 999-99-99"
                          @input_change="this.tracking_form__phone_input_changed"
                          :set_values="this.clicked_item_data['Driver_Phone'] ||
                              this.clicked_item_data['Driver_Phone_Non_Validation'].replace(/\D/g, '')"/>

              <CheckBox :is_toggle="true"
                        :init_value="this.is_old_phone"
                        :label_text_left="this.loc('Кнопочный телефон')"
                        @input_check="(value) => {this.is_old_phone = value;}">
              </CheckBox>

              <InputField type="textarea"
                          :is_all_time_open="true"
                          @input_change="this.error_info_form__error_text__changed"
                          :label_text="this.loc('Сообщение об ошибке')"/>

              <InputField ref="error_info_files"
                          type="file"
                          :is_all_time_open="true"
                          :label_text="this.loc('Файлы')"/>

              <ButtonComponent :label="this.loc('Отслеживать')"
                               :elementStatusModifier="(!this.tracking_form.phone_number_entered)
                                      ? 'disabled' : undefined"
                               :elementInLoading="this.tracking_form.is_button_loading"
                               @click_to_button="this.start_tracking_ttn()"/>

              <ButtonComponent :label="this.loc('Отправить сообщение об ошибке')"
                               :elementStatusModifier="(!this['error_info_form'].error_text_send)
                                    ? 'disabled' : 'danger'"
                               :elementInLoading="this['error_info_form'].is_button_loading"
                               @click_to_button="this.send_error_message()"/>
            </BarLayout>
          </ModalView>
        </template>
      </ModalViewConductor>
    </template>
  </BasePage>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import BasePage from '../../components/navigation/BasePage';
import SeparatorComponent from '../../components/unit/Separator';
import BarLayout from '../../components/unit/BarLayout';
import LabelComponent from '../../components/unit/Label';
import LoadingFrame from '../../components/LoadingFrame';
import ModalView from '../../components/ModalView';
import InputField from '../../components/unit/InputField';
import CheckBox from '../../components/unit/CheckBox';
import ButtonComponent from '../../components/unit/Button';
import ListView from '../../components/unit/ListView';
import ModalViewConductor from '../../components/navigation/ModalViewConductor';
import NamedField from '../../components/unit/NamedField';
import {dataRequester, ROOT_URL} from '../../assets/v1/js/project_management/DataRequester';
import ButtonDateRange from '../../components/unit/ButtonDateRange';

export default builder({
  name: 'AllDriversLocation',
  components: {
    BasePage, BarLayout, LoadingFrame, SeparatorComponent,
    LabelComponent, ModalView, InputField, ButtonComponent,
    ModalViewConductor, ListView, NamedField, CheckBox, ButtonDateRange,
  },
  data: function() {
    return {
      data_loading: true,
      current_data: [],
      current_data_calc: [],
      clicked_item_data: undefined,
      date_range: undefined,
      current_card_track_status: undefined,
      current_await_timer: undefined,
      is_old_phone: false,
      // inputField_phone_number: undefined,
      tracking_form: {
        phone_number_entered: '',
        error_text: undefined,
        success_text: undefined,
        is_button_loading: false,
      },
      error_info_form: {
        error_text_send: undefined,
        error_text: undefined,
        success_text: '',
        is_button_loading: false,
      },
    };
  },
  created() {
    // date_range получает значения по умолчанию
    this.set_defaults();
  },
  mounted() {
    return false;
    this.renew_data();
  },
  computed: {
    get_search_hints() {
      return [
        /* {
          value: 'Отслеживается',
          color: 'status-color-3',
        },
        {
          value: 'Запрос отклонен',
          color: 'status-color-5',
        },
        {
          value: 'Запрос отправлен',
          color: 'status-color-1',
        }, */
      ];
    },
  },
  methods: {
    set_defaults() {
      this.date_range={
        from: new Date().shiftDateFromCurrent(0, 0, -3),
        to: new Date().shiftDateFromCurrent(0, 1, 0),
      };
    },
    set_date_range(date) {
      this.date_range=date;
    },

    renew_data() {
      this.data_loading = true;
      const period = {
        from: this.date_range['from'].toISOString(),
        to: this.date_range['to'].toISOString(),
      };

      const inputFields = {
        'param_0': {
          'input_type': 'date_period',
          'name': 'Период',
          'serializer': 'SettingsParameterValue',
          'system_name': 'Период',
          'userSettingID': '31b55079-928a-4c26-b49d-b089cf4563be',
          'value': {
            'end_date': period.to,
            'start_date': period.from,
          },
        },
      };

      const options={
        url: `${ROOT_URL}/api/v1/src/Internal__Logistic__GetOptimisedWaybillsAll`,
        method: 'POST',
        body: {
          params: {
            period: period,
            input_fields: inputFields,
          },
        },
      };
      dataRequester(options)
          .then((data)=>{
            this.compute_data(data.result.data);
          })
          .catch((errorObj)=>{})
          .finally( ()=>{
            this.data_loading = false;
          });
    },
    compute_data(data) {
      if (data) this.current_data = data;
      this.data_loading = false;
      this.current_data_calc = this.current_data.map((item) => item);
      this.current_data.forEach((item) => {
        item.Document_ID_Num = parseInt(item['Document_ID']);
      });
      this.current_data.sort((itemA, itemB) => {
        const compare = (itemFirst, itemSecond) => {
          return (itemFirst > itemSecond) ? 1 : (itemFirst < itemSecond) ? -1 : 0;
        };

        const itemADriverPhone = itemA['Driver_Phone'] || itemA['Driver_Phone_Non_Validation'];
        const itemBDriverPhone = itemB['Driver_Phone'] || itemB['Driver_Phone_Non_Validation'];

        return compare(itemB['Tracking_Status'], itemA['Tracking_Status']) ||
            compare(!!itemB['Driver'], !!itemA['Driver']) ||
            compare(!!(itemBDriverPhone), !!(itemADriverPhone)) ||
            compare(itemB['Document_ID_Num'], itemA['Document_ID_Num']);
      });

      this.current_data_calc = this.current_data;
    },
    click_by_card(item) {
      this.clicked_item_data = item;
      this.$refs.modalViewConductor.up_level();
      this.updateCardTrackingState();
    },
    updateCardTrackingState() {
      this.current_card_track_status = 'Обновление...';
      this.current_await_timer = undefined;

      const data = {
        guid: this.clicked_item_data.Waybill_GUID,
      };
      const options={
        url: `${ROOT_URL}/api/v1/src/Internal__Tracking__GetTrackingStatus`,
        method: 'POST',
        body: {params: data}};
      dataRequester(options)
          .then((data)=>{
            if (data.status === 200) {
              switch (data.result.data) {
                case 'NO_REQUEST':
                  this.current_card_track_status = 'Не ставился на отслеживание';
                  break;
                case 'REFUSED':
                  this.current_card_track_status = 'Неудачная попытка постановки';
                  break;
                case 'TRACKING':
                  this.current_card_track_status = 'Отслеживание';
                  break;
                case 'AWAIT':
                  this.current_card_track_status = 'Ожидание подтверждения...';
                  this.current_await_timer = setTimeout(() => {
                    this.updateCardTrackingState();
                  }, 1000);
                  break;
                default:
                  this.current_card_track_status = 'Неопределено';
                  break;
              }
            } else {
              // обработка ошибок
              window.globalEvents.callEvent('new_message', [{
                messageType: 'error',
                messageData: {
                  message: 'нештатный ответ от сервера!'},
              }]);
              console.error('внимание, неожиданный ответ от сервера!');
            }
          })
          .catch((errorObj)=>{});
    },
    closed_modal() {
      this.is_old_phone = false;
      this.clicked_item_data = undefined;
      this.tracking_form.phone_number_entered = '';
      this.tracking_form.error_text = undefined;
      this.tracking_form.success_text = undefined;
      this.$refs.modalViewConductor.close_level();
      if (this.current_await_timer) {
        clearTimeout(this.current_await_timer);
      }
      this.current_await_timer = undefined;
    },
    start_tracking_ttn() {
      const data = {
        guid: this.clicked_item_data.Waybill_GUID,
        phone_number: this.get_current_entered_number(),
        is_old_phone: this.is_old_phone,
      };
      this.tracking_form.is_button_loading = true;
      const options={
        url: `${ROOT_URL}/api/v1/src/Internal__Tracking__StartTrackingDrivers`,
        method: 'POST',
        body: {params: data}};
      dataRequester(options)
          .then((data)=>{
            if (data.status === 200) {
              if (data.result.error) {
                window.globalEvents.callEvent('new_message', [{
                  messageType: 'error',
                  messageData: {
                    name: 'ExternalSysError',
                    message: data.result.data.description,
                  },
                }]);
              } else {
                window.globalEvents.callEvent('new_message', [{
                  messageType: 'success',
                  messageData: 'Трекинг задан',
                }]);
                this.updateCardTrackingState();
              }
            } else {
              // обработка ошибок
              window.globalEvents.callEvent('new_message', [{
                messageType: 'error',
                messageData: {
                  message: 'нештатный ответ от сервера!'},
              }]);
              console.error('внимание, неожиданный ответ от сервера!');
            }
          })
          .catch((errorObj)=>{})
          .finally( ()=>{
            this.tracking_form.is_button_loading = false;
          });
    },
    tracking_form__phone_input_changed(text) {
      this.tracking_form.phone_number_entered = text.length > 0 && (text.match(/_/g) || []).length === 0;
    },
    close_error_message_modal() {
      this.error_info_form.error_text_send = undefined;
      this.error_info_form.error_text = undefined;
      this.error_info_form.success_text = undefined;
      this.$refs.modalViewConductor.close_level();
    },
    error_info_form__error_text__changed(text) {
      this.error_info_form.error_text_send = text;
    },
    get_current_entered_number() {
      return this.$refs.number_input.$refs.input_field.value.replace(/\D/g, '');
    },
    send_error_message() {
      const sendData = {
        guid: this.clicked_item_data['Waybill_GUID'],
        phone_number: this.get_current_entered_number(),
        error_text: this.error_info_form.error_text_send,
        is_old_phone: this.is_old_phone,
      };
      const files = this.$refs.error_info_files.get_files();

      this.error_info_form.is_button_loading = true;
      const options={
        url: `${ROOT_URL}/api/v1/src/Internal__Tracking__SendErrorMessage`,
        method: 'POST',
        body: {
          params: sendData,
        },
        files: files};
      dataRequester(options)
          .then((data)=>{
            if (data.status === 200) {
              window.globalEvents.callEvent('new_message', [{
                messageType: 'success',
                messageData: 'Сообщение об ошибке успешно отправлено администратору',
              }]);
            } else {
              // обработка ошибок
              window.globalEvents.callEvent('new_message', [{
                messageType: 'error',
                messageData: {
                  message: 'нештатный ответ от сервера!'},
              }]);
              console.error('внимание, неожиданный ответ от сервера!');
            }
          })
          .catch((errorObj)=>{})
          .finally( ()=>{
            this.error_info_form.is_button_loading = false;
          });
    },
  },

});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.card{
  &__header{
    .flex(row, space-between, center);

    &__title__type {
      margin-right: 5px;
    }
  }

  &:hover {
    .card__header__title,
    .card__header__title__type{
      color: @green;
    }
  }
}

.error_text {
  color: @error;
}

.success_text {
  color: @green;
}

</style>
