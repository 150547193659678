const loaderAndModalsControllerHook = () => {
  const setLoading = (isLoading) => {
    if (isLoading) {
      window.globalEvents.callEvent('showLoader');
    } else {
      window.globalEvents.callEvent('closeLoader');
    }
  };

  const alertError = (message, description) => {
    window.globalEvents.callEvent('showModal', ['error', message, description]);
  };

  const alertWarning = (message, description) => {
    window.globalEvents.callEvent('showModal', ['warning', message, description]);
  };

  const alertDebug = (message, description) => {
    window.globalEvents.callEvent('showModal', ['debug', message, description]);
  };

  const alertInfo = (message, description) => {
    window.globalEvents.callEvent('showModal', ['info', message, description]);
  };

  return {
    setLoading,
    alertInfo,
    alertError,
    alertWarning,
    alertDebug,
  };
};

export default loaderAndModalsControllerHook;
