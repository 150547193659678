<template>
  <div class = "paginatedTable__container">
    <Table :table-columns = "this.table_columns"
           :table-columns-ordering = "this.table_columns_ordering"
           :table-data = "this.table_data"
           :is-loading = "this.is_loading"
           :selected-row-props = "this.selected_row_props"
           :is-selectable = "this.is_selectable"
           :is-compact-table = "this.is_compact_table"
           :is-dashed-table = "this.is_dashed_table"
           :padding = "this.padding"
           :no-data-text = "this.no_data_text"
           :hide-header = "this.hide_header"
           :default-order-column = "this.default_order_column"
           @selectChanged = "this.$emit('select_changed')"
           @selectChangedByClick = "this.selectChangedByClick"
           @orderingChanged="this.orderingChanged">

      <template v-slot = "{row_id, column_id}">
        <slot :row_id = "row_id" :column_id = "column_id"></slot>
      </template>
    </Table>
    <PaginationComponent v-model = "this.selectedPage"
                         :limit_page = "this.getPages"/>
  </div>
</template>


<script>
import Table from './Table.vue';
import PaginationComponent from './PaginationComponent.vue';

export default {
  components: {
    Table,
    PaginationComponent,
  },
  props: {
    max_rows: {
      required: false,
      type: Number,
      default: 1,
    },
    startPage: {
      required: false,
      type: Number,
      default: 1,
    },
    pages: {
      required: true,
      type: Number,
    },
    table_columns: {
      required: true,
      type: Array,
    },
    table_columns_ordering: {
      required: false,
      type: Array,
    },
    table_data: {
      required: true,
      type: Array,
    },
    is_loading: {
      type: Boolean,
      default: false,
    },
    selected_row_props: {
      type: Number,
      default: undefined,
    },
    is_selectable: {
      type: Boolean,
      default: false,
    },
    is_compact_table: {
      type: Boolean,
      default: false,
    },
    is_dashed_table: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      required: false,
    },
    no_data_text: {
      type: String,
      default: 'нет данных',
      required: false,
    },
    hide_header: {
      type: Boolean,
      default: false,
    },
    default_order_column: {
      type: Number,
      default: 0,
    },
  },
  emits: {
    select_changed: null,
    select_changed_by_click: null,
    pageChange: null,
    ordering_changed: null,
  },
  computed: {
    getTableData() {
      return (this.table_data) ? this.table_data : [];
    },
    getPages() {
      return this.pages ? this.pages : 10;
    },
    getStartPage() {
      return this.startPage ? this.startPage : 1;
    },

  },
  watch: {
    selectedPage(value) {
      this.$emit('pageChange', value);
    },
  },

  methods: {
    orderingChanged(value) {
      this.$emit('ordering_changed', value);
    },
    selectChangedByClick(id) {
      const row = this.getTableData[id];
      this.$emit('select_changed_by_click', row);
    },

  },
  data: () => ({
    // eslint-disable-next-line no-invalid-this
    selectedPage: undefined,
  }),
  beforeMount() {
    this.selectedPage = this.getStartPage;
    console.log('Selected page: ' + this.selectedPage);
  },
};
</script>

<style lang = "less">

</style>
