function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function requester(url, method='GET', data, headers, contentRequestType='json') {
  let body;
  const myHeaders =new Headers(headers);
  // debugger;
  // создаём объект с заголовками, начальные значения задаем из аргумента данной фукнции
  // const myHeaders=new Headers(headers);
  if (contentRequestType==='json') {
    // если функция имеет параметр json=true , то тело запроса преобразуем в JSON
    body = JSON.stringify(data);
  } else {
    // задаём заголовки
    // const sessionKey = getCookie('passport_session_id');
    // если есть куки, то помещаем их в заголовок запроса
    // if (sessionKey) myHeaders.append('Cookie', sessionKey);
    // myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    // задаем body запроса
    body = new FormData();
    Object.entries(data).forEach(([key, value])=>{
      body.append(key, value);
    });
  }
  // debugger;

  return fetch(
      url,
      {
        method,
        headers: myHeaders,
        credentials: 'include',
        body},
  );
}

async function jsonRequester(url, method, data, headers, contentRequestType) {
  // eslint-disable-next-line prefer-rest-params
  const response = await requester(...arguments);
  // debugger;
  // определяем contentType ответа
  const contentType= response.headers.get('content-type');
  // console.log('HTTP ответ:', response.status);
  // все заголовки выводим в console
  // for (const [key, value] of response.headers) {
  //   console.log(`${key} = ${value}`);
  // }
  if (contentType.startsWith('application/json')) {
    // console.info('HTTP обрабатываем как JSON');
    return await response.json();
  }
  if (contentType.startsWith('text')) {
    // console.info('HTTP обрабатываем как TEXT');
    return await response.text();
  }
  console.error('Сервер вернул неожиданный contentType');
}
export {
  requester,
  jsonRequester,
  getCookie,
};
