<template>
  <BasePage>
    <Icon v-if="Object.keys(this.getKeys).length < 1" name="check"/>
    <div v-for="(item, idx) in this.getKeys" :key="idx"
         class="item">{{this.buildText(idx)}}</div>
  </BasePage>
</template>

<script>
import BasePage from '../../components/navigation/BasePage';
import builder from '../../assets/v1/js/builder';
import LabelComponent from '../../components/unit/Label';
import Icon from '../../components/unit/Icon';

export default builder({
  components: {
    BasePage,
    LabelComponent,
    Icon,
  },
  data: ()=>({}),
  created() {

  },
  computed: {
    getKeys() {
      return this.$store.state.locals;
    },
  },
  methods: {
    buildText(base) {
      return base; // '\'' + base + '\'';
    },
  },

});
</script>

<style lang="less">
  @import url('../../assets/v1/less/base.less');

  .item {
    margin: 7px;
    white-space: pre-wrap;
  }
</style>
