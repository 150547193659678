<template>
  <PieChart
      :meas_unit="this.loc(this.draw_info.units)"
      :draw_info="this.getDrawData"
      :titles="this.getTitles"
      :pie_chart_width="30"
      @pie_select_changed_by_click="this.rowSelected"
  >
  </PieChart>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import PieChart from '@/components/PieChart';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';

export default builder({
  components: {
    PieChart,
  },
  data: () => ({
  }),
  mixins: [uniViewMixin],
  computed: {
    getTitles() {
      return { // задаются заголовки таблицы Piechart
        name: this.loc(this.draw_info.object_label),
        value: this.loc(this.draw_info.value_label),
      };
    },
    getDrawData() {
      if (this.draw_data.length < 1) {
        return [];
      }

      return this.draw_data.map((item)=>({name: item.label, value: item.value}));
    },
  },
  watch: {

  },
  methods: {
    getObjectFromQueryIdx(queryIdx) {
      return {
        modalCaption: this.getDrawData[queryIdx].name,
        next_level: this.draw_data[queryIdx].next_level,
        next_type_data: this.draw_data[queryIdx].next_type_data,
      };
    },
    rowSelected(data) {
      this.$emit('next_select', this.draw_index, this.getObjectFromQueryIdx(data), data);
    },
  },
  emits: {
    next_select: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">

</style>
