<template>
  <div class="switcher-wrapper">
    <div v-for="(item, index) in this.values" :key="item.name" class="switcher-wrapper__element">

      <ButtonDateRange  v-if="item.type==='date_range'" :ref="`elem-${item.id}`"
                        :elementType="this.buttonType"
                        :label_text="item.name"
                        :elementStatus="this.selected_item_index === index ? 'selected': 'enabled'"
                        @date_range_changed="this.date_range_changed(index, $event)"
                        @date_range_changed_by_click="this.date_range_changed_by_click(index, $event)"/>

      <ButtonComponent v-else
                       :elementType="this.buttonType"
                       :label="item.name"
                       :elementStatus="this.selected_item_index === index ? 'selected': 'enabled'"
                       :elementSize="this.size"
                       @click_to_button="this.click_handler(index)"/>

    </div>
  </div>
</template>

<script>
import ButtonComponent from './Button';
import builder from '@/assets/v1/js/builder';
import ButtonDateRange from './ButtonDateRange';

export default builder({
  name: 'Switcher',
  components: {ButtonComponent, ButtonDateRange},
  emits: {
    // если произошла смена активного элемента
    select_changed: null,
    // по любому клику событие
    select_changed_by_click: null,
  },
  props: {
    values: {
      required: true,
      type: Array,
    },
    buttonType: {
      default: 'hover',
      type: String,
    },
    init_selected_index: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
  data: function() {
    return {
      selected_item_index: this.init_selected_index,
      values_calc: [],
      date_range_states: undefined,
    };
  },
  mounted() {
    // после отрисовки компонента посылаем событие родителю, что выбор произведён
    this.$emit('select_changed', this.get_selected_value(true));
  },
  methods: {
    date_range_changed(index, value) {
      // пришло событие, что данные в ButtonDateRange поменялись
      if (this.selected_item_index === index) {
        this.date_range_states=value;
        this.$emit('select_changed', this.get_selected_value(true));
      }
    },
    date_range_changed_by_click(index) {
      // пришло событие, что выбран элемент ButtonDateRange на Switcher
      this.selected_item_index = index;
      this.$emit('select_changed_by_click', this.get_selected_value(true));
    },
    click_handler(index) {
      // пришло событие, что выбран один из простых элементов на Switcher
      this.selected_item_index = index;
      this.$emit('select_changed_by_click', this.get_selected_value(true));
      this.$emit('select_changed', this.get_selected_value(true));
    },
    get_selected_value(full = false) {
      // debugger;
      const fullObj = this.values[this.selected_item_index];
      if (fullObj.type==='date_range') fullObj.return_value=this.date_range_states;
      return (full) ? fullObj : this.selected_item_index;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.switcher-wrapper {
.flex(row, flex-start, flex-start);
  flex-wrap: wrap;

  &__element{
    margin-right: 9px;
  }

  &__element:last-child{
    margin-right: 0;
  }
}

</style>
