<template>
  <BasePage :name="this.getPageName" :is_with_background="this.show_background">
    <div
    class="report-header"
    :class="{
      'report-header__padding': this.show_background,
      'empty-report-header': !this._availableReports &&
          !Object.keys(this.rawInputFields).length &&
          !this.loading &&
          !this.show_generate_button && !this.show_export_to_excel
    }">

      <ReportVersionSelector v-if="this._availableReports"
                             :drop_down_title="this.loc(this.reportVersionsText == null ? 'Варианты отчёта' : this.reportVersionsText)"
                             :available_variants="this._availableReports"
                             :selected_variant="this.currentReportId"
                             :is_disabled="this.loading || this.loading_excel"
                             @variant_change="this.available_selected"
      />

      <UniInputFieldsPainter
        :input_fields="this.rawInputFields"
        :standard_version="this.current_standard_version"
        ref="inputsPainter"
        @onFormChanged="this.isFormChanged = true"
      />

      <div class="uni-report__gen-buttons-row">
        <template v-if="!this.excel_is_primary">
          <ButtonComponent class="uni-report__generate-button" :label="this.loc('Сформировать')"
                          v-if="this.show_generate_button"
                          :elementStatusModifier="this.loading_excel ? 'disabled' : undefined"
                          :elementInLoading="this.loading" @click_to_button="this.rebuildViews"/>
          <ButtonComponent class="uni-report__download-button"
                          v-if="this.show_export_to_excel"
                          :label="''"
                          :iconType="'file-arrow-down'"
                          :elementStatusModifier="this.loading ? 'disabled' : undefined"
                          :iconSize="'19'"
                          :elementType="'default'"
                          :elementInLoading="this.loading_excel" @click_to_button="this.downloadReportInExcel"/>
        </template>
        <template v-else>
          <ButtonComponent class="uni-report__generate-button" :label="this.loc('Сформировать в Excel')"
                          v-if="this.show_export_to_excel"
                          :elementStatusModifier="this.loading ? 'disabled' : undefined"
                          :elementInLoading="this.loading_excel" @click_to_button="this.downloadReportInExcel"/>
          <ButtonComponent class="uni-report__download-button"
                          v-if="this.show_generate_button"
                          :label="''"
                          :iconType="'buy_history'"
                          :elementStatusModifier="this.loading_excel ? 'disabled' : undefined"
                          :iconSize="'19'"
                          :elementType="'default'"
                          :elementInLoading="this.loading" @click_to_button="this.rebuildViews"/>
        </template>
      </div>
      <LoadingFrame v-if="!this.show_generate_button && loading" />
    </div>

    <div class="uni-report"
         :class="{'uni-report__padding': this.show_background}">
      <LabelComponent v-if="this.cacheDate"
                      :label_text="this.getCacheDate"
      />

      <LabelComponent v-if="this.isFormChanged && this.views.length > 0"
                      class="for-rebuild-text"
                      :label_text="this.loc('Фильтры изменены! Переформируйте отчет!')"
      />

      <div
        v-if="this.multiViews[0] != null"
        class="zero-level-multiselect">
        <component
        :is="this.multiViews[0].action_component"
        :cur_view="this.multiViews[0].cur_view"
        :action_data="this.multiViews[0].action_data"
        :selected_items="this.multiViews[0].selected_items">
        </component>
      </div>

      <component
          v-if="this.views.length > 0 && !this.noData"
          ref="firstView"
          :is="this.views[0].component"
          :draw_index="0"
          :draw_info="this.views[0].draw_info"
          :draw_data="this.views[0].draw_data"
          :is-xlsx-exporting="this.show_export_to_excel"
          @download_files="this.download_files"
          @update_multi_select="(selectItems) => this.updateMultiSelect(selectItems, 0)"
          @next_select="this.changeViewSelect">
      </component>

      <LabelComponent v-else-if="!this.loading"
                      :label_text="this.isFirstPreRequest ?
                      this.loc('Нажмите кнопку \'Сформировать\', для просмотра отчета') :
                      this.loc(this.noDataText)"
      />

    </div>
    <template v-slot:modal>
      <ModalViewConductor ref="modalViewConductor">
        <template v-slot="{level, rev_level}">
          <ModalView
                     :open_level="rev_level"
                     :type_modal="this.views[level].modal_type"
                     :ref="'modal_'+level"
                     :caption="this.views[level].modalCaption"
                     @click_close="this.closed_modal_view_first"
                     @click_closing="this.$refs.modalViewConductor.close_level_shadow();">

            <!-- <div style="position: relative;">
              <div class="kek" style="position: absolute; background: red; width: 100%;">
                <div>kek</div>
                <div>kek</div>
                <div>kek</div>
                <div>kek</div>
              </div>
            </div> -->

            <div
              v-if="this.multiViews[level] != null"
              class="zero-level-multiselect">
              <component
              :is="this.multiViews[level].action_component"
              :cur_view="this.multiViews[level].cur_view"
              :action_data="this.multiViews[level].action_data"
              :selected_items="this.multiViews[level].selected_items">
              </component>
            </div>

            <component
                :is="this.views[level].component"
                :draw_index="level"
                :draw_info="this.views[level].draw_info"
                :draw_data="this.views[level].draw_data"
                :before_total="this.views[level].before_total"
                :is-xlsx-exporting="this.show_export_to_excel"
                @download_files="this.download_files"
                @next_select="this.changeViewSelect"
                @update_multi_select="(selectItems) => this.updateMultiSelect(selectItems, level)"
                @close_view="this.closeView">
            </component>
          </ModalView>
        </template>
      </ModalViewConductor>
      <ModalView v-if="this.download_files_window"
                type_modal="internal"
                :caption="this.loc(this.download_files_window_info.title)"
                @click_close="this.download_files_window_closed">

        <LoadingFrame v-if="this.download_files_list == null" />

        <template v-else-if="this.download_files_list.length < 1">
          <LabelComponent label_type="button/small/medium 12"
                          :label_text="this.loc('Файлов нет')"
          />
        </template>

        <div class="files-pack" v-else>
          <div class="file-line" v-for="(item, idx) in this.download_files_list" :key="item">
            <ButtonComponent :label="(idx+1).toString() + ') ' + this.loc(item.performance)"
                            elementType="default"
                            labelType="button/small/medium 14"
                            @click_to_button="this.download_file(item.guid, item.performance)"/>
          </div>
        </div>
      </ModalView>
    </template>

  </BasePage>

</template>

<script>
import BasePage from '../../components/navigation/BasePage';
import builder from '../../assets/v1/js/builder';
// import {markRaw} from 'vue';
import ModalViewConductor from '../../components/navigation/ModalViewConductor';
import ModalView from '../../components/ModalView';
import LabelComponent from '../../components/unit/Label';
import DropDownInput from '../../components/unit/DropDownInput';
import ButtonComponent from '../../components/unit/Button';
import LoadingFrame from '../../components/LoadingFrame';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';

import UniInputFieldsPainter from '@/uni_page_components/uni_input_fields_painter';

import uniTable from '@/uni_page_components/views/uni_table';
import uniPieChart from '@/uni_page_components/views/uni_pieChart';
import uniSlicedTable from '@/uni_page_components/views/uni_slicedTable';
import uniMap from '@/uni_page_components/views/uni_map';
import uniCardList from '@/uni_page_components/views/uni_card_list';
import uniExternalForm from '@/uni_page_components/views/uni_external_form';
import uniForm from '@/uni_page_components/views/uni_form';
import {markRaw} from '@vue/reactivity';

import uniOpenLinkAction from '../../uni_page_components/actions/uni_open_link_action';
import uniDownloadFileAction from '../../uni_page_components/actions/uni_download_file_action';

import nextLevelForm from '@/uni_page_components/multiSelectActions/next_level_form';

import TableComponent from '../../components/unit/Table.vue';

import downloadBLOB from '../../assets/v1/js/project_management/downloadBLOB';

import ReportVersionSelector from '@/uni_page_components/inputs/reportVersionSelector';

const accordanceViews={
  'table': uniTable,
  'pie_chart': uniPieChart,
  'sliced_table': uniSlicedTable,
  'map': uniMap,
  'redirect_link': uniOpenLinkAction,
  'download_file': uniDownloadFileAction,
  'card_list': uniCardList,
  'external_form': uniExternalForm,
  'form': uniForm,
};

const multiSelectActions = {
  'next_level_form': nextLevelForm,
};

export default builder({
  name: 'UniPage',
  components: {
    BasePage,
    ModalViewConductor,
    ModalView,
    LabelComponent,
    DropDownInput,
    ButtonComponent,
    LoadingFrame,
    TableComponent,
    UniInputFieldsPainter,
    ReportVersionSelector,
  },
  data: ()=>({
    _availableReports: undefined, // доступные отчёты
    additionalPageName: undefined,
    currentReportId: -1,
    currentReportSrc: '',
    reportVersionsText: undefined,
    loading: false, // статус загрузки
    loading_excel: false,
    isFirstPreRequest: true,
    hour_update: false,
    noData: false,
    noDataText: '',
    isFormChanged: false,

    rawInputFields: {},
    views: [], // массив объектов с оутпутами для модальных окон
    multiViews: [],

    cacheDate: null,
    auto_first_request: false,
    show_generate_button: true,
    show_export_to_excel: true,
    show_background: true,
    download_files_window: false,
    download_files_window_info: null,
    download_file_resource: null,
    download_files_list: null,
    excel_is_primary: false,
    current_standard_version: 0,
  }),
  mounted() {
    this.isFirstPreRequest = true;
    this.resetParams();
    window.escapeableElements.push(this.close_all_levels);
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.rebuildViewsFromParams();
        return;
      }

      this.currentReportId = -1;
      this.isFirstPreRequest = true;
      this.reportVersionsText = undefined;
      // при переключении роутера заново инициализируем параметры
      this.resetParams();
    },
  },
  computed: {
    getCacheDate() {
      const format = new Intl.DateTimeFormat(this.getUserLangName(), {month: 'short'});

      return this.loc('Данные от: ') +
          this.cacheDate.getDate().toString().padStart(2, '0') + ' ' +
          format.format(this.cacheDate) + ' ' +
          this.cacheDate.getFullYear() + ' ' +
          this.cacheDate.getHours().toString().padStart(2, '0') + ':' +
          this.cacheDate.getMinutes().toString().padStart(2, '0');
    },
    getPageName() {
      let ans = this.loc(this.$store.state.navigate_settings.current_page_info.name);
      if (this.additionalPageName) {
        ans += ' (' + this.additionalPageName + ')';
      }
      return ans;
    },
  },
  methods: {
    close_all_levels() {
      for (let i = 1; i < this.views.length; i++) {
        this.closed_modal_view_first();
      }
      this.$router.push({query: {}});
    },
    updateMultiSelect(selectItems, level) {
      if (selectItems != null && selectItems.length > 0) {
        this.multiViews[level] = {
          cur_view: this.views[level],
          action_data: this.views[level].draw_info.multiselect_action,
          action_component: markRaw(multiSelectActions[this.views[level].draw_info.multiselect_action.type]),
          selected_items: selectItems,
        };
      } else {
        this.multiViews[level] = null;
      }
    },
    download_files_window_closed() {
      this.download_files_window = false;
    },
    rebuildViewsFromParams() {
      if (this.views.length < 1) {
        this.$router.push({query: {}});
        return;
      }

      // const firstView = this.views[0].__orig__;
      this.views.length = 1;
      this.$refs.modalViewConductor.clearAll();
    },
    resetParams() {
      this.$refs.modalViewConductor.clearAll();
      this._availableReports = undefined;
      this.additionalPageName = undefined;
      this.rawInputFields = {};
      this.hour_update = false;
      this.show_generate_button = true,
      this.show_export_to_excel = true,
      this.views.length = 0;
      this.cacheDate = null;
      this.isFormChanged = false;
      this.auto_first_request = false;
      this.show_background = true;
      this.excel_is_primary = false;
      this.current_standard_version = 0;
      this.noDataText = 'Нет данных соответствующих, заданным фильтрам';
      this.requestInputs(); // первоначально запрашиваем инпуты
    },
    available_selected(reportId, selectedText) { // отрабатываем нажатие выбора вариантов отчёта
      this.currentReportId = reportId;
      this.resetParams();
      this.additionalPageName = selectedText;
    },
    requestInputs() {
      const addID= '&report_id='+Math.max(this.currentReportId, 0);
      const options={
        url: `${ROOT_URL}/api/v1/__get_page_additional_data?id=${this.$store.state.navigate_settings.current_page_info.id}${addID}`,
        method: 'GET',
      };
      // this.changedFields = {};
      this.$store.commit('set_full_screen_loading', true);
      // запрос инпутов
      dataRequester(options)
          .then((data)=>{
            // console.log('пришёл ответ', data);
            // получаем экземпляр объекта с инпутами
            // this.uniReportsInputs=markRaw(new UniReportInputs(data)); // markRaw помечает не использовать Proxy
            if (data.aviable_reports) {
              // получаем доступные отчёты
              this._availableReports = data.aviable_reports;
            } else {
              this._availableReports = null;
            }

            this.hour_update = data.hour_update || false;
            this.reportVersionsText = data.report_versions_text;

            if (this.currentReportId < 0 && this._availableReports != null) {
              this._availableReports.sort((a, b) => a.priority - b.priority);
              this.currentReportId = this._availableReports[0].id;

              this.additionalPageName = this.loc(this._availableReports[0].performance);

              if (this.currentReportId != 0) {
                this.requestInputs();
                return;
              }
            }

            this.currentReportId = data.cur_report_id == null ? -1 : data.cur_report_id;
            this.currentReportSrc = data.report_src;

            this.auto_first_request = data.auto_first_request === true;

            this.show_generate_button = data.show_generate_button !== false,

            this.show_export_to_excel = data.no_export_to_excel !== true,

            this.noDataText = data.no_data_text == null ? this.noDataText : data.no_data_text,

            this.show_background = data.show_background !== false;

            this.excel_is_primary = data.excel_is_primary == true;

            this.rawInputFields = data.input_fields;

            // this.current_standard_version = data.is_old_mode ? 0 : 1;

            // Если эта страница открыта только что, запрашиваем данные
            if (this.auto_first_request) {
              this.rebuildViews();
            }
          })
          .catch((errData) => {
            // debugger;
          })
          .finally(()=>{
            this.$store.commit('set_full_screen_loading', false);
          });
    },
    /* formFilled(field, value) {
      // console.log('Field', field, 'changed! New value:', value);
      this.changedFields[field] = value;
    }, */
    // метод собирающий данные по инпутам в один новый объект и подготавливающий данные для запроса
    getReturnValues: function() {
      // создаём новый объект
      const result={input_fields: {}};

      // если были варианты доступных отчётов, то добавляем в запрос ID отчёта
      if (this.currentReportId > -1) result.report_id=this.currentReportId;

      result.lang_code = this.$store.state.localize_settings.cur_lang;

      Object.assign(result.input_fields, this.$refs.inputsPainter.fill_form);
      return result;
    },
    downloadReportInExcel() {
      if (this.loading || this.loading_excel) {
        return;
      }

      this.loading_excel=true; // включаем спиннер на кнопке

      // делаем задержку на 10мс, чтобы жождаться закрытия всех дропдаунов, если таковые имеются
      setTimeout(()=> {
        const result=this.getReturnValues();

        result.page_id = this.$store.state.navigate_settings.current_page_info.id;
        result.build_to_excel = true;
        // console.log('сформирован запрос на сервер ', result );
        // this.views.length = 0;
        const options={
          url: `${ROOT_URL}/api/v1/src/${this.currentReportSrc}`,
          method: 'POST',
          body: {params: result},
        };
        // запрос данных на основе инпутов
        dataRequester(options)
            .then((data)=>{
              if (this.hour_update && (new Date()).getMinutes() < 6) {
                window.globalEvents.callEvent('new_message', [{
                  messageType: 'warning',
                  messageData: 'Внимание!\n\nДанные 1С обновляются в начале каждого часа.\n\nПри обновлении, данные в отчёте могут быть некорректны!\n\nВремя обновления примерно 5 минут.',
                }]);
              }

              downloadBLOB(data.result.url, this.getPageName + '.xlsx');
            })
            .catch((errData) => {
              throw new Error(errData);
            })
            .finally(()=>{
              this.loading_excel=false; // выключаем спиннер на кнопке
            });
      }, 10);
    },
    rebuildViews() {
      if (this.loading || this.loading_excel) {
        return;
      }

      this.isFormChanged = false;
      this.isFirstPreRequest = false;

      this.noData = false;
      this.loading=true; // включаем спиннер на кнопке

      // делаем задержку на 10мс, чтобы жождаться закрытия всех дропдаунов, если таковые имеются
      setTimeout(()=> {
        const result=this.getReturnValues();

        result.page_id = this.$store.state.navigate_settings.current_page_info.id;

        // console.log('сформирован запрос на сервер ', result );
        this.views.length = 0;
        const options={
          url: `${ROOT_URL}/api/v1/src/${this.currentReportSrc}`,
          method: 'POST',
          body: {params: result},
        };
        // запрос данных на основе инпутов
        dataRequester(options)
            .then((data)=>{
              if (this.hour_update && (new Date()).getMinutes() < 6) {
                window.globalEvents.callEvent('new_message', [{
                  messageType: 'warning',
                  messageData: 'Внимание!\n\nДанные 1С обновляются в начале каждого часа.\n\nПри обновлении, данные в отчёте могут быть некорректны!\n\nВремя обновления примерно 5 минут.',
                }]);
              }

              this.cacheDate = data.__time__ ? new Date(Date.parse(data.__time__)) : undefined;

              if (data.result == null || data.result.packed_data == null) {
                throw new Error('Не пришел отчет с сервера!');
              }

              if (!!data.result.packed_data.total) {
                const totalToPackedData = {
                  columns: {},
                  level: 1,
                  is_total: true,
                };
                data.result.packed_data.total.values.forEach((item) => {
                  totalToPackedData.columns[item.system_name] = {value: item.value};
                });

                data.result.packed_data.next_level.push(totalToPackedData);
              }

              this.changeViewSelect(-1, data.result.packed_data, 0);
            })
            .catch((errData) => {
              throw new Error(errData);
            })
            .finally(()=>{
              this.loading=false; // выключаем спиннер на кнопке
            });
      }, 10);
    },
    setView(index, view) {
      this.views.length = index;

      const curView = accordanceViews[view.next_type_data.type];
      if (curView == null) {
        console.warn('View type not found!', view.next_type_data.type, 'Level:', index, 'View:', view);
        return;
      }

      this.views.push({
        component: markRaw(curView),
        modal_type: view.next_type_data.modal_type === 'fly' ? 'internal' : 'bottom',
        modalCaption: view.modalCaption,
        draw_info: view.next_type_data,
        draw_data: view.next_level,
        before_total: view.before_total,
        __orig__: view,
      });

      return true;
    },
    closeView(clickIndex) {
      this.$refs['modal_'+clickIndex].close_window();
    },
    download_files(filableObject) {
      this.download_files_window_info = filableObject;
      this.download_files_list = null;
      this.download_files_window = true;
      this.get_files_list(filableObject.resource, filableObject.guid);
    },
    get_files_list(resource, guid) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${resource}`,
        method: 'POST',
        body: {params: {'GUID': guid}},
      };
      dataRequester(options)
          .then((response) => {
            this.download_files_list = response.result.FilesList;
            this.download_file_resource = response.result.next_src;
          })
          .catch((errData)=> {
            console.log(errData);
          });
    },
    download_file(guid, name) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${this.download_file_resource}`,
        method: 'POST',
        responseType: 'blob',
        body: {params: {'GUID': guid}},
      };
      this.$store.commit('set_full_screen_loading', true);
      dataRequester(options)
          .then((response) => {
            downloadBLOB(response.result.url, name);
          })
          .catch((errData)=> {
            console.log(errData);
          })
          .finally(()=>{
            this.$store.commit('set_full_screen_loading', false);
          });
    },
    changeViewSelect(clickIndex, nextView, selectIdx) {
      const nextIsHard = nextView.next_type_data.is_hard_loading;

      if (!this.__setFullLoad__ && nextIsHard) {
        this.$store.commit('set_full_screen_loading', true);
      }
      this.__setFullLoad__ = true;

      const workFunc = () => {
        const nextViewComponent = accordanceViews[nextView.next_type_data.type];
        let alreadyQuery = nextViewComponent ? nextViewComponent.ignoreHistory === true : false;

        if (clickIndex > -1 && !alreadyQuery) {
          const newQuery = JSON.parse(JSON.stringify(this.$route.query));
          alreadyQuery = newQuery['uni_level__' + clickIndex] === selectIdx;

          if (!alreadyQuery) {
            newQuery['uni_level__' + clickIndex] = selectIdx;
            this.$router.push({query: newQuery});
          }
        } else {
          if ((nextView.next_level ? nextView.next_level : []).length < 1) {
            this.noData = true;
          }
        }

        if (clickIndex < 0 || alreadyQuery) {
          this.__setFullLoad__ = false;
          if (this.setView(clickIndex + 1, nextView)) {
            if (clickIndex > -1) {
              this.$refs.modalViewConductor.up_level();
            }
          } else {
            if (nextIsHard) {
              this.$store.commit('set_full_screen_loading', false);
            }
          }
        }
      };

      if (nextIsHard === true) {
        setTimeout(workFunc, 0);
      } else {
        workFunc();
      }
    },
    closed_modal_view_first() {
      this.$refs.modalViewConductor.close_level();

      this.views.pop(); // удаляем последний объект в массиве

      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery['uni_level__' + (this.views.length-1)] = undefined;
      this.$router.push({query: newQuery});
    },
  },

});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.files-pack {
  display: flex;
  flex-direction: column;
}

.file-line {
  width: fit-content;
  margin-bottom: 9px;
}

.report-header{
  .flex(column, flex-start, unset);

  &__padding{
    padding: 10px;
  }

  & > *{
    margin-top: 10px;

    &:nth-child(1){
      margin-top: 0;
    }
  }
}

.empty-report-header {
  display: none;
}

.uni-report{
  // overflow: auto;
  &__padding{
    padding: 10px;
  }
  &__gen-buttons-row {
    margin: auto;
    .flex(row, flex-start, unset);
  }
  &__generate-button{
    max-width: 250px;
    align-self: center;
  }
  &__download-button{
    align-self: center;
    margin-left: 14px;
  }
  // &__select-report{
  //   max-width: 400px;
  //   align-self: flex-start;
  // }
}
.input-blocks{
  .flex(row, flex-start, flex-start);
  flex-wrap: wrap;
  gap: 5px;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  // row-gap: 5px;
  // column-gap: 10px;
  align-items: center;
}
.for-rebuild-text {
  color: @hover-error;
}

.zero-level-multiselect {
  // .flex(column, flex-start, stretch);

  & > * {
    margin-bottom: 5px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  // position: absolute;
  width: 100%;

  background: @white;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
}

</style>
