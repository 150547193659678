<template>
  <div class = "mob-user-menu">
    <div class = "mob-user-menu__frame">
      <IconComponent type = 'svg' size = 32 name = "close" class = "mob-user-menu__close-button" @click = "$emit('close_modal')"/>
      <div class = "mob-user-menu__preview">
        <IconComponent size = "72"/>
        <LabelComponent class = "mob-user-menu__user-name"
                        label_type = 'caption/big/bold 20'
                        :label_text = "get_user_full_name"/>

      </div>
      <div class = "menu-elements">
        <SeparatorComponent class = "menu-elements__separator"/>
        <div v-for = "(item, idx) in menuItems"
             :key = "idx">
          <SeparatorComponent v-if = "item.separatorTop" class = "menu-elements__separator"/>
          <div class = "menu-elements__element"
               @click = "$emit('select_elem', idx)">
            <IconComponent type = 'svg' size = 24 :name = "item.icon" class = "menu-elements__icon"/>
            <LabelComponent label_type = 'body-large-regular-16'
                            :label_text = "item.name"/>

          </div>
          <SeparatorComponent v-if = "item.separatorBottom" class = "menu-elements__separator"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import buildDesign from '@/assets/v1/js/mixins/design/buildDesign';
import LabelComponent from '../../unit/Label';
import IconComponent from '../../unit/Icon';
import SeparatorComponent from '../../unit/Separator';

// TODO: Вот тут написать мобильную версию меню

export default ({
  name: 'ProfileUserMenuMobile',
  components: {
    IconComponent,
    LabelComponent,
    SeparatorComponent,
  },
  emits: {select_elem: null},
  props: ['menuItems'],
  computed: {
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    get_user_full_name() {
      if (!this.get_user_info['first_name'] && !this.get_user_info['last_name']) return this.loc('Неизвестное имя');
      return [this.get_user_info['first_name'], this.get_user_info['last_name']].join(' ');
    },
  },
});
</script>

<style lang = "less">
@import url('../../../assets/v1/less/base.less');

@profile__mobile__text__color: @gray;
@profile__mobile__background-color: @white;
@profile__mobile__close-button__color: @light-gray;
@profile__mobile__user-name__color: @dark;
@profile__mobile__item__hover__background-color: @light-green-opacity-10;
@profile__mobile__item__icon__color: @light-green;

//.icon-style-color{
//  background-color: @green;
//}
.mob-user-menu {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 150;
  display: none;

  //.flex(column, flex-start, flex-start);
  color: @profile__mobile__text__color;
  background-color: @profile__mobile__background-color;
  /*Ширина профиль меню*/
  min-width: 250px;
  /*отступы в выпадающем Профиль-меню*/
  //padding: 8px 0 8px;
  //z-index: 100;

  &__frame {
    max-width: 250px;
    margin: 30px auto;
    //.flex (column, flex-start, flex-start);
  }

  &__preview {
    .flex(row, flex-start, center);
  }

  &__menu-item {
  }

  &__close-button {
    background-color: @profile__mobile__close-button__color;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__user-name {
    padding-left: 17px;
    color: @profile__mobile__user-name__color;
  }
}

.menu-elements {
  //width: 100%;
  padding-top: 26px;

  &__element {
    .flex(row, flex-start, center);
    padding: 15px 0;

    &:hover {
      cursor: pointer;
      background: @profile__mobile__item__hover__background-color;
    }
  }

  &__icon {
    margin-right: 11px;
    .set_icon_color(@profile__mobile__item__icon__color);

  }

  &__separator {
    //margin: 14px 0;
    margin: 5px 0;
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .mob-user-menu {
    display: block;
  }
}
</style>
