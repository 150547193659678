<template>
  <div style="margin: 300px auto">
    <DropDownInput
        @input_change="this.output"
        :dropdown_items_init="this.get_dropdown_status_objects"/>
    <DropDownInput
        @input_change="this.output"
        :dropdown_items_init="this.get_dropdown_status_objects"
        :is_multi_select="true"/>
    <DropDownInput
        @input_change="this.output"
        :dropdown_items_init="this.get_dropdown_status_objects"
        :is_filterable="true"/>
    <DropDownInput
        @input_change="this.output"
        :dropdown_items_init="this.get_dropdown_status_objects"
        :is_filterable="true"
        :is_multi_select="true"/>
    <InputField
                type="date"
                :label_text="'Дата доставки (отправки)'"
                :init_text="new Date()
                            .toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
                            .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2')"/>
    <CheckBoxComponent :init_value="true"
                       @input_check="output"
                       label_text_right="Согласовать с директором"/>
    <CheckBoxComponent :init_value="false"
                       @input_check="output"
                       label_text_right="Согласовать с бухом"/>
  </div>
</template>

<script>
import DropDownInput from '../../components/unit/DropDownInput';
import InputField from '../../components/unit/InputField';
import CheckBoxComponent from '../../components/unit/CheckBox';

export default {
  components: {DropDownInput, InputField, CheckBoxComponent},
  name: 'Test_DropDowns',
  methods: {
    date_range_changed(result) {
      console.log('диапазон выбран', result);
    },
    output(data) {
      console.log('DropDownInput изменился на: ', data);
    },
  },
  computed: {
    get_dropdown_status_objects() {
      return [
        {
          value_show: 'Товар отгружен',
          return_value: 'Товар отгружен',
          is_selected: true,
        },
        {
          value_show: 'Отменено',
          return_value: 'Отменено',
        },
        {
          value_show: 'В лесу',
          return_value: 'Товар получен',
        },
        {
          value_show: 'родилась',
          return_value: 'Товар получен',
        },
        {
          value_show: 'ёлочка',
          return_value: 'Товар получен',
        },
        {
          value_show: 'в лесу',
          return_value: 'Товар получен',
        },
        {
          value_show: 'она росла',
          return_value: 'Товар получен',
        },
        {
          value_show: 'зимой',
          return_value: 'Товар получен',
        },
        {
          value_show: 'и летом',
          return_value: 'Товар получен',
        },
        {
          value_show: 'стройная',
          return_value: 'Товар получен',
        },
        {
          value_show: 'зелёная',
          return_value: 'Товар получен',
        },
        {
          value_show: 'была',
          return_value: 'Товар получен',
        },
      ];
    },
  },
};
</script>

<style>

</style>
