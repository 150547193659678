<template>
  <BasePage :name="'Выбор ' + (this.workObjectTypeInfo ? this.workObjectTypeInfo.entity_view_name : '-')" :onlyContent="this.isModal">
    <EntitySelector :work_object_name="this.workObjectName" @entitySelect="this.selectObject"/>
  </BasePage>
</template>

<script>
import BasePage from '@/components/navigation/BasePage';
import getObjectTypeInfo from '@/assets/dataSources/admin/getObjectTypeInfo';
import EntitySelector from '@/views/related_pages/adminLayout/EntitySelector';

export default ({
  name: 'SelectObjectList',
  components: {
    BasePage,
    EntitySelector,
  },
  data: () => ({
    drawObjects: null,
    workObjectTypeInfo: null,
  }),
  props: {

  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.rebuildForm();
    },
  },
  computed: {
    isModal() {
      return this.$route.query['modal_handler_id'] != null;
    },
    modalHandlerId() {
      return this.$route.query['modal_handler_id'];
    },
    isManySelect() {
      return this.$route.query['select_many'] == '1';
    },
    workObjectName() {
      return this.$route.params.objectName ? this.$route.params.objectName : '-';
    },
  },
  methods: {
    selectObject(objects) {
      if (!this.isModal) {
        window.location.href = `/object_edit_form/${this.workObjectName}/${objects[0].uid}`;
        return;
      }

      if (this.isModal && !this.isManySelect) {
        const handler = window.opener.getModalHandler(this.modalHandlerId);
        handler(objects);
        window.close();
      }
    },
    rebuildForm() {
      const curThis = this;

      getObjectTypeInfo(curThis.workObjectName, function(objectTypeInfo) {
        curThis.workObjectTypeInfo = objectTypeInfo;
      });
    },
  },
  mounted() {
    this.rebuildForm();
  },
});
</script>

<style lang="less">
</style>
