<template>
  <div class="pie-chart">
    <svg class="pie-chart__donut"
         :viewBox="this.get_pie_chart_view_box()"
         ref="main_area"
         @mousedown="this.action_click_by_svg($event, $event)"
         @touchstart="this.action_click_by_svg(this.convert_touch_to_cursor($event), $event)"
        style="z-index: 99">

      <circle class="pie-chart__donut__piece"
              v-for="(elem, i) in get_draw_info" :key="i"
              v-show="elem.percent >= 0.01"
              :class = "['touch-action-none', elem.stroke_class]"
              :style="{stroke: elem.calculated_color}"
              :cx="this.get_pie_chart_outer_radius"
              :cy="this.get_pie_chart_outer_radius"
              :r="this.get_pie_chart_radius"
              fill="transparent"
              :stroke-width="this.pie_chart_width"
              :stroke-dasharray="this.get_calc_dasharray_value(elem)"
              :stroke-dashoffset="this.get_calc_dashoffset_value(elem)"/>

      <circle
          :cx="this.get_pie_chart_outer_radius"
          :cy="this.get_pie_chart_outer_radius"
          :r="this.pie_chart_radius_inner"
          fill="white"/>

      <polygon
          class="pie-chart__donut__arrow"
          :transform="'translate(0,'+(is_rotate ? -10 : -2)+')'"
          :points="this.get_arrow_down_points" />

      <text
          @selectstart="() => { return false; }"
          class="pie-chart__donut__total"
          dominant-baseline="middle"
          :x="this.get_pie_chart_outer_radius"
          :y="this.get_pie_chart_outer_radius">

        {{ convert_number_to_three_symbol(this.get_factors_total_value) }}
      </text>

      <text
          class="pie-chart__donut__meas"
          dominant-baseline="middle"
          :x="this.get_pie_chart_outer_radius"
          :y="this.get_pie_chart_outer_radius + 12">

        {{this.loc(getMultiplierText(this.get_factors_total_value))}} {{ meas_unit }}
      </text>
    </svg>
    <div class="pie-chart__scroller">
      <TableComponent
          v-if="table_pre_calc_data.columns"
          :table-columns="table_pre_calc_data.columns"
          :table-data="table_pre_calc_data.data"
          :selected-row-props="selected_elem_id"
          :is-loading="loading_page"
          :is-compact-table="true"
          :is-dashed-table="false"
          :is-selectable="true"
          @selectChangedByClick="this.set_active_elem_by_id">
        <template v-slot="{row_id, column_id}">
          <LabelComponent v-if="column_id === 0"
                          :class="[get_draw_info[row_id].color_class]"
                          :style="{color: get_draw_info[row_id].calculated_color}"
                          :label_type="this.labelTextSize"
                          label_text="●"/>
          <LabelComponent v-else-if="column_id === 1"
                          :label_type="this.labelTextSize"
                          :label_text="get_draw_info[row_id].name"/>
          <LabelComponent v-else-if="column_id === 2"
                          :label_type="this.labelTextSize"
                          white_space="nowrap"
                          :is_monospace="true"
                          :digits_precision="2"
                          :label_text="get_draw_info[row_id].value"/>
<!--          <LabelComponent v-else-if="column_id === 3"-->
<!--                          label_type='button/large/regular 14'-->
<!--                          white_space="nowrap"-->
<!--                          label_text="|"/>-->
          <LabelComponent v-else-if="column_id === 3"
                          :label_type="this.labelTextSize"
                          white_space="nowrap"
                          :label_text="`${(get_draw_info[row_id].percent).toFixed(2)} %`"/>
          <IconComponent v-else-if="column_id === 4 && is_elem_is_selected(get_draw_info[row_id])"
                         type='svg'
                         size=16
                         name="arrow-filled-left"/>
        </template>
      </TableComponent>
    </div>
  </div>
</template>


<script type="text/javascript">
import builder from '@/assets/v1/js/builder';
import IconComponent from './unit/Icon';
import LabelComponent from './unit/Label';
import TableComponent from './unit/Table';
import helper from '../assets/v1/js/helper';
import localiser from '@/assets/v1/js/packs/localiser';

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export default builder({
  components: {
    IconComponent,
    LabelComponent,
    TableComponent,
  },
  computed: {
    get_arrow_down_points() {
      return ((this.get_pie_chart_outer_radius) - 5) + ',' +
          ((this.get_pie_chart_outer_radius) - 10 + this.pie_chart_radius_inner) + ' ' +
          ((this.get_pie_chart_outer_radius) + 5) + ',' +
          ((this.get_pie_chart_outer_radius) - 10 + this.pie_chart_radius_inner) + ' ' +
          (this.get_pie_chart_outer_radius) + ',' +
          ((this.get_pie_chart_outer_radius) + this.pie_chart_radius_inner);
    },
    get_draw_info() {
      const drawInfo = helper.copy_object(this.draw_info);
      drawInfo.forEach((item, index) => {
        item['id_parent_sort'] = index;
        item['percent'] = item.value / this.get_factors_total_value * 100;
      });
      drawInfo.sort((itemA, itemB) => {
        if (itemA.percent > itemB.percent) return -1;
        else if (itemB.percent > itemA.percent) return 1;
        else return 0;
      });

      let angleStart = 0;
      let angleSize = 0;

      const infoCount = drawInfo.length;

      return drawInfo.map((item, index) => {
        item['id'] = index;
        item['percent'] = item.percent;
        item['stroke_class'] = `pie-color-${index % 10}__stroke`;
        item['color_class'] = `pie-color-${index % 10}__color`;

        angleStart += angleSize;
        angleSize = 360 * item.percent / 100;

        item['calculated_color'] = hslToHex(160 + (1 - angleStart/360) * 160, 60, 50)+'f0';

        item['angle_start'] = angleStart;
        item['angle_size'] = angleSize;
        item['angle_end'] = angleStart + angleSize;

        return item;
      });
    },
    get_factors_total_value() {
      return this.draw_info.map((elem) => elem.value).reduce(
          (valA, valB) => valA + valB,
          0,
      );
    },
    get_pie_chart_outer_radius() {
      return this.pie_chart_radius_inner + this.pie_chart_width;
    },
    get_pie_chart_radius() {
      return this.pie_chart_radius_inner + (this.pie_chart_width / 2);
    },
    get_pie_chart_view_box() {
      const radiusOuter = this.get_pie_chart_outer_radius;
      return () => `${0} ${0} ${radiusOuter * 2} ${radiusOuter * 2}`;
    },
  },
  watch: {
    draw_info: function(newValue) {
      this.loading_page = !newValue.length;
      this.set_pre_calc_data();
      if (this.selected_elem_id === undefined) {
        this.set_active_elem(this.get_draw_info[0]);
      }
    },
    selected_elem_id: function(newValue) {
      // при изменении selected_elem инициируем событие pie_select_changed на PieChartTemplate
      const selectedElem = this.get_element_by_id(newValue);
      this.$emit('pie_select_changed', selectedElem.id_parent_sort);
    },
  },
  props: {
    draw_info: {
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [{name: '', value: 1}],
      type: Array},
    meas_unit: {default: 'тонн'},
    titles: {default: {
      name: localiser.loc('Объект'),
      value: localiser.loc('Значение'),
    }},
    pie_chart_radius_inner: {default: 60},
    pie_chart_width: {default: 55},
    multiplier_symbols: {default: [
      {
        symbol: '',
        multiplier: 1,
      },
      {
        symbol: localiser.loc('тыс.'),
        multiplier: Math.pow(10, 3),
      },
      {
        symbol: localiser.loc('млн'),
        multiplier: Math.pow(10, 6),
      },
      {
        symbol: localiser.loc('млрд'),
        multiplier: Math.pow(10, 9),
      },
      {
        symbol: localiser.loc('трлн'),
        multiplier: Math.pow(10, 12),
      },
      {
        symbol: localiser.loc('квадр.'),
        multiplier: Math.pow(10, 15),
      },
    ]},
    isLoading: {default: false, type: Boolean},
  },
  emits: {
    pie_select_changed: null,
    pie_select_changed_by_click: null,
  },
  data: () => ({
    labelTextSize: 'body/large/regular 12',
    angle_current: 0,
    angle_primary: 90,
    angle_start_rotate: 0,
    angle_start_rotate_click: 0,
    is_rotate: false,
    default_vector: {
      PositionX: 1,
      PositionY: 0,
    },
    selected_elem_id: 0,
    this_no_click: false,
    table_pre_calc_data: {},
    loading_page: false, // было True, косяк может всплыть
  }),
  created() {
    // загружаем заголовки таблицы заранее
    this.set_pre_calc_data();
    this.set_active_elem(this.get_element_by_id(this.selected_elem_id));
  },
  methods: {
    forceTabledraw() {
      this.set_active_elem(this.get_draw_info[0]);
    },
    is_click_by_pie_chart(event) {
      const svgSize = this.get_pie_chart_outer_radius * 2;
      const LocationX = event.clientX - this.$refs.main_area.getBoundingClientRect().left;
      const LocationY = event.clientY - this.$refs.main_area.getBoundingClientRect().top;

      const svgClickX = svgSize * LocationX / this.$refs.main_area.clientWidth;
      const svgClickY = svgSize * LocationY / this.$refs.main_area.clientHeight;

      const svgLocationX = svgClickX - this.get_pie_chart_outer_radius;
      const svgLocationY = svgClickY - this.get_pie_chart_outer_radius;

      const currentRadius = Math.sqrt(Math.pow(svgLocationX, 2) + Math.pow(svgLocationY, 2));
      return (this.pie_chart_radius_inner <= currentRadius) && (currentRadius <= this.get_pie_chart_outer_radius);
    },
    is_elem_is_selected(elem) {
      if (this.selected_elem_id === undefined) return false;
      return elem.id === this.selected_elem_id;
    },
    action_click_by_svg(event, eventOriginal) {
      if (this.is_click_by_pie_chart(event)) {
        this.is_rotate = true;
        this.angle_start_rotate = this.get_click_angle(event);

        eventOriginal.preventDefault();
        eventOriginal.stopPropagation();
      }
    },
    action_move_pie_chart(event) {
      if (this.is_rotate) {
        const selectedElem = this.get_element_by_id(this.selected_elem_id);
        const angleCurrent = selectedElem.angle_start + (selectedElem.angle_size / 2);
        const angle = this.get_click_angle(event);
        this.angle_current = ((angleCurrent + (this.angle_start_rotate - angle)) + 360) % 360;
      }
    },
    action_up_pie_chart() {
      if (!this.is_rotate) return;
      this.is_rotate = false;

      let elem = undefined;
      const selectedElem = this.get_element_by_id(this.selected_elem_id);
      const angleCurrent = selectedElem.angle_start + (selectedElem.angle_size / 2);
      if (Math.abs(this.angle_current - angleCurrent) < 1) {
        const clickAngle = this.angle_start_rotate;
        let anglePieChart = (clickAngle - this.angle_primary + this.angle_current) % 360;
        if (anglePieChart < 0) anglePieChart += 360;
        elem = this.get_element_by_angle_on_pie_chart(anglePieChart);
      } else {
        elem = this.get_element_by_angle_on_pie_chart(this.angle_current);
      }

      const isNewElem = selectedElem.id !== elem.id;

      this.set_active_elem(elem);
      if (isNewElem) {
        this.$emit('pie_select_changed_by_click', elem.id_parent_sort);
      }
    },
    get_click_angle(event) {
      const LocationX = event.clientX -
          (this.$refs.main_area.getBoundingClientRect().left + (this.$refs.main_area.clientWidth / 2));
      const LocationY = event.clientY -
          (this.$refs.main_area.getBoundingClientRect().top + (this.$refs.main_area.clientHeight / 2));


      const angle = Math.sign(LocationY) * this.get_vectors_angle(
          this.default_vector,
          {
            PositionX: LocationX,
            PositionY: LocationY,
          },
      );

      let anglePieChart = (angle - this.angle_primary + this.angle_current) % 360;
      if (anglePieChart < 0) anglePieChart += 360;
      return angle;
    },
    get_calc_dasharray_value(elem) {
      return this.get_px_by_angle_to_pie_chart(elem.angle_size) + ', ' +
          this.get_px_by_angle_to_pie_chart(360 - elem.angle_size);
    },
    get_calc_dashoffset_value(elem) {
      const angle = (-this.angle_primary - elem.angle_start + this.angle_current) % 360;
      return `${this.get_px_by_angle_to_pie_chart(angle)}`;
    },
    get_px_by_angle_to_pie_chart(angle) {
      return (angle / 360) * (2 * Math.PI * this.get_pie_chart_radius);
    },
    get_vectors_angle(vectorA, vectorB) {
      // Метод получения угла между двумя векторами
      const multiply = (vectorA.PositionX * vectorB.PositionX) + (vectorA.PositionY * vectorB.PositionY);
      const lenA = Math.sqrt((vectorA.PositionX * vectorA.PositionX) + (vectorA.PositionY * vectorA.PositionY));
      const lenB = Math.sqrt((vectorB.PositionX * vectorB.PositionX) + (vectorB.PositionY * vectorB.PositionY));
      return (Math.acos(multiply / (lenA * lenB)) * 180) / Math.PI;
    },
    get_element_by_id(id) {
      return this.get_draw_info
          .filter((item) => item.id === id)[0];
    },
    get_element_by_angle_on_pie_chart(angle) {
      return this.get_draw_info
          .filter((item) => (item.angle_start <= angle) && (angle <= item.angle_end))[0];
    },
    do_animate({timing, draw, duration, eventStartAnimation, eventEndAnimation}) {
      const start = performance.now();
      if (eventStartAnimation) eventStartAnimation();

      requestAnimationFrame(function animate(time) {
        // timeFraction goes from 0 to 1
        let timeFraction = (time - start) / duration;
        if (timeFraction < 0) timeFraction = 0;
        if (timeFraction > 1) timeFraction = 1;

        // calculate the current animation state
        const progress = timing(timeFraction);

        draw(progress);

        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }
        if (timeFraction === 1) {
          if (eventEndAnimation) eventEndAnimation();
        }
      });
    },
    set_active_elem(elem, force = false) {
      if ((!this.this_no_click || force) && elem !== undefined) {
        this.selected_elem_id = elem.id;
        const selectedElem = elem;

        const angleStart = this.angle_current;
        const angleFinal = selectedElem.angle_start + (selectedElem.angle_size / 2);

        if (angleStart === angleFinal) return;

        let direction = Math.sign(angleFinal - angleStart);
        let angleSize = Math.abs(angleFinal - angleStart);
        if (angleSize > 180) {
          angleSize = 360 - Math.abs(angleFinal - angleStart);
          direction = -direction;
        }

        const curThis = this;
        this.do_animate({
          duration: 500,
          timing(timeFraction) {
            const y0 = 0;
            const y1 = 1.2;
            const y2 = 1;
            const y3 = 1;
            return (y0 * Math.pow(1 - timeFraction, 3)) +
                (3 * y1 * timeFraction * Math.pow(1 - timeFraction, 2)) +
                (3 * y2 * Math.pow(timeFraction, 2) * (1 - timeFraction)) +
                (y3 * Math.pow(timeFraction, 3));
          },
          draw(progress) {
            let currentAngle = angleStart + (direction * angleSize * progress);
            currentAngle = (currentAngle + 360) % 360;
            curThis.angle_current = currentAngle;
          },
        });
      }
    },
    set_active_elem_by_id(id) {
      const elem = this.get_element_by_id(id);
      this.$emit('pie_select_changed_by_click', elem.id_parent_sort);
      this.set_active_elem(elem);
    },
    convert_touch_to_cursor(event) {
      return event.targetTouches[0];
    },
    getMultiplierText(number) {
      const elem = this.multiplier_symbols
          .filter((item) => Math.abs(number) >= item.multiplier) // при кредиторской задолженности приходят минусы
          .slice(-1)[0];

      return elem == null ? '?' : elem.symbol;
    },
    convert_number_to_three_symbol(number) {
      const elem = this.multiplier_symbols
          .filter((item) => Math.abs(number) >= item.multiplier) // при кредиторской задолженности приходят минусы
          .slice(-1)[0];
      const value = (number / (elem == null ? 1 : elem.multiplier)).toLocaleString(
          'ru-RU',
          {useGrouping: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2},
      ).replace(/,/g, '.');

      return value; // `${value} ${elem.symbol}`;
    },
    set_pre_calc_data() {
      const preCalcData = {
        columns: [
          {
            id: 0,
            title: '',
            ordering: undefined,
            is_with_sum: false,
            is_delete_if_empty: false,
          },
          {
            id: 1,
            title: this.titles.name,
            ordering: undefined,
            is_with_sum: false,
            is_delete_if_empty: false,
          },
          {
            id: 2,
            title: this.titles.value,
            ordering: undefined,
            is_with_sum: false,
            is_delete_if_empty: false,
            content_align: 'right',
          },
          // {
          //   id: 3,
          //   title: '',
          //   ordering: undefined,
          //   is_with_sum: false,
          //   is_delete_if_empty: false,
          // },
          {
            id: 3,
            title: this.loc('Процент'),
            ordering: undefined,
            is_with_sum: false,
            is_delete_if_empty: false,
            content_align: 'right',
          },
          {
            id: 4,
            title: '',
            ordering: undefined,
            is_with_sum: false,
            is_delete_if_empty: false,
          },
        ],
        columns_ordering: [],
        data: [],
      };
      // данные берем только если не статус isLoading (иначе таблица покажет заглушку по умолчанию)
      if (!this.isLoading) {
        preCalcData.data = this.get_draw_info.map((item) => ({
          id: item.id,
          1: item.name,
          2: item.value,
          3: item.percent,
        }));
      }

      this.table_pre_calc_data = preCalcData;
    },
  },
  beforeUnmount() {
    document.removeEventListener(
        'mousemove',
        this.action_move_pie_chart,
    );
    document.removeEventListener(
        'touchmove',
        (event)=> this.action_move_pie_chart(this.convert_touch_to_cursor(event)),
    );

    document.removeEventListener(
        'mouseup',
        this.action_up_pie_chart,
    );
    document.removeEventListener(
        'touchend',
        (event)=> this.action_up_pie_chart(thisthisCurrent.convert_touch_to_cursor(event)),
    );
  },
  mounted() {
    document.addEventListener(
        'mousemove',
        this.action_move_pie_chart,
    );
    document.addEventListener(
        'touchmove',
        (event)=> this.action_move_pie_chart(this.convert_touch_to_cursor(event)),
    );

    document.addEventListener(
        'mouseup',
        this.action_up_pie_chart,
    );
    document.addEventListener(
        'touchend',
        (event)=> this.action_up_pie_chart(this.convert_touch_to_cursor(event)),
    );
  },
  name: 'PieChart',

});
</script>

<style lang="less">
@import url('../assets/v1/less/base.less');


@pie_chart__total__fill: @gray;
@pie_chart__meas__fill: @light-gray;
@pie_chart__select_arrow__fill: @dark;

@legend__text__color: @dark;
@legend__selected__text__color: @light-green;
@legend__hover__text__background-color: @light-green-opacity-10;


.pie-chart {
  .flex(row, center, flex-start);
  .set_custom_scrollbar(4px, 10px, 0px, 20px, 0px);
  flex-grow: 1;
  margin-bottom: 30px;

  //display: flex;
  //align-items: center;
  & > * {
    margin-left: 35px;
  }

  & > *:nth-child(1){
    margin-left: 0;
  }

  width: 100%;

  &__donut {
    min-width: 150px;
    max-width: 300px;
    align-self: center;

    &__arrow {
      fill: @pie_chart__select_arrow__fill;
      transition: all 200ms linear;
    }

    &__total {
      text-anchor: middle;
      font-weight: bold;
      fill: @pie_chart__total__fill;
    }

    &__meas {
      text-anchor: middle;
      font-size: 13px;
      fill: @pie_chart__meas__fill;
    }
  }

  &__scroller{
    // .flex(row, flex-start, unset);
    align-self: stretch;
    overflow-y: auto;

  }
}


@media screen and (max-width: @transition-threshold-1){
  .pie-chart {
    .flex(column, inherit, center);
    height: inherit;
    margin-bottom: 15px;

    & > * {
      margin-left: 0;
      margin-top: 35px;
    }

    & > *:nth-child(1){
      margin-top: 0;
    }

    &__scroller{
      justify-content: inherit;
      overflow-x: auto;
      max-width: 100%;
      align-self: inherit;
    }
  }
}

.touch-action-none{ touch-action: none; }


.pie-legend-wrap{
  width: 100%;

  table td{
    padding: 4px;
    //color: @dark;
  }
}

.legend-cell{
  white-space: nowrap;
}

.legend-row{
  color: @legend__text__color;
  &:hover{
    background: @legend__hover__text__background-color;
  }
  &-selected{
    color: @legend__selected__text__color;
  }
}

.legend-cell-icon{
  display: none;
  &-selected{
    display: block;
  }
}


.legend{
  //margin-left: 5%;
  //background: antiquewhite;
  flex: 1;
  min-width: 275px;
  overflow: auto;
  display: flex;

  &__table{
    width: 100%;
    border-spacing: 0;
  }
}

</style>
