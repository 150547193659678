<template>
    <div class="icon" v-if="type==='svg'"
         @click="$emit('icon_click')"
         :style="get_styles"
         :class="this.url||this.name?'icon-style-color':'default-icon'"/>
    <img v-else :style="get_styles" :src="get_styles.src">
</template>

<script type="text/javascript">

/*
 * Поле вывода строки текста.
 * По запросу меняет свою высоту и ширину.
 * При сборке данных формы отдаёт свое значение, если разрешено сервером.
 * Настраивается на сервере
 */


export default {
  name: 'Icon',
  props: {
    size: {
      type: String,
      required: false,
    },
    sizeHeight: {
      type: String,
      default: '24',
    },
    sizeWidth: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: undefined,
    },
    url: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      default: '0',
    },
    name: {
      type: String,
      required: false,
    },
    type: {
      default: 'svg',
      type: String,
      required: false,
    },

  },
  emits: {'icon_click': null},
  computed: {
    get_styles() {
      const localUrl = this.url ?
      this.url :
          `/media/icons/${this.name}.${this.type}`;
      const url = this.$store.state.urls.root + localUrl;
      const ans = {
        'src': this.type ==='png'? url: null,
        'mask': (this.url || (this.type && this.name)) ?
            `url(${url}) no-repeat center / contain` :
      null,
        '-webkit-mask': (this.url || (this.type && this.name)) ?
            `url(${url}) no-repeat center / contain` :
      null,
        'min-width': `${this.size || this.sizeWidth}px`,
        'max-width': `${this.size || this.sizeWidth}px`,
        'min-height': `${this.size || this.sizeHeight}px`,
        'max-height': `${this.size || this.sizeHeight}px`,
      };

      if (this.color) {
        if (!(this.url||this.name)) {
          ans['border-color'] = this.color;
        } else {
          ans['fill'] = this.color;
          ans['stroke'] = this.color;
          ans['background'] = this.color;
        }
      }

      return ans;
    },
  },

};
</script>

<style lang="less">
  @import url('../../assets/v1/less/base.less');
  // Стили для отдельных элементов

  @icon__border-color: @light-green;

  .icon{
    transition: color 0.2s;
  }

  .default-icon{
    border: 1px solid @icon__border-color;
    border-radius: 30%;
  }

</style>
