<template>
  <div class="loading-frame">
    <SpinnerComponent class="loading-frame__spinner" :width="1"/>
    <div class="loading-frame__message">
      <LabelComponent :label_text="this.loc('Загрузка данных')"/>
    </div>
  </div>
</template>

<script>
import builder from '@/assets/v1/js/builder';
import SpinnerComponent from './unit/Spinner.vue';
import LabelComponent from './unit/Label';

export default builder({
  name: 'LoadingFrame',
  components: {
    SpinnerComponent,
    LabelComponent,
  },
});
</script>


<style  lang="less">
@import url('../assets/v1/less/base.less');

@loading__frame__background-color: @white;

.loading-frame {
  padding: 10px;
  background-color: @loading__frame__background-color;
  border-radius: 10px;
  .flex(row,flex-start, center);
  &__spinner{
    .spinner{
      width: 40px;
      height: 40px;
    }
  }
  &__message{
    margin: 20px;
  }
}
</style>
