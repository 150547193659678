<template>
  <div class="modal" ref="modal_ref"
       :style="{position: this.position}"
       :type="type_modal"
       :status="[this.modal_status, `level-${this.open_level}`]"
       @click="this.checkCloseModal">

    <div class="modal__block">

      <div class="modal__block__header">
        <LabelComponent class="modal__block__caption"
                        label_type="h3"
                        :label_text="caption"/>

        <div class="modal__block__buttons">

          <ButtonComponent v-if="this.type_modal==='bottom' && this.modal_status === 'open'"
                           elementType="hover"
                           elementStatus="selected"
                           @click="full_size_toggle"
                           iconType="arrow-in-full-size"
                           iconSize="22"/>
          <ButtonComponent v-else-if="this.type_modal==='bottom' && this.modal_status === 'full-size'"
                           elementType="hover"
                           @click="full_size_toggle"
                           iconType="arrow-in-simple"
                           iconSize="22"/>

          <ButtonComponent elementType="hover"
                           @click="close_window"
                           iconType="close"
                           iconSize="22"/>
        </div>
      </div>

      <div class="modal__block__content">
        <div class="modal__block__content__wrapper">
          <slot>Содержимое модалки</slot>
        </div>
      </div>
    </div>
  </div>

  <slot name="super-modal"/>
</template>

<script>
import builder from '@/assets/v1/js/builder';
import LabelComponent from '@/components/unit/Label';
import ButtonComponent from '@/components/unit/Button';
import ScrollerComponent from '@/components/core/Scroller';
import Vue from 'q';

export default builder({
  name: 'ModalView',
  emits: {
    'click_close': null,
    'click_closing': null,
  },
  props: {
    caption: {
      required: true,
      type: String,
    },
    open_level: {
      default: 1,
      type: Number,
      // validator: (item)=> item >= 1,  непонятно зачем этот валидатор нужен
    },
    type_modal: {
      required: true,
      validator: (item)=> ['internal', 'bottom'].includes(item),
    },
    position: {
      default: 'absolut',
      validator: (item)=> ['absolut', 'fixed'].includes(item),
    },
  },
  methods: {
    get_modal_status() {
      let status = this.modal_status;
      if (status === 'open') status += `-${this.open_level}`;
      return status;
    },
    full_size_toggle() {
      this.modal_status = this.modal_status === 'open' ? 'full-size' : 'open';
    },
    close_window() {
      if (this.modal_status === 'closed') {
        return;
      }

      // Закрытие моделаки
      this.modal_status = 'closed';
      const timeout = this.type_modal === 'bottom' ? 400 : 200;
      this.$emit('click_closing');

      setTimeout(()=>{
        this.$emit('click_close');
      }, timeout);
    },
    checkCloseModal(event) {
      if (this.modal_status !== 'open') return false;
      if (this.$refs.modal_ref && this.$refs.modal_ref.compareDocumentPosition(event.target)) return false;
      this.close_window();
    },
  },
  data: ()=>({
    modal_status: null,
  }),
  mounted() {
    // window.escapeableElements.push(this.close_window);
    // debugger;
    Vue.nextTick(() => this.modal_status = 'open');
  },
  // unmounted() {
  //   const index = window.escapeableElements.indexOf(this.close_window);
  //   if (index > -1) {
  //     window.escapeableElements.splice(index, 1);
  //   }
  // },
  components: {
    LabelComponent,
    ButtonComponent,
    ScrollerComponent,
  },
});
</script>

<style lang="less">
@import url('../assets/v1/less/base.less');

@modal__window__background-color: @white;
@fullscreen__background-color: @dark-opacity-15;

@scroll__track__vertical__width: @margin-horizontal-default;
@scroll__track__bottom__padding: @margin-vertical-default;

@slider__margin__top: @margin-vertical-default;
@slider__margin__left: @margin-horizontal-default;
@slider__margin__bottom: @margin-vertical-default;

@scroll__track__background-color: @super-light;
@scroll_track__thumb__color: @light-gray;

@scroll__slider__background-color: @white;

.modal{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 180;
  background: transparent;

  &__block{
    .flex(column, flex-start, flex-start);

    & > * {
      margin-bottom: 24px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
    background-color: @white;
    z-index: 130;
    max-width: 98%;

    .set_custom_scrollbar(4px, 10px, 0px, 0px, 10px);


    &__header {
      .flex(row, space-between, center);
      width: 100%;
    }

    &__buttons {
      .flex(row, center, center);

      & > * {
        margin-right: 25px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }

    &__content{
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    .scroll-track-vertical{
      .flex(column, flex-start, flex-end);
      width: @scroll__track__vertical__width;

      padding: 10px 10px @scroll__track__bottom__padding 0;
      background: transparent;

      .scroll-track-vertical-view{
        border-radius: 10px;
        width: 4px;
        height: 100%;
        background: @scroll__track__background-color;

        .scroll-track-vertical-thumb{
          background: @scroll_track__thumb__color;
        }
      }
    }
  }

  &[type=bottom] {
    transition: background 400ms;

    .modal__block {
      box-shadow: 0px -6px 50px rgba(0, 0, 0, 0.1);
      border-radius: 20px 20px 0 0;
      position: absolute;
      padding: 34px 36px 0;
      width: 100%;
      top: 100%;
      max-width: 100%;
      bottom: 0;
      transition: top 400ms;
    }

    &[status=closed] {
      .modal__block {
        top: 100%;
      }
    }

    &[status*=open] {
      &[status*=level-1]{ .modal__block { top: 49% - 7% * 0 } }
      &[status*=level-2]{ .modal__block { top: 49% - 7% * 1 } }
      &[status*=level-3]{ .modal__block { top: 49% - 7% * 2 } }
      &[status*=level-4]{ .modal__block { top: 49% - 7% * 3 } }
      &[status*=level-5]{ .modal__block { top: 49% - 7% * 4 } }
      &[status*=level-6]{ .modal__block { top: 49% - 7% * 5 } }
      &[status*=level-7]{ .modal__block { top: 49% - 7% * 6 } }
      &[status*=level-8]{ .modal__block { top: 49% - 7% * 7 } }
    }

    &[status*=full-size] {
      .modal__block {
        box-shadow: inherit;
        border-radius: inherit;
        top: 0;
      }
    }
  }

  &[type=internal] {
    .flex(row, center, center);
    transition: background 200ms, opacity 200ms;

    .modal__block {
      box-shadow: -2px -2px 50px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      transition: opacity 200ms;
      max-height: 90vh;

      &__header {
        padding: 28px 35px 0 35px;
      }

      &__content {
        overflow: auto;

        &__wrapper {
          padding: 0 35px 35px;

        }
      }
    }

    &[status*=open] {
      .modal__block {
        opacity: 1;
      }
    }

    &[status*=closed] {
      .modal__block {
        opacity: 0;
      }
    }
  }


  &[status=closed]{
    background: transparent;
  }
  &[status=full-size],
  &[status*=open]{
    background: @fullscreen__background-color;
  }

}


@media screen and (max-width: @transition-threshold-1) {
  .modal{
    position: fixed;
  }
}

</style>
