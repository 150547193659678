export default {
  mounted() {
    if (this.draw_info.is_hard_loading) {
      this.$nextTick(() => {
        this.$store.commit('set_full_screen_loading', false);
      });
    }

    this.forceNext();
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.forceNext();
      }
    },
  },
  methods: {
    forceNext() {
      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      const selectIdx = newQuery['uni_level__' + this.draw_index];

      if (selectIdx != null && this.getObjectFromQueryIdx != null) {
        this.$emit('next_select', this.draw_index, this.getObjectFromQueryIdx(selectIdx), selectIdx);
      }
    },
  },
};
