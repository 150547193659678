<template>
  <div class="map-wrap">

    <template v-if="this.showValueData">
      Максимальный объем: {{getMaxValue(this.draw_data, calcParamName).toFixed(0)}} тонн <br>
      Суммарный объем: {{getSumValue(this.draw_data, calcParamName).toFixed(0)}} тонн<br><br>
    </template>

    <div class="ymap-container" id="map"></div>
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function getMaxValue(array, paramName) {
  let maxValue = 0;

  // eslint-disable-next-line guard-for-in
  for (const idx in array) {
    maxValue = Math.max(maxValue, array[idx][paramName]);
  }
  return maxValue;
}

function getSumValue(array, paramName) {
  let sumValue = 0;

  // eslint-disable-next-line guard-for-in
  for (const idx in array) {
    sumValue += array[idx][paramName];
  }
  return sumValue;
}

// let curMap = null;

export default builder({
  components: {

  },
  data: () => ({
    showValueData: false,
    calcParamName: 'weight',
  }),
  computed: {

  },
  watch: {

  },
  methods: {
    getMaxValue(array, paramName) {
      return getMaxValue(array, paramName);
    },
    getSumValue(array, paramName) {
      return getSumValue(array, paramName);
    },
    rowSelected(data) {
      this.$emit('next_select', this.draw_index, {
        modalCaption: this.getDrawData[data].name,
        next_level: this.draw_data[data].next_level,
        next_type_data: this.draw_data[data].next_type_data,
      });
    },
  },
  emits: {
    next_select: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
  beforeDestroy() {
    if (window.curMap != null) {
      window.curMap.destroy();
      document.getElementById('map').innerHTML = '';
      window.curMap = null;
    }
  },
  mounted() {
    const address = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=<ваш API-ключ>';

    if (window.ymaps == null) {
      const yamapScript = document.createElement('script');
      yamapScript.setAttribute('src', address);
      document.head.appendChild(yamapScript);
    }

    const worker = () => {
      window.ymaps.ready(() => {
        let center = [55.733835, 42.588227];

        if (this.draw_info.map_type === 'single') {
          this.showValueData = false;
          if (this.draw_data && this.draw_data.length > 0) {
            center = [this.draw_data[0].coords.latitude, this.draw_data[0].coords.longitude];
          }
        } else {
          this.showValueData = this.draw_info.showValueData == true;
        }

        if (window.curMap != null) {
          window.curMap.destroy();
          document.getElementById('map').innerHTML = '';
          window.curMap = null;
        }

        try {
          window.curMap = new window.ymaps.Map('map', {
            center: center,
            zoom: 5,
          }, {
            searchControlProvider: 'yandex#search',
          });
        } catch (err) {
          console.error('MAPERROR', err);
        }
        const myMap = window.curMap;
        window.ymaps.modules.require(['geoObject.Arrow'], (inputArrow) => {
          // const maxValue = getMaxValue(this.draw_data, this.calcParamName);

          // console.log('DRAW DATA', this.draw_data);

          this.draw_data.map((item) => {
            const normValue = 0.04;// maxValue != 0 ? item[this.calcParamName] / maxValue : 0;

            if (this.draw_info.map_type === 'single') {
              const placemark = new window.ymaps.Placemark([item.coords.latitude, item.coords.longitude], {}, {
                // Задаем стиль метки (метка в виде круга).
                preset: 'islands#circleDotIcon',
                // Задаем цвет метки (в формате RGB).
                // iconColor: '#ff0000'
              });
              placemark.events.add(['click'], () => {
                console.log(item);
              });
              myMap.geoObjects.add(placemark);
            } else {
              const curColor = hslToHex(normValue * 320, 100, 50);

              const curParams = {
                geodesic: true,
                strokeWidth: Math.max(normValue, 0.01) * 10,
                // opacity: 0.7,
                strokeStyle: 'solid',
                strokeColor: curColor,
                work_item: item,
              };

              const arrowData = [
                [parseFloat(item.coords.coordinate_from.latitude), parseFloat(item.coords.coordinate_from.longitude)],
                [parseFloat(item.coords.coordinate_to.latitude), parseFloat(item.coords.coordinate_to.longitude)]];

              const arrow = inputArrow(arrowData, null, curParams);

              arrow.events.add(['click'], () => {
                console.log(item);
              });

              myMap.geoObjects.add(arrow);
            }
          });
          return true;
        });
      });
      window.ymaps.modules.define('geoObject.Arrow', [
        'Polyline',
        'overlay.Arrow',
        'util.extend',
      ], (provide, Polyline, ArrowOverlay, extend) => {
        const Arrow = (geometry, properties, options) => new Polyline(geometry, properties,
            extend({}, options, {
              lineStringOverlay: ArrowOverlay,
            }));
        provide(Arrow);
      });
      window.ymaps.modules.define('overlay.Arrow', [
        'overlay.Polygon',
        'util.extend',
        'event.Manager',
        'option.Manager',
        'Event',
        'geometry.pixel.Polygon',
      ],
      (provide, PolygonOverlay, extend, EventManager, OptionManager, Event, PolygonGeometry) => {
        const domEvents = [
          'click',
          'contextmenu',
          'dblclick',
          'mousedown',
          'mouseenter',
          'mouseleave',
          'mousemove',
          'mouseup',
          'multitouchend',
          'multitouchmove',
          'multitouchstart',
          'wheel',
        ];
        const ArrowOverlay = function(pixelGeometry, data, options) {
          // Поля .events и .options обязательные для IOverlay.
          this.events = new EventManager();
          this.options = new OptionManager(options);
          this._map = null;
          this._data = data;
          this._geometry = pixelGeometry;
          this._overlay = null;
        };

        ArrowOverlay.prototype = extend(ArrowOverlay.prototype, {
          // Реализовываем все методы и события, которые требует интерфейс IOverlay.
          getData() {
            return this._data;
          },

          setData(data) {
            if (this._data != data) {
              const oldData = this._data;
              this._data = data;
              this.events.fire('datachange', {
                oldData,
                newData: data,
              });
            }
          },

          getMap() {
            return this._map;
          },

          setMap(map) {
            if (this._map != map) {
              const oldMap = this._map;
              if (!map) {
                this._onRemoveFromMap();
              }
              this._map = map;
              if (map) {
                this._onAddToMap();
              }
              this.events.fire('mapchange', {
                oldMap,
                newMap: map,
              });
            }
          },

          setGeometry(geometry) {
            if (this._geometry != geometry) {
              const oldGeometry = geometry;
              this._geometry = geometry;
              if (this.getMap() && geometry) {
                this._rebuild();
              }
              this.events.fire('geometrychange', {
                oldGeometry,
                newGeometry: geometry,
              });
            }
          },

          getGeometry() {
            return this._geometry;
          },

          getShape() {
            return null;
          },

          isEmpty() {
            return false;
          },

          _rebuild() {
            this._onRemoveFromMap();
            this._onAddToMap();
          },

          _onAddToMap() {
            this._overlay = new PolygonOverlay(new PolygonGeometry(this._createArrowContours()));
            this._startOverlayListening();
            this._overlay.options.setParent(this.options);
            this._overlay.setMap(this.getMap());
          },

          _onRemoveFromMap() {
            this._overlay.setMap(null);
            this._overlay.options.setParent(null);
            this._stopOverlayListening();
          },

          _startOverlayListening() {
            this._overlay.events.add(domEvents, this._onDomEvent, this);
          },

          _stopOverlayListening() {
            this._overlay.events.remove(domEvents, this._onDomEvent, this);
          },

          _onDomEvent(e) {
            this.events.fire(e.get('type'), new Event({
              target: this,
            }, e));
          },

          _createArrowContours() {
            const contours = [];
            const mainLineCoordinates = this.getGeometry().getCoordinates();
            const arrowLength = calculateArrowLength(
                mainLineCoordinates,
                this.options.get('arrowMinLength', 3),
                this.options.get('arrowMaxLength', 20),
            );
            contours.push(getContourFromLineCoordinates(mainLineCoordinates));
            if (arrowLength > 0) {
              const lastTwoCoordinates = [
                mainLineCoordinates[mainLineCoordinates.length - 2],
                mainLineCoordinates[mainLineCoordinates.length - 1],
              ];
                // Для удобства расчетов повернем стрелку так, чтобы она была направлена вдоль оси y,
                // а потом развернем результаты обратно.
              const rotationAngle = getRotationAngle(lastTwoCoordinates[0], lastTwoCoordinates[1]);
              const rotatedCoordinates = rotate(lastTwoCoordinates, rotationAngle);

              const arrowAngle = this.options.get('arrowAngle', 20) / 180 * Math.PI;
              const arrowBeginningCoordinates = getArrowsBeginningCoordinates(
                  rotatedCoordinates,
                  arrowLength,
                  arrowAngle,
              );
              const firstArrowCoordinates = rotate([
                arrowBeginningCoordinates[0],
                rotatedCoordinates[1],
              ], -rotationAngle);
              const secondArrowCoordinates = rotate([
                arrowBeginningCoordinates[1],
                rotatedCoordinates[1],
              ], -rotationAngle);

              contours.push(getContourFromLineCoordinates(firstArrowCoordinates));
              contours.push(getContourFromLineCoordinates(secondArrowCoordinates));
            }
            return contours;
          },
        });

        function getArrowsBeginningCoordinates(coordinates, arrowLength, arrowAngle) {
          const p1 = coordinates[0];
          const p2 = coordinates[1];
          const dx = arrowLength * Math.sin(arrowAngle);
          const y = p2[1] - arrowLength * Math.cos(arrowAngle);
          return [[p1[0] - dx, y], [p1[0] + dx, y]];
        }

        function rotate(coordinates, angle) {
          const rotatedCoordinates = [];
          for (let i = 0, l = coordinates.length, x, y; i < l; i += 1) {
            x = coordinates[i][0];
            y = coordinates[i][1];
            rotatedCoordinates.push([
              x * Math.cos(angle) - y * Math.sin(angle),
              x * Math.sin(angle) + y * Math.cos(angle),
            ]);
          }
          return rotatedCoordinates;
        }

        function getRotationAngle(p1, p2) {
          return Math.PI / 2 - Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
        }

        function getContourFromLineCoordinates(coords) {
          const contour = coords.slice();
          for (let i = coords.length - 2; i > -1; i -= 1) {
            contour.push(coords[i]);
          }
          return contour;
        }

        function calculateArrowLength(coords, minLength, maxLength) {
          let linePixelLength = 0;
          for (let i = 1, l = coords.length; i < l; i += 1) {
            linePixelLength += getVectorLength(
                coords[i][0] - coords[i - 1][0],
                coords[i][1] - coords[i - 1][1],
            );
            if (linePixelLength / 3 > maxLength) {
              return maxLength;
            }
          }
          const finalArrowLength = linePixelLength / 3;
          return finalArrowLength < minLength ? 0 : finalArrowLength;
        }

        function getVectorLength(x, y) {
          return Math.sqrt(x * x + y * y);
        }

        provide(ArrowOverlay);
      });
    };

    let interval = null;

    interval = setInterval(()=>{
      if (window.ymaps !== undefined) {
        clearInterval(interval);
        fetch(address).then(worker);
      }
      // console.log('aaaaa',window.ymaps);
    }, 10);
  },
});
</script>

<style  lang="less">
  .ymap-container {
    height: 800px;
  }

  @media only screen and (max-height: 1290px) {
    .ymap-container {
      height: 700px;
    }
  }

  @media only screen and (max-height: 1170px) {
    .ymap-container {
      height: 600px;
    }
  }

  @media only screen and (max-height: 1040px) {
    .ymap-container {
      height: 500px;
    }
  }

  @media only screen and (max-height: 900px) {
    .ymap-container {
      height: 400px;
    }
  }
</style>
