<template>
  <div class="dropdown-input">
    <div ref="input_field" @click="this.input_field__click">
      <InputField class="dropdown-input__input-block"
                  ref="input_field_component"
                  :class="{'no-pointer-events': !this.is_filterable && false}"
                  :set_values="this.get_inputfield_value"
                  :input_field_status="this.get_field_status"
                  :label_text="this.label_text"
                  :style_type="this.style_type"
                  :block_edit="!this.is_filterable"
                  :is_required="this.is_required"
                  @focus_change="this.focus_change"
                  @keyups="this.change_sort"
      />
      <div v-if="!this.is_filterable" style="position: absolute; inset: 0;"></div>
      <Icon class="dropdown-arrow" name="caret-circle-down"
            v-show="this.style_type === 'default'"
            style="background-color: #6ec75681;"
            :style="{transform: this.is_dropped_down ? 'rotate(180deg)' : 'rotate(0deg)'}"
            @click="this.closeOpenIconClick"/>
    </div>

    <div v-if="this.is_dropped_down && !this.get_is_mobile_info"
         class="dropdown-area"
         ref="dropdown">
      <div class="dropdown-items"
           :key="forceUpdate">
        <div v-for="(item) in this.dropdown_items" :key="item.id">
          <div v-if="item.is_filtered" class="dropdown-row">
            <CheckBoxComponent class="dropdown-item"
                               v-if="this.is_multi_select"
                               :label_text_right="item.value_show"
                               :init_value="item.is_selected"
                               @input_check="(data)=>this.click_check_box(item,data)"/>
            <LabelComponent v-else
                            class="dropdown-item"
                            :class="{'dropdown-item__selected': item.is_selected}"
                            :label_text="item.value_show"
                            @click="this.click_label(item)"/>
            <Icon class="favorite-button" :name="item.is_favorite === true ? 'favorite_full' : 'favorite_empty'"
                  sizeHeight="20"
                  sizeWidth="20"
                  v-if="this.is_favoriteble === true"
                  @click="favoriteChange(item)"
            />
          </div>
        </div>
      </div>
      <div class="dropdown-btns" v-if="this.is_multi_select || (this.is_extendable && this.input_data.length)">
        <SeparatorComponent/>
        <ButtonComponent class="dropdown-btn"
                         v-if="this.is_extendable"
                         :label="loc('Добавить') + ' ' + this.input_data"
                         elementType="default"
                         iconType="plus"
                         @click="addNewRecord()"/>
        <ButtonComponent class="dropdown-btn"
                         v-if="this.is_multi_select"
                         :label="loc('Отметить всё')"
                         elementType="default"
                         iconType="plus"
                         @click="selectAll()"/>
        <ButtonComponent class="dropdown-btn"
                         v-if="this.is_multi_select"
                         :label="loc('Снять всё')"
                         elementType="default"
                         iconType="minus"
                         @click="unselectAll()"/>
      </div>
    </div>
    <ModalView v-else-if="this.is_dropped_down"
               ref="modal_view_ref"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               :caption="this.loc('Выбор пунктов')"
               @click_close="this.closeDropDown()">
      <div class="modal-area-wrapper">
        <div ref="input_field">
          <InputField class="dropdown-input__input-block"
                      ref="input_field_component"
                      :class="{'no-pointer-events': !this.is_filterable && false}"
                      :set_values="this.get_inputfield_value"
                      :input_field_status="this.get_field_status"
                      :label_text="this.label_text"
                      :style_type="this.style_type"
                      :block_edit="!this.is_filterable"
                      @focus_change="this.focus_change"
                      @keyups="this.change_sort"
          />
        </div>
        <div class="dropdown-area dropdown-area-modal">
          <div class="dropdown-items"
               :key="forceUpdate">
            <div v-for="(item) in this.dropdown_items" :key="item.id">
              <div v-if="item.is_filtered">
                <CheckBoxComponent class="dropdown-item"
                                   v-if="this.is_multi_select"
                                   :label_text_right="item.value_show"
                                   :init_value="item.is_selected"
                                   @input_check="(data)=>this.click_check_box(item,data)"/>
                <LabelComponent v-else
                                class="dropdown-item"
                                :class="{'dropdown-item__selected': item.is_selected}"
                                :label_text="item.value_show"
                                @click="this.click_label(item)"/>
                <Icon class="favorite-button" :name="item.is_favorite === true ? 'favorite_full' : 'favorite_empty'"
                      sizeHeight="20"
                      sizeWidth="20"
                      v-if="this.is_favoriteble === true"
                      @click="favoriteChange(item)"
                />
              </div>
            </div>
          </div>
          <div class="dropdown-btns" v-if="this.is_multi_select || (this.is_extendable && this.input_data.length)">
            <SeparatorComponent/>
            <ButtonComponent class="dropdown-btn"
                             v-if="this.is_extendable"
                             :label="loc('Добавить') + ' ' + this.input_data"
                             elementType="default"
                             iconType="plus"
                             @click="addNewRecord()"/>
            <ButtonComponent class="dropdown-btn"
                             v-if="this.is_multi_select"
                             :label="loc('Отметить всё')"
                             elementType="default"
                             iconType="plus"
                             @click="selectAll()"/>
            <ButtonComponent class="dropdown-btn"
                             v-if="this.is_multi_select"
                             :label="loc('Снять всё')"
                             elementType="default"
                             iconType="minus"
                             @click="unselectAll()"/>
          </div>
        </div>
      </div>
    </ModalView>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import InputField from './InputField';
import ButtonComponent from './Button';
import SeparatorComponent from './Separator';
import LabelComponent from './Label';
import CheckBoxComponent from './CheckBox';
import ModalViewConductor from '../navigation/ModalViewConductor';
import ModalView from '../ModalView';
import levenshtein from '../../assets/v1/js/packs/levenshtein';
import Icon from '@/components/unit/Icon';

export default builder({
  name: 'DropdownInput',
  components: {
    InputField,
    CheckBoxComponent,
    ButtonComponent,
    SeparatorComponent,
    LabelComponent,
    Icon,
    ModalView,
    ModalViewConductor,
  },
  data: function() {
    return {
      forceUpdate: 0, // принудительно ре-рендерит список
      is_dropped_down: false, // признак открытия выпадающего списка
      filter_mode: false, // режим фильтра
      filter_field: '', // текущее значения поля Фильтр
      input_data: '',
      dropdown_items: this.dropdown_items_init, // храним значения выпадающих меню. Начальные значения из пропса
      dropped_list_updated: undefined, // признак, что был произведен выбор после открытия списка
    };
  },
  emits: {
    input_change: null,
    favorite_change: null,
    observerTargetAdd: null,
    filterChange: null,
  },
  props: {
    is_extendable: {
      type: Boolean,
      default: false,
    }, //  Возможность добавления своих значений
    is_filterable: {
      type: Boolean,
      default: false,
    }, //  Возможность фильтрации
    is_multi_select: {
      type: Boolean,
      default: false,
    }, // Возможность выбрать несколько элементов
    addButton: {
      type: Boolean,
      default: false,
    }, // Отображение кнопки добавления
    dropdown_items_init: {
      type: Array,
      required: false,
    }, // Список начальных значений
    label_text: { // Заголовок
      type: String,
      default: 'Заголовок по умолчанию',
    },
    init_selected_item: { // Изначально выбранные объекты
      required: false,
      default: [],
    },
    input_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    style_type: {
      type: String,
      validator: (prop) => ['default', 'light'].includes(prop),
      default: 'default',
    },
    error_text: {
      type: String,
      default: 'Неизвестная ошибка, обратитесь к администратору',
    },
    is_required: {
      type: Boolean,
      default: false,
    },
    is_favoriteble: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dropdown_items_init() {
      this.dropdown_items = this.dropdown_items_init; // Если изменились базовые значения, сбрасываемся до них
      this.calc_dropdown_items();
    },
    is_dropped_down(value) {
      if (value) {
        // добавление цели для обзервера в PaginatedDropDown
        this.$emit('observerTargetAdd');
      }
    },
    init_selected_item() {
      this.dropdown_items = this.dropdown_items_init; // Если изменились базовые значения, сбрасываемся до них
      this.calc_dropdown_items();
    },
  },
  created() {
    document.addEventListener('click', this.checkCloseDropdown);
    // подготовка данных для выпадающего списка
    this.calc_dropdown_items();
  },
  computed: {
    get_inputfield_value() {
      if (this.filter_mode && !this.is_extendable) return this.input_data;
      return this.dropdown_items
          // отфильтровываем только выбранные значения
          .filter((item) => item.is_selected)
          // объединяем выбранные значения в одну строку через запятую
          .map((item) => item.value_show).join(', '); // состояние InputField;
    },
    get_selected_items() {
      // возвращает объект где только выбранные элементы
      return this.dropdown_items
          .filter((item) => item.is_selected);
    },
    get_field_status() {
      return this.is_dropped_down ? 'focused' : this.input_field_status;
    },
  },

  unmounted() {
    document.removeEventListener('click', this.checkCloseDropdown);
  },
  methods: {
    favoriteChange(field) {
      field.is_favorite = !field.is_favorite;
      this.$emit('favorite_change', field);
    },
    send_event_to_parent() {
      // посылаем родителю emit только в том случае если были изменения
      if (this.dropped_list_updated) this.$emit('input_change', this.get_selected_items);
    },
    focus_change() {
      if (this.is_filterable) {
        // вкл/выкл режим фильтрации
        this.filter_mode = true;
      }
    },
    change_sort(data) {
      if (this.filter_mode) {
        // в фильтр режиме когда пришло значение поля Фильтр сделаем сортировку списка
        this.recalculateDrawData(data);
        // для фильтра в PaginatedDropDown
        this.$emit('filterChange', data);
      }
    },
    closeOpenIconClick(event) {
      if (this.input_field_status === 'disabled') {
        return;
      }

      if (this.is_dropped_down) {
        event.stopPropagation();
        this.closeDropDown();
      } else {
        this.$refs.input_field_component.set_focus();
        this.focus_change();
      }
    },
    input_field__click(event) {
      if (this.input_field_status === 'disabled') {
        return;
      }

      // открытие выпадающего списка
      this.dropdown_items.forEach((a) => a.is_filtered = true);
      this.dropped_list_updated = false; // признак, что был произведен выбор обнуляем
      if (this.is_filterable) {
        this.is_dropped_down = true;
      } else {
        if (this.is_dropped_down) {
          this.closeDropDown();
        } else {
          this.is_dropped_down = true;
        }
      }
    },
    checkCloseDropdown(event) {
      if (!this.is_dropped_down || (this.is_dropped_down && this.get_is_mobile_info)) {
        return;
      }

      // закрытие выпадающего списка если кликнули вне
      const containerDropdown = this.$refs.dropdown;
      const containerInput = this.$refs.input_field;
      if (!containerDropdown.contains(event.target) &&
          (!containerInput.contains(event.target)) && this.is_dropped_down) {
        this.closeDropDown();
        this.dropdown_items = this.dropdown_items;
      }
    },
    closeDropDown() {
      this.is_dropped_down = false;
      this.filter_mode = false;
      this.addNewRecord();
      // сообщаем родителю об окончательном выборе (для типа multiselect)
      if (this.is_multi_select) this.send_event_to_parent();
    },
    calc_dropdown_items() {
      // добавляем ID элементов, init_selected_item которых есть, объект dropdown_items клонируется с помошью map
      this.dropdown_items = this.dropdown_items.map((item, index) => {
        const initSelected = this.init_selected_item ? this.init_selected_item.includes(item.return_value) : false;
        return {id: index, is_selected: initSelected, ...item, is_filtered: true};
      });
      const isSomethingSelected = this.dropdown_items.find((item) => item.is_selected);
      if (this.is_extendable && !isSomethingSelected && this.init_selected_item.length) {
        this.init_selected_item.forEach((item) => {
          this.addNewRecord(item);
        });
      }
    },
    click_label(currentItem) {
      this.dropped_list_updated = true; // признак, что были изменения
      this.dropdown_items.forEach((item) => { // выбранному элементу присваиваем true, остальным false
        item.is_selected = (item === currentItem);
      });
      // закрываем меню
      this.is_dropped_down = false;
      // выключаем режим Фильтра
      this.filter_mode = false;
      // сообщаем родителю об окончательном выборе (для типа не multiselect)
      this.send_event_to_parent();
    },
    click_check_box(item, data) {
      this.dropped_list_updated = true;
      item.is_selected = data;
    },
    recalculateDrawData(data) {
      // сохраняем текущее значение
      this.input_data = data;
      // в выпадающий список добавляем поле dist по алгоритму Левенштейна
      if (!data.length) {
        this.dropdown_items.forEach((a) => a.is_filtered = true);
        return;
      }
      const inpdata = data.toLowerCase().replace(/е/g, 'ё');
      this.dropdown_items.forEach((item) => {
        const lowerText = item.value_show.toLowerCase().replace(/е/g, 'ё');
        item.dist = levenshtein.calc_dist(inpdata, lowerText) * (lowerText.includes(inpdata) ? -1 : 1);
      });
      // фильтруем список по dist
      this.dropdown_items.forEach((a) => a.dist <= 0 ? a.is_filtered = true : a.is_filtered = false);
      // сортируем список по dist
      this.dropdown_items = this.dropdown_items.sort((a, b) => {
        if (a.dist < 0 && b.dist < 0) {
          return b.dist - a.dist;
        }
        return a.dist - b.dist;
      });
    },
    // добавляется новое значение в список и автоматически выбирается
    addNewRecord(customRecord = '') {
      if (customRecord.length) {
        this.input_data = customRecord;
      }
      if (!this.input_data.length) {
        return;
      }
      const item = this.dropdown_items.find((item) => item.return_value === this.input_data);
      if (item) {
        this.click_label(item);
        return;
      }

      if (!this.is_extendable) {
        this.input_data = '';
        return;
      }

      const newItem = {
        id: this.dropdown_items.length,
        is_filtered: true,
        is_selected: true,
        return_value: this.input_data,
        value_show: this.input_data,
      };
      this.dropdown_items.splice(this.dropdown_items.length, 0, newItem);
      this.click_label(this.dropdown_items[this.dropdown_items.length - 1]);
      this.input_data = '';
      this.forceUpdate++;
    },
    // выбираются все значения
    selectAll() {
      this.dropped_list_updated = true;
      this.dropdown_items = this.dropdown_items.map((item) => {
        if (item.is_filtered) return {...item, is_selected: true};
        return item;
      });
      this.forceUpdate++;
    },
    // отменяется выбор всех значений
    unselectAll() {
      this.dropped_list_updated = true;
      this.dropdown_items = this.dropdown_items.map((item) => {
        if (item.is_filtered) return {...item, is_selected: false};
        return item;
      });
      this.forceUpdate++;
    },
  },
});
</script>


<style lang="less">
@import url('../../assets/v1/less/base.less');

@hover__single__dd__elem_background-color: @light-green-opacity-10;
@select__single__dd__elem_background-color: @light-green;

.dropdown-input * {
  cursor: pointer;
}

.dropdown-input {
  position: relative;
  width: 100%;

  .dropdown-arrow {
    position: absolute;
    right: 10px;
    top: 20px;
    transition: all 0.1s ease 0s;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  &__input-block {
    position: relative;

    // background-color: #eb5050;
    div {
      // background-color: #eb5050;
      padding-right: 20px;
    }
  }

  .dropdown-area {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin-top: 10px;
    padding: 12px 0;
    z-index: 500;
    background: @white;
    border-radius: 10px;
    box-shadow: 0 13px 40px rgba(46, 50, 45, 0.08);
    .set_custom_scrollbar(4px, 4px, 4px, 0, 0);

    .flex(column, flex-start, stretch);

    .dropdown-items {
      max-height: 340px;
      overflow: auto;
      word-break: break-word;
    }

    .dropdown-row {
      .flex(row, space-between, center);

      .favorite-button {
        margin-right: 7px;
      }
    }

    .dropdown-item {
      width: 100%;
      padding: 4px 15px;

      &__selected {
        color: @green;
      }

      &:hover {
        background: @light-green-opacity-10;
        cursor: pointer;
      }
    }

    .dropdown-btns {
      margin: 0 6px;
      padding: 0 6px;
      .flex(column, flex-start, flex-start);
    }

    .dropdown-btn {
      margin-top: 6px;
    }

    .dropdown-content-wrapper {
      .flex(column, flex-start, stretch);

      .add-elem-button {
        align-self: start;
      }

      .single-dropdown-element {
        background-color: @select__single__dd__elem_background-color;

        &__not-selected {
          background-color: transparent;

          &:hover {
            background-color: @hover__single__dd__elem_background-color;
          }
        }
      }
    }
  }

  .dropdown-area-modal {
    position: relative;
    top: 0;
    max-height: 340px;
    height: calc(100% - 58px);
    box-shadow: none;
    padding: 0;

    .dropdown-items {
      max-height: 340px;
    }

    .dropdown-item {
      padding: 4px 15px 4px 0;
    }
  }

  .modal-area-wrapper {
    width: 250px;
    height: calc(90vh - (56px + 70px));
    max-height: calc(340px + 58px);
  }
}


</style>
