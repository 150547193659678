<template>
  <BasePage :name="this.getPageName" :is_with_background="this.show_background">

    <div class="uni-report"
         :class="{'uni-report__padding': this.show_background}">

      <ButtonComponent class="edit-struct-button"
                     :label="this.loc('Добавить / Удалить')"
                     elementType="hover"
                     elementStatus="selected"
                     iconType="plus"
                     iconPosition="left"
                     @click_to_button="this.enter_edit_mode()"/>

      <ButtonComponent v-if="this.is_delete_mode"
                     :label="this.loc('Выйти из режима удаления')"
                     elementType="default-bg"
                     @click_to_button="() => { this.enter_edit_mode(); this.close_edit_mode();  }"/>

      <component
          v-if="this.views.length > 0 && !this.noData"
          ref="firstView"
          :is="this.views[0].component"
          :draw_index="0"
          :draw_info="this.views[0].draw_info"
          :draw_data="this.views[0].draw_data"
          @download_files="this.download_files"
          @update_multi_select="(selectItems) => this.updateMultiSelect(selectItems, 0)"
          @next_select="this.changeViewSelect">
      </component>

      <LabelComponent v-if="this.noData"
                      :label_text="this.loc(this.noDataText)"
      />

    </div>
    <template v-slot:modal>
      <ModalViewConductor ref="modalViewConductor">
        <template v-slot="{level, rev_level}">
          <ModalView
                     :open_level="rev_level"
                     :type_modal="this.views[level].modal_type"
                     :ref="'modal_'+level"
                     :caption="this.views[level].modalCaption"
                     @click_close="this.closed_modal_view_first"
                     @click_closing="this.$refs.modalViewConductor.close_level_shadow();">

            <ButtonComponent class="edit-struct-button"
                     :label="this.loc('Добавить / Удалить')"
                     elementType="hover"
                     elementStatus="selected"
                     iconType="plus"
                     iconPosition="left"
                     @click_to_button="this.enter_edit_mode()"/>

            <ButtonComponent v-if="this.is_delete_mode"
                     :label="this.loc('Выйти из режима удаления')"
                     elementType="default-bg"
                     @click_to_button="() => { this.enter_edit_mode(); this.close_edit_mode();  }"/>

            <component
                :is="this.views[level].component"
                :draw_index="level"
                :draw_info="this.views[level].draw_info"
                :draw_data="this.views[level].draw_data"
                :before_total="this.views[level].before_total"
                @download_files="this.download_files"
                @next_select="this.changeViewSelect"
                @update_multi_select="(selectItems) => this.updateMultiSelect(selectItems, level)"
                @close_view="this.closeView">
            </component>
          </ModalView>
        </template>
      </ModalViewConductor>

      <ModalView v-if="this.is_edit_mode && !this.is_delete_mode"
                type_modal="internal"
                :caption="this.loc('Изменение структуры')"
                @click_close="this.close_edit_mode">

        <ButtonComponent  :label="this.loc('Режим удаления')"
                          elementType="default-bg"
                          labelType="button/small/medium 14"
                          @click_to_button="this.enter_delete_mode"/>

        <div class="flex-line">
          <InputField ref="upload_files"
                      type="file"
                      :is_all_time_open="true"
                      :label_text="this.loc('Загружаемые файлы')"
                      @input_change="this.fileFieldChanged"/>

          <ButtonComponent  :label="this.loc('Добавить файл')"
                            :elementStatusModifier="!this.sendFile ? 'disabled' : undefined"
                            elementType="default-bg"
                            labelType="button/small/medium 14"
                            @click_to_button="this.send_files"/>
        </div>

        <div class="flex-line">
          <InputField ref="new_folder_name"
                      type="text"
                      :set_values="this.loc('Новая папка')"
                      :is_all_time_open="true"
                      :label_text="this.loc('Наименование папки')"/>

          <ButtonComponent  :label="this.loc('Добавить папку')"
                            elementType="default-bg"
                            labelType="button/small/medium 14"
                            @click_to_button="this.create_folder"/>
        </div>
      </ModalView>

      <ModalView v-if="this.delete_path"
                type_modal="internal"
                :caption="this.loc('Удаление объекта')"
                @click_close="this.cancel_delete">

        <LabelComponent :label_text="this.loc('Вы действително хотите удалить: \'\'') + this.delete_path + '\'\' ?'"/>

        <div class="flex-line">
          <ButtonComponent  :label="this.loc('Да')"
                              elementType="default-bg"
                              labelType="button/small/medium 14"
                              @click_to_button="this.delete_object"/>

          <ButtonComponent  :label="this.loc('Нет')"
                              elementType="default-bg"
                              labelType="button/small/medium 14"
                              @click_to_button="this.cancel_delete"/>
        </div>
      </ModalView>

    </template>

  </BasePage>

</template>

<script>
import BasePage from '../../components/navigation/BasePage';
import builder from '../../assets/v1/js/builder';
import ModalViewConductor from '../../components/navigation/ModalViewConductor';
import ModalView from '../../components/ModalView';
import LabelComponent from '../../components/unit/Label';
import ButtonComponent from '../../components/unit/Button';
import InputField from '../../components/unit/InputField';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';
import {markRaw} from '@vue/reactivity';

import uniTable from '@/uni_page_components/views/uni_table';
import uniDownloadFileAction from '../../uni_page_components/actions/uni_download_file_action';

const accordanceViews={
  'table': uniTable,
  'download_file': uniDownloadFileAction,
};

export default builder({
  name: 'UniPage',
  components: {
    BasePage,
    ModalViewConductor,
    ModalView,
    LabelComponent,
    ButtonComponent,
    InputField,
  },
  data: ()=>({
    _availableReports: undefined, // доступные отчёты
    currentReportSrc: 'file_storage/get_files',
    loading: false, // статус загрузки
    isFirstPreRequest: true,
    noData: false,
    noDataText: '',
    isFormChanged: false,

    rawInputFields: {},
    views: [], // массив объектов с оутпутами для модальных окон
    multiViews: [],
    auto_first_request: false,
    show_generate_button: true,
    show_export_to_excel: true,
    show_background: true,
    download_files_window: false,
    download_files_window_info: null,
    download_file_resource: null,
    download_files_list: null,
    is_edit_mode: false,
    is_delete_mode: false,
    delete_path: undefined,
    sendFile: undefined,
  }),
  mounted() {
    window.escapeableElements.push(this.close_all_levels);

    this.isFirstPreRequest = true;
    this.resetParams();
    this.rebuildViews();
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.rebuildViewsFromParams();
        return;
      }
      this.isFirstPreRequest = true;
      // при переключении роутера заново инициализируем параметры
      this.resetParams();
      this.rebuildViews();
    },
  },
  computed: {
    getPageName() {
      const ans = this.loc(this.$store.state.navigate_settings.current_page_info.name);
      return ans;
    },
    getCurrentLocalPath() {
      const curQuery = JSON.parse(JSON.stringify(this.$route.query));

      const number = curQuery['uni_level__' + (this.views.length-2)];

      const localPath = this.views[this.views.length-2] ?
       this.views[this.views.length-2].__orig__.next_level[number].columns.File.path :
       '';

      return localPath;
    },
  },
  methods: {
    getNewFile() {
      let newFiles = this.$refs.upload_files;

      if (!newFiles) {
        return undefined;
      }

      newFiles = newFiles.get_files();
      this.sendFile = newFiles[0];
      return this.sendFile;
    },
    fileFieldChanged() {
      this.getNewFile();
    },
    create_folder() {
      const uploadFolder = this.getCurrentLocalPath;
      const newFolderName = uploadFolder.length > 0 ?
       uploadFolder + '/' + this.$refs.new_folder_name.data_values :
       this.$refs.new_folder_name.data_values;

      const formData = new FormData();
      formData.append('new_folder_path', newFolderName);
      formData.append('page_id', this.$store.state.navigate_settings.current_page_info.id);

      const options = {
        method: 'POST',
        credentials: 'include',
        body: formData,
      };

      fetch(`${ROOT_URL}/api/v1/file_storage/upload`, options).then(
          (data)=>{
            if (!data.ok) {
              return Promise.reject(new Error(data.statusText));
            }

            window.location.reload();
          },
      ).catch((errData) => {
        // обработка ошибок
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: errData,
        }]);
      });
    },
    send_files() {
      const uploadFolder = this.getCurrentLocalPath;

      const formData = new FormData();
      formData.append('upload_file', this.getNewFile());
      formData.append('upload_folder', uploadFolder);
      formData.append('page_id', this.$store.state.navigate_settings.current_page_info.id);

      const options = {
        method: 'POST',
        credentials: 'include',
        body: formData,
      };

      fetch(`${ROOT_URL}/api/v1/file_storage/upload`, options).then(
          (data)=>{
            if (!data.ok) {
              return Promise.reject(new Error(data.statusText));
            }
            window.location.reload();
          },
      ).catch((errData) => {
        // обработка ошибок
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: errData,
        }]);
      });
    },
    delete_object() {
      // this.delete_path = undefined;
      // window.location.reload();

      const formData = new FormData();
      formData.append('file_path', this.delete_path);
      formData.append('page_id', this.$store.state.navigate_settings.current_page_info.id);

      const options = {
        method: 'POST',
        credentials: 'include',
        body: formData,
      };

      fetch(`${ROOT_URL}/api/v1/file_storage/delete`, options).then(
          (data)=>{
            if (!data.ok) {
              return Promise.reject(new Error(data.statusText));
            }
            this.delete_path = undefined;
            window.location.reload();
          },
      ).catch((errData) => {
        // обработка ошибок
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: errData,
        }]);
      });
    },
    enter_delete_mode() {
      this.is_delete_mode = true;
    },
    enter_edit_mode() {
      this.is_edit_mode = true;
      this.is_delete_mode = false;
    },
    cancel_delete() {
      this.delete_path = undefined;
    },
    close_edit_mode() {
      this.is_edit_mode = false;
      // this.is_delete_mode = false;
    },
    close_all_levels() {
      for (let i = 1; i < this.views.length; i++) {
        this.closed_modal_view_first();
      }
      this.$router.push({query: {}});
    },
    updateMultiSelect(selectItems, level) {
      if (selectItems != null && selectItems.length > 0) {
        this.multiViews[level] = {
          cur_view: this.views[level],
          action_data: this.views[level].draw_info.multiselect_action,
          action_component: markRaw(multiSelectActions[this.views[level].draw_info.multiselect_action.type]),
          selected_items: selectItems,
        };
      } else {
        this.multiViews[level] = null;
      }
    },
    rebuildViewsFromParams() {
      if (this.views.length < 1) {
        this.$router.push({query: {}});
        return;
      }

      // const firstView = this.views[0].__orig__;
      this.views.length = 1;
      this.$refs.modalViewConductor.clearAll();
    },
    resetParams() {
      this.$refs.modalViewConductor.clearAll();
      this._availableReports = undefined;
      this.rawInputFields = {};
      this.show_generate_button = true,
      this.show_export_to_excel = true,
      this.views.length = 0;
      this.isFormChanged = false;
      this.auto_first_request = false;
      this.show_background = true;
      this.noDataText = 'Нет файлов и папок';
      // this.requestInputs(); // первоначально запрашиваем инпуты
    },
    // метод собирающий данные по инпутам в один новый объект и подготавливающий данные для запроса
    getReturnValues: function() {
      // создаём новый объект
      const result={input_fields: {}};
      result.lang_code = this.$store.state.localize_settings.cur_lang;
      return result;
    },
    rebuildViews() {
      if (this.loading) {
        return;
      }

      this.isFormChanged = false;
      this.isFirstPreRequest = false;

      this.noData = false;
      this.loading=true; // включаем спиннер на кнопке

      // делаем задержку на 10мс, чтобы жождаться закрытия всех дропдаунов, если таковые имеются
      setTimeout(()=> {
        const result=this.getReturnValues();
        result.page_id = this.$store.state.navigate_settings.current_page_info.id;

        // console.log('сформирован запрос на сервер ', result );
        this.views.length = 0;
        const options={
          url: `${ROOT_URL}/api/v1/${this.currentReportSrc}`,
          method: 'POST',
          body: {params: result},
        };
        // запрос данных на основе инпутов
        dataRequester(options)
            .then((data)=>{
              if (data.result == null || data.result.packed_data == null) {
                throw new Error('Не пришел отчет с сервера!');
              }

              this.changeViewSelect(-1, data.result.packed_data, 0);
            })
            .catch((errData) => {
              throw new Error(errData);
            })
            .finally(()=>{
              this.loading=false; // выключаем спиннер на кнопке
            });
      }, 10);
    },
    setView(index, view) {
      this.views.length = index;

      const curView = accordanceViews[view.next_type_data.type];
      if (curView == null) {
        console.warn('View type not found!', view.next_type_data.type, 'Level:', index, 'View:', view);
        return;
      }

      this.views.push({
        component: markRaw(curView),
        modal_type: view.next_type_data.modal_type === 'fly' ? 'internal' : 'bottom',
        modalCaption: view.modalCaption,
        draw_info: view.next_type_data,
        draw_data: view.next_level,
        before_total: view.before_total,
        __orig__: view,
      });

      return true;
    },
    closeView(clickIndex) {
      this.$refs['modal_'+clickIndex].close_window();
    },
    download_files(filableObject) {
      this.download_files_window_info = filableObject;
      this.download_files_list = null;
      this.download_files_window = true;
      this.get_files_list(filableObject.resource, filableObject.guid);
    },
    get_files_list(resource, guid) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${resource}`,
        method: 'POST',
        body: {params: {'GUID': guid}},
      };
      dataRequester(options)
          .then((response) => {
            this.download_files_list = response.result.FilesList;
            this.download_file_resource = response.result.next_src;
          })
          .catch((errData)=> {
            console.log(errData);
          });
    },
    download_file(guid, name) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${this.download_file_resource}`,
        method: 'POST',
        responseType: 'blob',
        body: {params: {'GUID': guid}},
      };
      this.$store.commit('set_full_screen_loading', true);
      dataRequester(options)
          .then((response) => {
            downloadBLOB(response.result.url, name);
          })
          .catch((errData)=> {
            console.log(errData);
          })
          .finally(()=>{
            this.$store.commit('set_full_screen_loading', false);
          });
    },
    changeViewSelect(clickIndex, nextView, selectIdx) {
      if (clickIndex > -1 && this.is_delete_mode) {
        const clickPath = this.views[this.views.length-1].__orig__.next_level[selectIdx].columns.File.path;
        // console.log('clickPath', clickPath);
        this.delete_path = clickPath;
        return;
      }

      const nextIsHard = nextView.next_type_data.is_hard_loading;

      if (!this.__setFullLoad__ && nextIsHard) {
        this.$store.commit('set_full_screen_loading', true);
      }
      this.__setFullLoad__ = true;

      const workFunc = () => {
        const nextViewComponent = accordanceViews[nextView.next_type_data.type];
        let alreadyQuery = nextViewComponent ? nextViewComponent.ignoreHistory === true : false;

        if (clickIndex > -1 && !alreadyQuery) {
          const newQuery = JSON.parse(JSON.stringify(this.$route.query));
          alreadyQuery = newQuery['uni_level__' + clickIndex] === selectIdx;

          if (!alreadyQuery) {
            newQuery['uni_level__' + clickIndex] = selectIdx;
            this.$router.push({query: newQuery});
          }
        } else {
          if ((nextView.next_level ? nextView.next_level : []).length < 1) {
            this.noData = true;
          }
        }

        if (clickIndex < 0 || alreadyQuery) {
          this.__setFullLoad__ = false;
          if (this.setView(clickIndex + 1, nextView)) {
            if (clickIndex > -1) {
              this.$refs.modalViewConductor.up_level();
            }
          } else {
            if (nextIsHard) {
              this.$store.commit('set_full_screen_loading', false);
            }
          }
        }
      };

      if (nextIsHard === true) {
        setTimeout(workFunc, 0);
      } else {
        workFunc();
      }
    },
    closed_modal_view_first() {
      this.$refs.modalViewConductor.close_level();

      this.views.pop(); // удаляем последний объект в массиве

      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery['uni_level__' + (this.views.length-1)] = undefined;
      this.$router.push({query: newQuery});
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.flex-line {
  margin-top: 7px;
  display: flex;
  gap: 7px;
  align-items: center;
}
</style>
