<template>
  <div></div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';

export default builder({
  ignoreHistory: true,
  components: {
  },
  data: () => ({
  }),
  mixins: [uniViewMixin],
  mounted() {
    if (this.draw_data.length > 0 && this.draw_data[0].link) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = this.draw_data[0].link;
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    }
    this.$emit('close_view', this.draw_index);
  },
  computed: {
  },
  watch: {

  },
  methods: {
  },
  emits: {
    next_select: null,
    close_view: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">

</style>
