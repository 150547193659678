<template>
  <div class="date_picker">
    <div>
      <LabelComponent class="top-label"
                      v-if="this.top_label"
                      :label_text="this.top_label"
                      label_type="caption/small/regular 10"
      />
    </div>
    <div class="day-picker" v-if="!monthPickerIsOpened && !yearPickerIsOpened">

      <div class="buttons-wrapper-days">
        <LabelComponent label_text="<"
                        @click="this.drawArrowActiveBack ? addMonth(-1) : null"
                        label_type="body/large/regular 12"
                        :class="[this.drawArrowActiveBack ? 'arrow-active' : 'arrow-inactive']"/>
        <div class="label-wrapper">
          <LabelComponent label_type="body/large/regular 12"
                          :label_text="get_month_name(view_date)+' '+view_date.getFullYear().toString()"
                          @click="monthPickerIsOpened=true"/>
        </div>
        <LabelComponent label_text=">"
                        @click="this.drawArrowActiveForward ? addMonth(1) : null"
                        label_type="body/large/regular 12"
                        :class="[this.drawArrowActiveForward ? 'arrow-active' : 'arrow-inactive']"/>
      </div>

      <table class="picker-table">
        <tr class="row-wrapper">
          <td v-for="item in this.get_month_struct_full.week_names" :key="item" class="week-names">
            <LabelComponent label_type="body/large/regular 12"
                            :label_text="item.narrow"/>
          </td>
        </tr>

        <tr v-for="(itemRow) in this.get_month_struct_full.month_struct" :key="itemRow" class="row-wrapper">
            <td v-for="(itemColumn) in itemRow"
                :key="itemColumn"
                @click="isElemActive(itemColumn.day) ? setSelected(itemColumn.day): null">
                <div :class="[
                    itemColumn.no_active ? 'no-current-month' : 'current-month',
                    itemColumn.selected ? 'current-month-selected' : null,
                    !itemColumn.no_active && !isElemActive(itemColumn.day) ? 'current-month-inactive' : null,
                    !itemColumn.no_active && isElemActive(itemColumn.day) ? 'current-month-active': null,
                    'day-wrapper']">

                  <LabelComponent label_type="body/large/regular 12"
                                  :label_text="itemColumn.day.getDate()"/>
                </div>
            </td>
        </tr>
      </table>
    </div>

    <div class="month-picker" v-if="monthPickerIsOpened">
      <div class="buttons-wrapper-months">
        <LabelComponent label_text="<"
                        @click="this.drawArrowActiveBack ? addYear(-1) : null"
                        label_type="body/large/regular 12"
                        :class="[this.drawArrowActiveBack ? 'arrow-active' : 'arrow-inactive']"/>
        <div class="label-wrapper">
          <LabelComponent label_type="body/large/regular 12"
                          :label_text="view_date.getFullYear().toString()"
                          @click="yearPickerIsOpened=true; monthPickerIsOpened=false"/>
        </div>

        <LabelComponent label_text=">"
                        @click="this.drawArrowActiveForward ? addYear(1) : null"
                        label_type="body/large/regular 12"
                        :class="[this.drawArrowActiveForward ? 'arrow-active' : 'arrow-inactive']"/>
      </div>
      <table class="picker-table">
        <tr v-for="(itemRow) in this.get_month_struct_full.months" :key="itemRow"
            class="row-wrapper"
            style="height: 25px" >
          <td v-for="(itemColumn) in itemRow"
              :key="itemColumn"
              @click="this.isElemActive(itemColumn.date) ? setView(itemColumn.date): null">
            <div :class="[itemColumn.selected ? 'month-selected' : 'year',
                          this.isElemActive(itemColumn.date) ? 'month-active': 'month-inactive',
                          'month-wrapper']">
              <LabelComponent label_type="body/large/regular 12"
                              :label_text="itemColumn.text"
                              @click="this.isElemActive(itemColumn.date) ? monthPickerIsOpened=false: null"/>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="year-picker" v-if="yearPickerIsOpened">
      <div class="buttons-wrapper-years">
        <LabelComponent label_text="<"
                        @click="this.drawArrowActiveBack ? addYear(-12) : null"
                        label_type="body/large/regular 12"
                        :class="[this.drawArrowActiveBack ? 'arrow-active' : 'arrow-inactive']"/>
        <div class="label-wrapper">
          <LabelComponent label_type="body/large/regular 12"
                          :label_text="this.get_month_struct_full.dozen[0][0].date.getFullYear()
                                +' - '+this.get_month_struct_full.dozen[3][2].date.getFullYear()"/>
        </div>
        <LabelComponent label_text=">"
                        label_type="body/large/regular 12"
                        @click="this.drawArrowActiveForward ? addYear(12) : null"
                        :class="[this.drawArrowActiveForward ? 'arrow-active' : 'arrow-inactive']"/>
      </div>
      <table class="picker-table">
        <tr v-for="(itemRow) in this.get_month_struct_full.dozen" :key="itemRow"
            class="row-wrapper"
            style="height: 25px">
          <td v-for="(itemColumn) in itemRow"
              :key="itemColumn"
              @click="this.isElemActive(itemColumn.date) ? setView(itemColumn.date) : null"
              :class="[itemColumn.selected ? 'month-selected' : 'month',
                       this.isElemActive(itemColumn.date) ? 'month-active': 'month-inactive',
                       'month-wrapper']">
            <LabelComponent label_type="body/large/regular 12"
                            :label_text="itemColumn.date.getFullYear().toString()"
                            @click="this.isElemActive(itemColumn.date)? this.switchToMonths() : null"/>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import LabelComponent from './Label';
import Button from './Button';

function compareDates(dateA, dateB, ignoreDate, ignoreMonth) {
  return (dateA.getFullYear() === dateB.getFullYear() &&
      (dateA.getMonth() === dateB.getMonth() || ignoreMonth) &&
      (dateA.getDate() === dateB.getDate() || ignoreDate));
}

function getMonthsForLocale(locale) {
  const format = new Intl.DateTimeFormat(locale, {month: 'short'});
  const months = [];
  for (let month = 0; month < 12; month++) {
    const testDate = new Date(0, month, 2);
    months.push(format.format(testDate));
  }
  return months;
}

export default builder({
  name: 'LabelTemplate',
  components: {LabelComponent, Button},
  data: function() {
    return {
      view_date: this.init_date || new Date(),
      select_date: this.init_date || new Date(),
      monthPickerIsOpened: false,
      yearPickerIsOpened: false,
    };
  },
  props: {
    day_start_week_id: 0,
    restrictions_start: {
      type: Date,
      default: new Date(-8640000000000000),
    },
    restrictions_end: {
      type: Date,
      default: new Date(8640000000000000),
    },
    init_date: {
      type: Date,
      default: new Date(),
    },
    top_label: {
      type: String,
      default: null,
    },
  },
  emits: ['select_changed', 'select_changed_by_click'],
  computed: {
    region() {
      return this.getUserLangName();
    },
    drawArrowActiveForward() {
      const viewYear = this.view_date.getFullYear();
      const restrictionYear = this.restrictions_end.getFullYear();
      if (!this.monthPickerIsOpened && !this.yearPickerIsOpened) {
        if (viewYear === restrictionYear) {
          return this.view_date.getMonth() < this.restrictions_end.getMonth();
        }
        return viewYear < restrictionYear;
      }
      if (this.monthPickerIsOpened) {
        return viewYear < restrictionYear;
      }
      if (this.yearPickerIsOpened) {
        return viewYear+8 <= restrictionYear;
      }
    },
    drawArrowActiveBack() {
      const viewYear = this.view_date.getFullYear();
      const restrictionYear = this.restrictions_start.getFullYear();

      if (!this.monthPickerIsOpened && !this.yearPickerIsOpened) {
        if (viewYear === restrictionYear) {
          return this.view_date.getMonth() > this.restrictions_start.getMonth();
        }
        return viewYear > restrictionYear;
      }
      if (this.monthPickerIsOpened) {
        return viewYear > restrictionYear;
      }
      if (this.yearPickerIsOpened) {
        return viewYear-5 >= restrictionYear;
      }
    },
    get_month_struct_full() {
      const weekInfo = this.get_region_week_names(this.region);
      return {
        week_names: weekInfo,
        month_struct: this.get_month_struct(weekInfo),
        months: this.getStructuredMonths(),
        dozen: this.getDozen(),
      };
    },
  },
  methods: {
    switchToMonths() {
      this.monthPickerIsOpened = true;
      this.yearPickerIsOpened = false;
    },
    isElemActive(dateToCheck) {
      if (!this.monthPickerIsOpened && !this.yearPickerIsOpened) {
        return dateToCheck <= this.restrictions_end && dateToCheck >= this.restrictions_start;
      }
      if (this.monthPickerIsOpened) {
        if (dateToCheck.getFullYear() === this.restrictions_start.getFullYear()) {
          return dateToCheck.getMonth() >= this.restrictions_start.getMonth();
        }
        if (dateToCheck.getFullYear() === this.restrictions_end.getFullYear()) {
          return dateToCheck.getMonth() <= this.restrictions_end.getMonth();
        }
        return true;
      }
      if (this.yearPickerIsOpened) {
        return dateToCheck.getFullYear() <= this.restrictions_end.getFullYear() &&
              dateToCheck.getFullYear()>=this.restrictions_start.getFullYear();
      }
    },
    getDozen() {
      function* inc(start, stopCount) {
        while (stopCount > 0) {
          yield start++;
          stopCount--;
        }
      }

      let years = [...inc(this.view_date.getFullYear() - 4, 12)];

      years = years.map((item) => {
        const curMonth = this.view_date.getMonth();

        const curDate = new Date(this.view_date);
        curDate.setFullYear(item);

        if (curDate.getMonth() !== curMonth) {
          curDate.setDate(0);
        }

        return {
          selected: compareDates(curDate, this.select_date, true, true),
          date: curDate,
        };
      });

      return [
        [years[0], years[1], years[2]],
        [years[3], years[4], years[5]],
        [years[6], years[7], years[8]],
        [years[9], years[10], years[11]]];
    },
    getStructuredMonths() {
      let curMonths = getMonthsForLocale(this.region);

      curMonths = curMonths.map((item, key) => {
        const curDate = new Date(this.view_date);
        curDate.setMonth(key);
        // Если в предыдущий выбранный день больше чем текущий выбранный месяц, то перескочит на месяц вперед
        if (curDate.getMonth() !== key) {
          curDate.setDate(0);
        }
        return {
          text: item,
          selected: compareDates(curDate, this.select_date, true),
          date: curDate,
        };
      });

      return [
        [curMonths[0], curMonths[1], curMonths[2]],
        [curMonths[3], curMonths[4], curMonths[5]],
        [curMonths[6], curMonths[7], curMonths[8]],
        [curMonths[9], curMonths[10], curMonths[11]]];
    },
    setSelected(newDate) {
      const oldValue = this.select_date;
      this.select_date = new Date(newDate);
      if (this.select_date.getTime()!==oldValue.getTime()) {
        this.$emit('select_changed', this.select_date);
      }
      this.$emit('select_changed_by_click', this.select_date);
      this.view_date = new Date(newDate);
    },
    setView(newDate) {
      this.view_date = new Date(newDate);
    },
    addMonth(delta) {
      this.view_date = new Date(this.view_date);
      this.view_date.setMonth(this.view_date.getMonth()+delta);
    },
    addYear(delta) {
      this.view_date = new Date(this.view_date);
      this.view_date.setYear(this.view_date.getFullYear()+delta);
    },
    get_week_start(region, language) {
      const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g);
      const regionSun = ('AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPT' +
          'PYSASGSVTHTTTWUMUSVEVIWSYEZAZW').match(/../g);
      const languageSat = ['ar', 'arq', 'arz', 'fa'];
      const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'
          .match(/../g);

      return (
      region ? (
          regionSun.includes(region) ? 0 :
              regionSat.includes(region) ? 7 : 1) : (
          languageSun.includes(language) ? 0 :
              languageSat.includes(language) ? 7 : 1));

      // TODO Удалить это!!!!
      // console.log('Удали строку ниже!');
      // ans = 2;
      // return ans;
    },
    get_week_start_locale(locale) {
      const parts =
          locale.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i);
      return this.get_week_start(parts[4], parts[1]);
    },
    get_month_name(date) {
      const formatter = new Intl.DateTimeFormat(this.region, {month: 'long'});
      return formatter.format(date);
    },
    get_region_week_names(locale) {
      const weekStartDay = this.get_week_start_locale(locale);
      const weekdayDateMap = [
        new Date('2020-01-05T00:00:00.000Z'),
        new Date('2020-01-06T00:00:00.000Z'),
        new Date('2020-01-07T00:00:00.000Z'),
        new Date('2020-01-08T00:00:00.000Z'),
        new Date('2020-01-09T00:00:00.000Z'),
        new Date('2020-01-10T00:00:00.000Z'),
        new Date('2020-01-11T00:00:00.000Z'),
      ];
      const weekdayNames = weekdayDateMap.map((item, index) => ({
        id: index,
        narrow: new Intl.DateTimeFormat(locale, {weekday: 'narrow'}).format(item),
        short: new Intl.DateTimeFormat(locale, {weekday: 'short'}).format(item),
        long: new Intl.DateTimeFormat(locale, {weekday: 'long'}).format(item),
      }));
      weekdayNames.sort((itemA, itemB) => {
        const idA = (itemA.id < weekStartDay) ? itemA.id + 10 : itemA.id;
        const idB = (itemB.id < weekStartDay) ? itemB.id + 10 : itemB.id;
        if (idA < idB) return -1;
        else if (idB < idA) return 1;
        else return 0;
      });

      return weekdayNames;
    },
    get_month_struct(weekInfo) {
      const weekStartDay = weekInfo[0].id;
      const monthStartDay = (new Date(this.view_date.getFullYear(), this.view_date.getMonth(), 1)).getDay();
      // const monthDayCount = this.view_date.daysInMonth();

      const prevMonthDays = new Date(this.view_date.getFullYear(), this.view_date.getMonth() - 1).daysInMonth();
      const curMonthDays = new Date(this.view_date.getFullYear(), this.view_date.getMonth()).daysInMonth();
      // const nextMonthDays = new Date(this.view_date.getFullYear(), this.view_date.getMonth()+1).daysInMonth();
      // let current_day

      // console.log('-----------------');
      // console.log('current date', this.view_date);
      // console.log('weekStartDay', weekStartDay);
      // console.log('monthStartDay', monthStartDay);
      // console.log('monthDayCount', monthDayCount);
      // console.log('nextMonthDays', prevMonthDays);
      // console.log('curMonthDays', curMonthDays);
      // console.log('prevMonthDays', nextMonthDays);
      // console.log('-----------------');

      // Сколько дней нужно подкачать спереди
      let prevDaysCount = monthStartDay - weekStartDay;
      // Если наш месяц начинался раньше чем начало недели, будет отрицательное число. Прибавляем 7 дней
      if (prevDaysCount < 0) {
        prevDaysCount += 7;
      }

      const ans = [[]];

      while (prevDaysCount > 0) {
        const curDate = new Date(this.view_date.getFullYear(),
            this.view_date.getMonth() - 1,
            prevMonthDays-(prevDaysCount-1));
        ans[0].push({
          day: curDate,
          selected: compareDates(curDate, this.select_date),
          no_active: true,
        });
        prevDaysCount--;
      }

      let curWeekNum = 0;
      let curMonthCounter = 1;

      while (curMonthCounter <= curMonthDays) {
        if (ans[curWeekNum].length > 6) {
          curWeekNum++;
          ans.push([]);
        }
        const curDate = new Date(this.view_date.getFullYear(), this.view_date.getMonth(), curMonthCounter);
        ans[curWeekNum].push({
          day: curDate,
          selected: compareDates(curDate, this.select_date),
        });
        curMonthCounter++;
      }

      let nextMonthCounter = 1;

      while (ans[curWeekNum].length < 7) {
        const curDate = new Date(this.view_date.getFullYear(), this.view_date.getMonth()+1, nextMonthCounter);
        ans[curWeekNum].push({
          day: curDate,
          selected: compareDates(curDate, this.select_date),
          no_active: true,
        });
        nextMonthCounter++;
      }
      // console.log('ans', ans);

      if (ans.length < 6) {
        ans.push([]);
        curWeekNum++;
        while (ans[curWeekNum].length < 7) {
          const curDate = new Date(this.view_date.getFullYear(), this.view_date.getMonth()+1, nextMonthCounter);
          ans[curWeekNum].push({
            day: curDate,
            selected: compareDates(curDate, this.select_date),
            no_active: true,
          });
          nextMonthCounter++;
        }
      }

      return ans;

      // return [
      //   [{day: 1, no_active: true}, {day: 2, no_active: true}, {day: 3, no_active: true},
      //   {day: 4}, {day: 5}, {day: 6}, {day: 7}],
      //   [{day: 1}, {day: 2}, {day: 3}, {day: 4}, {day: 5}, {day: 6}, {day: 7}],
      //   [{day: 1}, {day: 2}, {day: 3}, {day: 4, current: true}, {day: 5}, {day: 6}, {day: 7}],
      //   [{day: 1}, {day: 2}, {day: 3}, {day: 4}, {day: 5}, {day: 6}, {day: 7}],
      //   [{day: 1}, {day: 2}, {day: 3}, {day: 4, no_active: true}, {day: 5, no_active: true},
      //   {day: 6, no_active: true}, {day: 7, no_active: true}]];
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.date_picker{
  user-select: none;
  text-align: center;
  //width: 250px;
  //min-height: 200px;
  max-width: max-content;
}
.day_picker{
  max-width: max-content;
}
.month-picker {
  max-width: max-content;
}
.year-picker {
  max-width: max-content;
}

.top-label {
  color: @light-gray;
}

.buttons-wrapper{

  &-days {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
  }
  &-months {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid @super-light;
  }

  &-years {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid @super-light;
  }
}
.label-wrapper{
  margin: auto;
  color: @green;
  .flex(row, center, center);
  :hover {
    cursor: pointer;
  }
}


.picker-table {
  .flex(column, flex-start, stretch);
  width: 100%;
  height: 100%;
  //max-width: 28px;
}

.week-names{
  color: @light-gray;
}

.day-wrapper{
  width: 28px;
  height: 28px;
}

.no-current-month {
  color: @super-light;
  .flex(column, center, center);
  :hover {
    cursor: pointer;
  }
}

.current-month {
  color: @dark;
  .flex(column, center, center);
  &-inactive {
    .set_color(@stroke-gray);
  }
  &-selected {
    background-color: @mint;
    .set_color(@green);
    border-radius: 50%;
  }
}

.current-month-active:hover {
  .set_color(@green);
  border: 1px solid @super-light;
  border-radius: 50%;
  cursor: pointer
}
.month {
  .flex(column, center, center);
  &-active {
    color: @gray;
    :hover {
      .set_color(@green);
      cursor: pointer;
    }
    p > span:hover {
      padding: 3px 6px;
      border: 1px solid @super-light;
      border-radius: 40px;
    }
  }
  &-inactive {
    color: @gray-opacity-10;
  }
  &-selected {
    .set_color(@green);
    :hover {
      cursor: pointer;
    }
    p > span {
      padding: 3px 6px;
      border-radius: 40px;
      background-color: @mint;
    }
  }
}

.row-wrapper {
  .flex(row, space-around, center);
}

.arrow {
  &-active {
    .set_color(@green);
    border-radius: 24px;
    background-color: @mint;
    min-width: 24px;
    min-height: 24px;
    .flex(column, center, center);
    :hover {
      cursor: pointer
    }
  }
  &-inactive {
    .set_color(@gray);
    border-radius: 24px;
    background-color: @super-light;
    min-width: 24px;
    min-height: 24px;
    .flex(column, center, center);
  }
  .set_color(@green);
  border-radius: 24px;
  background-color: @mint;
  min-width: 24px;
  min-height: 24px;
  .flex(column, center, center);
  :hover {
    cursor: pointer
  }
}
.arrow-active:hover{
  cursor: pointer;
}

.month-wrapper{
  width: 65.33px;
}

@media screen and (max-width: @transition-threshold-1) {
  .date_picker {
    max-width: unset;
  }
  .month-picker {
    max-width: unset;
  }
  .year-picker {
    max-width: unset;
  }
  .picker-table {
    & > * {
      margin-bottom: 6px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

</style>
