<template>
  <BasePage>
    <div class="some-page"/>
  </BasePage>

</template>

<script>
import BasePage from '../components/navigation/BasePage';

export default {
  name: 'SomePage',
  components: {BasePage},
  mounted() {
    console.log('user_info', this.$store.state.auth_settings.user_obj);
    console.log('web_site_struct', this.$store.state.auth_settings.web_site_struct);
  },
};
</script>

<style lang="less">
@import url('../assets/v1/less/base.less');

.some-page{
  position: fixed;
  inset: 0;
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(157,147,119,1) 22%, rgba(86,205,146,1) 45%,
              rgba(171,80,169,1) 73%, rgba(210,170,75,1) 86%, rgba(252,70,107,1) 100%);
}

</style>
