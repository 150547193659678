// Данный миксин занимается управлением источников дынных для компонентов
// В data компонента объявите массив dataSourceNames с именами источников, которые требуется подключить
// Подключите этот миксин к компоненту
// в methods опредилите функцию newExternalData.
// newExternalData будет вызываться, когда будет приходить новая информация из источника
// Вызовите makeRequest с параметрами, чтобы сделать запрос к источнику
// makeRequest(dataSourceName, arg1, arg2, arg3 ... argN)

export default {
  data: ()=>({
    __cur_resources: {},
    __resource_handlers: {},
  }),
  created() {
    const dataSources = require.context(
        // Относительный путь до каталога компонентов
        '../project_management/dataSources',
        // Обрабатывать или нет подкаталоги
        true,
        // Регулярное выражение для определения файлов базовых компонентов
        /[A-z]\w+\.(js)$/,
    );

    const findNames = this.dataSourceNames;

    if (!findNames) {
      return;
    }

    const curThis = this;

    dataSources.keys().forEach((fileName) => {
      // Получение конфигурации компонента
      const componentConfig = dataSources(fileName);

      let compName = fileName.split('/');
      for (let i = compName.length-1; i > -1; i--) {
        if (compName[i] === '.') {
          compName.splice(i, 1);
        }

        compName[i] = compName[i].replace(/\.\w+$/, '');
      }
      compName = compName.join('-');

      if (findNames.includes(compName)) {
        curThis.__resource_handlers[compName] = (newData, idx) => {
          curThis.newExternalData(newData, compName, idx);
        };
        curThis.__cur_resources[compName] = componentConfig.default(curThis.__resource_handlers[compName]);
      }
    });
  },
  methods: {
    newExternalData(newData, compName, idx) {
      // console.log(newData, compName);
      // this.$store.commit('user_obj_mut', newData);
    },
    makeRequest() {
      let resourceName = this.dataSourceNames[0];

      let sendArgs = [];

      if (arguments.length > 0) {
        // eslint-disable-next-line prefer-rest-params
        resourceName = arguments[0];
        sendArgs = Array.prototype.slice.call(arguments, 1);
      }

      this.__cur_resources[resourceName].makeRequest(...sendArgs);
    },
  },
};
