import {dataSourceHook} from '@/assets/v1/js/hooks/dataSourceHook';
import {computed, onBeforeMount, ref} from 'vue';
import translatorHook from '@/assets/v1/js/hooks/translatorHook';
import loaderAndModalsControllerHook from '@/assets/v1/js/hooks/loaderAndModalsControllerHook';
import simpleValidatorHook from '@/assets/v1/js/hooks/simpleValidatorHook';

const builderHook = () => {
  const dataSourceNames = ref([]);
  const isMobileWall = ref(0);
  const currentWidth = ref(0);

  const dataSource = dataSourceHook(dataSourceNames);
  const translator = translatorHook();
  const loadAndModalsController = loaderAndModalsControllerHook();
  const simpleValidator = simpleValidatorHook();

  const getIsMobileInfo = computed(() => currentWidth.value <= isMobileWall.value);

  onBeforeMount(() => {
    const appElem = document.getElementById('app');
    const appStyles = window.getComputedStyle(appElem, null);
    const mobileTransitionThreshold = appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2);

    isMobileWall.value = parseInt(mobileTransitionThreshold);
    currentWidth.value = window.innerWidth;

    window.addEventListener('resize', () => {
      currentWidth.value = window.innerWidth;
    });
  });

  return {
    ...dataSource,
    ...translator,
    ...loadAndModalsController,
    ...simpleValidator,
    getIsMobileInfo,
    isMobileWall,
    currentWidth,
  };
};

export default builderHook;
