function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function(c) {
        const rrr = Math.random() * 16 | 0;
        const vvv = (c === 'x') ? rrr : (rrr & 0x3 | 0x8);
        return vvv.toString(16);
      },
  );
}

export default {
  data: () => ({
    id: undefined,
  }),
  beforeMount() {
    this.id = uuidv4();
  },
};
