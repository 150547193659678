<template>
  <NamedField :label="this.getTitle">
    <LabelComponent v-bind="this.getComponentSettings"
                    :label_text="this.getContent"/>
  </NamedField>
</template>

<script type="text/javascript">
import builder from '../../../assets/v1/js/builder';
import LabelComponent from '@/components/unit/Label';
import NamedField from '../../../components/unit/NamedField';

export default builder({
  components: {
    LabelComponent,
    NamedField,
  },
  computed: {
    getTitle() {
      const title = this.fieldInfo.title || this.fieldInfo.system_name;
      return this.loc(title);
    },
    getContent() {
      const content = this.fieldInfo.title_system || this.fieldInfo.value;
      const returnContent = content != null && content !== '' ? content : '-';
      return this.loc(returnContent);
    },
    getComponentSettings() {
      return this.fieldInfo.typeSettings;
    },
  },
  props: {
    fieldInfo: {
      required: true,
      type: Object,
      default: {},
    },
  },
});
</script>

<style lang="less">

</style>
