import {dataSource, ROOT_URL} from './dataSource.js';

const REQUEST_LINK = '/translate/language';

function getLocalizeLanguage(handler, lang) {
  const url = ROOT_URL + REQUEST_LINK + '?lang=' + lang;
  const localDataSource = Object.create(dataSource(url, handler));

  localDataSource.method = 'GET';

  return localDataSource;
}

export default getLocalizeLanguage;
