import {createRouter, createWebHistory} from 'vue-router';

import store from '../store';
import getUserInfo from '../assets/v1/js/project_management/dataSources/GetUserInfo';
import getWebSiteStructure from '../assets/v1/js/project_management/dataSources/getWebSiteStructure';

import LoadingPage from '../views/system/LoadingPage';
import NavigationPage from '../views/system/NavigationPage';
import MaterialsGuide from '../views/internal/MaterialsGuide';

import Page404 from '../views/system/Page404';
import LoginPage from '../views/system/LoginPage';

function addRoutesFromBack(data) {
  // Метод полчения компонентов для сопоставления со страницами из админки
  const getLoadComponents = () => {
    const req = require.context('@/views', true, /[A-z]\w+\.(vue)$/);
    let pageComponents = req.keys().map((key) => {
      return [key.slice(2, -4), req(key).default];
    });
    pageComponents = Object.fromEntries(pageComponents);
    return pageComponents;
  };

  // Получение компонента по имени
  const getComponent = (name, components) => {
    if (Object.keys(components).includes(name)) return pageComponents[name];
    return NavigationPage;
  };

  const pageComponents = getLoadComponents();

  data = data.filter((item) => (item.open_new_tab !== true && item.url.startsWith('/')));

  data.map((item) => ({
    path: item.url,
    open_new_tab: item.open_new_tab,
    name: item.url,
    component: getComponent(item.component_type, pageComponents),
    beforeEnter(to, from, next) {
      store.commit('current_page_info_mut', item);
      document.title = `${item.name}`;
      next();
    },
  })).forEach((item) => router.addRoute(item));
}

const routes = [
  {
    path: '/page_404',
    name: 'Page404',
    component: Page404,
    props: (route) => ({
      path: route.redirectedFrom ? route.redirectedFrom.path : undefined,
    }),
  },
  {
    path: '/guide',
    name: 'Check',
    component: MaterialsGuide,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: (route) => {
      return {next_page: route.query.next_page};
    },
  },
  {
    path: '/loading_initial',
    name: 'LoadingInitial',
    component: LoadingPage,
    props: (route) => ({
      func: () => {
        const afterRequestFunc = () => {
          // Если все скачалось, то можно идти дальше
          getWebSiteStructure((data) => {
            const result = (data.length !== 0) ? data : null;
            store.commit('web_site_struct_mut', result);
            addRoutesFromBack(data);

            const toQuery = JSON.parse(JSON.stringify(route.query));
            delete toQuery.next_page;

            const to = {
              path: route.query.next_page,
              query: toQuery,
            };
            if (store.getters.isEndLoadAuthInfo) router.replace(to);
          });
        };

        getUserInfo((data) => {
          const result = (data != null) ? data.result : null;
          store.commit('user_obj_mut', result);
          afterRequestFunc();
        });
      },
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isEndLoadAuthInfo && to.name !== 'LoadingInitial') {
    // Если данные еще не скачивались, то скачать начальные данные
    next({name: 'LoadingInitial', query: {next_page: to.path, ...to.query}});
  } else if (window.WORK_IN_APPLICATION && store.getters.isEndLoadAuthInfo && !store.getters.isLoggedIn && to.name !== 'Login') {
    // Если начальные данные скачаны, но их нет, то отправить на страницу логина
    next({name: 'Login', query: {next_page: to.path}});
  } else if (store.getters.isLoggedIn && to.name === 'Login') {
    // Если человек уже аутентифицирован, то ему не доступна страница логина
    next({path: '/'});
  } else if (to.path === '/' && store.getters.getSortedLayer(null)[0]) {
    next({path: store.getters.getSortedLayer(null)[0].url});
  } else if (router.resolve(to).matched.length === 0 && !['Page404'].includes(to.name)) {
    next({name: 'Page404'});
  } else next();


  // // Если данные не скачались, то направить на страницу 404
  // if (!store.getters.isLoggedIn && to.meta['is_access_without_login'] !== true) next({name: 'LoadingInitial'});
  // // Если страница не найдена, то направить на страницу 404
  // else if (router.resolve(to).matched.length === 0 && !['Page404'].includes(to.name)) next({name: 'Page404'});
  // // Если ссылка ведет в корень, то перенаправить на первую доступную страницу
  // else if (to.path === '/' && store.getters.getSortedLayer(null)[0]) {
  //   next({path: store.getters.getSortedLayer(null)[0].url});
  // } else next();
});

export default router;
