<template>
  <div class="period-date-field">
    <LabelComponent :label_text="'Выбранная дата: ' + this.input_value.toLocaleString('ru-RU').split(',')[0]"
                    label_type="body/large/regular 16"
                    />
    <br/>
    <LabelComponent label_text="Год:"
                    label_type="body/large/regular 16"
                    />
    <div class="year-selector">
      <LabelComponent label_text="<"
                      @click="this.year_scroll_delta -= 1"
                      label_type="body/large/regular 14"
                      class="year-scroll-arrow"/>
      <div class="year-list">
        <LabelComponent v-for="year in this.year_list" :key="year"
                        :class="year == this.selectedYear ? 'year-active' : 'year'"
                        label_type="body/large/regular 14"
                        :label_text="String(year)"
                        @click="set_year(year)"/>
      </div>
      <LabelComponent label_text=">"
                      @click="this.year_scroll_delta += 1"
                      label_type="body/large/regular 14"
                      class="year-scroll-arrow"/>
    </div>
    <br/>
    <LabelComponent label_text="Квартал:"
                    label_type="body/large/regular 16"
                    />
    <div class="quarter-selector">
      <div class="quarter" v-for="quarter in this.quarter_list" :key="quarter">
        <div>{{ quarter.label }}</div>
        <ButtonComponent elementType="default"
                        label="Начало"
                        @click_to_button="this.set_quarter_date(quarter.date_start)"/>
        <ButtonComponent elementType="default"
                        label="Конец"
                        @click_to_button="this.set_quarter_date(quarter.date_end)"/>
      </div>
    </div>
    <br/>
    <LabelComponent label_text="Месяц:"
                    label_type="body/large/regular 16"
                    />
    <table class="months-table">
      <tbody>
        <tr v-for="block, bid in this.getMonthNames" :key="bid">
          <td v-for="month, mid in block" :key="mid" @click="set_month(month.id)">
            <LabelComponent label_type="body/large/regular 14"
                            :label_text="month.label"
                            />
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <LabelComponent :label_text="'Свободный выбор'"
                    label_type="body/large/regular 16"
                    class="custom-select-button"
                    @click="this.is_custom_select=true"
                    />
    <div class="select-date-border" v-if="this.is_select_month_border">
      <LabelComponent label_type="body/large/regular 16"
                      :label_text="this.selectedMonth.label"
                      class="header"
                      />
      <div class="date-border-buttons-wraper">
        <ButtonComponent elementType="default-bg"
                        label="Начало"
                        @click_to_button="this.set_month_start()"/>
        <ButtonComponent elementType="default-bg"
                        label="Конец"
                        @click_to_button="this.set_month_end()"/>
      </div>
    </div>
    <ModalView v-if="this.is_custom_select"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               :caption="'Свободный выбор даты'"
               @click_close="this.is_custom_select=false">
      <LabelComponent :label_text="'Выбранная дата: ' + this.input_value.toLocaleString('ru-RU').split(',')[0]"
                      label_type="body/large/regular 16"
                      />
      <br/><br/>
      <DatePicker @select_changed_by_click="(value) => this.input_value = value"/>
      <br/><br/>
      <ButtonComponent elementType="default-bg"
                      label="Применить"
                      @click_to_button="this.confirm()"/>
    </ModalView>
  </div>
</template>

<script>
import ModalView from '../ModalView';
import LabelComponent from './Label';
import ButtonComponent from './Button';
import DatePicker from './DatePicker';

function buildMonthNames() {
  const shortFormat = new Intl.DateTimeFormat('ru', {month: 'short'});
  const fullFormat = new Intl.DateTimeFormat('ru', {month: 'long'});
  const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return months.map((month) => ({
    id: month,
    label: fullFormat.format(new Date(0, month, 2)),
    short_label: shortFormat.format(new Date(0, month, 2)),
  }));
}

const monthNames = buildMonthNames();

export default {
  components: {
    ModalView,
    LabelComponent,
    ButtonComponent,
    DatePicker,
  },
  emits: ['confirmed'],
  data: function() {
    return {
      input_value: null,
      is_select_month_border: false,
      year_scroll_delta: 0,
      is_custom_select: false,
    };
  },
  props: {
    init_value: {
      type: Object,
      required: false,
      default: new Date(),
    },
  },
  created() {
    this.input_value = new Date(this.init_value);
  },
  watch: {
    input_value() {
      // const extDate = JSON.stringify(this.input_value);
      // const extDate = this.input_value;
      // console.log('confirmed DateField', extDate);
      // this.$emit('confirmed', extDate);
    },
    init_value() {
      this.input_value = new Date(this.init_value);
    },
  },
  methods: {
    set_month(monthId) {
      this.is_select_month_border = true;
      this.input_value = new Date(this.selectedYear, monthId, 1);
    },
    set_year(year) {
      this.input_value = new Date(year, this.selectedMonth.id, 1);
      this.year_scroll_delta = 0;
    },
    set_month_start() {
      this.input_value = new Date(this.selectedYear, this.selectedMonth.id, 1);
      this.confirm();
    },
    set_month_end() {
      this.input_value = new Date(this.selectedYear, this.selectedMonth.id, this.input_value.daysInMonth());
      this.confirm();
    },
    set_quarter_date(newDate) {
      this.input_value = new Date(newDate);
      this.confirm();
    },
    confirm() {
      this.is_select_month_border = false;
      this.is_custom_select=false;

      // const extDate = JSON.stringify(this.input_value);
      const extDate = this.input_value;
      this.$emit('confirmed', extDate);
    },
  },
  computed: {
    selectedYear() {
      return this.input_value.getFullYear();
    },
    selectedMonth() {
      return monthNames[this.input_value.getMonth()];
    },
    getMonthNames() {
      return [[0, 1, 2], [3, 4, 5], [6, 7, 8], [9, 10, 11]].map((block) => block.map((month) => monthNames[month]));
    },
    year_list() {
      const yearViewRange = 2;
      return Array.from(
          {length: (yearViewRange * 2 + 1)},
          (elem, idx) => (this.selectedYear - yearViewRange + this.year_scroll_delta + idx),
      );
    },
    quarter_list() {
      const curYear = this.selectedYear;
      return [
        {
          label: '1 квартал ' + curYear + ' года:',
          date_start: this.input_value.beginCurrentYear(),
          date_end: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 3, 0).shiftDateFromCurrent(0, 0, -1),
        },
        {
          label: '2 квартал ' + curYear + ' года:',
          date_start: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 3, 0),
          date_end: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 6, 0).shiftDateFromCurrent(0, 0, -1),
        },
        {
          label: '3 квартал ' + curYear + ' года:',
          date_start: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 6, 0),
          date_end: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 9, 0).shiftDateFromCurrent(0, 0, -1),
        },
        {
          label: '4 квартал ' + curYear + ' года:',
          date_start: this.input_value.beginCurrentYear().shiftDateFromCurrent(0, 9, 0),
          date_end: this.input_value.beginCurrentYear().shiftDateFromCurrent(1, 0, 0).shiftDateFromCurrent(0, 0, -1),
        },
      ];
    },
  },
};
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.period-date-field {
  position: relative;

  .year-selector{
    .flex(row, flex-start, center);
    margin: 0 16px 5px;

    .year-scroll-arrow {
      .set_color(@green);
      border-radius: 24px;
      background-color: @mint;
      min-width: 24px;
      min-height: 24px;
      .flex(column, center, center);
      cursor: pointer;
    }

    .year {
      &-active {
        .set_color(@green);
        border-radius: 8px;
        background-color: @mint;
        min-width: 24px;
        min-height: 24px;
        .flex(column, center, center);
        cursor: default;
        padding: 6px;
      }
      margin-left: 2px;
      margin-right: 2px;
    }

    .year-list{
      margin: auto;
      color: @green;
      .flex(row, center, center);
      cursor: pointer;
    }
  }

  .quarter {
    .flex(row, center, center);
    margin: 10px;

    > :nth-child(n) {
      margin-right: 16px;
    }
  }

  .months-table {
    color: @hover-green;

    td {
      text-align: center;
      cursor: pointer;
      padding-bottom: 8px;
      padding-top: 8px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .custom-select-button {
    color: @hover-green;
    cursor: pointer;
  }

  .select-date-border {
    .header {
      margin: auto;
      width: fit-content;
    }
    padding: 14px;
    color: @super-light;
    position: absolute;
    inset: 0;
    background-color: @dark-opacity-80;
    border-radius: 20px;
  }
  .date-border-buttons-wraper {
    display: flex;
    place-content: space-evenly;
    flex-flow: wrap;
    height: 100%;
  }
}
</style>
