<template>
  <div class="login-page">
    <div class="left-panel">
      <MainLogo class="main-logo"
                branch=""
                colorStyle="white-green"
                width="128"/>

      <div class="left-panel__rings">
        <IconComponent class="left-panel__rings_position1" type="png" name="sphere-green-glossy" size="55" />
        <IconComponent class="left-panel__rings_position2" type="png" name="sphere-green-glossy" size="150" />
        <IconComponent class="left-panel__rings_position3" type="png" name="sphere-white-matte" size="207" />
        <IconComponent class="left-panel__rings_position4" type="png" name="sphere-green-glossy" size="65" />
        <IconComponent class="left-panel__rings_position5" type="png" name="toroid-green-glossy" size="360" />
        <IconComponent class="left-panel__rings_position6" type="png" name="toroid-white-matte" size="315" />
      </div>
    </div>
    <div class="right-panel">
      <div class="right-panel__header">
        <SwitcherComponent class="right-panel__header__languages"
                           ref="switcher"
                           size="medium"
                           :values="this.get_lang_switcher_values"
                           :init_selected_index="this.getUserLangId()"
                           @select_changed="this.switchLanguage"/>

      </div>
      <div class="right-panel__content">
        <form class="right-panel__content__panel">
          <LabelComponent class="login-frame__header"
                          label_type="h1"
                          :label_text="loc('Авторизация')"/>

          <LabelComponent class="login-frame__global-error"
                          v-show="this.global_error_text != null"
                          :label_text="loc(this.global_error_text)"/>

          <InputField class="login-frame__login-field"
                      @input_change="set_login"
                      name="username"
                      autocomplete="on"
                      :label_text="loc('Логин')"
                      :input_field_status="this.login_input_data.status"
                      :error_text="this.login_input_data.error_text"/>
          <InputField class="login-frame__login-field"
                      @input_change="set_password"
                      name="password"
                      autocomplete="on"
                      :label_text="loc('Пароль')"
                      :input_field_status="this.password_input_data.status"
                      :error_text="this.password_input_data.error_text"
                      type="password"/>

          <ButtonComponent class="login-frame__button1"
                           @click="this.loginUserInSystem()"
                           :label="loc('Войти')"/>
          <ButtonComponent class="login-frame__button2"
                           elementType="default"
                           :label="loc('Забыли пароль?')"/>
          <ButtonComponent v-if="this.work_in_application()"
                           class="login-frame__button2"
                           elementType="default"
                           @click="this.clear_cache()"
                           :label="loc('Очистить кэш приложения')"/>

        </form>
        <PinPage v-if="this.usePinWindow"
                 :next_page="this.next_page"
                 :cryptUserDataSaveKey="this.cryptUserDataSaveKey"
                 :savedUserData="this.readApplicationUserData"/>
      </div>
      <div class="right-panel__footer" v-if="false && !this.usePinWindow">
        <LabelComponent label_type="button/large/regular 14"
                        :label_text="loc('Нет аккаунта?')"/>

        <ButtonComponent :label="loc('Зарегистрироваться')"
                         elementType="default"
                         iconType="arrow-left"
                         iconMargin="0 0 0 4px" iconPosition="right" iconRotate="180"/>
      </div>
    </div>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import MainLogo from '../../components/unit/MainLogo';
import LabelComponent from '../../components/unit/Label';
import ButtonComponent from '../../components/unit/Button';
import InputField from '../../components/unit/InputField';
import IconComponent from '../../components/unit/Icon';
import SwitcherComponent from '../../components/unit/Switcher';
import PinPage from './PinPage';

import {authRequest} from '../../assets/v1/js/project_management/dataSources/authRequest';

export default builder({
  name: 'Login',
  components: {
    MainLogo,
    LabelComponent,
    ButtonComponent,
    InputField,
    IconComponent,
    SwitcherComponent,
    PinPage,
  },
  props: {
    next_page: {default: '/'},
  },
  data: ({
    global_error_text: undefined,
    login_input_data: {
      status: undefined,
      error_text: undefined,
    },
    password_input_data: {
      status: undefined,
      error_text: undefined,
    },
    login: undefined,
    password: undefined,
    usePinWindow: false,
    readApplicationUserData: undefined,
    cryptUserDataSaveKey: 'cryptCurrentUser',
  }),
  mounted() {
    window.acceptableElements.push(this.loginUserInSystem);

    if (!this.usePin) {
      return;
    }

    window.applicationCallback.setCryptCurrentUser = (status, key, value) => {
      this.readApplicationUserData = value;

      if (value != null && value !== '') {
        this.usePinWindow = true;
      }
    };

    window.sendMessageInApplication({
      type: 'get_saved_string',
      key: this.cryptUserDataSaveKey,
      callback: 'window.applicationCallback.setCryptCurrentUser',
    });
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.loginUserInSystem);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    usePin() {
      return window.WORK_IN_APPLICATION && window.hasFunctions([
        'save_string',
        'get_saved_string',
        'delete_saved_string',
      ]);
    },
  },
  methods: {
    work_in_application() {
      return window.WORK_IN_APPLICATION;
    },
    clear_cache() {
      window.sendMessageInApplication({
        type: 'clear_cache',
        callback: 'window.applicationCallback.cacheCleared',
      });
    },
    set_login(data) {
      this.login=data;
    },
    set_password(data) {
      this.password=data;
    },
    nextUserLogin() {
      if (this.usePin) {
        this.usePinWindow = true;
      } else {
        this.$store.commit('is_user_login_now');
        this.$router.replace({path: this.next_page});
      }
    },
    loginUserInSystem() {
      const errorText = this.loc('Поле должно быть заполнено');
      this.login_input_data.status = !this.login ? 'error' : undefined;
      this.login_input_data.error_text = !this.login ? errorText : undefined;
      this.password_input_data.status = !this.password ? 'error' : undefined;
      this.password_input_data.error_text = !this.password ? errorText : undefined;
      if (!this.login || !this.password) return 0;

      this.$store.commit('set_full_screen_loading', true);

      const request = authRequest(
          {
            login: this.login,
            password: this.password,
          });

      const curThis = this;

      request
          .then((result) => {
            if (result.status != 200) {
              throw result;
            }

            this.nextUserLogin();
          }).catch(async function(result) {
            if (result.status == 401) {
              curThis.global_error_text = 'Не удалось авторизоваться. ' +
              'Введено неправильное сочетание логина и пароля.';
            } else {
              const errorText = await result.text();
              const errorStatus = result.statusText;
              const errorCode = result.status;

              curThis.global_error_text = 'Не удалось авторизоваться. ' +
              'Возникла техническая ошибка.' +
              ' Код: ' + errorCode +
              ' Статус: ' + errorStatus +
              ' Текст: ' + errorText;
            }
          }).finally(() => {
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.login-page{
  .flex(row, flex-start, stretch);
  width: 100vw;
  height: 100%;

  .left-panel{
    .flex(column, flex-start, flex-start);
    background: @green ;
    position: relative;
    flex-basis: 550px; // здесь регулируем ширину левой зеленой панели

    .main-logo {
      position: absolute;
      margin: @margin-vertical-default @margin-horizontal-default;
    }
    &__rings{
      position: absolute;
      top:0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      right: -30px;
      &_position1{
        position: absolute;
        top:79px;
        right:108px
      }
      &_position2{
        position: absolute;
        top:163px;
        right:290px;
        z-index:1;
      }
      &_position3{
        position: absolute;
        top:244px;
        right:151px;
      }
      &_position4{
        position: absolute;
        top:667px;
        right:447px
      }
      &_position5{
        position: absolute;
        top:530px;
        right:0;
        z-index:1;
      }
      &_position6{
        position: absolute;
        top:730px;
        right:170px
      }
    }


  }

  .right-panel{
    .flex(column, space-between, stretch);
    flex-grow: 1;
    flex-basis: 890px;

    &__header{
      .flex(column, flex-end, flex-end);
      &__languages {
        margin: @margin-vertical-default @margin-horizontal-default;
      }
    }

    &__content{
      .flex(column, center, center);
      flex-grow: 1;
      position: relative;

      &__panel {
        .flex(column, flex-start, center);
        position: absolute;
        width: 390px;
      }
    }

    &__footer{
      .flex(row, center, center);
      flex-basis: 80px;
      flex-wrap: wrap;
      gap: 8px;
      //padding: 10px 0;
      //margin-bottom: 40px;

    }
  }
}

.login-frame{
  //width: 100%;
  width: 390px;
  flex-grow: 1;
  .flex(column, center, center);
  //margin: -10px 0;
  &__header{
    .flex(row, center, flex-end);
    text-align: center;
    //flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 75px;
    //margin: 10px 0;
  }
  &__global-error{
    color: red;
    text-align: center;
  }

  &__login-field{
    margin: 11px 0;
  }
  &__button1{
    width: 100%;
    margin-top: 25px;
  }
  &__button2{
    margin-top: 20px;
  }

}


@media (max-width: @transition-threshold-1) {
  .login-page {
    .left-panel {
      display: none;
    }

    .right-panel {
      &__content {
        &__panel {
          width: calc(100% - 2 * @margin-horizontal-800);
        }
      }
    }
  }
}

</style>
