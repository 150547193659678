<template>
  <DropDownInput :label_text="this.loc(this.input_info.name)"
                 :dropdown_items_init="this.getAllItems"
                 :is_multi_select="this.is_multi_select"
                 :is_filterable="this.is_filterable"
                 :is_extendable="this.is_extendable"
                 :init_selected_item="this.getSelectedItems"
                 :is_favoriteble="true"
                 style="max-width: 400px"
                 @input_change="this.inputChanged"
                 @favorite_change="this.favoriteChanged">
  </DropDownInput>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import DropDownInput from '@/components/unit/DropDownInput';

export default builder({
  components: {
    DropDownInput,
  },
  data: () => ({
    is_multi_select: false,
    is_filterable: false,
    favorites: {},
  }),
  created() {
    const savedFavorites = localStorage.getItem('favorites__' + this.input_info.system_name);
    this.favorites = savedFavorites == null ? {} : JSON.parse(savedFavorites);
    // debugger;
    this.is_extendable = this.input_info.input_type.startsWith('extendable');
    this.is_multi_select = this.input_info.input_type.startsWith('multi_select');
    this.is_filterable = this.input_info.input_type.endsWith('filtered') || this.is_extendable;
  },
  computed: {
    getAllItems() {
      const ans = this.available_objects[this.input_info.dropdown_object_type].map((item)=> ({
        value_show: this.loc(item.performance),
        is_favorite: this.favorites[item.id] === true,
        return_value: item.id,
      }));

      ans.sort((itemA, itemB) => {
        if (itemA.is_favorite !== itemB.is_favorite) {
          return itemA.is_favorite === true ? -1 : 1;
        }

        if (itemA.value_show > itemB.value_show) {
          return 1;
        }
        if (itemA.value_show < itemB.value_show) {
          return -1;
        }
        // a должно быть равным b
        return 0;
      });

      return ans;
    },
    getSelectedItems() {
      const ans = this.getSelectedItemsInternal;

      const filtered = this.getAllItems.filter((item) => ans.includes(item.return_value));
      this.inputChanged(filtered);

      return ans;
    },
    getSelectedItemsInternal() {
      const curFavorites = Object.keys(this.favorites);

      if (curFavorites.length > 0) {
        if (!this.is_multi_select) {
          return [curFavorites[0]];
        }
        return curFavorites;
      }

      if (this.input_info.value == null) {
        return [this.getAllItems[0].return_value];
      }

      if (!this.is_multi_select) {
        return [this.input_info.value.value];
      }

      return this.input_info.value.map((item)=>item.value);
    },
  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      let newValue = data.map((item) => ({value: item.return_value, title: item.value_show}));
      if (!this.is_multi_select) {
        newValue = newValue[0];
      }

      const ans = {};
      Object.assign(ans, this.input_info);

      ans.value = newValue;

      this.$emit('input_data_change', this.input_name, ans);
    },
    favoriteChanged(field) {
      if (field.is_favorite) {
        this.favorites[field.return_value] = true;
      } else {
        delete this.favorites[field.return_value];
      }

      localStorage.setItem('favorites__' + this.input_info.system_name, JSON.stringify(this.favorites));
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
