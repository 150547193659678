<template>
  <BasePage :is_with_background="false">
    <NavigationComponent/>
  </BasePage>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import BasePage from '../../components/navigation/BasePage';
import NavigationComponent from '../../components/navigation/Navigation';

export default builder({
  components: {BasePage, NavigationComponent},
  name: 'NavigationPage',
});

</script>

<style lang="less">

</style>
