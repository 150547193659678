<template>
    <ListView
        :search_data="this.getCards"
        :custom_searcher="this.checkItem"
        :enable_multiselect="this.draw_info.enable_multiselect == true"
        @click_by_list_item="this.cardClick"
        @select_list_item="this.cardSelect">
        <template v-slot="{item}">
          <div class="card__line" v-for="line, idx in item.lines" :key="line">
            <SeparatorComponent v-if="idx > 0"/>
            <div class="card__internal_line">
              <LabelComponent v-if="line[0].value != null"
                        class="card__internal_line__item left"
                        label_type='button/large/regular 14'
                        :label_text="toDrawText(line[0])"/>
              <LabelComponent v-if="line[1].value != null"
                        class="card__internal_line__item center"
                        label_type='button/large/regular 14'
                        :label_text="toDrawText(line[1])"/>
              <LabelComponent v-if="line[2].value != null"
                        class="card__internal_line__item right"
                        label_type='button/large/regular 14'
                        :label_text="toDrawText(line[2])"/>
            </div>
          </div>
        </template>
    </ListView>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';
import SeparatorComponent from '../../components/unit/Separator';
import ListView from '../../components/unit/ListView';
import LabelComponent from '../../components/unit/Label';

export default builder({
  ignoreHistory: true,
  components: {
    SeparatorComponent,
    ListView,
    LabelComponent,
  },
  data: () => ({
    selectedItems: {},
  }),
  mixins: [uniViewMixin],
  mounted() {
    /* const drawIndex = this.draw_index;
    const drawInfo = this.draw_info;
    const drawData = this.draw_data;

    debugger; */
  },
  computed: {
    getCards() {
      return this.draw_data.map((item, itemIndex) =>{
        const drawFields = item.fields.filter((item)=>item.visibility !== false);

        const lines = [];

        let counter = 0;

        let curLine = null;

        // eslint-disable-next-line guard-for-in
        for (const fieldIdx in drawFields) {
          if (counter % 3 === 0) {
            curLine = [{}, {}, {}];
            lines.push(curLine);
            counter = 0;
          }

          if (item.modalCaption == null && drawFields[fieldIdx].value != null) {
            item.modalCaption = drawFields[fieldIdx].value;
          }

          curLine[counter] = drawFields[fieldIdx];

          counter++;
        }

        return {
          isSelected: this.selectedItems[itemIndex] == true,
          orig: item,
          id: itemIndex,
          lines,
        };
      });
    },
    getSelectedCards() {
      const ans = [];
      const cards = {};

      this.getCards.forEach((el) => cards[el.id] = el);

      for (const elem in this.selectedItems) {
        if (this.selectedItems[elem]) {
          ans.push(cards[elem].orig);
        }
      }
      return ans;
    },
  },
  watch: {

  },
  methods: {
    checkItem(item, searchText) {
      // eslint-disable-next-line guard-for-in
      for (const key in item.lines) {
        for (const lineKey in item.lines[key]) {
          if ((item.lines[key][lineKey].value + '').toLowerCase().includes(searchText.toLowerCase())) {
            return true;
          }
        }
      }

      return false;
    },
    cardClick(card) {
      if (this.getSelectedCards.length < 1) {
        this.$emit('next_select', this.draw_index, card.orig, card.id);
        return;
      }

      this.cardSelect(card);
    },
    cardSelect(card) {
      this.selectedItems[card.id] = !(this.selectedItems[card.id] == true);

      this.$emit('update_multi_select', this.getSelectedCards);
    },
    toDrawText(src) {
      if (src.is_date !== true) {
        return src.value;
      }

      return (new Date(src.value)).toLocaleDateString('ru-RU');
    },
  },
  emits: {
    next_select: null,
    close_view: null,
    update_multi_select: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">
@import url('../../assets/v1/less/base.less');

.card{
  &__internal_line{
    .flex(row, space-between, center);

    &__item {
      flex: 1;

      &.right {
        text-align: end;
      }

      &.center {
        text-align: center;
      }
    }
  }
}
</style>
