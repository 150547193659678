<template>
  <ModalView type_modal="internal"
             caption="Свойства полимера"
             @click_close="this.close_external_item_modal($event)">

    <BarLayout gap="12" direct="vertical">
      <ButtonComponent label="Показать аналоги"
                       class="card__show-analogs-button"
                       @click="this.open_analogs_modal"/>

      <div v-for="formItem in this.external_selected_item.form" :key="formItem">

        <NamedField class="input-row_margin-bottom"
                    :label="formItem.name || ''">
          <LabelComponent label_type='body/large/regular 14'
                          :label_text="formItem.value || ''"/>
        </NamedField>

        <SeparatorComponent/>
      </div>
    </BarLayout>
  </ModalView>
  <ModalView v-if="this.show_analogs_modal"
             type_modal="bottom"
             caption="Аналоги"
             @click_close="this.close_analogs_modal($event)">
    <LoadingFrame v-if="this.is_analogs_loading"/>

    <TableComponent v-else
                    :table-data="this.get_analogs_table_data"
                    :table-columns="this.get_analogs_table_columns"
                    :is-selectable="true"
                    @selectChangedByClick="this.select_analog"
                    :no-data-text="'нет данных'"
    >
      <template v-slot="{row_id, column_id}">
        <LabelComponent label_type="body/large/regular 12"
                        :label_text="this.get_analogs_table_data[row_id][column_id]"/>
      </template>
    </TableComponent>
  </ModalView>
  <MaterialsModalPainter v-if="this.internal_selected_item"
                         @modalClose="this.close_internal_item_modal"
                         :external_selected_item="this.get_analog_struct_info"/>
</template>

<script>
import NamedField from '../../components/unit/NamedField';
import ModalView from '../../components/ModalView';
import LabelComponent from '../../components/unit/Label';
import SeparatorComponent from '../../components/unit/Separator';
import ButtonComponent from '../../components/unit/Button';
import LoadingFrame from '../../components/LoadingFrame';
import BarLayout from '../../components/unit/BarLayout';
import TableComponent from '../../components/unit/Table';

export default {
  name: 'MaterialsModalPainter',
  components: {
    NamedField,
    ModalView,
    BarLayout,
    LabelComponent,
    SeparatorComponent,
    ButtonComponent,
    LoadingFrame,
    TableComponent,
  },
  emits: ['modalClose'],
  props: {
    external_selected_item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show_analogs_modal: false,
    show_application_modal: false,
    internal_selected_item: null,
    analogs_list: null,
    is_analogs_loading: false,
  }),
  computed: {
    get_analogs_items() {
      return this.analogs_list;
    },
    get_analogs_table_data() {
      return this.get_analogs_items.map((item, index) => {
        return {
          '0': item.shortcode,
          '1': item.ptr,
          '2': item.density,
          'id': index,
        };
      });
    },
    get_analogs_table_columns() {
      return [
        {
          title: 'Марка',
          id: 0,
          is_with_sum: false,
        },
        {
          title: 'ПТР',
          id: 1,
          is_with_sum: false,
        },
        {
          title: 'Плотность',
          id: 2,
          is_with_sum: false,
        },
      ];
    },
    get_analog_struct_info() {
      return {
        id: this.internal_selected_item.id,
        mark: this.internal_selected_item.shortcode || '',
        form: [
          {
            name: 'Марка',
            value: this.internal_selected_item.shortcode,
          },
          {
            name: 'Тип',
            value: this.internal_selected_item.subtype.type.name,
          },
          {
            name: 'Подтип',
            value: this.internal_selected_item.subtype.name,
          },
          {
            name: 'ПТР',
            value: this.internal_selected_item.ptr,
          },
          {
            name: 'ПТР нагрузка',
            value: this.internal_selected_item.ptr_load,
          },
          {
            name: 'ПТР температура',
            value: this.internal_selected_item.ptr_temperature,
          },
          {
            name: 'Плотность',
            value: this.internal_selected_item.density,
          },
          {
            name: 'Сополимер',
            value: this.internal_selected_item.copolymer ? this.internal_selected_item.copolymer.name : '',
          },
          {
            name: 'Цвет',
            value: this.internal_selected_item.color.name,
          },
          {
            name: 'Температура ВИКА',
            value: this.internal_selected_item.t_vika,
          },
          {
            name: 'Область применения',
            value: this.get_joined_string_names(this.internal_selected_item.applications),
          },
          {
            name: 'Производители',
            value: this.get_joined_string_names(this.internal_selected_item.plants),
          },
          {
            name: 'Методы получения',
            value: this.get_joined_string_names(this.internal_selected_item.obtaining_methods),
          },
          {
            name: 'Методы переработки',
            value: this.get_joined_string_names(this.internal_selected_item.processing_methods),
          },
        ].filter((item) => item.value),
      };
    },
  },
  methods: {
    select_analog(index) {
      this.internal_selected_item = this.get_analogs_items[index];
    },
    open_analogs_modal() {
      this.is_analogs_loading = true;
      this.$store.dispatch('updateMaterialsGuideAnalogsData', this.external_selected_item.id).then((data) => {
        this.analogs_list = data;
        this.show_analogs_modal = true;
        this.is_analogs_loading = false;
      });
    },
    get_joined_string_names(items) {
      return items.map((item) => item.name).join(', ');
    },
    close_analogs_modal() {
      this.show_analogs_modal = false;
    },
    close_external_item_modal() {
      this.$emit('modalClose');
    },
    close_internal_item_modal() {
      this.internal_selected_item = null;
      this.$store.dispatch('updateMaterialsGuideAnalogsData', this.external_selected_item.id);
    },
  },
};
</script>

<style>

</style>
