<template>
  <div class="input-block" @click="this.set_focus">
    <div :class="this.get_input_block_field_classes">
      <div class="input-block__text">
        <div class="input-block__title-wrapper">
          <LabelComponent label_component_type="label"
                          :label_type="this.get_hint_label_type(this.is_focused)"
                          :label_text="this.label_text"/>
          <LabelComponent v-if="this.is_required"
                          class="input-block__title__required-symbol"
                          label_component_type="label"
                          :label_type="this.get_hint_label_type(this.is_focused)"
                          :label_text="' *'"/>
        </div>
        <textarea v-if="this.type === 'textarea'" class="input-block__input"
                  v-model="this.data_values"
                  @blur="this.focusout"
                  :name="this.name"
                  :id="this.id"
                  cols="28"
                  rows="5"
                  :disabled="this.input_field_status === 'disabled'"/>
        <input v-else class="input-block__input"
               ref="input_field"
               v-model="this.data_values"
               @blur="this.focusout"
               @keyup="this.keyup"
               :id="id"
               :name="this.name"
               :type="this.type"
               :disabled="this.input_field_status === 'disabled' || this.block_edit"
               :autocomplete="this.autocomplete"
               multiple
               pattern=".+"/>
      </div>

      <IconComponent class="input-block__icon"
                     size="24"
                     type="svg"
                     v-show="this.get_icon_name"
                     :name="this.get_icon_name"/>
    </div>

    <LabelComponent class="input-block__error-text"
                    label_type="caption/small/regular 10"
                    v-if="this.input_field_status==='error'"
                    :label_text="this.error_text"/>

  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import IconComponent from './Icon';
import LabelComponent from './Label';
import setIdMixin from '@/assets/v1/js/mixins/setIdMixin';

export default builder({
  name: 'InputField',
  mixins: [setIdMixin],
  components: {LabelComponent, IconComponent},
  emits: [
    'input_change',
    'focus_change',
    'keyups',
    'update:modelValue',
  ],
  data: function() {
    return {
      input_block_classes: [],
      is_clicked: false,
      is_focused: false,
      // input_text: '',
      data_values: '',
    };
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      default: '',
      type: String,
    },
    // значения поля, заданные снаружи
    set_values: {
      required: false,
      type: String,
    },
    style_type: {
      type: String,
      validator: (prop) => ['default', 'light'].includes(prop),
      default: 'default',
    },
    type: {
      type: String,
      validator: (prop) => ['text', 'textarea', 'date', 'time', 'password', 'tel', 'file', 'search', 'number']
          .includes(prop),
      default: 'text',
    },
    block_edit: {
      default: false,
    },
    input_field_status: {
      required: false,
      validator: (prop) => ['focused', 'error', 'disabled'].includes(prop),
    },
    input_mask: {
      type: String,
      default: undefined,
    },
    label_text: {
      type: String,
      default: 'Заголовок по умолчанию',
    },
    autocomplete: {
      type: String,
      required: false,
      default: undefined,
    },
    error_text: {
      type: String,
      default: 'Неизвестная ошибка, обратитесь к администратору',
    },
    is_all_time_open: {
      type: Boolean,
      default: false,
    },
    is_required: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue(newData) {
      if (newData) this.data_values = newData;
    },
    set_values(newData) {
      // при получении новых данных через props меняем data_values
      this.data_values = newData;
    },
    data_values(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
      this.$emit('update:modelValue', newData);
    },
  },

  mounted() {
    // маска на поле ввода
    if (this.input_mask) {
      (new Inputmask(this.input_mask)).mask(this.$refs.input_field);
    }
    this.data_values = this.set_values;
  },
  computed: {
    get_input_block_field_classes() {
      const classes = {
        'input-block__field': true,
        'input-block__filled': this.is_focused || this.data_values || this.is_all_time_open,
        'input-block__focused': this.is_focused,
        'input-block__text-area': this.type === 'textarea',
      };

      if (this.input_field_status) {
        classes[`input-block__${this.input_field_status}`] = true;
      }

      if (this.style_type === 'light') {
        const lightClasses = {};
        // eslint-disable-next-line guard-for-in
        for (const key in classes) {
          lightClasses[key + '__light'] = classes[key];
        }
        return lightClasses;
      }

      return classes;
    },
    get_icon_name() {
      switch (this.type) {
        case 'text':
          return;
        case 'date':
          return 'calendar';
        case 'time':
          return 'time';
        case 'search':
          return 'magnifying-glass';
      }
    },
  },
  methods: {
    get_files() { // only to file input
      return this.$refs.input_field.files;
    },
    focusout() {
      this.is_focused = false;
      this.$emit('focus_change', this.is_focused);
    },
    set_focus() {
      // для textarea не требуется вызывать focus()
      if (this.type !== 'textarea') {
        this.is_focused = true;
        this.$emit('focus_change', this.is_focused);
        this.$refs.input_field.focus();
      }
    },
    get_hint_label_type(isFocused) {
      if (isFocused || this.data_values || this.is_all_time_open) {
        return 'caption/small/regular 10';
      } else {
        return 'body/large/regular 14';
      }
    },
    keyup() {
      // использует для фильтра по выпадающему списку DropDownInput
      this.$emit('keyups', this.data_values);
    },
  },
});
</script>


<style lang="less">
@import url('../../assets/v1/less/base.less');

@input__border__color: @stroke-gray;
@input__icon__color: @light-gray;
@input__text__color: @light-gray;

@input__filled__border__color: @gray;
@input__focused__border__color: @green;
@input__error__border__color: @error;
@input__error__text__color: @error;

@input__disabled__background-color: @super-light;
@input__disabled__border__color: @super-light;
@input__disabled__text__color: @light-gray;

.input-block * {
  cursor: text;
}

.input-block {
  .flex(column, flex-start, flex-start);
  width: 100%;
  //min-width: 250px;
  resize: none;

  &__field, &__field__light {
    .flex(row, flex-start, center);
    min-height: 58px;
    width: 100%;
    border-radius: 6px;
    padding: 0 15px;

    background-color: transparent;

    .set_icon_color(@input__icon__color);
  }

  &__field {
    border: 1px solid @input__border__color;
  }

  &__field__light {
    border: 1px dashed transparent;
    border-bottom-color: @input__border__color;

    &:hover {
      border: 1px solid @input__focused__border__color;
    }
  }

  &__text {
    .flex(column, flex-start, stretch);
    flex-grow: 1;
    user-select: none;
    .set_color(@input__text__color);
  }

  &__title {
    &-wrapper {
      line-height: 0;
    }

    &__required-symbol {
      color: @error;
    }
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: 0 solid transparent;
    height: 0;
    position: absolute;
  }

  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }

  &__filled, &__filled__light {
    border: 1px solid @input__filled__border__color;
    resize: inherit;

    .input-block__input {
      height: 100%;
      position: relative;
      overflow: visible;
    }
  }

  &__filled {
    border: 1px solid @input__filled__border__color;
  }

  &__filled__light {
    border: 1px dashed transparent;
    border-bottom-color: @input__border__color;
  }

  &__focused, &__focused__light {
    border: 1px solid @input__focused__border__color;
  }

  &__error, &__error__light {
    border: 1px solid @input__error__border__color;

    &-text {
      color: @input__error__text__color;
      margin: 6px 0;
    }
  }

  &__disabled, &__disabled__light {
    //pointer-events: none;
    background-color: @input__disabled__background-color;
    border-color: @input__disabled__border__color;
    color: @input__disabled__text__color;
  }
}

.input-block__text {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-outer-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    .hide_icon_to_input()
  }

  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    .hide_icon_to_input();
  }

  input[type="date"]::-webkit-date-and-time-value,
  input[type="time"]::-webkit-date-and-time-value {
    text-align: left;
    color: @dark;
  }
}

textarea {
  resize: vertical;
}

.input-block__text-area, .input-block__text-area__light {
  padding-top: 12px;
  padding-bottom: 12px;
}

</style>
