<template>
  <div class="preload-wrap-screen" id="preload">
    <logoPanel class="preload-wrap-screen__center" width="100" />
    <div class="preload-wrap-screen__center" >
      <spinner :width="1"/>
    </div>
  </div>
</template>

<script>
import spinner from '../../components/unit/Spinner.vue';
import logoPanel from '../../components/unit/MainLogo.vue';

export default {
  name: 'LoadingPage',
  components: {spinner, logoPanel},
  props: {
    func: undefined,
  },
  created() {
    if (this.func !== undefined) this.func();
  },
};
</script>


<style  lang="less">
@import url('../../assets/v1/less/base.less');

@preload__background-color: @white;

.preload-wrap-screen {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: @preload__background-color;
  z-index: 1000;

  &__center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spinner{
      width: 200px;
      height: 200px;
    }
  }
}
</style>
