<template>
  <NonResizableOnOverflow>
    <div class="left-content">
      <StructNode :levelData="this.getTreeStruct" @select_changed="this.$emit('select_changed')"/>
    </div>
  </NonResizableOnOverflow>
</template>

<script type="text/javascript">
import NonResizableOnOverflow from '@/components/core/NonResizableOnOverflow';
import builder from '@/assets/v1/js/builder';
import StructNode from './StructNode';

export default builder({
  name: 'LeftMenu',
  components: {
    NonResizableOnOverflow,
    StructNode,
  },
  emits: ['select_changed'],
  computed: {
    getTreeStruct() {
      return this.$store.state.navigate_settings.web_site_tree_struct;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.left-content {
  overflow: auto;
  height: 100%;
  width: 100%;
  /* ::-webkit-scrollbar {
    display: none;
  } */
}
</style>
