<template>
  <InputField v-model="this.data_value"
              :label_text="this.getTitle"
              :type="this.getInputType"
              v-bind="this.getComponentSettings"
              :set_values="this.set_values"/>
</template>

<script type="text/javascript">
import builder from '../../../assets/v1/js/builder';
import InputField from '@/components/unit/InputField';

export default builder({
  components: {
    InputField,
  },
  emits: [
    'input_change',
    'update:modelValue',
  ],
  data: () => ({
    data_value: '',
  }),
  computed: {
    getTitle() {
      const title = this.fieldInfo.title || this.fieldInfo.system_name;
      return this.loc(title);
    },
    getInputType() {
      return this.getComponentSettings ? this.getComponentSettings.type : undefined;
    },
    getComponentSettings() {
      return this.fieldInfo.typeSettings;
    },
  },
  watch: {
    modelValue(newData) {
      if (newData) this.data_value = newData;
    },
    set_values(newData) {
      // при получении новых данных через props меняем data_values
      this.data_value = newData;
    },
    data_value(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
      this.$emit('update:modelValue', newData);
    },
  },
  props: {
    fieldInfo: {
      required: true,
      type: Object,
      default: {},
    },
    modelValue: {},
    set_values: {},
  },
});
</script>

<style lang="less">

</style>
