<template>
  <div class="named-field">
    <LabelComponent class="named-field__text-hint"
                    label_type='caption/small/regular 10'
                    :label_text="this.label"/>
    <slot/>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import LabelComponent from './Label';

export default builder({
  name: 'NamedField',
  components: {LabelComponent},
  props: {
    label: {
      type: String,
      require: false,
    },
  },
});
</script>


<style lang="less">
@import url('../../assets/v1/less/base.less');

.named-field{
  .flex(column, flex-start, flex-start);
  & > *{
    margin-bottom: 6px;
  }

  & > *:last-child{
    margin-bottom: 0;
  }

  &__text-hint{
    color: @gray;
  }
}

</style>
