<template>
  <div class="pin-page">
    <div class="work-block">
      <LabelComponent class="header" :label_text="this.loc('Введите новый PIN код')" v-if="this.savedUserData == null"/>
      <LabelComponent class="header" :label_text="this.loc('Введите PIN код')" v-else/>

      <LabelComponent class="global-error"
                      v-show="this.global_error_text != null"
                      :label_text="loc(this.global_error_text)"/>

      <div class="pin-points">
        <div class="pin-point" :class="{'active': this.writePin.length > 0}"/>
        <div class="pin-point" :class="{'active': this.writePin.length > 1}"/>
        <div class="pin-point" :class="{'active': this.writePin.length > 2}"/>
        <div class="pin-point" :class="{'active': this.writePin.length > 3}"/>
      </div>

      <div class="digit-panel">
        <div class="digit-row">
          <div class="digit-item" @click="this.inputDigit('1')">1</div>
          <div class="digit-item" @click="this.inputDigit('2')">2</div>
          <div class="digit-item" @click="this.inputDigit('3')">3</div>
        </div>
        <div class="digit-row">
          <div class="digit-item" @click="this.inputDigit('4')">4</div>
          <div class="digit-item" @click="this.inputDigit('5')">5</div>
          <div class="digit-item" @click="this.inputDigit('6')">6</div>
        </div>
        <div class="digit-row">
          <div class="digit-item" @click="this.inputDigit('7')">7</div>
          <div class="digit-item" @click="this.inputDigit('8')">8</div>
          <div class="digit-item" @click="this.inputDigit('9')">9</div>
        </div>
        <div class="digit-row">
          <div class="digit-item"></div>
          <div class="digit-item" @click="this.inputDigit('0')">0</div>
          <div class="digit-item" @click="this.inputBackspace">
            <Icon name="backspace" sizeHeight="" sizeWidth="" class="backspace"/>
          </div>
        </div>
      </div>

      <div class="clear-pin-button-container">
          <LabelComponent
                class="clear-pin-button"
                @click="this.clearPin"
                v-if="this.savedUserData != null"
                is_inline_markup=true
                :label_text="`${this.loc('Сбросить PIN код')}<br/ >${this.loc('и войти по логину/паролю')}`"
              />

          <LabelComponent v-if="this.work_in_application()"
                class="clear-pin-button"
                @click="this.clear_cache()"
                :label_text="this.loc('Очистить кэш приложения')"/>
      </div>
    </div>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import LabelComponent from '../../components/unit/Label';
import ButtonComponent from '../../components/unit/Button';
import Icon from '../../components/unit/Icon';

import {authRequest} from '../../assets/v1/js/project_management/dataSources/authRequest';
import * as pinManipulate from '../../assets/v1/js/project_management/dataSources/pinManipulate';

export default builder({
  name: 'PinPage',
  components: {
    LabelComponent,
    ButtonComponent,
    Icon,
  },
  props: {
    next_page: {default: undefined},
    savedUserData: {default: undefined},
    cryptUserDataSaveKey: {default: undefined},
  },
  data: ({
    writePin: '',
    try_auth_requesting: false,
    global_error_text: undefined,
  }),
  methods: {
    work_in_application() {
      return window.WORK_IN_APPLICATION;
    },
    clear_cache() {
      window.sendMessageInApplication({
        type: 'clear_cache',
        callback: 'window.applicationCallback.cacheCleared',
      });
    },
    clearPin() {
      /* if (window.APPLICATION_PROJECT_VERSION >= 3) {
        if (!confirm(this.loc('При сбросе PIN кода, повторная его установка будет доступна только после ввода логина/пароля! \nВы уверены, что хотите сбросить PIN код?'))) {
          return;
        }
      } */

      // remove pin in server
      pinManipulate.deletePinCode(this.savedUserData);

      window.sendMessageInApplication({
        type: 'delete_saved_string',
        key: this.cryptUserDataSaveKey,
      });

      this.accepted();
    },
    inputDigit(digit) {
      if (this.try_auth_requesting) {
        return;
      }

      this.writePin = this.writePin + digit;

      if (this.writePin.length > 3) {
        this.try_auth_requesting = true;
        // try_crypt_auth_with_pin if this.savedUserData else set_new_pin

        const setNewPin = this.savedUserData == null;

        this.$store.commit('set_full_screen_loading', true);

        if (setNewPin) {
          const curThis = this;

          pinManipulate.setNewPinCode(this.writePin).then(async function(result) {
            const newSession = await result.text();

            window.sendMessageInApplication({
              type: 'save_string',
              key: curThis.cryptUserDataSaveKey,
              value: newSession,
            });

            curThis.accepted();
          }).finally(() => {
            this.$store.commit('set_full_screen_loading', false);
          });
        } else {
          const request = authRequest(
              {
                pinCode: this.writePin,
                pinSession: this.savedUserData,
              });

          const curThis = this;

          request
              .then((result) => {
                if (result.status != 200) {
                  throw result;
                }

                this.accepted();
              }).catch(async function(result) {
                if (result.status == 401) {
                  curThis.global_error_text = 'Не удалось авторизоваться. ' +
                'Введен не верный пинкод.';
                } else {
                  const errorText = await result.text();
                  const errorStatus = result.statusText;
                  const errorCode = result.status;

                  curThis.global_error_text = 'Не удалось авторизоваться. ' +
                'Возникла техническая ошибка. Попробуйте сбросить пинкод.' +
                ' Код: ' + errorCode +
                ' Статус: ' + errorStatus +
                ' Текст: ' + errorText;
                }
              })
              .finally(() => {
                this.try_auth_requesting = false;
                this.writePin = '';
                this.$store.commit('set_full_screen_loading', false);
              });
        }
      }
    },
    inputBackspace() {
      if (this.writePin.length < 1) {
        return;
      }

      this.writePin = this.writePin.substring(0, this.writePin.length-1);
    },
    accepted() {
      this.$store.commit('is_user_login_now');
      this.$router.replace({path: this.next_page});
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.pin-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @white;
  .flex(row, center, center);

  .work-block {
    // margin: auto;
    width: min-content;
    height: min-content;

    .global-error{
      color: red;
      text-align: center;
    }

    .header {
      display: flex;
      justify-content: center;
      font-size: 2.5vh;
      font-weight: 500;
    }

    .pin-points {
      .flex(row, space-evenly, stretch);
      padding: 5vh 0;

      .pin-point{
        // color: gray;
        width: 3vh;
        height: 3vh;
        border-radius: 50%;
        background-color: @light-gray;

        transition: all 0.4s ease;
      }

      .active {
        background-color: @green;

        transform: translateY(-10px);
      }
    }

    .digit-panel {
      .flex(column, space-between, stretch);

      min-width: 50px;
      width: 50vh;
      max-width: 300px;

      min-height: 50px;
      height: 50vh;
      max-height: 300px;

      margin: auto;

      .digit-row {
        .flex(row, space-around, stretch);
        // gap: 15%;

        .digit-item {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 6vh;
          height: 6vh;
          border-radius: 50%;
          font-size: 6vh;

          .backspace {
            min-width: 80%;
            max-width: 80%;
            min-height: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .clear-pin-button-container {
      margin-top: 5vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .clear-pin-button {
        text-align: center;
        color: @green;
        font-size: 1.9vh;
        margin: 2vh 0;
      }
    }
  }
}
</style>
