import {dataRequester} from '../assets/v1/js/project_management/DataRequester';


export const materialsGuideModule = {
  state: {
    materialsGuideUrls: {
      base: 'https://ref.kartli.ch/api/v1',
      polymersList: '/polymers',
      polymer: '/polymers/{id}',
      polymersFilter: '/polymers/filter',
      polymerAnalogs: '/polymers/{id}/analogs',
    },

    materialsGuideFilters: {},
    materialsGuideFiltersItems: {},
    materialsGuideFiltersData: [],

    materialsGuideData: [],

    materialsGuideNextUrl: undefined,
    materialsGuideLastRequestUrl: undefined,

    materialsGuideSelectedItem: null,

    materialsGuideIsLoading: false,
    materialsGuideFiltersIsLoading: false,

  },

  getters: {
    getMaterialsGuideIsLoading(state) {
      return state.materialsGuideIsLoading;
    },
    getMaterialsGuideFiltersIsLoading(state) {
      return state.materialsGuideFiltersIsLoading;
    },
    getFiltersStruct() {
      return [
        {
          system_name: 'column',
          type: 'column',
          items: [
            {
              system_name: 'shortcode',
              title: 'Марка',
            },
            {
              system_name: 'type',
              type: 'dropdown',
              title: 'Тип',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'row',
              type: 'row',
              typeSettings: {
                namedFieldTitle: 'ПТР',
                isGroup: true,
              },
              items: [
                {
                  system_name: 'ptr_min',
                  title: 'Значение от',
                  title_system: 'ПТР от',
                  typeSettings: {
                    type: 'number',
                  },
                },
                {
                  system_name: 'ptr_max',
                  title: 'Значение до',
                  title_system: 'ПТР до',
                  typeSettings: {
                    type: 'number',
                  },
                },
              ],
            },
            {
              system_name: 'row',
              type: 'row',
              typeSettings: {
                namedFieldTitle: 'Плотность',
                isGroup: true,
              },
              items: [
                {
                  system_name: 'density_min',
                  title: 'Значение от',
                  title_system: 'Плотность от',
                  typeSettings: {
                    type: 'number',
                  },
                },
                {
                  system_name: 'density_max',
                  title: 'Значение до',
                  title_system: 'Плотность до',
                  typeSettings: {
                    type: 'number',
                  },
                },
              ],
            },
            {
              system_name: 'row',
              type: 'row',
              typeSettings: {
                namedFieldTitle: 'Температура Вика',
                isGroup: true,
              },
              items: [
                {
                  system_name: 't_vika_min',
                  title: 'Значение от',
                  title_system: 'Температура Вика от',
                  typeSettings: {
                    type: 'number',
                  },
                },
                {
                  system_name: 't_vika_max',
                  title: 'Значение до',
                  title_system: 'Температура Вика до',
                  typeSettings: {
                    type: 'number',
                  },
                },
              ],
            },
            {
              system_name: 'subtype',
              type: 'dropdown',
              title: 'Подтип',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'applications_category',
              type: 'dropdown',
              title: 'Категории применения',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'applications',
              type: 'dropdown',
              title: 'Область применения',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'copolymer',
              type: 'dropdown',
              title: 'Сополимер',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'color',
              type: 'dropdown',
              title: 'Цвет',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'plants',
              type: 'dropdown',
              title: 'Производитель',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'obtaining_methods',
              type: 'dropdown',
              title: 'Метод получения',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'processing_methods',
              type: 'dropdown',
              title: 'Метод переработки',
              typeSettings: {
                dropdown_items_init: [],
              },
            },
            {
              system_name: 'button_update',
              title: 'Применить фильтр',
              type: 'button',
              actions: [
                {type: 'sendFormAll__isOnlyChange__isWithRequired'},
              ],
            },
          ],
        },
      ];
    },
    getMaterialGuideFilters(state, getters) {
      const filterItems = state.materialsGuideFiltersItems;
      const filters = getters.getFiltersStruct;

      const setDropdownItems = function(struct) {
        struct.forEach((item) => {
          if (['row', 'column'].includes(item.type)) {
            setDropdownItems(item.items);
            return;
          }

          if (item.type === 'dropdown') {
            item.typeSettings.dropdown_items_init = filterItems[item.system_name] || [];
            item.typeSettings.dropdown_items_init.forEach((item) => {
              item.value_show = item.name;
            });
          }
        });
      };
      setDropdownItems(filters);
      return filters;
    },
    getMaterialsGuideData(state) {
      return state.materialsGuideData;
    },
  },

  mutations: {
  },

  actions: {
    setMaterialsGuideFilters(context, data) {
      context.state.materialsGuideFilters = data;
      context.dispatch('updateMaterialsGuideData');
    },

    updateMaterialsGuideData(context) {
      let url = context.state.materialsGuideNextUrl;

      if (url === undefined) {
        const filters = Object.entries(context.state.materialsGuideFilters)
            .map(([key, value]) => key + '=' + value);

        const baseUrl = context.state.materialsGuideUrls.base;
        url = baseUrl + context.state.materialsGuideUrls.polymersList;
        url += url.includes('?') ? '&' : '?';
        url += filters.join('&');
      }

      if (url === null) return;

      const options = {
        url: url,
        method: 'GET',
      };

      if (!context.state.materialsGuideNextUrl) context.state.materialsGuideIsLoading = true;
      context.state.materialsGuideLastRequestUrl = url;

      dataRequester(options)
          .then((data) => {
            if (context.state.materialsGuideLastRequestUrl === url) {
              context.state.materialsGuideData.push(...data.results);
              context.state.materialsGuideNextUrl = data.next;
              context.state.materialsGuideIsLoading = false;
            }
          });
    },
    updateMaterialsGuideAnalogsData(context, id) {
      const baseUrl = context.state.materialsGuideUrls.base;
      let url = baseUrl + context.state.materialsGuideUrls.polymerAnalogs;
      url = url.replace('{id}', id);

      const options = {
        url: url,
        credentials: true,
        method: 'GET',
      };

      context.state.materialsGuideAnalogsIsLoading = true;
      return dataRequester(options);
    },
    updateFilters(context) {
      const baseUrl = context.state.materialsGuideUrls.base;
      const url = baseUrl + context.state.materialsGuideUrls.polymersFilter;
      const options = {
        url: url,
        credentials: true,
        method: 'GET',
      };
      context.state.materialsGuideFiltersIsLoading = true;
      dataRequester(options)
          .then((data) => {
            context.state.materialsGuideFiltersItems = data;
            context.state.materialsGuideFiltersIsLoading = false;
          });
    },
    clearMaterialsGuide(context) {
      context.state.materialsGuideNextUrl = undefined;
      context.state.materialsGuideData = [];
    },
  },
  modules: {},
};
