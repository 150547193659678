export default class UserAdmin {
  constructor(baseUrl) {
    this.name = 'users';
    this.url = baseUrl + this.name;
  }
  get ModelName() {
    return this.name;
  }

  get Url() {
    return this.url;
  }

  get FilterStruct() {
    return [
      {
        system_name: 'username',
        type: 'dropdown',
      },
      {
        system_name: 'email',
        type: 'dropdown',
      },
      {
        system_name: 'first_name',
        type: 'dropdown',
      },
      {
        system_name: 'last_name',
        type: 'dropdown',
      },
    ];
  }

  get TableStruct() {
    return [
      {
        system_name: 'id',
        content_align: 'right',
        getValue: (item) => (item.id ? item.id : ''),
      },
      {
        system_name: 'username',
        getValue: (item) => (item.username ? item.username : ''),
      },
      {
        system_name: 'first_name',
        getValue: (item) => (item.first_name ? item.first_name : ''),
      },
      {
        system_name: 'last_name',
        getValue: (item) => (item.last_name ? item.last_name : ''),
      },
      {
        system_name: 'email',
        getValue: (item) => (item.email ? item.email : ''),
      },
      {
        type: 'boolean',
        system_name: 'allowed_code',
        getValue: (item) => item.allowed_code,
      },
      {
        type: 'boolean',
        system_name: 'allowed_password',
        getValue: (item) => item.allowed_password,
      },
      {
        type: 'boolean',
        system_name: 'allowed_password',
        getValue: (item) => item.allowed_password,
      },
      {
        type: 'boolean',
        system_name: 'is_active',
        getValue: (item) => item.is_active,
      },
      {
        type: 'boolean',
        system_name: 'is_admin',
        getValue: (item) => item.is_admin,
      },
      {
        type: 'boolean',
        system_name: 'is_superuser',
        getValue: (item) => item.is_superuser,
      },
      {
        type: 'boolean',
        system_name: 'sup_session',
        getValue: (item) => !!item.sup_session,
      },
      {
        type: 'boolean',
        system_name: 'erp_session',
        getValue: (item) => !!item.erp_session,
      },
      {
        type: 'boolean',
        system_name: 'ems_session',
        getValue: (item) => !!item.ems_session,
      },
      {
        type: 'boolean',
        system_name: 'additional_info',
        getValue: (item) => !!item.additional_info,
      },
    ];
  }

  get FormStruct() {
    return [
      {
        system_name: 'username',
        typeSettings: {
          is_required: true,
        },
        getValue: (item) => (item.username ? item.username : ''),
      },
      {
        system_name: 'password',
        typeSettings: {
          is_required: true,
        },
        getIsShowByType: (type) => type === 'create',
        getValue: (item) => (item.username ? item.username : ''),
      },
      {
        system_name: 'row',
        type: 'row',
        getIsShowByType: (type) => type !== 'create',
        items: [
          {
            system_name: 'first_name',
            getValue: (item) => (item.first_name ? item.first_name : ''),
          },
          {
            system_name: 'last_name',
            getValue: (item) => (item.last_name ? item.last_name : ''),
          },
        ],
      },
      {
        system_name: 'email',
        getIsShowByType: (type) => type !== 'create',
        getValue: (item) => (item.email ? item.email : ''),
      },
      {
        system_name: 'column',
        type: 'column',
        getIsShowByType: (type) => type !== 'create',
        typeSettings: {
          isNamed: true,
          namedFieldTitle: 'Доступы',
          isGroup: true,
        },
        items: [
          {
            system_name: 'allowed_code',
            type: 'boolean',
            getValue: (item) => (item.allowed_code ? item.allowed_code : ''),
          },
          {
            system_name: 'allowed_password',
            type: 'boolean',
            getValue: (item) => (item.allowed_password ? item.allowed_password : ''),
          },
        ],
      },
      {
        system_name: 'column',
        type: 'column',
        getIsShowByType: (type) => type !== 'create',
        typeSettings: {
          isNamed: true,
          namedFieldTitle: 'Тип доступа',
          isGroup: true,
        },
        items: [
          {
            system_name: 'is_active',
            type: 'boolean',
            getValue: (item) => !!item.is_active,
          },
          {
            system_name: 'is_admin',
            type: 'boolean',
            getValue: (item) => !!item.is_admin,
          },
          {
            system_name: 'is_superuser',
            type: 'boolean',
            getValue: (item) => !!item.is_superuser,
          },
        ],
      },
      {
        system_name: 'column',
        type: 'column',
        getIsShowByType: (type) => type !== 'create',
        typeSettings: {
          isNamed: true,
          namedFieldTitle: 'Внешние системы',
          isGroup: true,
        },
        items: [
          {
            system_name: 'sup_session',
            getValue: (item) => (item.sup_session ? item.sup_session : ''),
          },
          {
            system_name: 'erp_session',
            getValue: (item) => (item.erp_session ? item.erp_session : ''),
          },
          {
            system_name: 'ems_session',
            getValue: (item) => (item.ems_session ? item.ems_session : ''),
          },
        ],
      },
      // {
      //   system_name: 'dropdown',
      //   typeSettings: {
      //     dropdown_items_init: [
      //       {
      //         value_show: 'a',
      //         return_value: 'a',
      //       },
      //       {
      //         value_show: 'b',
      //         return_value: 'b',
      //       },
      //       {
      //         value_show: 'c',
      //         return_value: 'c',
      //       },
      //       {
      //         value_show: 'd',
      //         return_value: 'd',
      //       },
      //     ],
      //     is_multi_select: true,
      //     is_filterable: true,
      //   },
      //   value: ['c', 'd'],
      //   title: 'Дропдаун',
      //   type: 'dropdown',
      // },
    ];
  }
}
