import {ROOT_URL, store} from '@/store';

const REQUEST_LINK = '/api/v1/data/object_list/';

function getObjectList(objectTypeName, handler) {
  store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + objectTypeName + '/', {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    const objectTypeData = await response.json();
    store.commit('set_full_screen_loading', false);
    handler(objectTypeData);
  }).catch((errData) => {
    store.commit('set_full_screen_loading', false);
  });
}

export default getObjectList;
