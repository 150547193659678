<template>
  <div>
    <FormPainterComponent :direction="this.getPainterDirection"
                          @buttonClick="(buttonField) => this.buttonActionHandler(buttonField)"
                          v-model="this.struct"/>

    <ModalView v-if="this.activeModalForm"
               ref="form_modal_ref"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               :caption="this.getModalTitle"
               @click_close="() => this.activeModalForm = null">

      <FormComponent v-model="this.activeModalForm.items"
                     :is-base="false"
                     @sendForm="this.sendForm"
                     @buttonClick="(buttonField) => this.buttonActionHandler(buttonField)"
                     @closeModal="this.emitCloseModalEvent"/>

    </ModalView>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';

import formButtonActions from '../../assets/v1/js/mixins/formButtonActions';

import ModalView from '../../components/ModalView.vue';
import FormPainterComponent from './FormPainterComponent.vue';

export default builder({
  components: {
    ModalView,
    FormPainterComponent,
  },

  emits: [
    'update:modelValue',
    'sendForm',
    'buttonClick',
    'closeModal',
  ],

  name: 'FormComponent',

  mixins: [formButtonActions],

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isBase: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    struct: {
      get: function() {
        return this.modelValue;
      },
      set: function(value) {
        this.$emit('update:modelValue', value);
      },
    },
    getPainterDirection() {
      return this.struct.direction || 'column';
    },
    getModalTitle() {
      const title = this.activeModalForm.title || this.activeModalForm.system_name;
      return this.loc(title);
    },
  },

  methods: {
    buttonActionHandler(buttonField) {
      buttonField.actions.forEach((action) => {
        const actionType = action.type.split('__')[0];

        if (this[actionType]) {
          this[actionType](buttonField, action);
        } else {
          console.error(`Нет функции ${action.type}}`);
        }
      });
    },
    emitCloseModalEvent() {
      this.$refs.form_modal_ref.close_window();
    },
    sendForm(form) {
      this.sendFormAll(form, null, false);
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');


</style>
