import localiser from '../packs/localiser';
import store from '../../../../store';

import getLocalizeLanguage from '../project_management/dataSources/getLocalizeLanguage';

export default {
  computed: {
    get_lang_switcher_values() {
      return [
        {
          name: 'RUS',
          return_value: 'ru',
        },
        /* {
          name: 'ENG',
          return_value: 'en',
        }, */
      ];
    },
  },
  methods: {
    loc(key) {
      return localiser.loc(key);
    },
    getUserLangId() {
      const selectLanguage = this.getUserLangName();

      return this.get_lang_switcher_values.findIndex((item)=> item.return_value === selectLanguage);
    },
    getUserLangName() {
      let selectLanguage = localStorage.getItem('userLanguage');
      if (selectLanguage == null) {
        selectLanguage = 'ru';
      }
      return selectLanguage;
    },
    switchLanguage(obj) {
      const lang = obj.return_value;
      localStorage.setItem('userLanguage', lang);
      this.$store.commit('cur_lang_mut', lang);
      const language = this.$store.getters.getLanguage(lang);
      if (language == null) {
        this.$store.commit('set_full_screen_loading', true);
        getLocalizeLanguage((data) => {
          store.commit('translations_mut', data);
          this.$store.commit('set_full_screen_loading', false);
        }, lang).makeRequest(lang);
      }
    },
  },
};
