export default {
  methods: {
    setLoading(isLoading) {
      if (isLoading) {
        window.globalEvents.callEvent('showLoader');
      } else {
        window.globalEvents.callEvent('closeLoader');
      }
    },
    alertError(message, description) {
      window.globalEvents.callEvent('showModal', ['error', message, description]);
    },
    alertWarning(message, description) {
      window.globalEvents.callEvent('showModal', ['warning', message, description]);
    },
    alertDebug(message, description) {
      window.globalEvents.callEvent('showModal', ['debug', message, description]);
    },
    alertInfo(message, description) {
      window.globalEvents.callEvent('showModal', ['info', message, description]);
    },
  },
};
