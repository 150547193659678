<template>
  <div class="scroll-block grow">
    <div class="scroll-wrapper" ref="scroll_wrapper"
         @mousemove="this.mouse_move">

      <div class="scroll-wrapper-horizontal">
        <div class="scroll-wrapper-vertical grow">
          <div class="scroll-track-horizontal">
            <div class="scroll-track-horizontal-view grow" ref="horizontal_track" >
              <div class="scroll-track-horizontal-thumb" ref="horizontal_thumb"
                   @dragstart="this.return_false"
                   @mousedown="this.horizontal_thumb_mouse_down"
              />
            </div>
          </div>
          <div class="scroll-block-content grow">
            <div class="scroll-wrapper-content grow" ref="content"
                 @scroll="this.scroll_func">
              <slot>Текст по умолчанию</slot>
            </div>
          </div>
        </div>

        <div class="scroll-wrapper-vertical">
          <div class="scroll-track-vertical grow">
            <div class="scroll-track-vertical-view grow" ref="vertical_track">
              <div class="scroll-track-vertical-thumb" ref="vertical_thumb"
                   @dragstart="this.return_false"
                   @mousedown="this.vertical_thumb_mouse_down"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import builder from '@/assets/v1/js//builder';

export default builder({
  data: () => ({
    scroll_horizontal_drag: false,
    scroll_vertical_drag: false,
    thumb_click_position: 0,
  }),
  mounted() {
    document.addEventListener(
        'mouseup',
        this.all_mouse_up,
        {passive: true},
    );
    document.addEventListener(
        'touchend',
        this.all_mouse_up,
        {passive: true},
    );

    this.$refs.horizontal_thumb.addEventListener(
        'touchstart',
        this.horizontal_thumb_mouse_down,
        {passive: true},
    );
    this.$refs.vertical_thumb.addEventListener(
        'touchstart',
        this.vertical_thumb_mouse_down,
        {passive: true},
    );
    this.$refs.scroll_wrapper.addEventListener(
        'touchmove',
        this.mouse_move,
        {passive: true},
    );
    this.init_scroll();
  },
  beforeUpdate() {
    this.init_scroll();
  },
  beforeDestroy() {
    document.removeEventListener(
        'mouseup',
        this.all_mouse_up,
    );
    document.removeEventListener(
        'touchend',
        this.all_mouse_up,
    );

    this.$refs.horizontal_thumb.removeEventListener(
        'touchstart',
        this.horizontal_thumb_mouse_down,
    );
    this.$refs.vertical_thumb.removeEventListener(
        'touchstart',
        this.vertical_thumb_mouse_down,
    );
    this.$refs.scroll_wrapper.removeEventListener(
        'touchmove',
        this.mouse_move,
    );
  },
  methods: {
    return_false() {
      return false;
    },
    propagate() {
      // event.stopPropagation();
      return false;
    },
    all_mouse_up(event) {
      this.horizontal_thumb_mouse_up(event);
      this.vertical_thumb_mouse_up(event);
    },
    horizontal_thumb_mouse_down(event) {
      const positionX = (event.type === 'touchstart') ?
          event.targetTouches[0].clientX :
          event.clientX;
      this.thumb_click_position = positionX - this.$refs.horizontal_track.getBoundingClientRect().left + 1;

      this.scroll_horizontal_drag = true;
      this.propagate(event);
      return false;
    },
    horizontal_thumb_mouse_up(event) {
      this.scroll_horizontal_drag = false;
      this.propagate(event);
      return false;
    },
    vertical_thumb_mouse_down(event) {
      const positionY = (event.type === 'touchstart') ?
          event.targetTouches[0].clientY :
          event.clientY;
      this.thumb_click_position = positionY - this.$refs.vertical_thumb.getBoundingClientRect().top + 1;

      this.scroll_vertical_drag = true;
      this.propagate(event);
      return false;
    },
    vertical_thumb_mouse_up(event) {
      this.scroll_vertical_drag = false;
      this.propagate(event);
      return false;
    },

    init_scroll() {
      this.$refs.vertical_track.style.opacity = this.$refs.content.clientHeight >= this.$refs.content.scrollHeight ?
          0 :
          null;
      this.$refs.horizontal_track.style.opacity = this.$refs.content.clientWidth >= this.$refs.content.scrollWidth ?
          0 :
          null;

      if (this.$refs.content.clientWidth >= this.$refs.content.scrollWidth) {
        this.$refs.horizontal_track.style.display = 'none';
      }

      const verticalPer = this.$refs.content.clientHeight / this.$refs.content.scrollHeight;
      const horizontalPer = this.$refs.content.clientWidth / this.$refs.content.scrollWidth;

      let verticalThumbSize = this.$refs.vertical_track.clientHeight * verticalPer;
      let horizontalThumbSize = this.$refs.horizontal_track.clientWidth * horizontalPer;

      if (verticalThumbSize < 24) {
        verticalThumbSize = 24;
      }
      if (horizontalThumbSize < 24) {
        horizontalThumbSize = 24;
      }

      this.$refs.horizontal_thumb.style.width = `${horizontalThumbSize}px`;
      this.$refs.vertical_thumb.style.height = `${verticalThumbSize}px`;
    },
    scroll_func() {
      this.init_scroll();
      this.set_scroll_position(
          'v',
          't',
          this.$refs.content.scrollTop,
      );
      this.set_scroll_position(
          'h',
          't',
          this.$refs.content.scrollLeft,
      );
    },
    mouse_move(event) {
      if (this.scroll_horizontal_drag) {
        const positionX = (event.type === 'touchmove') ?
            event.targetTouches[0].clientX :
            event.clientX;
        const position = positionX - this.$refs.horizontal_track.getBoundingClientRect().left + 1;
        this.set_scroll_position(
            'h',
            'c',
            position,
        );
        this.propagate(event);
        return false;
      }
      if (this.scroll_vertical_drag) {
        const positionY = (event.type === 'touchmove') ?
            event.targetTouches[0].clientY :
            event.clientY;
        const position = positionY - this.$refs.vertical_track.getBoundingClientRect().top + 1;
        this.set_scroll_position(
            'v',
            'c',
            position,
        );
        this.propagate(event);
      }
      this.propagate(event);
      return false;
    },
    set_scroll_position(direction, element, position) {
      switch (direction) {
        case 'horizontal':
          direction = 'h';
          break;
        case 'vertical':
          direction = 'v';
          break;
      }
      switch (element) {
        case 'thumb':
          element = 't';
          break;
        case 'content':
          element = 'c';
          break;
      }

      if (direction === 'v' && element === 'c') {
        const trackSize = this.$refs.vertical_track.clientHeight;
        const thumbSize = this.$refs.vertical_thumb.clientHeight;

        if (position < this.thumb_click_position) {
          position = this.thumb_click_position;
        }
        if (position > trackSize - thumbSize + this.thumb_click_position - 1) {
          position = trackSize - thumbSize + this.thumb_click_position - 1;
        }

        const thumbMargin = position - this.thumb_click_position;
        const percent = thumbMargin /
            (this.$refs.vertical_track.clientHeight - this.$refs.vertical_thumb.clientHeight - 2);
        const scrollPosition = percent * (this.$refs.content.scrollHeight - this.$refs.content.clientHeight);

        this.$refs.vertical_thumb.style.marginTop = `${thumbMargin}px`;
        this.$refs.content.scrollTop = scrollPosition;
      }

      if (direction === 'h' && element === 'c') {
        const trackSize = this.$refs.horizontal_track.clientWidth;
        const thumbSize = this.$refs.horizontal_thumb.clientWidth;

        if (position < this.thumb_click_position) {
          position = this.thumb_click_position;
        }
        if (position > trackSize - thumbSize + this.thumb_click_position - 1) {
          position = trackSize - thumbSize + this.thumb_click_position - 1;
        }

        const thumbMargin = position - this.thumb_click_position;
        const percent = thumbMargin /
            (this.$refs.horizontal_track.clientWidth - this.$refs.horizontal_thumb.clientWidth - 2);
        const scrollPosition = percent * (this.$refs.content.scrollWidth - this.$refs.content.clientWidth);

        this.$refs.horizontal_thumb.style.marginLeft = `${thumbMargin}px`;
        this.$refs.content.scrollLeft = scrollPosition;
      }

      if (direction === 'v' && element === 't') {
        const percent = position / (this.$refs.content.scrollHeight - this.$refs.content.clientHeight);
        const verticalPosition = percent *
            (this.$refs.vertical_track.clientHeight - this.$refs.vertical_thumb.clientHeight - 2);
        this.$refs.vertical_thumb.style.marginTop = `${verticalPosition}px`;
      }

      if (direction === 'h' && element === 't') {
        const percent = position / (this.$refs.content.scrollWidth - this.$refs.content.clientWidth);
        const horizontalPosition = percent *
            (this.$refs.horizontal_track.clientWidth - this.$refs.horizontal_thumb.clientWidth - 2);
        this.$refs.horizontal_thumb.style.marginLeft = `${horizontalPosition}px`;
      }
    },
    cancel_drag_and_drop() {
      return false;
    },
  },
});


</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

@scroll__background-color: @super-light;

.no-display{
  display: none;
}

.scroll-block,
.scroll-block-content{
  position: relative;
  width: 100%;

  .scroll-wrapper,
  .scroll-wrapper-content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}

.scroll-block-content,
.scroll-wrapper-content{
  .flex(row, flex-start, flex-start);
}

.scroll-wrapper-horizontal{
  .flex(row, flex-start, stretch);
  height: 100%;
}

.scroll-wrapper-vertical{
  .flex(column, flex-start, stretch);
  height: 100%;
}

.scroll-corner,
.scroll-content,
.scroll-track-horizontal-view,
.scroll-track-vertical-view
{
  display: flex;
  position: relative;
}
.scroll-wrapper-content{
  overflow: hidden;
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar { display: none; }
  -ms-overflow-style: none;  /* IE and Edge */
}

.scroll-track-horizontal{
  .flex(row, flex-start, flex-start);
  &-view{
    background: @scroll__background-color;
    width: 100%;
  }
  &-thumb{
    /*position: absolute;*/
    top: 0;
    width: 20px;
    height: 100%;
    background: red;
    border-radius: 100px;

    -webkit-user-select: none;
    /*-khtml-user-select: none;*/
    -moz-user-select: none;
    /*-o-user-select: none;*/
    user-select: none;
  }
}

.scroll-track-vertical{
  .flex(column, flex-start, flex-start);
  &-view{
    height: 100%;
  }
  &-thumb{
    /*position: absolute;*/
    left: 0;
    height: 20px;
    width: 100%;
    background: red;
    border-radius: 100px;

    -webkit-user-select: none;
    /*-khtml-user-select: none;*/
    -moz-user-select: none;
    /*-o-user-select: none;*/
    user-select: none;
  }
}

</style>
