<template>
  <InputField :label_text="this.loc(this.input_info.name)"
              :set_values="this.getInitValue"
              :is_all_time_open="this.is_all_time_open"
              :type="this.inputType"
              style="max-width: 400px;"
              @input_change="this.inputChanged">
  </InputField>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import InputField from '@/components/unit/InputField';

export default builder({
  components: {
    InputField,
  },
  data: () => ({
    inputType: 'text',
    is_all_time_open: false,
  }),
  created() {
    this.inputType = 'text';

    if (this.input_info.input_type === 'float') {
      this.inputType = 'number';
    }

    if (this.input_info.input_type === 'string' && this.input_info.multiline === true) {
      this.inputType = 'textarea';
      this.is_all_time_open = true;
    }
  },
  computed: {
    getInitValue() {
      if (this.standard_version === 1) {
        return this.input_info.value;
      }
      return this.input_info.value.value;
    },
  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      const ans = {};
      Object.assign(ans, this.input_info);

      if (this.standard_version === 1) {
        ans.value = data;
      } else {
        ans.value.value = data;
      }
      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
