import {computed} from 'vue';
import localiser from '@/assets/v1/js/packs/localiser';
import getLocalizeLanguage from '@/assets/v1/js/project_management/dataSources/getLocalizeLanguage';
import {useStore} from 'vuex';

const translatorHook = () => {
  const store = useStore();

  const getLangSwitcherValues = computed( () => {
    return [
      {
        name: 'RUS',
        return_value: 'ru',
      },
      /* {
              name: 'ENG',
              return_value: 'en',
            }, */
    ];
  });

  const loc = (key) => {
    return localiser.loc(key);
  };

  const getUserLangId = () => {
    const selectLanguage = getUserLangName();

    return getLangSwitcherValues.value.findIndex((item)=> item.return_value === selectLanguage);
  };

  const getUserLangName = () => {
    let selectLanguage = localStorage.getItem('userLanguage');
    if (selectLanguage == null) {
      selectLanguage = 'ru';
    }
    return selectLanguage;
  };

  const switchLanguage = (obj) => {
    const lang = obj.return_value;
    localStorage.setItem('userLanguage', lang);

    store.commit('cur_lang_mut', lang);
    const language = store.getters.getLanguage(lang);

    if (language == null) {
      store.commit('set_full_screen_loading', true);

      getLocalizeLanguage((data) => {
        store.commit('translations_mut', data);
        store.commit('set_full_screen_loading', false);
      }, lang).makeRequest(lang);
    }
  };

  return {
    switchLanguage,
    loc,
    getUserLangName,
    getUserLangId,
  };
};

export default translatorHook;
