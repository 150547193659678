<template>
  <div class="button-daterange">
    <div class="button-wrapper">
      <ButtonComponent v-if="this.componentType === 'button'"
                       :label="this.label_text"
                       @click_to_button="this.click_to_button()"
                       :elementType="(this['is_date_range_picker_opened']) ? 'hover-bg-additional' : this.elementType"
                       :elementStatus="(this['is_date_range_picker_opened']) ? 'selected' :  this.elementStatus"/>

      <div v-if="this.componentType === 'input_field'"
           @click="this.click_to_button()">

        <InputField type="text"
                    :label_text="this.label_text"
                    :set_values="get_data_value"/>
      </div>
    </div>

    <ModalViewConductor ref="modalViewConductor">
      <template v-slot="{level}">
        <ModalView v-if="level === 1" ref="modal_view_ref"
                   :open_level="1"
                   type_modal="internal"
                   position="fixed"
                   :caption="this.loc('Выбор периода')"
                   @click_close="this.closed_modal">

          <div class="period-buttons-wrapper">
            <ButtonComponent elementType="hover-bg"
                             :label="this.loc('Текущий месяц')"
                             :elementStatus="this.tmpSelectedRangeId === 0 ? 'selected': 'enabled'"
                             @click_to_button="this.activateRange(0)"/>

            <ButtonComponent elementType="hover-bg"
                             :label="this.loc('Текущий квартал')"
                             :elementStatus="this.tmpSelectedRangeId === 1 ? 'selected': 'enabled'"
                             @click_to_button="this.activateRange(1)"/>

            <ButtonComponent elementType="hover-bg"
                             :label="this.loc('Текущий год')"
                             :elementStatus="this.tmpSelectedRangeId === 2 ? 'selected': 'enabled'"
                             @click_to_button="this.activateRange(2)"/>

            <ButtonComponent elementType="hover-bg"
                             :label="this.loc('3 года')"
                             :elementStatus="this.tmpSelectedRangeId === 3 ? 'selected': 'enabled'"
                             @click_to_button="this.activateRange(3)"/>

            <ButtonComponent elementType="hover-bg"
                             :label="this.loc('Произвольный период')"
                             :elementStatus="this.tmpSelectedRangeId === 4 ? 'selected': 'enabled'"
                             @click_to_button="this.activateRange(4)"/>
          </div>

          <ButtonComponent v-if="this.get_is_mobile_info"
                           class="submit-button"
                           :label="this.loc('Применить')"
                           @click_to_button="this.applyAndClose"/>

          <DateRangePicker class="date-range-picker"
                           :class="{'date-range-picker-active': this.tmpSelectedRangeId === 4,
                           'date-range-picker': this.tmpSelectedRangeId !== 4}"
                           @range_changed="this.date_range_changed"
                           :range_init_start="this.customSelectorDate.from"
                           :range_init_end="this.customSelectorDate.to"/>

          <ButtonComponent v-if="!this.get_is_mobile_info"
                           class="submit-button"
                           :label="this.loc('Применить')"
                           @click_to_button="this.applyAndClose"/>
        </ModalView>
      </template>
    </ModalViewConductor>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import ButtonComponent from './Button';
import DateRangePicker from '../../components/unit/DateRangePicker';
import IconComponent from '../../components/unit/Icon';
import ModalViewConductor from '../navigation/ModalViewConductor';
import ModalView from '../ModalView';
import InputField from './InputField';
import BarLayout from '@/components/unit/BarLayout';

export default builder({
  name: 'ButtonDateRange',
  components: {DateRangePicker, IconComponent, ButtonComponent, ModalViewConductor, ModalView, InputField, BarLayout},
  data: function() {
    return {
      // date_range_current принимает начальные данные из props
      date_range_current: this.init_range || {from: new Date(), to: new Date()},
      customSelectorDate: this.init_range || {from: new Date(), to: new Date()},
      is_date_range_picker_opened: false,
      selectedRangeId: this.init_range ? 4 : 0,
      tmpSelectedRangeId: this.init_range ? 4 : 0,
    };
  },
  props: {
    label_text: {
      required: true,
      type: String,
    },
    elementType: {
      required: false,
      default: 'hover-bg',
    },
    elementStatus: {
      required: false,
      default: 'enabled',
    },
    init_range: {
      type: Object,
      required: false,
    },
    componentType: {
      required: false,
      default: 'button',
      validator: (prop) => ['button', 'input_field'].includes(prop),
    },
  },
  emits: ['date_range_changed_by_click', 'date_range_changed', 'click_to_button'],
  methods: {
    activateRange(id) {
      this.tmpSelectedRangeId = id;
    },
    format_date(date) {
      return new Intl.DateTimeFormat('ru-Ru').format(date);
    },
    date_range_changed(range) {
      // пришло событие, что изменились данные на DateRangePicker
      this.customSelectorDate = range;
    },
    click_to_button() {
      // получили клик по элементу ButtonDataRange
      // открываем или закрываем DataPicker
      this.is_date_range_picker_opened = !this.is_date_range_picker_opened;
      if (this.is_date_range_picker_opened) {
        this.tmpSelectedRangeId = this.selectedRangeId;
        this.customSelectorDate = this.date_range_current;

        // если открыт дата пикер, то посылаем событие, что элемент (но пока не диапазон) выбран
        this.$refs.modalViewConductor.up_level();
        this.$emit('date_range_changed_by_click');
      }
    },
    applyAndClose() {
      this.selectedRangeId = this.tmpSelectedRangeId;
      this.date_range_current = this.getCurrentDateRange;

      this.$emit('date_range_changed', this.date_range_current);
      this.$refs.modal_view_ref.close_window();
    },
    closed_modal() {
      // метод вызываемый снаружи закрывает модалку
      // посылаем событие родителю что выбран период
      // this.$emit('date_range_changed', this.date_range_current);
      this.is_date_range_picker_opened=false;
      this.applyAndClose();
      this.$refs.modalViewConductor.close_level();
    },
  },
  computed: {
    getCurrentDateRange() {
      const ansPack = {
        0: {from: new Date().beginCurrentMonth(), to: new Date()},
        1: {from: new Date().beginCurrentQuarter(), to: new Date()},
        2: {from: new Date().beginCurrentYear(), to: new Date()},
        3: {from: new Date().shiftDateFromCurrent(-3, 0, 0), to: new Date()},
        4: this.customSelectorDate,
      };

      return ansPack[this.selectedRangeId];
    },
    get_data_value() {
      const val = this.date_range_current;
      return (val) ? `${this.format_date(val['from'])} - ${this.format_date(val['to'])}` : '';
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.no-pointer-events {
  pointer-events: none;
}

.button-daterange{
  position: relative;
}

.submit-button {
  width: min-content;
  margin-left: auto;
  margin-top: 25px;
}

.period-buttons-wrapper {
  .flex(row, flex-start, flex-start);
  flex-wrap: wrap;
  gap: 9px;
}

.date-range-picker {
  margin-top: 15px;
  transition: all 0.5s;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.date-range-picker-active {
  margin-top: 15px;
  transition: all 0.5s;
  opacity: 1;
  max-height: 600px;
  overflow: hidden;
}

.picker-wrapper {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  background: white;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid @super-light;
  display: flex;
  box-shadow: 0 16px 20px rgba(46, 50, 45, 0.06);
  z-index: 1002;

  &__back{
    position: fixed;
    inset: 0;
    z-index: -1;
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .picker-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    &__wrapper {
      position: fixed;
    }
  }
}
</style>
