<template>
  <template v-if="this.levelData">
    <div v-for="(item, key) in this.levelData" :key="key"
         v-show="this.selectedChildKey > -1">
      <linkTo :href="item.url" :newTab="item.open_new_tab === true"
                    @click="this.$emit('select_changed')"
                    class="menu__item"
                    :class="{'menu__item_active': this.isItemSelected(item, key)}">

          <div class="menu__content" :style="{'margin-left': this.currentDepth*17+'px'}">
            <IconComponent size=24 :url="item.icon_url" color="black"/>
            <LabelComponent class="menu__text"
                            label_type="caption/small/medium 10"
                            :label_text="this.loc(item.name)">

            </LabelComponent>
          </div>
          <div class="menu__pointer"/>
      </linkTo>
      <StructNode :levelData="item.children"
                  :levelKey="key"
                  :currentDepth="this.currentDepth+1"
                  @is_active="this.setActiveItem"
                  @select_changed="this.$emit('select_changed')"/>
    </div>
  </template>
</template>

<script type="text/javascript">
import LabelComponent from '@/components/unit/Label';
import IconComponent from '@/components/unit/Icon';
import builder from '@/assets/v1/js/builder';
import linkTo from '../core/linkTo.vue';

export default builder({
  name: 'StructNode',
  components: {
    LabelComponent,
    IconComponent,
    linkTo,
  },
  emits: ['select_changed', 'is_active'],
  data: ()=> ({
    selectedChildKey: -1,
  }),
  watch: {
    selectedChildKey: function() {
      if (this.selectedChildKey > -1) {
        this.$emit('is_active', this.levelKey);
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.selectedChildKey = -1;
    },
  },
  props: {
    levelData: {default: undefined},
    levelKey: {default: undefined},
    currentDepth: {default: 0},
  },
  computed: {
    get_current_page_info() {
      return this.$store.state.navigate_settings.current_page_info;
    },
  },
  methods: {
    setActiveItem(itemKey) {
      this.selectedChildKey = itemKey;
    },
    isItemSelected(item, key) {
      const ans = this.selectedChildKey === key || (this.get_current_page_info &&
          item.id === this.get_current_page_info.id);
      if (ans) {
        this.selectedChildKey = key;
      }
      return ans;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

@left__menu__content__text__color: @dark;
@left__menu__icon__color: black;

@left__menu__item__text__color: @white;
@left__menu__item__hover__background-color: @light-gray-opacity-10;
@left__menu__item__active__background-color: @light-green-opacity-10;
@left__menu__item__active__menu_pointer__color: @light-green;
@left__menu__item__active__hover__background-color: @light-green-opacity-10;

.menu {
  &__content{
    .flex(row, flex-start, center);
    padding: 10px 10px 10px 32px;
    color: @left__menu__content__text__color;
    .set_icon_color(@left__menu__icon__color)
  }
  &__text{
    margin-left: 14px;
  }

  &__item{
    .flex(row, space-between, stretch);
    width: 100%;
    color: @left__menu__item__text__color;
    text-decoration: none;

    &:hover{
      background: @left__menu__item__hover__background-color;
    }

    &_active{
      background: @left__menu__item__active__background-color;
      pointer-events: none;
      .menu__pointer{
        min-width: 4px;
        max-width: 4px;
        background: @left__menu__item__active__menu_pointer__color;
        display: flex;
        border-radius: 8px 0 0 8px;
      }

      &:hover{
        background: @left__menu__item__active__hover__background-color;
      }
    }
  }
}

</style>
