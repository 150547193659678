<template>
  <BasePage :name="'Редактирование ' + (this.workObjectType ? this.workObjectType.view : '-')">
    <div class="object-form">
        <div v-if="Object.keys(this.$route.query).length > 0">
            Обнаружены GET Параметры! {{ this.$route.query }} <br><br>
        </div>
        <ChangeObjectForm v-if="this.workObjectType != null"
                 :raw_fields="this.workObjectType.fields"
                 :raw_actions="this.workObjectType.actions"
                 @completeFormAction="this.completedForm"
        />
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/components/navigation/BasePage';
import getObjectInfo from '@/assets/dataSources/admin/getObjectInfo';
import changeObjectRequest from '@/assets/dataSources/admin/changeObjectRequest';
import ChangeObjectForm from '@/views/related_pages/adminLayout/forms/ChangeObjectForm';

export default ({
  name: 'ObjectEditForm',
  components: {
    BasePage,
    ChangeObjectForm,
  },
  data: () => ({
    workObjectType: null,
  }),
  props: {

  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.rebuildForm();
    },
  },
  methods: {
    completedForm(formData) {
      console.log('SEND_FORM', formData, Array.from(formData.entries()));

      // // const curThis = this;
      // const sendForm = Object.fromEntries(Object.keys(formData).map(
      //     (key) => [
      //       key, [
      //         typeof([]),
      //         typeof({}),
      //       ].includes(typeof(formData[key])) ? JSON.stringify(formData[key]) : formData[key],
      //     ],
      // ));

      changeObjectRequest(this.$route.params.objectName, formData, this.$route.params.objectUid, function() {
        // location.href = '/';
      });
    },
    rebuildForm() {
      const curThis = this;

      getObjectInfo(curThis.$route.params.objectName, curThis.$route.params.objectUid, function(objectTypeInfo) {
        curThis.workObjectType = objectTypeInfo;
      });
    },
  },
  mounted() {
    this.rebuildForm();
  },
  computed: {

  },
});
</script>

<style lang="less">
.object-form {
    margin: 14px;
}
</style>
