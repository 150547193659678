export default (url, filename) => {
  if (!filename) {
    filename = 'undefined';
  }

  if (window.WORK_IN_APPLICATION && window.hasFunctions(['open_file'])) {
    async function download() {
      // we use a second try block here to have more detailed error information
      // because of the nature of JS the outer try-catch doesn't know anything where the error happended
      const res = await fetch(url, {
        credentials: 'include',
      });

      const data = await res.blob();

      const fr = new FileReader();
      fr.onload = () => {
        window.sendMessageInApplication({
          type: 'open_file',
          data: `${filename};${fr.result}`,
        });
      };
      fr.readAsDataURL(data);
    }
    download();
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename;
    a.click();
    document.body.removeChild(a);
  }
};
