/* eslint-disable quote-props */
<template>
  <BasePage>
    <div class="admin-page">
      <div class="admin-page__header">
        <ButtonComponent class="admin-page__header__button"
                         :label="this.loc('Новый элемент')"
                         @click_to_button="this.showCreateModal()"/>
        <div class="admin-page__header__filters">
          <PaginatedDropDown v-for="filter in this.getFiltersStruct"
                             :key="filter.system_name"
                             :ref="filter.system_name"
                             :url="`/${this.getCurrentModel}/filter?select_by=${filter.system_name}`"
                             :label_text="filter.title || filter.system_name"
                             :is_filterable="true"
                             :paginateItemsCount="50"/>
          <ButtonComponent class="admin-page__header__button"
                           :label="this.loc('Применить фильтры')"
                           @click_to_button="this.collectAndSetFilters()"/>
        </div>
      </div>

      <PaginatedTable :max_rows="this.getPageCount"
                      :table_columns="this.getTableColumns"
                      :table_data="this.getTableData"
                      :selected_row_props="null"
                      :is_compact_table="false"
                      :is_dashed_table="false"
                      :is_selectable="true"
                      :pages="this.getPageCount"
                      :startPage="this.startPage"
                      :is_loading="this.getIsLoading"
                      @pageChange="this.changeCurrentPage"
                      @select_changed_by_click="this.selectRow"
                      @ordering_changed="this.orderingChanged">
        <template v-slot="{row_id, column_id}">
          <LabelComponent v-if="this.getStruct[column_id].type === 'label'"
                          label_type="body/large/regular 12"
                          :label_text="this.getTableData[row_id][column_id]"/>

          <div v-else-if="this.getStruct[column_id].type === 'boolean'"
               :class="this.getBooleanClass(this.getTableData[row_id][column_id])">

            <IconComponent :name="getBooleanIconName(this.getTableData[row_id][column_id])"
                           type="svg"
                           size="16"/>
          </div>

        </template>
      </PaginatedTable>
    </div>
  </BasePage>
  <ModalView v-if="this.selectedItemId"
             ref="settings_modal_view_ref"
             :open_level="1"
             type_modal="internal"
             position="fixed"
             :caption="this.getModalName"
             @click_close="this.closeTableView">
    <FormComponent class="admin-form__wrapper"
                   v-model="this.selectedElementStruct"
                   @closeModal="this.emitCloseModalEvent"
                   @sendForm="this.sendForm"/>
  </ModalView>
</template>

<script>
import store from '../../store';
import builder from '../../assets/v1/js/builder';

import PaginatedTable from '../../components/unit/PaginatedTable.vue';
import BasePage from '../../components/navigation/BasePage.vue';
import ModalView from '../../components/ModalView.vue';
import FormComponent from '../../uni_page_components/form/FormComponent';

import Separator from '../../components/unit/Separator';
import LabelComponent from '../../components/unit/Label';
import NamedField from '../../components/unit/NamedField';
import InputField from '../../components/unit/InputField';
import IconComponent from '../../components/unit/Icon';
import ButtonComponent from '../../components/unit/Button';
import PaginatedDropDown from '@/components/unit/PaginatedDropDown';

export default builder({
  components: {
    BasePage,
    PaginatedTable,
    ModalView,
    FormComponent,

    Separator,
    NamedField,
    LabelComponent,
    InputField,
    PaginatedDropDown,
    IconComponent,
    ButtonComponent,
  },

  data: () => ({
    selectedItemId: null,
    startPage: 1,
    currentFormStruct: null,
  }),

  watch: {
    selectedItemId(value) {
      this.$store.commit('setAdminSelectedElement', value);
      if (value == null) this.currentFormStruct = null;
    },
    getSelectedElementStruct: {
      deep: true,
      handler() {
        console.log(this.getSelectedElementStruct);
      },
    },
  },

  computed: {
    getStruct() {
      return this.$store.getters.getAdminCurrentTableStruct;
    },
    getTableColumns() {
      return this.$store.getters.getAdminCurrentTableColumns;
    },
    getTableData() {
      return this.$store.getters.getAdminCurrentData;
    },
    getIsLoading() {
      return this.$store.getters.getAdminIsLoading;
    },
    getIsHaveSelectedElement() {
      return this.getSelectedElementStruct !== [];
    },
    selectedElementStruct: {
      get: function() {
        return this.currentFormStruct || this.$store.getters.getAdminSelectedElementStruct;
      },
      set: function(value) {
        this.currentFormStruct = value;
        console.log('value', value);
      },
    },
    getFiltersStruct() {
      return this.$store.getters.getAdminCurrentFilterStruct;
    },
    getPageCount() {
      return this.$store.getters.getAdminPageCount;
    },
    getModalName() {
      return this.selectedItemId > 0 ? 'Просмотр' : 'Создание';
    },
    getCurrentModel() {
      return this.$store.getters.getAdminCurrentModelName;
    },
  },

  methods: {
    orderingChanged(value) {
      console.log(value);
      this.$store.dispatch('setAdminSortParameters', value);
    },
    changeCurrentPage(page) {
      this.$store.dispatch('setAdminCurrentPage', page);
    },
    selectRow(row) {
      const idStructItem =
          this.$store.getters.getAdminCurrentTableStruct.filter((item) => item.system_name === 'id')[0] || null;

      if (idStructItem == null) {
        console.warn('Haven`t system_name "id" in struct');
        return;
      }

      this.selectedItemId = row[idStructItem.id];
    },
    closeTableView() {
      this.selectedItemId = null;
    },
    getBooleanClass(value) {
      return 'paginatedTable__container__boolean__' + (
          (value == null) ? 'null' :
              (value === true) ? 'true' : 'false');
    },
    getBooleanIconName(value) {
      return (value == null) ? 'help_round' :
          (value === true) ? 'check_round' : 'cancel_round';
    },
    test() {
      // this.$store.dispatch('deleteAdminItem', 11);
    },
    showCreateModal() {
      this.selectedItemId = -1;
    },
    saveItem() {
      this.$store.dispatch('updateAdminSelectedElementData', this.getSelectedElementStruct);
    },
    deleteItem() {
      console.log(this.selectedItemId);
      this.$store.dispatch('deleteAdminItem', this.selectedItemId);
      this.$refs.settings_modal_view_ref.close_window();
    },
    sendForm(data) {
      switch (data.calling_button) {
        case 'button_delete':
          this.$store.dispatch('deleteAdminItem', data.form.id);
          break;
        case 'button_update':
          this.$store.dispatch('updateAdminItem', JSON.parse(JSON.stringify(data.form)));
          break;
        case 'button_create':
          this.$store.dispatch('createAdminItem', JSON.parse(JSON.stringify(data.form)));
          break;
      }
      console.log('data', data);
    },
    emitCloseModalEvent() {
      this.$refs.settings_modal_view_ref.close_window();
    },
    collectAndSetFilters() {
      const filters = this.getFiltersStruct.map((filter) => {
        const selectedItems = this.$refs[filter.system_name].selectedItems;
        filter.value = selectedItems.map((item) => item.return_value);
        return filter;
      });
      this.$store.dispatch('setAdminFilterParameters', filters);
    },
  },

  beforeRouteEnter(from, to, next) {
    store.dispatch('updateAdminCurrentModel');
    next();
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.admin-page {
  display: grid;
  grid-template-columns: 100%;
  gap: 15px;

  padding: 10px;

  &__header {
    display: grid;
    gap: 10px;

    &__button {
      max-width: 200px;
    }

    &__filters {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(calc(((@transition-threshold-0 * 0.98) - 70px - 10px) / 2), 1fr));
      width: 100%;
    }
  }
}

.paginatedTable__container {
  &__boolean {
    &__true {
      .set_icon_color(@green);
    }

    &__false {
      .set_icon_color(@error);
    }

    &__null {
      .set_icon_color(@light-gray);
    }
  }
}

.admin-form__wrapper{
  max-width: calc((@transition-threshold-0 * 0.98) - 70px);
  width: calc(98vw - 70px);
}
</style>
