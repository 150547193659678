import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import eventsHandler from './assets/v1/js/project_management/events_handler';
import {ROOT_URL} from './assets/v1/js/project_management/DataRequester';
import 'inputmask';

window.WORK_IN_IOS_APPLICATION = window.webkit != null &&
                                 window.webkit.messageHandlers != null &&
                                 window.webkit.messageHandlers.jsHandler != null;
window.WORK_IN_ANDROID_APPLICATION = window.Android != null &&
                                     window.Android.additionalFunction != null;

window.WORK_IN_APPLICATION = window.WORK_IN_IOS_APPLICATION || window.WORK_IN_ANDROID_APPLICATION;

if (window.WORK_IN_APPLICATION) {
  window.applicationCallback = {};
  window.availableApplicationFunctions = [];
  window.emptyCallback = () => {};

  window.hasFunctions = (checkFunctions) => {
    for (const funcIdx in checkFunctions) {
      if (window.availableApplicationFunctions.find((item) => item === checkFunctions[funcIdx]) == null) {
        return false;
      }
    }

    return true;
  };

  if (window.WORK_IN_IOS_APPLICATION) {
    window.sendMessageInApplication = (data) => {
      if (data['callback'] == null) {
        data['callback'] = 'window.emptyCallback';
      }
      window.webkit.messageHandlers.jsHandler.postMessage(data);
    };
  }

  if (window.WORK_IN_ANDROID_APPLICATION) {
    window.sendMessageInApplication = (data) => {
      if (data['callback'] == null) {
        data['callback'] = 'window.emptyCallback';
      }
      window.Android.additionalFunction(JSON.stringify(data));
    };
  }

  window.applicationCallback.availableTypes = (types) => {
    window.availableApplicationFunctions = types;

    if (window.hasFunctions(['check_permissions'])) {
      window.sendMessageInApplication({type: 'check_permissions'});
    }

    /* if (window.hasFunctions(['get_app_version'])) {
      window.sendMessageInApplication({
        type: 'get_app_version',
        callback: 'window.applicationCallback.setApplicationVersion',
      });
    } */
  };

  /* window.APPLICATION_PROJECT_VERSION = -1;
  window.APPLICATION_MARKET_VERSION = -1;

  window.applicationCallback.setApplicationVersion = (projectVersion, marketingVersion) => {
    try {
      window.APPLICATION_PROJECT_VERSION = parseFloat(projectVersion);
      window.APPLICATION_MARKET_VERSION = parseFloat(marketingVersion);
    } catch (err) {
      console.log('Parse version error! ', err);
    }
  }; */

  window.CallApplicationEvent = (platform, type, data) => {
    alert(platform + ' ' + type + ' ' + data.token);
  };

  window.sendMessageInApplication({type: 'available_types', callback: 'window.applicationCallback.availableTypes'});
}

const VueInputMask = require('vue-inputmask').default;

window.globalEvents = eventsHandler();
createApp(App)
    .use(store)
    .use(router)
    .use(VueInputMask)
    .mount('#app');
