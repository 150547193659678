<template>
  <BasePage :is_with_background="false">
    <ListView
        :is_loading="this.data_loading"
        :search_hints="this.get_search_hints"
        :search_data="this.current_data"
        @click_by_list_item="this.click_by_card">

      <template v-slot:additional_settings>
        <div class="card">
          <ButtonDateRange :init_range="this.date_range"
                           :label_text="this.loc('Задать период выборки данных')"
                           componentType="input_field"
                           @date_range_changed="this.set_date_range"
                           />

          <div class="button-panel">
            <ButtonComponent class="modal-button"
                             :label="this.loc('Применить')"
                             @click_to_button="this.renew_data()"/>

            <ButtonComponent :label="this.loc('Сбросить')"
                             elementStatusModifier="danger"
                             @click_to_button="this.set_defaults"/>
          </div>
        </div>
      </template>

      <template v-slot="{item}">
        <div class="card__header">
          <LabelComponent class="card__header__title"
                          label_type='button/large/regular 14'
                          :label_text="item['Number'] || ''"/>

          <LabelComponent :label_type="(item['TTN_Status']) ? 'body/large/regular 12':'button/large/regular 14'"
                          :label_color="
                              (item['TTN_Status'] === 'Товар получен') ? 'status-color-3':
                              (item['TTN_Status'] === 'Товар отгружен') ? 'status-color-3':
                              (item['TTN_Status'] === 'Отменено') ? 'status-color-5':
                              (item['TTN_Status'] === 'Оформлено') ? 'status-color-1': undefined"
                          :label_text="item['TTN_Status'] || ''"/>
        </div>

        <SeparatorComponent/>

        <LabelComponent label_type='button/large/regular 14'
                        :label_text="'от ' + (item['TTN_Date'] || '')"/>

        <LabelComponent label_type='button/large/regular 14'
                        :label_text="item['Consignee'] || ''"/>
      </template>
    </ListView>

    <template v-slot:modal>
      <ModalViewConductor ref="modalViewConductor">
        <ModalView v-if="this.ttn_form.selected_item != null"
                   :open_level="1"
                   type_modal="internal"
                   :caption="`${this.loc('Документ №')} ${this.ttn_form.selected_item['Number']}`"
                   @click_close="this.closed_modal($event); this.$refs.modalViewConductor.close_level()">

          <BarLayout direct="vertical" gap="12" style="overflow: visible">
            <LabelComponent v-if="this.ttn_form.success_text" class="success_text"
                            label_type='body/large/regular 12'
                            :label_text="this.ttn_form.success_text"/>

            <LabelComponent v-if="this.ttn_form.error_text" class="error_text"
                            label_type='body/large/regular 12'
                            :label_text="this.ttn_form.error_text"/>

            <NamedField :label="this.loc('Дата ТТН')">
              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['TTN_Date']"/>
            </NamedField>

            <NamedField v-if="!this.alreadySetCarDate"
                        :label="this.loc('Дата и время прибытия машины на загрузку')">
              <div class="row">
                <InputField @input_change="this.set_car_date"
                            type="date"
                            :label_text="this.loc('Дата')"
                            :set_values="this.car_date"/>
                <InputField @input_change="this.set_car_time"
                            type="time"
                            :label_text="this.loc('Время')"
                            :set_values="this.car_time"/>
              </div>
            </NamedField>

            <DropDownInput @input_change="this.set_ttn_status"
                            :label_text="this.loc('Статус')"
                           :init_selected_item="[this.ttn_status]"
                           :dropdown_items_init="this.get_dropdown_status_objects"/>

            <NamedField
                        :label="this.loc('Дата и время отправки/доставки')"
                        v-if="!this.statusIsZero">
              <div class="row">
                <InputField @input_change="this.set_ttn_date"
                            type="date"
                            :label_text="this.loc('Дата')"
                            :set_values="this.ttn_date"/>
                <InputField @input_change="this.set_ttn_time"
                            type="time"
                            :label_text="this.loc('Время')"
                            :set_values="this.ttn_time"/>
              </div>
            </NamedField>

            <NamedField :label="this.loc('Грузополучатель')">
              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['Consignee']"/>
            </NamedField>

            <SeparatorComponent/>

            <NamedField :label="this.loc('Адрес погрузки')">
              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['Sending_Address']"/>
            </NamedField>

            <SeparatorComponent/>

            <NamedField :label="this.loc('Контактное лицо')">
              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['Releaser']"/>
            </NamedField>

            <SeparatorComponent/>

            <NamedField :label="this.loc('Водитель')">
              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['FIO_Driver']"/>
            </NamedField>

            <SeparatorComponent/>

            <NamedField :label="this.loc('Номер водителя')">

              <LabelComponent label_type='button/large/regular 14'
                              :label_text="this.ttn_form.selected_item['Driver_Number'] || this.loc('Не определено')"/>
            </NamedField>


            <ButtonComponent :label="this.loc('Применить')"
                             :elementInLoading="this.ttn_form.is_button_loading"
                             :elementStatusModifier="this.set_button_apply_enabled"
                             @click_to_button="this.start_tracking_ttn()"/>

          </BarLayout>
        </ModalView>
      </ModalViewConductor>
    </template>
  </BasePage>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import BasePage from '../../components/navigation/BasePage';
import SeparatorComponent from '../../components/unit/Separator';
import BarLayout from '../../components/unit/BarLayout';
import LabelComponent from '../../components/unit/Label';
import ModalView from '../../components/ModalView';
import InputField from '../../components/unit/InputField';
import ButtonComponent from '../../components/unit/Button';
import ModalViewConductor from '../../components/navigation/ModalViewConductor';
import NamedField from '../../components/unit/NamedField';
import DropDownInput from '../../components/unit/DropDownInput';
import ListView from '../../components/unit/ListView';
import ButtonDateRange from '../../components/unit/ButtonDateRange';
import {dataRequester, ROOT_URL} from '../../assets/v1/js/project_management/DataRequester';

export default builder({
  name: 'Logistic',
  components: {
    BasePage, BarLayout, SeparatorComponent,
    LabelComponent, ModalView, InputField, ButtonComponent,
    ModalViewConductor, NamedField, DropDownInput, ListView, ButtonDateRange,
  },
  data: () => ({
    data_loading: true,
    current_data: [],
    current_data_calc: [],
    date_range: undefined,
    ttn_form: undefined,
    ttn_status: undefined,
    ttn_date: undefined,
    ttn_time: undefined,
    car_date: undefined,
    car_time: undefined,
    alreadySetCarDate: false,
  }),
  created() {
    // date_range получает значения по умолчанию
    this.set_defaults();
  },
  mounted() {
    // данные запрошиваем после отрисовки страницы
    this.renew_data();
  },
  computed: {
    get_search_hints() {
      return [
        {
          value: 'Оформлено',
          color: 'status-color-1',
        },
        {
          value: 'Товар отгружен',
          color: 'status-color-3',
        },
        /* {
          value: 'Отменено',
          color: 'status-color-5',
        },
        {
          value: 'Товар получен',
          color: 'status-color-3',
        }, */
      ];
    },
    get_dropdown_status_objects() {
      return [
        {
          value_show: 'Готово к загрузке',
          return_value: 'Готово к загрузке',
        },
        {
          value_show: 'Товар отгружен',
          return_value: 'Товар отгружен',
        },
        {
          value_show: 'Отменено',
          return_value: 'Отменено',
        },
        {
          value_show: 'Товар получен',
          return_value: 'Товар получен',
        },
      ];
    },
    statusIsZero() {
      return this.ttn_status == this.get_dropdown_status_objects[0].return_value;
    },
    set_button_apply_enabled() {
      const isActive = this.ttn_status != null &&
      (this.ttn_date != null || this.statusIsZero) &&
      (this.ttn_time != null || this.statusIsZero) &&
      (this.car_date != null) &&
      (this.car_time != null);

      return isActive ? undefined : 'disabled';
    },
  },
  methods: {
    set_defaults() {
      this.date_range={
        from: new Date().shiftDateFromCurrent(0, 0, -3),
        to: new Date().shiftDateFromCurrent(0, 1, 0),
      };
    },
    set_date_range(date) {
      this.date_range=date;
    },
    renew_data() {
      this.data_loading = true;
      const dateRange = {
        from: this.date_range['from'].toISOString().split('T')[0].replace(/-/g, ''),
        to: this.date_range['to'].toISOString().split('T')[0].replace(/-/g, ''),
      };

      const period = {
        from: this.date_range['from'].toISOString(),
        to: this.date_range['to'].toISOString(),
      };

      const inputFields = {
        'param_0': {
          'input_type': 'date_period',
          'name': 'Период',
          'serializer': 'SettingsParameterValue',
          'system_name': 'Период',
          'userSettingID': '31b55079-928a-4c26-b49d-b089cf4563be',
          'value': {
            'end_date': period.to,
            'start_date': period.from,
          },
        },
      };

      const options={
        url: `${ROOT_URL}/api/v1/src/` + 'Internal__Logistic__GetOptimisedWaybillsAll',
        method: 'POST',
        body: {
          params: {
            'date_range': dateRange,
            'period': period,
            'input_fields': inputFields,
          },
        },
      };
      dataRequester(options)
          .then((data)=>{
            this.compute_data(data.result.data);
          })
          .catch((errorObj)=>{})
          .finally( ()=>{
            this.data_loading = false;
          });
    },
    compute_data(data) {
      if (!data) return;
      this.current_data = data;
      this.current_data.sort((itemA, itemB) => {
        const compare = (itemFirst, itemSecond) => {
          return (itemFirst > itemSecond) ? 1 : (itemFirst < itemSecond) ? -1 : 0;
        };
        return compare(itemA['TTN_Status'], itemB['TTN_Status']) ||
            compare(parseInt(itemB['Number']), parseInt(itemA['Number']));
      });
    },
    click_by_card(item) {
      // заново инициализируем объект формы
      this.ttn_form={
        selected_item: undefined,
        is_button_loading: false,
        status: undefined,
        date: undefined,
        error_text: '',
        success_text: '',
      };
      this.ttn_form.selected_item = item;
      this.ttn_status=this.ttn_form.selected_item['TTN_Status']?this.ttn_form.selected_item['TTN_Status']: undefined;
      // преобразование пришедшей с сервера даты-время в формат YYYY-MM-DD
      let redate=this.ttn_form.selected_item['Date_Delivery'].match(/(\d+)/g);
      this.ttn_date=redate[2]+'-'+redate[1]+'-'+redate[0];
      // если дата пришла 0001-01-01, то считаем её пустой
      if (this.ttn_date==='0001-01-01') this.ttn_date=null;

      redate=this.ttn_form.selected_item['Car_Date'].match(/(\d+)/g);
      this.car_date=redate[2]+'-'+redate[1]+'-'+redate[0];
      this.car_time=redate[3].padStart(2, '0')+':'+redate[4];
      // если дата пришла 0001-01-01, то считаем её пустой
      if (this.car_date==='0001-01-01') {
        this.car_date=null;
        this.car_time=null;
      }

      this.alreadySetCarDate = this.car_date != null;

      this.$refs.modalViewConductor.up_level();
    },
    closed_modal() {
      this.clicked_item_data = undefined;
    },
    start_tracking_ttn() {
      if (this.statusIsZero) {
        this.ttn_date = this.car_date;
        this.ttn_time = this.car_time;
      }

      const slicedTtnDate = this.ttn_date.split('-');
      const slicedTtnTime = this.ttn_time.split(':');
      const slicedCarDate = this.car_date.split('-');
      const slicedCarTime = this.car_time.split(':');

      const ttnDate = new Date(
          parseInt(slicedTtnDate[0]),
          parseInt(slicedTtnDate[1])-1,
          parseInt(slicedTtnDate[2]),
          parseInt(slicedTtnTime[0]),
          parseInt(slicedTtnTime[1]),
          0, 0,
      );

      const carDate = new Date(
          parseInt(slicedCarDate[0]),
          parseInt(slicedCarDate[1])-1,
          parseInt(slicedCarDate[2]),
          parseInt(slicedCarTime[0]),
          parseInt(slicedCarTime[1]),
          0, 0,
      );

      const data = {
        guid: this.ttn_form.selected_item['TTN_GUID'],
        status: this.ttn_status,
        date: this.ttn_date,
        ttn_date: ttnDate.toISOString(),
        car_date: carDate.toISOString(),
      };
      this.ttn_form.is_button_loading = true;
      const options={
        url: `${ROOT_URL}/api/v1/src/Internal__Logistic__SetStatusTTN`,
        method: 'POST',
        body: {
          params: data,
        },
        ignoreError: true,
      };
      dataRequester(options)
          .then((data)=>{
            // debugger;
            if (data.status === 200) {
              this.ttn_form.selected_item['TTN_Status'] = this.ttn_status;
              window.globalEvents.callEvent('new_message', [{
                messageType: 'success',
                messageData: 'Параметры документа заданы',
              }]);
            } else {
              // обработка ошибок
              window.globalEvents.callEvent('new_message', [{
                messageType: 'error',
                messageData: {
                  message: 'нештатный ответ от сервера! ' + data},
              }]);
              console.error('внимание, нештатный ответ от сервера!');
            }
          }).catch((errorObj)=>{
            errorObj = JSON.parse(errorObj.message.text_content);

            window.globalEvents.callEvent('new_message', [{
              messageType: 'error',
              messageData: {
                name: 'ExternalSysError',
                message: errorObj.report,
              },
            }]);
          }) .catch((error) => {
            window.globalEvents.callEvent('new_message', [{
              messageType: 'error',
              messageData: {
                message: 'нештатный ответ от сервера! ' + error},
            }]);
            console.error('внимание, нештатный ответ от сервера!');
          })
          .finally( ()=>{
            this.ttn_form.is_button_loading = false;
          });
    },
    set_ttn_status(data) {
      this.ttn_status=data[0].return_value;
    },
    set_ttn_date(data) {
      this.ttn_date=data;
    },
    set_ttn_time(data) {
      this.ttn_time=data;
    },
    set_car_date(data) {
      this.car_date=data;
    },
    set_car_time(data) {
      this.car_time=data;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.text_hint {
  color: @gray;
}

.search {
  &__hints {
    .flex(row, flex-start, center);
    gap: 5px;
    flex-wrap: wrap;
  }
}

.row {
  width: 100%;
  .flex(row, space-between, center);
  gap: 10px;
}

.card{
  &__header{
    .flex(row, space-between, center);
  }
}

.button-panel {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 6px;
  }
  & > *:last-child{
    margin-right: 0;
  }
}

.error_text {
  color: @error;
}

.success_text {
  color: @green;
}

</style>
