import store from '../../../../store';

export default {
  loc(key) {
    // return '-';

    const curLang = store.state.localize_settings.cur_lang;
    const curTranslator = store.getters.getLanguage(curLang);

    if (curTranslator != null && curTranslator[key] != null) return curTranslator[key];

    if (curLang != null &&
        curLang.toString().length > 0 &&
        Object.keys(store.state.localize_settings.translations).length > 0) {
      store.commit('set_local', key);
    }

    return key;
  },
};
