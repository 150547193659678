<template>
  <div></div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';
import downloadBLOB from '../../assets/v1/js/project_management/downloadBLOB';

export default builder({
  ignoreHistory: true,
  components: {
  },
  data: () => ({
  }),
  mixins: [uniViewMixin],
  mounted() {
    if (this.draw_data.length > 0 && this.draw_data[0].src_name && this.draw_data[0].guid) {
      this.loadExtFile(this.draw_data[0]);
    }

    if (this.draw_data.length > 0 && !this.draw_data[0].src_name) {
      this.loadInternalFile(this.draw_data[0]);
    }

    this.$emit('close_view', this.draw_index);
  },
  computed: {
  },
  watch: {

  },
  methods: {
    loadInternalFile(data) {
      const formData = new FormData();
      formData.append('file_path', data.path);
      formData.append('page_id', this.$store.state.navigate_settings.current_page_info.id);

      const url = `${ROOT_URL}/api/v1/file_storage/download`;

      const options={
        method: 'POST',
        credentials: 'include',
        responseType: 'blob',
        body: formData,
      };
      this.$store.commit('set_full_screen_loading', true);

      fetch(url, options).then(
          async (response)=>{
            if (!response.ok) {
              return Promise.reject(new Error(response.statusText));
            }

            const contentType= response.headers.get('content-type');

            const curText = await response.blob();
            const blob = new Blob([curText], {type: contentType});
            const url = window.URL.createObjectURL(blob);
            // return {'result': {url}, 'is_file': true};

            // console.log('response', response);

            downloadBLOB(url, `${data.name}.${data.extention}`);
            window.URL.revokeObjectURL(url);
          },
      ).catch((errData) => {
        // обработка ошибок
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: errData,
        }]);
      }).finally(()=>{
        this.$store.commit('set_full_screen_loading', false);
      });
    },
    loadExtFile(data) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${data.src_name}`,
        method: 'POST',
        responseType: 'blob',
        body: {params: {'GUID': data.guid}},
      };
      this.$store.commit('set_full_screen_loading', true);
      dataRequester(options)
          .then((response) => {
            downloadBLOB(response.result.url, `${data.name}.${data.extention}`);
            window.URL.revokeObjectURL(response.result);
          })
          .catch((errData)=> {
            console.log(errData);
          })
          .finally(()=>{
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
  emits: {
    next_select: null,
    close_view: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">

</style>
