<template>
  <div class="pagination pagination__container">
    <div class="pagination__container__pageButton"
         @click="this.previousPage()">

      <IconComponent class="pagination__container__arrowLeft"
                     type="svg"
                     size="16"
                     name="caret-circle-down"/>
    </div>

    <div v-for="(item, index) in this.getPaginationStruct" :key="index">
      <div v-if="item.type === 'pageButton'"
           class="pagination__container__pageButton"
           :class="this.modelValue === item.index  ? 'pagination__container__pageButton__active' : ''"
           @click="this.selectPage(item.index)">

        <LabelComponent label_type="body/large/regular 12"
                        :label_text="item.index"/>
      </div>

      <div v-else-if="item.type === 'dots'"
           class="pagination__container__dots">

        <LabelComponent label_type="body/large/regular 12"
                        label_text="..."/>
      </div>
    </div>

    <div class="pagination__container__pageButton"
         @click="this.nextPage()">

      <IconComponent class="pagination__container__arrowRight"
                     type="svg"
                     size="16"
                     name="caret-circle-down"/>
    </div>
  </div>
</template>

<script>
import LabelComponent from './Label.vue';
import IconComponent from './Icon.vue';

export default {
  components: {LabelComponent, IconComponent},
  props: {
    visible_length: {
      type: Number,
      default: 7,
    },
    limit_page: {
      type: Number,
      required: true,
    },
    modelValue: { // выбранная страница
      type: Number,
      default: null,
    },
  },
  computed: {
    getPaginationStruct() {
      const getPaginationStructPart = function(type, fromIndex, count) {
        const indexes = [...Array(count).keys()].map((item) => item + fromIndex);
        return indexes.map((item) => ({
          type: type,
          index: item,
        }));
      };

      if (this.limit_page <= this.visible_length) {
        return getPaginationStructPart('pageButton', 1, this.limit_page);
      }
      const baseStartCount = Math.ceil((this.visible_length-1)/2);
      const baseEndCount = Math.floor((this.visible_length-1)/2);
      let startCount = 0;
      let middleCount = 0;
      let endCount = 0;

      if (this.modelValue <= baseStartCount -1 || this.modelValue > this.limit_page - baseEndCount + 1) {
        startCount = baseStartCount;
        endCount = baseEndCount;
      } else {
        startCount = (this.modelValue >= this.visible_length-2) ? 1 : this.visible_length-2;
        endCount = (this.limit_page - this.modelValue + 1 >= this.visible_length-2) ? 1 : this.visible_length-2;
      }

      middleCount = (startCount === 1 && endCount === 1) ? 4 - this.visible_length % 2 : 0;


      const struct = [];

      struct.push(...getPaginationStructPart('arrowPrev', 1, 1));
      struct.push(...getPaginationStructPart('pageButton', 1, startCount));
      struct.push(...getPaginationStructPart('dots', -1, 1));

      if (middleCount !== 0) {
        struct.push(...getPaginationStructPart('pageButton', this.modelValue - 1, middleCount));
        struct.push(...getPaginationStructPart('dots', -1, 1));
      }

      struct.push(...getPaginationStructPart('pageButton', this.limit_page - endCount + 1, endCount));
      struct.push(...getPaginationStructPart('arrowNext', 1, 1));

      return struct;
    },
  },
  methods: {
    selectPage(index) {
      this.$emit('update:modelValue', index);
    },
    previousPage() {
      if (this.modelValue !== 1) {
        this.$emit('update:modelValue', this.modelValue - 1);
      }
    },
    nextPage() {
      if (this.modelValue !== this.limit_page) {
        this.$emit('update:modelValue', this.modelValue + 1);
      }
    },
  },
};
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.pagination {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;

    &__arrowLeft {
      transform: rotate(90deg);
    }

    &__arrowRight {
      transform: rotate(-90deg);
    }

    &__dots {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 34px;
      height: 34px;
      margin: 0 2px;
    }

    &__pageButton {
      .set_icon_color(@gray);

      display: flex;
      justify-content: center;
      align-items: center;

      color: @gray;
      cursor: pointer;

      background: @white;
      border: 1px solid @mint;
      border-radius: 6px;
      transition: 0.5s;
      width: 34px;
      height: 34px;
      margin: 0 2px;

      &:hover {
        border-color: @green;
      }

      &__active {
        border-color: @green;
        background: @green;
        color: @white;
      }
    }
  }
}
</style>
