<template>
  <UniInputFieldsPainter
        v-if="this.rawInputFields != null"
        :input_fields="this.rawInputFields"
        :download_additional_objects_src="this.draw_data[0].download_additional_objects_src"
        @onFormFilled="this.formFilled"
      />
  <LoadingFrame v-else />
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import uniViewMixin from '../../assets/v1/js/mixins/uniViewMixin';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';
import UniInputFieldsPainter from '@/uni_page_components/uni_input_fields_painter';
import LoadingFrame from '../../components/LoadingFrame';

export default builder({
  ignoreHistory: true,
  components: {
    UniInputFieldsPainter,
    LoadingFrame,
  },
  data: () => ({
    rawInputFields: null,
    // changedFields: {},
  }),
  mixins: [uniViewMixin],
  mounted() {
    if (this.draw_data.length > 0 && this.draw_data[0].data_source && this.draw_data[0].GUID) {
      this.loadForm(this.draw_data[0]);
    } else {
      this.$emit('close_view', this.draw_index);
    }
    /* if (this.draw_data.length > 0 && this.draw_data[0].src_name && this.draw_data[0].guid) {
      this.loadfile(this.draw_data[0]);
    } */
  },
  computed: {
  },
  watch: {

  },
  methods: {
    loadForm(data) {
      this.rawInputFields = null;
      // this.changedFields = {};
      const options={
        url: `${ROOT_URL}/api/v1/src/${data.data_source}`,
        method: 'POST',
        body: {params: {'GUID': data.GUID}},
      };
      dataRequester(options)
          .then((response) => {
            this.rawInputFields = response.result.input_fields[0];
          })
          .catch((errData)=> {
            console.log(errData);
          });
    },
    formFilled(filledForm) {
      /* let resultArray = this.changedFields;
      let sendForm = false;

      if (value.input_type == 'button') {
        if (value.require_inputs != null && Array.isArray(value.require_inputs) && value.require_inputs.length > 0) {
          const notFillInputNames = value.require_inputs.map((item) => {
            const address = item.split('__');
            let fieldName = null;
            if (address.length > 1) {
              address[1] = parseInt(address[1]);
              if (resultArray[address[0]] == null || resultArray[address[0]][address[1]] == null) {
                fieldName = '"' + this.rawInputFields[address[0]][address[1]].name + '"';
              }
            } else {
              if (resultArray[address[0]] == null) {
                fieldName = '"' + this.rawInputFields[address[0]].name + '"';
              }
            }

            return fieldName;
          }).filter((item) => item != null);

          if (notFillInputNames.length > 0) {
            window.globalEvents.callEvent('new_message', [{
              messageType: 'error',
              messageData: new Error('Заполните все требуемые поля!\n' + notFillInputNames.join('\n')),
            }]);

            return;
          }
        }

        sendForm = true;

        resultArray = {};
        Object.assign(resultArray, this.changedFields);
      }

      if (rowName != null) {
        if (resultArray[rowName] == null) {
          resultArray[rowName] = {'type': 'row', 'fields': {}};
        }
        resultArray[rowName].fields[field] = value;
      } else {
        resultArray[field] = value;
      }

      if (sendForm) { */
      this.sendFormToServer(filledForm);
      // }
    },
    sendFormToServer(formData) {
      const clearLines = false;

      if (clearLines) {
        Object.keys(formData).forEach((element) => {
          if (Array.isArray(formData[element])) {
            formData[element] = formData[element].filter((item) => item != null);
          }
        });
      }

      const sendSrcName = this.draw_data[0].send_form_source;

      this.$store.commit('set_full_screen_loading', true);

      const options={
        url: `${ROOT_URL}/api/v1/src/${sendSrcName}`,
        method: 'POST',
        body: {
          params: {
            'tasks': [this.draw_data[0].GUID],
            'input_fields': formData,
          },
        },
      };
      dataRequester(options)
          .then((response) => {
            console.log(response);
            window.location.reload();
          })
          .catch((errData)=> {
            console.log(errData);
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
  emits: {
    next_select: null,
    close_view: null,
  },
  props: {
    draw_index: {default: 0},
    draw_info: {default: {}},
    draw_data: {default: {}},
    before_total: {default: null},
  },
});
</script>

<style  lang="less">

</style>
