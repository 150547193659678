import {dataRequester} from '../assets/v1/js/project_management/DataRequester';
import AdminHelper from '../assets/v1/js/admin/adminHelper';

import LanguagesAdmin from '../assets/v1/js/admin/structs/localization/languages';
import TranslationsAdmin from '../assets/v1/js/admin/structs/localization/translations';
import UserAdmin from '../assets/v1/js/admin/structs/user';

const defaultUrl = 'http://51.158.175.189:8000/admin/api/v1/';
const adminHelper = new AdminHelper();

export const adminModule = {
  state: {
    adminFilterParameters: null,
    adminSortParameters: null,

    adminDefaultUrl: defaultUrl,
    adminCurrentModel: null,
    adminCurrentData: [],

    adminCurrentPage: 1,
    adminCurrentTableSize: 20,
    adminPageCount: 0,

    adminSelectedElement: null,

    adminModels: [
      new LanguagesAdmin(defaultUrl),
      new TranslationsAdmin(defaultUrl),

      new UserAdmin(defaultUrl),
    ],

    adminIsLoading: false,
  },
  getters: {
    getAdminIsLoading(state) {
      return state.adminIsLoading;
    },
    getAdminPageCount(state) {
      return state.adminPageCount;
    },
    getCurrentModel(state) {
      return state.adminCurrentModel || null;
    },
    getAdminCurrentModelName(state) {
      return state.adminCurrentModel && state.adminCurrentModel.ModelName ? state.adminCurrentModel.ModelName : '';
    },
    getAdminCurrentFilterStruct(state) {
      const filterStruct = state.adminCurrentModel && state.adminCurrentModel.FilterStruct ?
        state.adminCurrentModel.FilterStruct : [];
      if (filterStruct.some((item) => !item.system_name)) {
        console.warn(`Some element in FilterStruct ${getters.getAdminCurrentModelName} model hasn't system_name`);
      }
      return filterStruct;
    },
    getAdminCurrentTableStruct(state, getters) {
      if (!getters.getAdminCurrentModelName) return [];

      const tableStruct = state.adminCurrentModel && state.adminCurrentModel.TableStruct ?
          state.adminCurrentModel.TableStruct : [];

      if (tableStruct.some((item) => !item.system_name)) {
        console.warn(`Some element in TableStruct ${getters.getAdminCurrentModelName} model hasn't system_name`);
      }

      tableStruct.forEach((item, index) => {
        item.id = index;
        if (item.type == null) item.type = 'label';
        if (item.title == null) item.title = item.system_name || '';
      });

      return tableStruct;
    },
    getAdminCurrentFormStruct(state, getters) {
      if (!getters.getAdminCurrentModelName) return [];

      let formStruct = state.adminCurrentModel && state.adminCurrentModel.FormStruct ?
          state.adminCurrentModel.FormStruct : [];

      formStruct.unshift(...adminHelper.BeforeStruct);
      formStruct.push(...adminHelper.AfterStruct);

      formStruct = formStruct.filter((item) => {
        if (!item.getIsShowByType) return true;
        return item.getIsShowByType(adminHelper.Status);
      });

      if (formStruct.some((item) => !item.system_name)) {
        console.warn(`Some element in FormStruct ${getters.getAdminCurrentModelName} model hasn't system_name`);
      }

      return formStruct;
    },
    getAdminCurrentTableColumns(state, getters) {
      return getters.getAdminCurrentTableStruct.map((item) => ({
        id: item.id,
        title: item.title,
        ordering: item.ordering ? item.ordering : '__vue_devtool_undefined__',
        is_with_sum: item.is_with_sum ? item.is_with_sum : false,
        is_delete_if_empty: item.is_delete_if_empty ? item.is_delete_if_empty : false,
        is_empty: item.is_empty ? item.is_empty : false,
      }));
    },
    getAdminCurrentData(state, getters) {
      const data = state.adminCurrentData ? state.adminCurrentData : [];
      return data.map((item, index) => {
        const row = {
          id: index,
          background_color: undefined,
          color: undefined,
        };
        getters.getAdminCurrentTableStruct.forEach((column) => {
          row[column.id] = column.getValue(item);
        });

        return row;
      });
    },
    getAdminSelectedElementStruct(state, getters) {
      if (adminHelper.Status === 'create') return getters.getAdminCurrentFormStruct;
      if (!state.adminSelectedElement) return [];

      const setDefault = function(struct) {
        struct.forEach((item) => {
          if (['row', 'column'].includes(item.type)) {
            setDefault(item.items);
            return;
          }

          if (item.getValue) {
            item.value = item.getValue(state.adminSelectedElement);
            item.valueDefault = JSON.parse(JSON.stringify(item.getValue(state.adminSelectedElement)));
          }
        });
      };

      const formStruct = getters.getAdminCurrentFormStruct;

      setDefault(formStruct);
      return formStruct;
    },
  },

  mutations: {
    setPageCount(state, elementsCount) {
      let count = Math.ceil(elementsCount / state.adminCurrentTableSize);
      if (count === 0) count = 1;

      state.adminPageCount = count;
    },
    setAdminSelectedElement(state, selectedElementId) {
      if (selectedElementId == null) {
        state.adminSelectedElement = null;
        adminHelper.Status = null;
        return;
      } else if (selectedElementId < 0) {
        state.adminSelectedElement = null;
        adminHelper.Status = 'create';
        return;
      }

      adminHelper.Status = 'change';
      state.adminSelectedElement =
          state.adminCurrentData.filter((item) => item.id === selectedElementId)[0] || null;
    },
  },

  actions: {
    setAdminFilterParameters(context, options) {
      context.state.adminFilterParameters = options;
      context.dispatch('updateAdminCurrentData');
    },
    setAdminSortParameters(context, options) {
      if (!options.length) {
        context.state.adminSortParameters = null;
        return;
      }
      const row = context.getters.getAdminCurrentTableColumns[Math.abs(options['0'])];
      const sortParameters = {
        system_name: row.title,
        direction: getDirection(options['0']),
      };
      context.state.adminSortParameters = sortParameters;
      function getDirection(value) {
        let sign = Math.sign(value);
        if (sign === 0) sign = Math.sign(1 / value);
        const direction = (sign < 0) ? 'asc' : 'desc';
        return direction;
      };
      context.dispatch('updateAdminCurrentData');
    },
    setAdminCurrentModel(context, modelName) {
      console.log(context.state.adminModels);
      const currentModel = context.getters.getCurrentModel;
      if (currentModel != null && currentModel.ModelName === modelName) return;

      context.state.adminCurrentModel =
          context.state.adminModels.filter((item) => item.ModelName === modelName)[0] || null;

      context.dispatch('updateAdminCurrentData');
    },
    setAdminCurrentPage(context, currentPage) {
      if (context.state.adminCurrentPage === currentPage) return;

      context.state.adminCurrentPage = currentPage;
      context.dispatch('updateAdminCurrentData');
    },

    createAdminItem(context, form) {
      const currentModel = context.getters.getCurrentModel;
      if (!currentModel) {
        console.error('Не определена текущая модель');
        return;
      }

      delete form.id;

      const url = currentModel.Url;
      const options = {
        url: url,
        method: 'POST',
        body: form,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      dataRequester(options).then(() => context.dispatch('updateAdminCurrentData'));
    },

    updateAdminCurrentModel(context) {
      const pageId = context.rootState.navigate_settings.current_page_info.id;
      const url = `${context.rootState.urls.root}/api/v1/__get_page_additional_data?id=${pageId}`;
      const options = {
        url: url,
        method: 'GET',
      };

      context.state.adminCurrentPage = 1;

      dataRequester(options).then((data) => {
        context.dispatch('setAdminCurrentModel', data.model);
      });
    },
    updateAdminCurrentData(context) {
      const baseUrl = context.getters.getCurrentModel.Url;
      let url = baseUrl;
      url += url.includes('?') ? '&' : '?';
      url += `page=${context.state.adminCurrentPage}&size=${context.state.adminCurrentTableSize}`;
      url += getFiltersQuery(context.state.adminFilterParameters);
      url += getSortQuery(context.state.adminSortParameters);

      const options = {
        url: url,
        method: 'GET',
      };

      context.state.adminIsLoading = true;
      dataRequester(options)
          .then((data) => {
            if (baseUrl !== context.getters.getCurrentModel.Url) return;

            context.state.adminCurrentData = data.items;
            context.commit('setPageCount', data.total);

            context.state.adminIsLoading = false;
          });
      function getFiltersQuery(value) {
        if (!value) return '';
        let query= '';
        value.forEach((item) => {
          if (!item.value.length) return;
          query += '&' + item.system_name + '=' + item.value.join(',');
        });
        console.log(query);
        return query;
      }
      function getSortQuery(value) {
        if (!value) return '';
        let query = '';
        query += '&order_by' + '=' + value.system_name;
        query += '&order_direction' + '=' + value.direction;
        return query;
      }
    },
    updateAdminItem(context, form) {
      const currentModel = context.getters.getCurrentModel;
      if (!currentModel) {
        console.error('Не определена текущая модель');
        return;
      }

      const itemId = form.id;
      delete form.id;

      const url = currentModel.Url + '/' + itemId;
      const options = {
        url: url,
        method: 'PATCH',
        body: form,
        headers: {
          'Content-Type': 'application/json',
        },
      };

      dataRequester(options).then(() => context.dispatch('updateAdminCurrentData'));
    },


    deleteAdminItem(context, itemId) {
      if (!context.getters.getCurrentModel) return;

      const url = context.getters.getCurrentModel.Url + '/' + itemId;
      const options = {
        url: url,
        method: 'DELETE',
      };

      dataRequester(options)
          .then(() => {
            context.dispatch('updateAdminCurrentData');
          });
    },
  },
  modules: {
  },
};
