import {ROOT_URL} from './dataSource.js';
import JsEncrypt from 'jsencrypt';

export function authRequest({login, password, pinCode, pinSession}) {
  const ans = fetch(ROOT_URL+'/auth/get_crypt_session/', {
    method: 'GET',
  }).then(async function(result) {
    const jsonData = await result.json();

    const cryptSession = jsonData.session;
    const cryptPublicKey = jsonData.public_key;

    const cryptData = {};

    if (login != null && password != null) {
      cryptData.login = login;
      cryptData.password = password;
    } else {
      cryptData.pin_code = pinCode;
      cryptData.pin_session = pinSession;
    }

    const en = new JsEncrypt();
    en.setPublicKey(cryptPublicKey);
    const encrypted = en.encrypt(JSON.stringify(cryptData));

    return fetch(
        ROOT_URL+'/auth/crypt_login/',
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'ENCRYPTED-DATA': encrypted,
            'CRYPT-SESSION': cryptSession,
          },
        },
    );
  });

  return ans;
}
