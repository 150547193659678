import {ROOT_URL, store} from '@/store';
import {dataRequester} from '@/assets/v1/js/project_management/DataRequester';

const REQUEST_LINK = '/api/v1/__light_page_data';

function lightPageInfo(handler) {
  store.commit('set_full_screen_loading', true);

  // const addID= '&report_id='+Math.max(this.currentReportId, 0);

  const addID = '';

  dataRequester({
    url: ROOT_URL + REQUEST_LINK + `?id=${store.state.navigate_settings.current_page_info.id}${addID}`,
    method: 'GET',
  }).then((pageData) => {
    handler(pageData);
  }).catch((errData) => {

  }).finally(() => store.commit('set_full_screen_loading', false));
}

export default lightPageInfo;
