<template>
  <component :is="this.getWrapperComponent"
             :class="this.getWrapperClasses"
             :label="this.namedFieldTitle">
    <div class="form-painter"
         :class="[`form-painter__${this.direction}`]">
      <div v-for="(item, index) in this.data_value" :key="index">
        <template v-if="['row', 'column'].includes(item.type)">

          <FormPainterComponent :direction="item.type"
                                v-bind="item.typeSettings"
                                @buttonClick="(buttonField) => this.buttonClick(buttonField)"
                                v-model="item.items"/>

        </template>
        <component v-else
                   :is="this.getFieldComponent(item)"
                   v-model="item.value"
                   :set_values="item.value"
                   @buttonClick="(buttonField) => this.buttonClick(buttonField)"
                   :fieldInfo="item"/>
        <FormSeparator v-if="this.isHaveAfterSeparator(item, index) && this.direction !== 'row'"
                       class="form-painter_separator"/>
      </div>
    </div>
  </component>
</template>

<script>
import builder from '../../assets/v1/js/builder';

import NamedField from '../../components/unit/NamedField';
import FormSeparator from './fields/FormSeparator';
import FormLabel from './fields/FormLabel';
import FormInput from './fields/FormInput';
import FormButton from './fields/FormButton';
import FormDropdown from './fields/FormDropdown';
import FormSwitcher from './fields/FormSwitcher';

const fieldsComponents = {
  'label': FormLabel,
  'input': FormInput,
  'dropdown': FormDropdown,
  'button': FormButton,
  'boolean': FormSwitcher,
};

const separatorBetweenTypes = [
  ['label', 'label'],
  ['label', 'input'],
];

export default builder({
  name: 'FormPainterComponent',

  emits: [
    'update:modelValue',
    'buttonClick',
  ],

  components: {
    FormSeparator,
  },

  props: {
    direction: {
      required: true,
      type: String,
      validator: (prop) => ['row', 'column'].includes(prop),
    },
    modelValue: {
      required: true,
      type: Array,
    },
    namedFieldTitle: {
      type: String,
      default: '',
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    modelValue(newData) {
      if (newData) this.data_value = newData;
    },
    data_value: {
      deep: true,
      handler(newData) {
        this.$emit('update:modelValue', newData);
      },
    },
  },

  data: () => ({
    data_value: {},
  }),

  computed: {
    getWrapperComponent() {
      return this.namedFieldTitle ? NamedField : 'div';
    },
    getWrapperClasses() {
      return [this.isGroup ? 'form-painter-wrapper__group' : ''];
    },
  },

  methods: {
    getFieldComponent(item) {
      return fieldsComponents[item.type];
    },
    buttonClick(buttonField) {
      this.$emit('buttonClick', buttonField);
    },
    isHaveAfterSeparator(item, index) {
      const itemNext = this.data_value[index + 1];
      if (itemNext) {
        if (separatorBetweenTypes.some((rule) => rule[0] === item.type && rule[1] === itemNext.type)) {
          return true;
        }
      }
      return false;
    },
    setDefault() {
      this.data_value = this.modelValue.map((item, index) => {
        const newItem = {...item};
        if (newItem.id == null) newItem.id = index;
        if (newItem.type == null) newItem.type = 'input';
        if (newItem.title == null) newItem.title = newItem.system_name || '';
        return newItem;
      });
    },
  },
  beforeMount() {
    this.setDefault();
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.form-painter {
  display: grid;
  grid-gap: 10px;
  width: 100%;

  &__row {
    grid-template-columns: repeat(auto-fit, minmax(calc(((@transition-threshold-0 * 0.98) - 70px - 10px) / 2), 1fr));
  }

  &__column {
    grid-template-columns: 100%;
  }

  &_separator {
    margin-top: 10px;
  }
}

.form-painter-wrapper__group {
  margin: 10px 0;
}

.admin-page {
  &__modal-form {
    display: grid;
    row-gap: 10px;

    &__input {
      max-width: 500px;
    }
  }
}

.paginatedTable__container {
  &__boolean {
    &__true {
      .set_icon_color(@green);
    }

    &__false {
      .set_icon_color(@error);
    }

    &__null {
      .set_icon_color(@light-gray);
    }
  }
}
</style>
