<template>
  <ButtonComponent :label="this.loc(this.input_info.name)"
                   :elementStatusModifier="this.input_info.button_state == 'negative' ? 'danger' : undefined"
                    @click_to_button="this.inputChanged">
  </ButtonComponent>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import ButtonComponent from '@/components/unit/Button';

export default builder({
  components: {
    ButtonComponent,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {
  },
  watch: {

  },
  methods: {
    inputChanged() {
      const ans = {};
      Object.assign(ans, this.input_info);

      ans.value = true;

      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
