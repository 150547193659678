<template>
  <NamedField  class="input-row_margin-bottom"
              :label="this.getTitle">
    <LabelComponent label_type='body/large/regular 14'
                    :label_text="this.getContent"
                    :is_html="this.getIsHtml"/>
  </NamedField>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import LabelComponent from '@/components/unit/Label';
import NamedField from '../../components/unit/NamedField';

export default builder({
  components: {
    LabelComponent,
    NamedField,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {
    getIsHtml() {
      const checkString = this.getContent.toString();
      return checkString.startsWith('<html') || checkString.startsWith('<!DOCTYPE html>');
    },
    getTitle() {
      return this.loc(this.input_info.name);
    },
    getContent() {
      const content = this.input_info.value;
      if (this.input_info.is_datetime) {
        return new Date(Date.parse(content)).toLocaleDateString();
      }
      const returnContent = content != null && content !== '' ? content : '-';
      return this.loc(returnContent);
    },
  },
  watch: {

  },
  methods: {

  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
