<template>
  <div class="bar-layout" ref="barLayout" :class="get_bar_classes"
       :style="{margin: `${margin}`, padding: `${padding}`}" >
    <slot/>
<!--    <transition-group name="bar-layout__addon">-->
        <div v-for="(item_of_list, index) in components_list"
             :key="index"
             class="bar-layout__add-item">
            <slot name="component_item" :item_of_list="item_of_list"/>
        </div>
<!--    </transition-group>-->
  </div>

</template>

<script>
import builder from '../../assets/v1/js/builder';

export default builder({
  name: 'BarLayout',
  props: {
    components_list: {
      required: false,
      type: Object,
    },
    direct: {
      type: String,
      default: 'horizontal',
    },
    alignX: {
      type: String,
      required: false,
    },
    alignY: {
      type: String,
      required: false,
    },
    gap: {
      type: String,
      required: false,
    },
    margin: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      required: false,
    },
  },
  components: {builder},

  computed: {
    alignXfunc() {
      if (this.alignX === 'left') return 'start';
      if (this.alignX === 'center') return 'center';
      if (this.alignX === 'right') return 'end';
    },

    alignYfunc() {
      if (this.alignY === 'up') return 'start';
      if (this.alignY === 'center') return 'center';
      if (this.alignY === 'down') return 'end';
    },

    justify() {
      return this.direct === 'horizontal' ?
      this.alignXfunc :
      this.alignYfunc;
    },
    align() {
      return this.direct === 'vertical' ?
      this.alignXfunc :
      this.alignYfunc;
    },
    get_bar_classes() {
      /*
       * justify - выравание по главной оси
       * align - выравнивание по другой оси
       * direct выбор главной оси
       */
      return [this.justify ?
      `bar-layout_justify_${this.justify}` :
      undefined, this.align ?
      `bar-layout_align_${this.align}` :
      undefined, this.direct ?
        `bar-layout_${this.direct}` :
        undefined];
    },
  },
  mounted() {
    this.$refs.barLayout.style.setProperty('--gap', `${this.gap}px`);
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.bar-layout{
  //.flex(row, center, center);
  display: flex;
  width: 100%;
  --gap: 0;

  &_vertical{
    flex-direction: column;

    & > * {
      margin-bottom: var(--gap);
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &_horizontal{
    & > * {
      margin-right: var(--gap);
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  &_justify{
    &_start{
      justify-content: flex-start;
    }
    &_center{
      justify-content: center;
    }
    &_end{
      justify-content: flex-end;
    }
  }
  &_align{
    &_start{
      align-items: flex-start;
    }
    &_center{
      justify-content: center;
    }
    &_end{
      align-items: flex-end;
    }
  }
  &__add-item{
      //transition: height 2s;
    &-enter-from{
      //transition: height 2s;
      //height: 0;
    }
    &-enter-to{
      //transition: height 2s;
      //height: 100%;
    }
    &-enter-active, &-leave-active {

    }
    &-leave-from{
      //height: 100%;
    }

    &-leave-to{
      //height: 0%;


    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .bar-layout{
    overflow: auto;
  }
}


</style>
