<template>
  <router-view/>
  <LoadingPage v-if="this.$store.state.full_screen_loading > 0"/>
  <MessageWindow/>
  <ModalView v-if="this.breakLogIn"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             caption = "Сессия авторизации неактивна"
             @click_close="this.fullReload">
      <LabelComponent label_text = "Ваша авторизация более недействительна! Войдите в аккаунт заново."/>
  </ModalView>
</template>

<script>
import ModalView from '@/components/ModalView';
import LabelComponent from '@/components/unit/Label';
import getUserInfo from '@/assets/v1/js/project_management/dataSources/GetUserInfo';
import MessageWindow from './views/system/MessageWindow';
import LoadingPage from './views/system/LoadingPage';
import checkAutoUpdate from '@/assets/v1/js/autoUpdateCache';

export default {
  components: {
    LoadingPage,
    MessageWindow,
    LabelComponent,
    ModalView,
  },
  data: ()=> ({
    breakLogIn: false,
  }),
  beforeCreate() {
    // eslint-disable-next-line no-extend-native
    Date.prototype.daysInMonth = function() {
      return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.shiftDateFromCurrent = function(deltaYears, deltaMonths, deltaDays) {
      this.setDate(this.getDate() + deltaDays);
      this.setMonth(this.getMonth() + deltaMonths);
      this.setFullYear(this.getFullYear() + deltaYears);
      return this;
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentYear=function() {
      // возвращает начало текущего года
      return new Date(this.getFullYear(), 0, 1);
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentQuarter=function() {
      // возвращает начало текущего квартала
      return new Date(this.getFullYear(), this.getMonth()-(this.getMonth() % 3), 1);
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.beginCurrentMonth=function() {
      // возвращает начало текущего месяца
      return new Date(this.getFullYear(), this.getMonth(), 1);
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.pastYear=function() {
      // возвращает начало текущего года
      return new Date(this.getFullYear()-1, 0, 1);
    };
    // eslint-disable-next-line no-extend-native
    Date.prototype.prePastYear=function() {
      // возвращает начало текущего года
      return new Date(this.getFullYear()-2, 0, 1);
    };
    // e
    // eslint-disable-next-line no-extend-native
    Number.prototype.truncated_n = function(precision) {
    // отсекает до точности в precision знаков
      if (precision == null) console.error(`${this} truncated_n to ${precision}`);
      return (precision == null) ? Math.trunc(this * (10 ** precision)) / (10 ** precision) : this;
    };

    // eslint-disable-next-line no-extend-native
    String.comparePaths=function(pathOne, pathTwo) {
      pathOne = String(pathOne);
      pathTwo = String(pathTwo);

      pathOne = pathOne[pathOne.length-1] == '/' ? pathOne : pathOne + '/';
      pathTwo = pathTwo[pathTwo.length-1] == '/' ? pathTwo : pathTwo + '/';

      return pathOne == pathTwo;
    };
  },
  methods: {
    fullReload() {
      this.$store.commit('set_full_screen_loading', true);
      window.location.reload();
    },
  },
  created() {
    window.__is_online__ = true;
    window.addEventListener('offline', () => {
      console.log('I am offline.');
      window.__is_online__ = false;
    });

    window.addEventListener('online', () => {
      console.log('I am back online.');
      window.__is_online__ = true;
    });

    window.escapeableElements = [];
    window.acceptableElements = [];
    document.addEventListener('keydown', (e) => {
      // Keycode for "Escape"
      if (e.keyCode === 27) {
        if (window.escapeableElements.length > 0) {
          window.escapeableElements[window.escapeableElements.length-1]();
        }
      }
      // Keycode for "Return"
      if (e.keyCode == 13) {
        if (window.acceptableElements.length > 0) {
          window.acceptableElements[0]();
        }
      }
    });

    window.onerror = function() {
      const args = Array.prototype.slice.call(arguments);
      window.globalEvents.callEvent('new_message', [{
        messageType: 'error',
        messageData: {
          name: 'Error',
          message: args.toString(),
        },
      }]);
    };

    checkAutoUpdate();

    // Ping methods
    setInterval(() => {
      if (this.$store.getters.isLoggedIn && !this.breakLogIn) {
        getUserInfo((data) => {
          const isLoggedIn = data != null && !data.result.is_anonymous;

          if (!isLoggedIn) {
            this.breakLogIn = true;
          }
        });
      }
    }, 10000);
  },
};
</script>

<style lang="less">
@import url('./assets/v1/less/base.less');
@import url('./assets/v1/less/components.less');

#app {
  --transition-threshold-0: @transition-threshold-0;
  --transition-threshold-1: @transition-threshold-1;
  --transition-threshold-2: @transition-threshold-2;
  --transition-threshold-3: @transition-threshold-3;

  height: 100%;
}

</style>
