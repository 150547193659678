export default () => {
  const EventsHandler = {};
  EventsHandler.events = {};

  EventsHandler.addListener = (name, func) => {
    if (EventsHandler.events[name] === undefined) {
      EventsHandler.events[name] = {};
      const curEvent = EventsHandler.events[name];
      curEvent.funcs = [];
      curEvent.$on = (newFunc) => {
        if (typeof newFunc != 'function') {
          console.warn('You try add non function! <actionsCaller>', typeof newFunc, newFunc);
          return -1;
        }
        EventsHandler.removeListener(name, newFunc);
        curEvent.funcs.push(newFunc);
        return curEvent.funcs.length-1;
      };

      curEvent.$off = (oldFunc) => {
        const idx = curEvent.funcs.indexOf(oldFunc);
        if (idx < 0) {
          return false;
        }
        curEvent.funcs.splice(idx, 1);
        return true;
      };
    }
    return EventsHandler.events[name].$on(func);
  };

  EventsHandler.removeListener = (name, func) => {
    if (EventsHandler.events[name] !== undefined) {
      return EventsHandler.events[name].$off(func);
    }
    return false;
  };

  EventsHandler.callEvent = (name, args) => {
    if (args==null) {
      args=[];
    }
    if (name !== 'try_event_call') {
      EventsHandler.callEvent('try_event_call', [name, args]);
    }

    const curEvent = EventsHandler.events[name];
    if (curEvent === undefined) {
      return [];
    }

    const ans = [];
    curEvent.funcs.forEach((element) => {
      ans.push(element(...args));
    });
    return ans;
  };
  return EventsHandler;
};
