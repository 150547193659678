<template>
  <div class="main-logo" :class="mainClasses" @click="this.clickLogo">
    <iconModule class="main-logo__icon" type='svg'
                name="main-logo" :sizeWidth="width" :sizeHeight="iconHeight" />
    <!-- <div class="main-logo__hr" :style="hrStyles"/>
    <div v-if="branch" class="main-logo__branch"  :style="branchStyles">
      <div v-for="(elem, idx) in this.branch"
           :key="idx" class="main-logo__char" :style="charStyles">{{ elem }}</div>
    </div> -->
  </div>
</template>

<script>
import iconModule from './Icon';

export default {
  name: 'Test_LoadingScreen',
  components: {
    iconModule,
  },
  props: {
    branch: {
      type: String,
    },
    width: {
      type: String,
      default: '500',
    },
    colorStyle: {
      type: String,
      default: 'black-green',
      validator: (prop) => ['black-green', 'black', 'white', 'white-green'].includes(prop),
    },
  },
  emits: {'click_to_logo': null},
  computed: {

    mainClasses() {
      return [`main-logo_${this.colorStyle}`];
    },

    iconHeight() {
      return `${this.width * 0.245}`;
    },


    hrStyles() {
      return {
        // толщина полоски
        'height': `${this.width * 0.01}px`,
        'margin-top': `${this.width * 0.01}px`,
      };
    },
    branchStyles() {
      return {
        'margin-top': `${this.width * 0.04}px`,
        'justify-content': this.branch.length < 4 ? 'flex-start' : 'space-between',
        'margin-left': this.branch.length < 4 ? '-5px' : '0',
        'margin-right': this.branch.length < 4 ? '-5px' : '0',
      };
    },
    charStyles() {
      return {
        // высота букв
        'font-size': `${this.width * 0.075}px`,
        'margin': this.branch.length < 4 ? '0 5px' : '0',
      };
    },
  },
  methods: {
    clickLogo() {
      this.$emit('click_to_logo');
    },
  },
};
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

//black-green
// TODO завести бы такую константу цвета
@logo__black-green__background-color: #59a445;
@logo__black-green__icon__color: @dark;
@logo__black-green__text__color: @dark;
//____________

//black
@logo__black__background-color: @dark;
@logo__black__text__color: @dark;
//____________

//white
@logo__white__background-color: @white;
@logo__white__text__color: @white;
//____________

//white-green
// TODO завести бы такую константу цвета
@logo__white-green__background-color: #59a445;
@logo__white-green-green__icon__color: @white;
@logo__white-green__text__color: @white;
//____________

.main-logo{
  cursor: pointer;
  &_black-green{
    .icon-style-color{
      background-color: @logo__black-green__icon__color;
    }
    .main-logo__hr{
      background-color: @logo__black-green__background-color;
    }
    .main-logo__char{
      color: @logo__black-green__text__color;
    }
  }
  &_black{
    .icon-style-color, .main-logo__hr {
      background-color: @logo__black__background-color;
    }
    .main-logo__char{
      color: @logo__black__text__color;
    }
  }
  &_white{
    .icon-style-color, .main-logo__hr{
      background-color: @logo__white__background-color;
    }
    .main-logo__char{
      color: @logo__white__text__color;
    }
  }
  &_white-green{
    .icon-style-color{
      background-color: @logo__white-green-green__icon__color;
    }
    .main-logo__hr{
      background-color:@logo__white-green__background-color;
    }
    .main-logo__char{
      color: @logo__white-green__text__color;
    }
  }
  &__branch{
    .flex(row, space-between, center);
    margin: 0 -5px;
  }

  &__hr{
    display: block;
  }
  &__char{
    font-weight: 900;
    text-transform: uppercase;
    line-height: 80%;

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}

</style>
