<template>
  <DateRange :label_text="this.loc(this.input_info.name)"
                   :init_range="this.getInitRange"
                   :base_select_element="this.getBaseSelected"
                   componentType="input_field"
                   @date_range_changed="this.inputChanged">
  </DateRange>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import DateRange from '../../components/unit/DateRange';

export default builder({
  components: {
    DateRange,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {
    getInitRange() {
      return {from: new Date(Date.parse(this.input_info.value.start_date)),
        to: new Date(Date.parse(this.input_info.value.end_date))};
    },
    getBaseSelected() {
      if (this.input_info.base_select_element != null) {
        return this.input_info.base_select_element;
      }

      return 1;
    },
  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      const ans = {};
      Object.assign(ans, this.input_info);

      ans.value = {
        start_date: data.from.toISOString(),
        end_date: data.to.toISOString(),
      };
      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
