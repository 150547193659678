<template>
  <DropDownInput :label_text="this.label_text"
                 :dropdown_items_init="this.dataItems"
                 :init_selected_item="this.initSelectedItems"
                 :is_extendable="this.is_extendable"
                 :is_filterable="this.is_filterable"
                 :is_multi_select="this.is_multi_select"
                 :is_favoriteble="this.is_favoriteble"
                 :is_required="this.is_required"
                 :addButton="this.addButton"
                 :error_text="this.error_text"
                 :input_field_status="this.input_field_status"
                 :style_type="this.style_type"
                 @observerTargetAdd="this.observerTargetAdd()"
                 @input_change="(data) => this.inputChange(data)"
                 @filterChange="(data) => this.filterQueryString = data"/>
</template>

<script>
import DropDownInput from './DropDownInput';
import {dataRequester} from '@/assets/v1/js/project_management/DataRequester';
import levenshtein from '../../assets/v1/js/packs/levenshtein';

/**
 * Дропдаун с пагинацией
 * Если указан this.setItems, то пагинирует элементы заданные в этом пропсе
 * Если указан this.url, то выполняет постраничные запросы по этой ссылке
 * */

export default {
  name: 'PaginatedDropDown',
  components: {
    DropDownInput,
  },
  emits: [
    'input_change',
    'update:modelValue',
  ],
  props: {
    url: {
      type: String,
    },
    setItems: {
      type: Array,
    },
    paginateItemsCount: { // Количество элементов на одной странице пагинации
      type: Number,
      default: 50,
    },
    initSelectedItems: { // Массив изначально выбранных объектов
      type: Array,
      default: () => [],
    },
    modelValue: { // Массив выбранных объектов заданных через v-model
      type: Array,
      required: false,
    },
    // Остальные пропсы передаются напрямую в DropDownInput, и совпадают с его пропсами
    label_text: {
      type: String,
    },
    is_extendable: {
      type: Boolean,
      default: false,
    },
    is_filterable: {
      type: Boolean,
      default: false,
    },
    is_multi_select: {
      type: Boolean,
      default: false,
    },
    is_favoriteble: {
      type: Boolean,
      default: false,
    },
    is_required: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
    error_text: {
      type: String,
      default: 'Неизвестная ошибка, обратитесь к администратору',
    },
    input_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    style_type: {
      type: String,
      validator: (prop) => ['default', 'light'].includes(prop),
      default: 'default',
    },
  },
  data: () => ({
    dataItems: [], // Выведенные элементы
    usedItemsCount: 0, // Количество выведенных элементов
    availableItems: 0, // Количество элементов на беке
    currentPage: 1, // Нынешняя страница запроса
    currentRequestUrl: '', // url последнего вызваного запроса
    filterQueryString: '', // Фильтр из дропдауна
    selectedItems: [], // Выбранные в DropDownInput элементы
    observer: null,
  }),
  watch: {
    // Если меняется фильтр то сбрасываем элементы и добляем новые
    filterQueryString() {
      this.setDefault();
      this.addItems();
    },
  },
  methods: {
    // Простановка дефолтных значений
    setDefault() {
      this.dataItems = [];
      this.usedItemsCount = 0;
      this.availableItems = 0;
      this.currentPage = 1;
    },
    // Добавление новых объектов в массив dataItems
    addItems() {
      if (this.setItems) {
        let items = this.setItems;

        // Сортировка заданных значений
        if (this.filterQueryString) {
          items = this.itemsSort(items, this.filterQueryString);
        }

        // Вырезка и добавление элементов из массива заданных объектов
        const startIndex = this.usedItemsCount;
        const endIndex = this.usedItemsCount + this.paginateItemsCount;
        const addingItems = items.slice(startIndex, endIndex);
        this.dataItems = this.dataItems.concat(addingItems);

        // Обновляем цель обзервера
        this.usedItemsCount += this.paginateItemsCount;
        if (this.usedItemsCount < items.length) this.observerTargetAdd();
      } else if (this.url) {
        if (!this.availableItems || this.usedItemsCount < this.availableItems) {
          // Подготовка url
          const baseUrl = 'http://51.158.175.189:8000/admin/api/v1';
          let url = `${baseUrl}${this.url}`;
          url.includes('?') ? url += `&` : url += `?`;
          const pageFilter = `size=${this.paginateItemsCount}&page=${this.currentPage}`;
          url += pageFilter;
          url += `&filter_by=${this.filterQueryString}`;

          const requestOptions = {
            url: url,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          };

          // Установка url этого запроса, как url последнего сделанного
          // Нужно для того, что бы при быстром вызове нескольких запросов,
          // учитывались данные только последнего сделанного
          this.currentRequestUrl = url;

          dataRequester(requestOptions)
              .then((data) => {
                // Проверка является ли запрос последним сделанным
                if (this.currentRequestUrl === url) {
                  // Обновление данных по результатам запроса
                  this.availableItems = data.total;
                  this.usedItemsCount += this.paginateItemsCount;
                  this.currentPage++;
                  const formattedData = data.items.map((item) => ({value_show: item, return_value: item}));
                  this.dataItems = this.dataItems.concat(formattedData);
                  if (this.usedItemsCount < this.availableItems) this.observerTargetAdd();
                }
              });
        }
      }
    },
    // Колбек-функция вызываемая обзервером
    onElementObserved(entries) {
      entries.forEach(({target, isIntersecting}) => {
        if (!isIntersecting) return;
        this.observer.unobserve(target);
        this.addItems();
      });
    },
    // Добавление новой цели для обзервера
    observerTargetAdd() {
      // Таймаут для того, что бы элементы успели отрисоваться
      setTimeout(() => {
        const length = document.getElementsByClassName('dropdown-row').length;
        if (length) {
          const target = document.getElementsByClassName('dropdown-row')[length - 1];
          this.observer.observe(target);
        }
      }, 200);
    },
    // Сортировка и фильтрация массива items по filterQueryString
    itemsSort(items, filterQueryString) {
      const inputData = filterQueryString.toLowerCase().replace(/е/g, 'ё');

      // В объекты массива items добавляем поле dist по алгоритму Левенштейна
      items.forEach((item) => {
        const lowerText = item.value_show.toLowerCase().replace(/е/g, 'ё');
        item.dist = levenshtein.calc_dist(inputData, lowerText) * (lowerText.includes(inputData) ? -1 : 1);
      });

      // Фильтруем список по dist
      items = items.filter((a) => a.dist <= 0);

      // Сортируем список по dist
      items = items.sort((a, b) => {
        if (a.dist < 0 && b.dist < 0) return b.dist - a.dist;
        return a.dist - b.dist;
      });
      return items;
    },
    // Уведомление родительского компонент о изменении выбора в DropDownInput
    // и установка выбранных объектов в этом компоненте
    inputChange(data) {
      this.$emit('input_change', data);
      this.$emit('update:modelValue', data);
      this.selectedItems = data;
    },
  },
  created() {
    // Инициализация обзервера
    this.observer = new IntersectionObserver(this.onElementObserved, {threshold: 0});
  },
  beforeMount() {
    // Добавление первых элементов при маунте
    this.addItems();
  },
  beforeUnmount() {
    // Удаление обзервера
    this.observer.disconnect();
  },
};
</script>

<style>

</style>
