<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <div class="form-painter" :class="[`form-painter__${'column'}`]">
      <LabelComponent style="color: red;" v-if="this.global_error_text != null" :label_text="this.global_error_text" />
      <div class="meta-actions-container">
        <div v-for="(action, index) in this.getRawMetaActions" :key="index">
            <ButtonComponent :elementStatusModifier="action.system_name != 'delete' ? undefined : 'danger'"
                             :label="action.view"
                             @click="this.completeForm(action)"
            />
        </div>
      </div>
      <!-- <div v-for="(item, index) in this.getRawFields" :key="index">
        <component :is="this.getFieldComponent(item)"
                   :rawField="item"
                   @input_change="(newData)=> this.inputChanged(item, newData)"
                   />
      </div> -->
      <template v-for="field, fieldName in this.form_data" :key="fieldName">
        <!-- <LabelComponent v-if="field.itype == 'link'" class="register-frame__login-field"
                        :label_text="field.label"
                        /> -->
        <ForeignObject v-if="field.itype == 'link'"
                   :is_extendable="false"
                   :is_filterable="true"
                   :is_multi_select="false"
                   :label_text="field.label"
                   :object_type_view="field.link_object_view"
                   :object_type="field.link_object"
                   :selected_items="field.val"
                   :input_field_status="this.getFieldStatus(fieldName)"
                   :is_favoritable="true"
                   @input_change="(data) => this.setFormValue(fieldName, data)"
        />
        <CheckBox v-else-if="field.itype == 'boolean'"
            :is_toggle="true"
            :label_text_left="field.label"
            :init_value="field.val"
            :check_box_field_status="this.getFieldStatus(fieldName)"
            :error_text="field.error"
            @input_check="(data) => this.setFormValue(fieldName, data)"
            />
        <InlineFormField v-else-if="field.itype == 'table'"
                         :set_values="field.val"
                         :formInitStruct="field"
                         @input_change="(data) => this.setFormValue(fieldName, data)"
                         />
        <DateRange v-else-if="field.itype == 'dateperiod'"
                   :label_text="field.label"
                   :init_range="field.val"
                   :base_select_element="1"
                   componentType="input_field"
                   @date_range_changed="(data) => this.setFormValue(fieldName, data)"
        />
        <InputField v-else class="register-frame__login-field"
                    :type="field.itype"
                    :label_text="field.label"
                    :input_field_status="this.getFieldStatus(fieldName)"
                    :error_text="field.error"
                    :set_values="field.val"
                    autocomplete="off"
                    @input_change="(data) => this.setFormValue(fieldName, data)"
                    :check_pattern="field.input_pattern ? field.input_pattern : '.+'"
                    :pattern_invalid_message="field.input_pattern_message"
                    />
      </template>
      <div class="actions-container" :class="{'center-content': this.actionsAlign == 'center'}">
        <div v-for="(action, index) in this.getRawActions" :key="index">
            <ButtonComponent :elementStatusModifier="action.is_loading ? 'disabled' : (action.system_name != 'delete' ? undefined : 'danger')"
                             :elementInLoading="action.is_loading"
                             :label="action.view"
                             @click="action.is_loading ? '' : this.completeForm(action)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import downloadBLOB from '@/assets/downloadBLOB';

import InputField from '@/components/unit/InputField';
import DateRange from '@/components/unit/DateRange';
// import DropDownInput from '@/units/forms/DropDownInput';
// import CheckBox from '@/units/forms/CheckBox';
// import AuthBase from './AuthBase';
import InlineFormField from '@/components/unit/InlineFormField';

import ButtonComponent from '@/components/unit/Button';

import LabelComponent from '@/components/unit/Label';

import ForeignObject from '@/components/unit/ForeignObject';

import CheckBox from '@/components/unit/CheckBox';

// const fieldComponents = {
//   'boolean': AdminFormBooleanField,
//   'text': AdminFormTextField,
//   'number': AdminFormNumberField,
//   'date': AdminFormDateField,
//   'link': AdminFormLinkField,
//   'table': AdminFormMtoMField,
//   'qr': AdminQRCode,
// };

// const separatorBetweenTypes = [
//   ['label', 'label'],
//   ['label', 'input'],
// ];

export default ({
  emits: [
    'completeFormAction',
  ],
  components: {
    ButtonComponent,
    InputField,
    // DropDownInput,
    ForeignObject,
    InlineFormField,
    LabelComponent,
    CheckBox,
    DateRange,
  },
  props: {
    raw_fields: {
      required: true,
      type: Array,
    },
    raw_actions: {
      required: true,
      type: Array,
    },
    actionsAlign: {
      required: false,
      default: 'left',
      type: String,
    },
  },
  data: () => ({
    global_error_text: null,
    form_data: {
      // surname: {label: 'Last Name', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+'},
      // name: {label: 'First Name', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+'},
      // citizenship: {label: 'Citizenship', error: null, val: null},
      // country_of_residence: {label: 'Country of Residence', error: null, val: null, itype: 'text'},
      // email: {label: 'Email', error: null, val: null, itype: 'email'},
      // password1: {label: 'Password', error: null, val: null, itype: 'password'},
      // password2: {label: 'Password confirmation', error: null, val: null, itype: 'password'},
      // privacy_policy_agreed: {label: 'I agree with the privacy policy', error: null, val: false},
      // __action__: null
    },
  }),
  mounted() {
    this.initFromRaw();
  },
  watch: {
    raw_fields() {
      this.initFromRaw();
    },
  },
  computed: {
    // getRawFields() {
    //   return this.raw_fields.filter((field) => field.system_name != '__uid__');
    // },
    getRawActions() {
      return this.raw_actions.filter((item) => !item.is_meta_action);
    },
    getRawMetaActions() {
      return this.raw_actions.filter((item) => item.is_meta_action);
    },
  },

  methods: {
    initFromRaw() {
      // console.log(this.raw_fields, this.form_data);

      if (this.raw_fields == null) {
        return;
      }

      this.form_data = {};

      this.raw_fields.forEach((field) => {
        // child_set: {
        //   label: 'Children',
        //   error: null,
        //   val: null,
        //   itype: 'inline',
        //   content_form: {
        //     children_age: {label: 'Child age', error: null, val: null, itype: 'number'},
        //     children_gender: {label: 'Child gender', error: null, val: null, itype: 'dropdown', ddVals: []},
        //     children_live_together: {label: 'Do your children live with you?', error: null, val: false, itype: 'boolean'},
        //   },
        // },

        this.form_data[field.system_name] = {
          label: field.view,
          error: null,
          val: field.value,
          itype: field.type,
          required: field.nullable !== true,
          readonly: field.readonly === true,
          link_object: field.linked_object_type,
          link_object_view: field.view,
          input_pattern: field.pattern,
          input_pattern_message: field.pattern_message ? field.pattern_message : '',
        };

        if (field.type == 'link') {
          this.form_data[field.system_name].val = field.value == null ? [] : [field.value];
        }

        if (field.type == 'dateperiod') {
          this.form_data[field.system_name].val = field.value == null ? null : {
            'from': new Date(Date.parse(field.value.start_date)),
            'to': new Date(Date.parse(field.value.end_date)),
          };
        }

        if (field.type == 'table') {
          const tableForm = {};

          field.linked_object_form.fields.forEach((subField) => {
            tableForm[subField.system_name] = {
              label: subField.view,
              error: null,
              val: null,
              itype: subField.type,
              required: subField.nullable !== true,
              readonly: subField.readonly === true,
              link_object: subField.linked_object_type,
              link_object_view: subField.view,
              input_pattern: subField.pattern,
              input_pattern_message: subField.pattern_message ? subField.pattern_message : '',
            };
          });

          this.form_data[field.system_name].val = field.value.map((rawVal) => Object.fromEntries(Object.keys(rawVal.values).map((fieldKey) => [
            fieldKey,
            {
              label: tableForm[fieldKey].label,
              error: null,
              val: tableForm[fieldKey].itype == 'link' ? (rawVal.values[fieldKey] == null ? [] : [rawVal.values[fieldKey]]) : rawVal.values[fieldKey],
              itype: tableForm[fieldKey].itype,
              required: tableForm[fieldKey].required,
              readonly: tableForm[fieldKey].readonly,
              link_object: tableForm[fieldKey].link_object,
              link_object_view: tableForm[fieldKey].link_object_view,
              input_pattern: tableForm[fieldKey].input_pattern,
              input_pattern_message: tableForm[fieldKey].input_pattern_message,
            },
          ])));

          this.form_data[field.system_name]['content_form'] = tableForm;
        }
      });

      // console.log('this.form_data', this.form_data);

      // this.form_data = {};
    },
    // getFieldComponent(item) {
    //   const ans = fieldComponents[item.type];

    //   if (ans == null) {
    //     console.warn('AdminForm Не удалось отрисовать поле ввода для типа:', item.type, ' Поле: ', item);
    //   }

    //   return ans;
    // },
    // inputChanged(rawField, newData) {
    //   const customMethods = this.getFieldComponent(rawField)['staticMethods'];

    //   let packedData = newData;

    //   if (customMethods != null && customMethods['getPackedInputData']) {
    //     packedData = customMethods['getPackedInputData'](newData, rawField);
    //   }

    //   this.changedDataValue[rawField.system_name] = packedData;
    // },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;
    },
    getFieldStatus(field) {
      return this.form_data[field].error != null ? 'error' : undefined;
    },
    completeForm(rawAction) {
      // this.form_data.__action__ = rawAction;

      let isError = false;
      const fillCorrectText = 'Проверьте правильность заполнения данных!';

      Object.keys(this.form_data).forEach((key) => {
        if (this.form_data[key].required == true && (!this.form_data[key].val || this.form_data[key].val.length < 1)) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        }
      });

      // if (!this.form_data.full_registration.val) {
      //   this.form_data.full_registration.error = 'Примите политику!';
      //   isError = true;
      // }

      // if (this.form_data.password1.val != this.form_data.password2.val) {
      //   this.form_data.password1.error = 'Введенные пароли не совпадают!';
      //   this.form_data.password2.error = this.form_data.password1.error;
      //   isError = true;
      // }

      if (isError) {
        this.global_error_text = fillCorrectText;

        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: {
            name: 'Error',
            message: fillCorrectText,
          },
        }]);
        return;
      }

      // console.log('confirmed', this.form_data, rawAction);

      function fillPostForm(fillForm, translateVal, valType, basedKey) {
        if (translateVal == null) {
          return;
        }

        if (valType == 'table') {
          fillForm.append(basedKey + '-TOTAL_FORMS', translateVal.length);
          // fillForm.append(basedKey + '-INITIAL_FORMS', 0);
          translateVal.forEach((elem, idx) => {
            Object.keys(elem).forEach((inlineKey) => {
              fillPostForm(
                  fillForm,
                  elem[inlineKey].val,
                  elem[inlineKey].itype,
                  basedKey + '-' + idx + '-' + inlineKey,
              );
            });
          });
          return;
        }

        if (valType == 'dateperiod') {
          let ans = '';

          if (translateVal.from instanceof Date) {
            ans += translateVal.from.toISOString();
          } else {
            ans += translateVal.from;
          }

          ans += '__';

          if (translateVal.to instanceof Date) {
            ans += translateVal.to.toISOString();
          } else {
            ans += translateVal.to;
          }

          fillForm.append(basedKey, ans);
          return;
        }

        if (Array.isArray(translateVal)) {
          translateVal.forEach((elem) => (elem.uid !== undefined ? fillForm.append(basedKey, elem.uid) : fillForm.append(basedKey, elem)));
          return;
        }

        if (translateVal instanceof Date) {
          fillForm.append(basedKey, translateVal.toISOString());
          return;
        }

        if (translateVal === true) {
          fillForm.append(basedKey, 1);
          return;
        }

        if (translateVal === false) {
          fillForm.append(basedKey, 0);
          return;
        }

        if (translateVal.uid !== undefined) fillForm.append(basedKey, translateVal.uid);
        else fillForm.append(basedKey, translateVal);
      }

      const postForm = new FormData();
      Object.keys(this.form_data).forEach((key) => {
        fillPostForm(postForm, this.form_data[key].val, this.form_data[key].itype, key);
      });

      postForm.append('__action__', rawAction.system_name);

      this.$emit('completeFormAction', postForm);
    },
  },
});
</script>

<style lang="less">
@import url('../../../../assets/v1/less/base.less');

.center-content {
  justify-content: center;
}

.form-painter {
  display: grid;
  grid-gap: 10px;
  width: 100%;

  &__row {
    grid-template-columns: repeat(auto-fit, minmax(calc(((@transition-threshold-0 * 0.98) - 70px - 10px) / 2), 1fr));
  }

  &__column {
    grid-template-columns: 100%;
  }

  &_separator {
    margin-top: 10px;
  }

  .actions-container {
    display: flex;
    gap: 10px;
  }

  .meta-actions-container {
    display: flex;
    gap: 10px;
  }
}

.form-painter-wrapper__group {
  margin: 10px 0;
}

.admin-page {
  &__modal-form {
    display: grid;
    row-gap: 10px;

    &__input {
      max-width: 500px;
    }
  }
}

.paginatedTable__container {
  &__boolean {
    &__true {
      .set_icon_color(@green);
    }

    &__false {
      .set_icon_color(@error);
    }

    &__null {
      .set_icon_color(@light-gray);
    }
  }
}
</style>
