<template>
  <div class="qr-container" :id="this.qrId" @click="this.clickQR"></div>
</template>

<script type="text/javascript">

// import downloadBLOB from '@/assets/downloadBLOB';
import downloadBLOB from '@/assets/v1/js/project_management/downloadBLOB';

export default ({
  components: {
  },
  emits: [

  ],
  data: () => ({

  }),
  mounted() {
    QrCreator.render({
      text: this.qrValue,
      radius: 0.5, // 0.0 to 0.5
      ecLevel: 'H', // L, M, Q, H
      fill: '#000000', // foreground color
      background: '#ffffff', // color or null for transparent
      size: 550, // in pixels
      quiet: 25,
    }, document.querySelector('#' + this.qrId));

    const imageSize = 90;
    const imageBorderSize = 15;

    const cv = document.querySelector('#' + this.qrId + ' canvas');
    const context = cv.getContext('2d');

    context.beginPath();
    context.fillStyle = 'white';
    context.arc(cv.width/2, cv.height/2, imageSize / 2, 0, Math.PI*2);
    context.fill();

    const img = new Image();
    img.onload = function() {
      context.drawImage(
          img,
          cv.width/2-imageSize/2 + imageBorderSize,
          cv.height/2-imageSize/2 + imageBorderSize,
          imageSize - imageBorderSize*2,
          imageSize - imageBorderSize*2,
      );
    };
    img.src = '/favicon.png';
  },
  methods: {
    clickQR() {
      const allImages = Array.from(document.querySelectorAll('#' + this.qrId + ' img')).filter((img) => String(img.getAttribute('src')).startsWith('data:'));
      const allCanvases = Array.from(document.querySelectorAll('#' + this.qrId + ' canvas')).filter((img) => String(img.toDataURL()).startsWith('data:'));

      let downloadImage = '';

      if (allImages.length < 1) {
        downloadImage = allCanvases[0].toDataURL();
      } else {
        downloadImage = allImages[0].getAttribute('src');
      }

      if (window.WORK_IN_APPLICATION) {
        window.applicationCallback.savedFileCallback = (savedUri) => {
          if (savedUri != null && window.IntIsFileSelectMode === true) {
            window.sendMessageInApplication({
              type: 'select_media',
              selectedURIs: JSON.stringify([savedUri]),
            });
          }
        };
      }

      downloadBLOB(
          downloadImage,
          'pay_qr',
          window.IntIsFileSelectMode === true ? 'window.applicationCallback.savedFileCallback' : undefined,
      );
      return;
    },
  },
  computed: {

  },
  watch: {

  },
  props: {
    qrId: {
      required: true,
      type: String,
    },
    qrValue: {
      required: true,
      type: String,
    },
  },
});
</script>

<style lang="less">
  .qr-container {
    canvas {
      width: 75vw;
      max-width: 480px;
    }
  }
</style>
