<template>
  <BasePage :name="`${loc('Ошибка')} 404. ${loc('Данная страница не найдена')}`">
    <div  class="page-not-found">
      <div class="page-not-found__caption">
        <div v-if="logoff" class="page-not-found__message">
          Вы не авторизованы
        </div>
        <div class="page-not-found__message">
          Страница {{ get_domain }}{{ path }} не найдена
        </div>
      </div>
      <div class="page-not-found__buttons">
        <ButtonComponent v-if="get_first_menus_item"
        :label="this.loc('Перейти на главную')"
        :href="get_first_menus_item.url"
        />
        <ButtonComponent v-if="logoff" :label="this.loc('Авторизация')" href="/login"/>
      </div>
    </div>
  </BasePage>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import BasePage from '../../components/navigation/BasePage';
import ButtonComponent from '../../components/unit/Button';

export default builder({
  name: 'Page404',
  components: {BasePage, ButtonComponent},
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.get_first_menus_item) {
      this.$router.push(this.get_first_menus_item.url);
    }
  },
  computed: {
    logoff() {
      console.log('isLoggedIn', this.$store.getters.isLoggedIn);
      console.log('isLoggedIn', this.$store.state.auth_settings.user_obj);
      return !this.$store.getters.isLoggedIn;
    },
    get_first_menus_item() {
      return this.$store.getters.getSortedLayer(null)[0];
    },
    get_domain() {
      return window.location.host;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');
.page-not-found{
  .flex(column, unset, unset);
  padding: 10px;
  & > *{
    margin-bottom: 15px;
  }

  & > *:last-child{
    margin-bottom: 0;
  }
  &__caption{

  }
  &__buttons{
    .flex(row, unset, unset)
  }
}

</style>
