<template>
  <div class="input-form">
    <div v-for="(item, idx) in this.getPaintComponents" :key="idx" class="input-fields-line">
      <component v-if="item.type !== 'row'"
                 :is="item.component"
                 :input_name="item.name"
                 class="input-row_margin-bottom"
                 :input_info="item.field"
                 :standard_version="this.standard_version"
                 :available_objects="item.availableObjects"
                 @input_data_change="(drawField, value) => this.inputChanged(item, item.name, undefined, drawField, value)">
      </component>
      <div v-else class="input-row_flex">
        <component v-for="(column, cidx) in item.columns"
                   :key="cidx"
                   :is="column.component"
                   :input_name="column.name"
                   :input_info="column.field"
                   :standard_version="this.standard_version"
                   :available_objects="column.availableObjects"
                   @input_data_change="(drawField, value) => this.inputChanged(column, column.name, column.rowName, drawField, value)">
        </component>
      </div>
      <SeparatorComponent v-if="item.isHaveAfterSeparator"
                          class="input-row_margin-bottom"/>
    </div>

    <ModalViewConductor ref="formModalViewConductor">
      <template v-slot="{level, rev_level}">
        <ModalView :open_level="rev_level"
                   type_modal="internal"
                   :caption="this.loc(this.subforms[level-1].title)"
                   @click_close="this.closed_modal_view_first"
                   @click_closing="this.$refs.formModalViewConductor.close_level_shadow();">
          {{ this.loc('Для продолжения заполните поля:') }}<br><br>

          <div v-for="(item, idx) in this.getAdditionalPaintComponents[level-1]" :key="idx" class="input-fields-line">
            <component v-if="item.type !== 'row'"
                      :is="item.component"
                      :input_name="item.name"
                      class="input-row_margin-bottom"
                      :input_info="item.field"
                      :standard_version="this.standard_version"
                      :available_objects="item.availableObjects"
                      @input_data_change="(drawField, value) => this.inputChanged(item, item.name, undefined, drawField, value)">
            </component>
            <div v-else class="input-row_flex">
              <component v-for="(column, cidx) in item.columns"
                        :key="cidx"
                        :is="column.component"
                        :input_name="column.name"
                        :input_info="column.field"
                        :standard_version="this.standard_version"
                        :available_objects="column.availableObjects"
                        @input_data_change="(drawField, value) => this.inputChanged(column, column.name, column.rowName, drawField, value)">
              </component>
            </div>
            <SeparatorComponent v-if="item.isHaveAfterSeparator"
                                class="input-row_margin-bottom"/>
          </div>

          <ButtonComponent :label="this.loc(this.subforms[level-1].title)"
                           style="width: max-content;"
                           :elementStatusModifier="this.subforms[level-1].baseButton.field.button_state == 'negative' ? 'danger' : undefined"
                           labelType="button/small/medium 14"
                           @click_to_button="this.checkSubformIsClicked(level-1)"/>
        </ModalView>
      </template>
    </ModalViewConductor>

    <!-- <ModalView type_modal="internal" caption="test">
      Kek
    </ModalView> -->
  </div>
</template>

<script type="text/javascript">
import builder from '../assets/v1/js/builder';

import uniMultiSelectDropdown from './inputs/uni_multiSelectDropdown';
import uniDatePeriod from './inputs/uni_datePeriod';
import Datetime from './inputs/uni_datetime';
import uniInputField from './inputs/uni_inputField';
import uniCheckBox from './inputs/uni_checkBox';
import uniInnInput from './inputs/uni_inn_input';
import {markRaw} from 'vue';
import uniLabel from './inputs/uni_label';
import uniButton from './inputs/uni_button';
import uniFileField from './inputs/uni_file_field';
import SeparatorComponent from '../components/unit/Separator';
import ModalView from '../components/ModalView';
import ModalViewConductor from '../components/navigation/ModalViewConductor';
import ButtonComponent from '../components/unit/Button';

const accordanceInputs = {
  'multi_select_dropdown': uniMultiSelectDropdown,
  'multi_select_dropdown_filtered': uniMultiSelectDropdown,
  'extendable_dropdown': uniMultiSelectDropdown, // стоит изменить на "extendable_dropdown_filtered"
  'inn_input': uniInnInput,
  'dropdown_filtered': uniMultiSelectDropdown,
  'dropdown': uniMultiSelectDropdown,
  'date_period': uniDatePeriod,
  'datetime': Datetime,
  'float': uniInputField,
  'string': uniInputField,
  'boolean': uniCheckBox,
  'label': uniLabel,
  'button': uniButton,
  'file_field': uniFileField,
};

const separatorBetweenTypes = [
  ['label', 'label'],
  ['label', 'string'],
  ['string', 'file_field'],
];

function sortLogic(itemA, itemB) {
  return (itemA.priority == null ? 0 : itemA.priority) - (itemB.priority == null ? 0 : itemB.priority);
}

export default builder({
  // ignoreHistory: true,
  components: {
    SeparatorComponent,
    ModalView,
    ModalViewConductor,
    ButtonComponent,
  },
  data: () => ({
    draw_structure: {},
    fill_form: {},
    subforms: [],
    draw_additional_structure: [],
  }),
  // mixins: [uniViewMixin],
  mounted() {
    Object.keys(this.input_fields).forEach(
        (key) => {
          if (this.input_fields[key].type == 'string' && this.input_fields[key].is_active === false) {
            this.input_fields[key].type = 'label';
          }
        },
    );
  },
  computed: {
    getAdditionalPaintComponents() {
      this.draw_additional_structure = [];
      this.buildDrawAdditionalStructure();
      return this.draw_additional_structure.map((addStruct) => this.prepareToDrawStruct(addStruct));
    },
    getPaintComponents() {
      this.draw_structure = {};
      this.buildDrawStructure();

      return this.prepareToDrawStruct(this.draw_structure);
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.draw_structure = {};
      this.fill_form = {};
      this.subforms = [];
      this.draw_additional_structure = [];
    },
  },
  methods: {
    prepareToDrawStruct(struct) {
      // Перепаковка и сортировка строк
      let paintComponents = Object.keys(struct).map((itemKey) => struct[itemKey]);

      paintComponents.sort(sortLogic);

      paintComponents = paintComponents.filter((row) => row.visible !== false);

      // перепаковка и распаковка внутри строк
      paintComponents.forEach((line) => {
        if (line.type != 'row') {
          return;
        }

        const curColumns = Object.keys(line.columns).map((itemKey) => line.columns[itemKey]);
        curColumns.sort(sortLogic);
        line.columns = curColumns.filter((item) => item.visible !== false);
      });

      // расстановка разделительных линий
      paintComponents.forEach((item, index) => {
        const itemNext = paintComponents[index + 1];
        if (itemNext) {
          if (separatorBetweenTypes.some((rule) => rule[0] === item.type && rule[1] === itemNext.type)) {
            item.isHaveAfterSeparator = true;
          }
        }
      });

      return paintComponents;
    },
    closed_modal_view_first() {
      this.$refs.formModalViewConductor.close_level();

      const requireInputs = this.getRequireInputs(this.subforms[this.subforms.length-1].baseButton.field);

      const modalFillInputs = requireInputs.filter(
          (item) => item.field.alwaysShow !== true,
      );

      modalFillInputs.forEach((reqFillInput) => {
        if (reqFillInput.rowName != null) {
          if (this.fill_form[reqFillInput.rowName] != null) {
            this.fill_form[reqFillInput.rowName].columns[reqFillInput.name] = null;
          }
          return;
        }
        this.fill_form[reqFillInput.name] = null;
      });

      this.subforms.pop(); // удаляем последний объект в массиве

      // const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      // newQuery['uni_level__' + (this.views.length-1)] = undefined;
      // this.$router.push({query: newQuery});
    },
    buildDrawAdditionalStructure() {
      this.subforms.forEach((subform, index) => {
        subform.paths.forEach((curPath) => {
          const rawInput = this.getRawInput(curPath);
          const builtInput = this.getInput(curPath);

          this.addInputFieldToDrawStruct(rawInput, builtInput.name, builtInput.rowName, index);
        });
      });
    },
    buildDrawStructure() {
      Object.keys(this.input_fields).forEach((key) => {
        if (this.input_fields[key].type == 'row') {
          Object.keys(this.input_fields[key].fields).forEach((rawFieldKey) => {
            this.addInputFieldToDrawStruct(this.input_fields[key].fields[rawFieldKey], rawFieldKey, key);
          });
        } else {
          this.addInputFieldToDrawStruct(this.input_fields[key], key);
        }
      });

      this.setVisibilityButtonsRequiredInputs();
    },
    addInputFieldToDrawStruct(field, fieldName, rowName, addToAdditionalStructToIndex) {
      if (fieldName[0] === '_' || field.visible === false) {
        return;
      }

      if (this.standard_version === 1) {
        // TODO во всех местах (включая сервер и в полях ввода) нужно input_type поменять на type
        field.input_type = field.type;
      }

      field.download_additional_objects_src = this.download_additional_objects_src;

      const drawField = {
        component: accordanceInputs[field.input_type],
        type: field.input_type,
        priority: field.priority == null ? 0 : field.priority,
        field: field,
        name: fieldName,
        rowName: rowName,
        availableObjects: this.input_fields.__choice_available_objects__,
      };

      if (drawField.component != null) {
        drawField.component = markRaw(drawField.component);
        this.insertBuiltFieldInDrawStruct(drawField, fieldName, rowName, addToAdditionalStructToIndex);
        return drawField;
      }

      console.warn('Input type not found!', field.input_type, 'name', fieldName, 'rowName', rowName);
      return undefined;
    },
    insertBuiltFieldInDrawStruct(builtField, fieldName, rowName, addToAdditionalStructToIndex) {
      while (addToAdditionalStructToIndex != null &&
             addToAdditionalStructToIndex >= this.draw_additional_structure.length) {
        this.draw_additional_structure.push({});
      }

      const addingStruct = addToAdditionalStructToIndex != null ? this.draw_additional_structure[addToAdditionalStructToIndex] : this.draw_structure;

      if (rowName != null) {
        if (addingStruct[rowName] == null) {
          addingStruct[rowName] = {
            type: 'row',
            field: this.input_fields[rowName],
            rowName: rowName,
            priority: this.input_fields[rowName].priority == null ? 0 : this.input_fields[rowName].priority,
            columns: {},
          };
        }

        addingStruct[rowName].columns[fieldName] = builtField;
      } else {
        addingStruct[fieldName] = builtField;
      }
    },
    setVisibilityButtonsRequiredInputs() {
      Object.keys(this.draw_structure).forEach((lineKey) => {
        if (this.draw_structure[lineKey].type == 'row') {
          Object.keys(this.draw_structure[lineKey].columns).forEach((fieldKey) => {
            if (this.draw_structure[lineKey].columns[fieldKey].type == 'button') {
              this.setUnvisibilityForRequiredInputs(this.draw_structure[lineKey].columns[fieldKey].field.require_inputs);
            }
          });
        }

        if (this.draw_structure[lineKey].type == 'button') {
          this.setUnvisibilityForRequiredInputs(this.draw_structure[lineKey].field.require_inputs);
        }
      });
    },
    setUnvisibilityForRequiredInputs(inputs) {
      inputs.forEach((requireInput) => {
        const workInput = this.getInput(requireInput);

        if (workInput.field.alwaysShow !== true) {
          workInput.visible = false;
        }
      });
    },
    getRawInput(inputPath) {
      inputPath = inputPath.split('__');

      if (inputPath > 1) {
        return this.input_fields[inputPath[0]].fields[inputPath[1]];
      }
      return this.input_fields[inputPath[0]];
    },
    getInput(inputPath) {
      inputPath = inputPath.split('__');

      if (inputPath > 1) {
        return this.draw_structure[inputPath[0]].columns[inputPath[1]];
      }
      return this.draw_structure[inputPath[0]];
    },
    checkInputIsFilled(builtInput) {
      // checking input in fill_form

      if (builtInput.rowName != null) {
        return !(this.fill_form[builtInput.rowName] == null ||
        this.fill_form[builtInput.rowName].columns[builtInput.name] == null);
      }
      return this.fill_form[builtInput.name] != null;
    },
    checkSubformIsClicked(subformIndex) {
      if (this.subforms[subformIndex].paths.some((curPath) => {
        return !this.checkInputIsFilled(this.getInput(curPath));
      })) {
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: new Error('Заполните все требуемые поля!'),
        }]);
        return;
      }

      this.$refs.formModalViewConductor.close_level();

      if (subformIndex < 1) {
        this.inputChanged(this.subforms[subformIndex].baseButton,
            undefined,
            undefined,
            undefined,
            this.subforms[subformIndex].baseButton.field,
        );
      }
    },
    getRequireInputs(value) {
      return value.require_inputs.map((reqInputPath) => {
        return this.getInput(reqInputPath);
      });
    },
    inputChanged(builtField, fieldName, rowName, paintFieldName, value) {
      if (builtField.type == 'button') {
        if (value.require_inputs != null && Array.isArray(value.require_inputs) && value.require_inputs.length > 0) {
          const requireInputs = this.getRequireInputs(value);

          const notFillInputs = requireInputs.filter(
              (item) => item.field.alwaysShow === true && !this.checkInputIsFilled(item),
          );

          if (notFillInputs.length > 0) {
            window.globalEvents.callEvent('new_message', [{
              messageType: 'error',
              messageData: new Error('Заполните все требуемые поля!\n' +
              notFillInputs.map((item) => item.field.name).join('\n')),
            }]);

            return;
          }

          const modalFillInputs = requireInputs.filter(
              (item) => item.field.alwaysShow !== true && !this.checkInputIsFilled(item),
          );
          if (modalFillInputs.length > 0) {
            // open modal fill

            const nextDrawModalPaths = modalFillInputs.map((reqFillInput) => {
              let curPath = reqFillInput.rowName;
              curPath = curPath == null ? '' : curPath + '__';
              curPath += reqFillInput.name;

              return curPath;
            });

            this.openSubform(nextDrawModalPaths, builtField);
            return;
          }
        }
        // form full filled

        value.value = true;

        this.addInputToFillStruct(value, builtField.rowName, builtField.name);

        this.$emit('onFormFilled', this.fill_form);

        this.removeInputToFillStruct(builtField.rowName, builtField.name);

        return;
      }

      this.addInputToFillStruct(value, rowName, paintFieldName);
    },
    openSubform(subformPaths, baseButton) {
      this.subforms.push({
        title: baseButton.field.name,
        baseButton,
        paths: subformPaths,
      });
      this.$refs.formModalViewConductor.up_level();
    },
    addInputToFillStruct(input, rowName, fieldName) {
      if (rowName != null) {
        if (this.fill_form[rowName] == null) {
          this.fill_form[rowName] = {'type': 'row', 'fields': {}};
        }
        this.fill_form[rowName].fields[fieldName] = input;
      } else {
        this.fill_form[fieldName] = input;
      }
      this.$emit('onFormChanged');
    },
    removeInputToFillStruct(rowName, fieldName) {
      if (rowName != null) {
        if (this.fill_form[rowName] != null) {
          delete this.fill_form[rowName].fields[fieldName];
          if (this.fill_form[rowName].fields.length < 1) {
            delete this.fill_form[rowName];
          }
        }
      } else {
        delete this.fill_form[fieldName];
      }
    },
  },
  emits: {
    onFormChanged: null,
    onFormFilled: null,
    // close_view: null,
  },
  props: {
    input_fields: {default: {}},
    standard_version: {default: 1},
    download_additional_objects_src: {default: null},
  },
});
</script>

<style lang="less">
@import url('../assets/v1/less/base.less');

.input-row_flex {
  .flex(row, flex-start, flex-start);

  & > * {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  // row-gap: 5px;
  // column-gap: 10px;
  align-items: center;
}

.input-row_margin-bottom {
  margin-bottom: 10px;
}

</style>
