<template>
  <div class="date-field">
    <DropDownInput class="select-report-dropdown"
                    :dropdown_items_init="this.dropdownData"
                    :init_selected_item="[this.default_choice]"
                    :label_text="this.label_text"
                    style_type="default"
                    @input_change="(number) => this.selectDefaultValue(number[0].return_value)"/>

    <ModalView v-if="this.is_extended_opened"
               :open_level="1"
               type_modal="internal"
               position="fixed"
               :caption="'Выбор даты'"
               @click_close="this.closed_modal">
      <PeriodDatePicker label_text="test picker"
                        @confirmed="this.set_custom_date"
                        :init_value="this.input_value"
                        />
    </ModalView>
  </div>
</template>

<script>
import DropDownInput from '@/components/unit/DropDownInput';
import ModalView from '../ModalView';
import PeriodDatePicker from './PeriodDatePicker';

{
// const defaultValues = {
//   7: {
//     label: 'Текущий день',
//     from: new Date(),
//     to: new Date().shiftDateFromCurrent(0, 0, 1),
//   },
//   0: {
//     label: 'Текущий месяц',
//     from: new Date().beginCurrentMonth(),
//     to: new Date().beginCurrentMonth().shiftDateFromCurrent(0, 1, 0).shiftDateFromCurrent(0, 0, -1),
//   },
//   1: {
//     label: 'Прошлый месяц',
//     from: new Date().beginCurrentMonth().shiftDateFromCurrent(0, -1, 0),
//     to: new Date().beginCurrentMonth().shiftDateFromCurrent(0, 0, -1),
//   },
//   2: {
//     label: 'Текущий год',
//     from: new Date().beginCurrentYear(),
//     to: new Date(),
//   },
//   3: {
//     label: 'Прошлый год',
//     from: new Date().pastYear(),
//     to: new Date().beginCurrentYear().shiftDateFromCurrent(0, 0, -1),
//   },
//   5: {
//     label: 'Позапрошлый год',
//     from: new Date().prePastYear(),
//     to: new Date().pastYear().shiftDateFromCurrent(0, 0, -1),
//   },
//   6: {
//     label: 'Последние 3 года',
//     from: new Date().prePastYear(),
//     to: new Date(),
//   },
// };
}

export default ({
  name: 'DateField',
  components: {
    DropDownInput,
    ModalView,
    PeriodDatePicker,
  },
  emits: ['input_changed'],
  data: function() {
    return {
      input_value: null,
      default_choice: null,
      is_extended_opened: false,
      default_values: {
        0: {
          label: 'Конец текущего квартала',
          value: new Date().beginCurrentQuarter().shiftDateFromCurrent(0, 3, 0).shiftDateFromCurrent(0, 0, -1),
        },
        1: {
          label: 'Конец прошлого квартала',
          value: new Date().beginCurrentQuarter().shiftDateFromCurrent(0, 0, -1),
        },
        2: {
          label: 'Конец 1 квартала ' + (new Date()).getFullYear() + ' года',
          value: new Date().beginCurrentYear().shiftDateFromCurrent(0, 3, 0).shiftDateFromCurrent(0, 0, -1),
        },
        3: {
          label: 'Конец 2 квартала ' + (new Date()).getFullYear() + ' года',
          value: new Date().beginCurrentYear().shiftDateFromCurrent(0, 6, 0).shiftDateFromCurrent(0, 0, -1),
        },
        4: {
          label: 'Конец 3 квартала ' + (new Date()).getFullYear() + ' года',
          value: new Date().beginCurrentYear().shiftDateFromCurrent(0, 9, 0).shiftDateFromCurrent(0, 0, -1),
        },
        5: {
          label: 'Конец 4 квартала ' + (new Date()).getFullYear() + ' года',
          value: new Date().beginCurrentYear().shiftDateFromCurrent(1, 0, 0).shiftDateFromCurrent(0, 0, -1),
        },
      },
    };
  },
  props: {
    label_text: {
      required: true,
      type: String,
    },
    // elementStatus: {
    //   required: false,
    //   default: 'enabled',
    // },
    init_value: {
      type: Object,
      required: false,
      default: new Date(),
    },
    init_choice: {
      required: false,
      default: 0,
    },
  },
  mounted() {
    this.input_value = this.init_value;
    this.default_choice = this.init_choice;
  },
  watch: {
    input_value() {
      // const extDate = JSON.stringify(this.input_value);
      const extDate = this.input_value;
      // console.log('input_changed DateField', extDate);
      this.$emit('input_changed', extDate);
    },
    default_choice() {
      this.input_value = this.defaultValuesList[this.default_choice].value;
      // this.selectDefaultValue(this.default_choice);
    },
    init_value() {
      this.input_value = this.init_value;
      this.default_choice = -1;
    },
    init_choice() {
      console.log('init_choice');
      this.default_choice = this.init_choice;
    },
  },
  methods: {
    selectDefaultValue(defId) {
      this.default_choice = this.default_choice; // Нужно чтоб сбросить состояние выпадашки реактивностью
      if (defId == -1) this.is_extended_opened = true;
      else this.default_choice = defId;
    },
    closed_modal() {
      this.is_extended_opened = false;
    },
    set_custom_date(newVal) {
      this.is_extended_opened = false;
      this.input_value = newVal;
      this.default_choice = -1;
    },
    init_default_values() {
      this.default_values[-1] = {
        label: ('Произвольный: ' + (new Intl.DateTimeFormat('ru-Ru').format(this.input_value))),
        value: this.input_value,
      };
    },
  },
  computed: {
    defaultValuesList() {
      this.init_default_values();

      return this.default_values;
    },
    dropdownData() {
      return Object.keys(this.defaultValuesList).map((key) => {
        return {
          value_show: this.default_values[key].label,
          return_value: parseInt(key),
        };
      });
    },
  },
});
</script>

<style lang="less">
// @import url('../../assets/v1/less/base.less');
</style>
