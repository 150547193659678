import {ROOT_URL} from './dataSource.js';
import {dataRequester} from '../DataRequester';

const REQUEST_LINK = '/api/v1/src/GetUserInfo';
const CHECK_LINK = '/auth/check/';

function getUserInfo(handler) {
  dataRequester({
    url: ROOT_URL + REQUEST_LINK,
    method: 'post',
    body: {
      params: {},
    },
  }).then((userData) => {
    handler(userData);
  }).catch((errData) => {});

  /* const userInfoRequester = (data) => {

  };

  dataRequester({
    url: ROOT_URL + CHECK_LINK,
    method: 'POST',
    body: {
      params: {},
    },
    ignoreError: true,
  }).then(userInfoRequester)
      .catch((errData) => handler(null)); */
}

export default getUserInfo;
