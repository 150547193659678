<comment>
  Это поле ввода внешнего объекта. Позволяет выбрать объект по ссылке
</comment>

<template>
  <div class="foreign-objects-input">
    <LabelComponent
          class="foreign-objects-item"
          :label_text="this.label_text + (this.label_text && this.label_text.length > 0 ? ':' : '')"/>
    <ButtonComponent
      v-if="this.input_field_status != 'disabled'"
      :elementType="'default'"
      :iconUrl="'/media/icons/magnifying-glass.svg'"
      @click_to_button="this.openedWindow = true"
    />

    <ModalView v-if="this.openedWindow"
              :open_level = "1"
              type_modal = "internal"
              position = "fixed"
              :caption = "'Выбрать ' + this.object_type_view"
              @click_close="this.openedWindow = false">
      <EntitySelector :work_object_name="this.object_type" @entitySelect="this.setNewSelect"/>
    </ModalView>

    <div v-if="this.get_selected_items.length > 0" class="foreign-object-painter">
      <ButtonComponent
        v-if="this.input_field_status != 'disabled'"
        :elementType="'default'"
        :iconUrl="'/media/icons/close.svg'"
        @click_to_button="this.setNewSelect([])"
      />
      <LabelComponent
          v-for="(draw_item, index) in this.get_selected_items"
          :key="index"
          class="foreign-objects-item"
          :label_text="draw_item.view"/>
    </div>
    <div v-else>
      <LabelComponent
          class="foreign-objects-item"
          :label_text="'-'"/>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/components/unit/Label';
import ButtonComponent from '@/components/unit/Button';
import EntitySelector from '@/views/related_pages/adminLayout/EntitySelector';
import ModalView from '@/components/ModalView';

export default ({
  name: 'ForeignObject',
  components: {
    LabelComponent,
    ButtonComponent,
    ModalView,
    EntitySelector,
  },
  data: function() {
    return {
      // is_dropped_down: false, // признак открытия выпадающего списка
      openedWindow: false,
      inputValues: [],
    };
  },
  emits: {
    input_change: null,
    favorite_change: null,
    filterChange: null,
  },
  props: {
    is_extendable: {
      type: Boolean,
      default: false,
    }, //  Возможность добавления дополнительных значений
    is_filterable: {
      type: Boolean,
      default: false,
    }, //  Возможность фильтрации
    is_multi_select: {
      type: Boolean,
      default: false,
    }, // Возможность выбрать несколько элементов
    label_text: { // Заголовок
      type: String,
      default: 'Заголовок по умолчанию',
    },
    selected_items: { // Изначально выбранные объекты
      required: false,
      default: [],
    },
    object_type: {
      type: String,
      default: null,
    },
    object_type_view: {
      type: String,
      default: null,
    },
    input_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    is_favoritable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    inputValues() {
      this.$emit('input_change', this.inputValues);
    },
    selected_items(newData) {
      this.inputValues = newData;
    },
  },
  mounted() {
    this.inputValues = this.selected_items;
  },
  computed: {
    get_selected_items() {
      // возвращает объект где только выбранные элементы
      return this.inputValues == null ? [] : this.inputValues;
    },
  },
  methods: {
    setNewSelect(newValues) {
      this.inputValues = newValues;
      this.openedWindow = false;
    },
  },
});
</script>


<style lang="less">
@import url('../../assets/v1/less/base.less');

@hover__single__dd__elem_background-color: @light-green-opacity-10;
@select__single__dd__elem_background-color: @light-green;

.foreign-objects-input * {
  cursor: pointer;
}

.foreign-objects-input {
  width: 100%;
  display: flex;
  gap: 10px;

  .modal-area-wrapper {
    width: 250px;
    height: calc(90vh - (56px + 70px));
    max-height: calc(340px + 58px);
  }

  .foreign-object-painter {
    .flex(row, start, start);
    gap: 10px;
  }

  .icon {
    margin: 0 !important;
  }
}
</style>
