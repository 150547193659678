<template>
  <div></div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';

export default builder({
  mounted() {
    const addID= '&report_id='+this.currentReportId;
    const options={
      url: `${ROOT_URL}/api/v1/__get_page_additional_data?id=${
        this.$store.state.navigate_settings.current_page_info.id}${addID}`,
      method: 'GET',
    };
    this.changedFields = {};
    this.$store.commit('set_full_screen_loading', true);
    // запрос инпутов
    dataRequester(options)
        .then((data)=>{
          window.open(data.redirect_link, '_blank');
        })
        .catch((errData) => {})
        .finally(()=>{
          window.location.href = this.get_parent_structure.url;
        });
  },
  computed: {
    get_current_page_info() {
      return this.$store.state.navigate_settings.current_page_info;
    },
    get_parent_structure() {
      const currentPage = this.get_current_page_info;
      if (currentPage) return this.$store.getters.getPageInfo(currentPage['parent_page_id']);
    },
  },
});
</script>

<style  lang="less">

</style>
