<template>
  <div class="topPanelFrame">
    <div class="top-wrapper">
      <IconComponent class="topPanelFrame__burger"
                     @click="burgerClick"
                     name="burger"
                     type="svg"
                     size="28"/>

      <SwitcherComponent ref="switcher"
                         size="medium"
                         :values="this.get_lang_switcher_values"
                         :init_selected_index="this.getUserLangId()"
                         @select_changed="this.switchLanguage"/>
    </div>
    <ProfileFrame/>
  </div>
</template>

<script>

import builder from '../../../assets/v1/js/builder';
import ProfileFrame from './ProfileFrame';
import IconComponent from '../../unit/Icon';
import SwitcherComponent from '../../unit/Switcher';
import ButtonComponent from '../../unit/Button';


export default builder({
  name: 'TopPanel',
  components: {
    IconComponent,
    ProfileFrame,
    SwitcherComponent,
    ButtonComponent,
  },
  methods: {
    burgerClick() {
      this.$emit(
          'left-menu',
          'on',
      );
    },
  },
});


</script>

<style lang="less">
@import url('../../../assets/v1/less/base.less');

@panel__top_bottom__padding: @margin-horizontal-default;

@panel__top__padding__800: @margin-horizontal-800;

@panel__burger__color: @gray;

.topPanelFrame{
  width: 100%;
  .flex(row, space-between, center);
  padding: 0 @panel__top_bottom__padding;
  &__burger{
    display: none;
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .lang-toggle{
    display: none;
  }

  .topPanelFrame{
    padding: 0 @panel__top__padding__800 0;
    .top-wrapper {
      .flex(row, center, center);

      & > * {
        margin-right: 7px;
      }

      & > *:last-child {
        margin-right: 0;
      }
    }
    &__burger{
      display: block;
      background-color: @panel__burger__color;
      &:hover { cursor: pointer}
    }
  }
}

</style>
