<template>
  <ModalView ref = "settings_modal_view_ref"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             :caption = "this.loc('Привязка Аккаунта')"
             @click_close = "this.$emit('closeAccountLinkModal')">
    <div class = "accountLink__container">
      <div class = "accountLink__container__group">
        <LabelComponent class = "accountLink__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Логин"/>
        <InputField class = "accountLink__container__input"
                    type = "password"
                    label_text = "Логин"/>
      </div>
      <div class = "accountLink__container__group">
        <LabelComponent class = "accountLink__container__header"
                        label_type = "button/large/medium 14"
                        label_text = "Пароль"/>
        <InputField class = "accountLink__container__input"
                    type = "password"
                    label_text = "Пароль"/>
      </div>
      <ButtonComponent class = "accountLink__container__button"
                       label = "Привязать"
                       elementType = "default-bg"
      />
    </div>
  </ModalView>
</template>

<script>
import builder from '../../../assets/v1/js/builder';
import ModalView from '../../ModalView';
import LabelComponent from '../../unit/Label';
import ButtonComponent from '../../unit/Button';
import InputField from '../../unit/InputField';

export default builder({
  name: 'AccountLinkModal',
  emits: ['closeAccountLinkModal'],
  components: {
    ModalView,
    LabelComponent,
    ButtonComponent,
    InputField,
  },
  methods: {
  },
});
</script>

<style lang = "less">
@import url('../../../assets/v1/less/constants.less');

.accountLink {
  &__container {
    min-width: 250px;
    width: 15vw;
    display: grid;
    grid-row-gap: 20px;

    &__group {
      display: grid;
      grid-row-gap: 10px;
    }

    &__input {
      width: 100%;
    }
  }
}

</style>
