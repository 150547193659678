<template>
  <div class="base-content-wrapper">
      <ButtonComponent
          v-if="this.get_parent_structure"
          class="back_button"
          iconType="arrow-left"
          iconMargin="0 8px 0 0"
          elementType="default"
          :href="this.get_parent_structure.url"
          :label="this.loc('Назад')">

        Назад
      </ButtonComponent>
      <template v-if="!this.get_is_mobile_info">
        <LabelComponent class="title"
                        label_type="h2"
                        :label_text="this.get_current_page_name"/>
        <LabelComponent v-if="this.get_current_page_description" class="description"
                        label_type='body/large/regular 12'
                        :label_text="this.get_current_page_description"/>
      </template>

      <div class="for-scroll-wrapper grow">
        <div
            class="content__slider" :class="{'content__slider__back': this.is_with_background}"
            id="content-slider">

          <div class="content">
            <div v-if="this.get_is_mobile_info" class="mobile-header">
              <LabelComponent class="title"
                              label_type="h2"
                              :label_text="this.get_current_page_name"/>
              <LabelComponent v-if="this.get_current_page_description" class="description"
                              label_type='body/large/regular 12'
                              :label_text="this.get_current_page_description"/>
            </div>

            <slot>{{this.loc('Место для контента')}}</slot>
          </div>
        </div>
      </div>
  </div>
</template>

<script type="text/javascript">

import builder from '../../assets/v1/js/builder';
import ButtonComponent from '../unit/Button';
import LabelComponent from '../unit/Label';

export default builder({
  components: {
    ButtonComponent,
    LabelComponent,
  },
  computed: {
    get_current_page_info() {
      return this.$store.state.navigate_settings.current_page_info;
    },
    get_parent_structure() {
      const currentPage = this.get_current_page_info;
      if (currentPage) return this.$store.getters.getPageInfo(currentPage['parent_page_id']);
    },
    get_current_page_name() {
      if (this.name) return this.name;
      const currentPage = this.get_current_page_info;
      if (currentPage) return this.loc(currentPage.name);
    },
    get_current_page_description() {
      return this.get_current_page_info?this.get_current_page_info.description: undefined;
    },
  },
  props: {
    name: null,
    is_with_background: {
      type: Boolean,
      default: true,
    },
  },
});

</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');


@page__slider__background-color: @white;

@scroll__track__background-color: @super-light;
@scroll_track__thumb__color: @light-gray;

// default size
@scroll__track__vertical__width: @margin-horizontal-default;

@page__padding__top: @margin-vertical-default;
@page__padding__left: @margin-horizontal-default;

@page__slider__margin-bottom: @margin-vertical-default;
@scroll__track__bottom__padding: @margin-vertical-default;
//_________________

//transition-threshold-1 size (800)
@scroll__track__vertical__width__800: @margin-horizontal-800;

@page__padding__top__800: @margin-vertical-800;
@page__padding__left__800: @margin-horizontal-800;

@page__slider__margin-bottom__800: @margin-vertical-800;
@scroll__track__bottom__padding__800: @margin-vertical-800;
//_________________________________

.base-content-wrapper{
  .flex(column, flex-start, stretch);
  .set_custom_scrollbar(6px, 10px, 0px, 20px, 20px);
  // position: relative;
  height: 100%;
  width: 100%;

  padding-top:@page__padding__top;
  padding-left:@page__padding__left;
  //.wrap{
  //  padding-right: @margin-horizontal-default
  //}
  .title{
    margin-top: 8px;
  }
  .description{
    width: 70%;
    margin-top: 2px;
  }

  .back_button{
    align-self: flex-start;
    // margin-right: auto;
    // background-color: #eb5050;
  }

  .for-scroll-wrapper {
    // flex-grow: 1;
    // background: linear-gradient(45deg, black, transparent);
    // overflow: auto;
    margin-top: @page__padding__top;
    position: relative;
  }

  .content{
    &__slider{
      max-width: 100%;
      margin-right: @page__padding__left;
      //margin-bottom: @page__slider__margin-bottom;
      //overflow: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      // bottom: 0;
      //max-height: 99%;
      right: 0;
      overflow: auto;

      .content{
        width: 100%;

        .mobile-header {
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }

      &__back{
        margin-bottom: @page__slider__margin-bottom;
        box-shadow: 0 4px 14px rgba(46, 50, 45, 0.05);
        background: @page__slider__background-color;
        border-radius: 10px;

        .content .mobile-header {
          padding: 2px 10px 0;
        }
      }
    }

    //.scroll-wrapper-content {
    //  padding: 0;
    //  border-radius: 10px 10px 0 0;
    //}
    //
    //.scroll-track-vertical{
    //  .flex(column, flex-start, flex-end);
    //  width: @scroll__track__vertical__width;
    //  padding: 0 10px @scroll__track__bottom__padding 0;
    //  background: transparent;
    //
    //  .scroll-track-vertical-view{
    //    border-radius: 10px;
    //    width: 4px;
    //    background: @scroll__track__background-color;
    //
    //    /*margin: 0 10px 0 36px;*/
    //    .scroll-track-vertical-thumb{
    //      background: @scroll_track__thumb__color;
    //    }
    //  }
    //}
  }
}


@media screen and (max-width: @transition-threshold-1) {
  .base-content-wrapper {
    padding-top: 0;
    padding-left: 21px;

    .for-scroll-wrapper {
      margin-top: 0;
    }

    .back_button {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .description {
      margin-bottom: 7px;
    }
    .content__slider{
      margin-right: 5px;
      padding-right: 10px;
    }
    .content__slider__back{
        margin-right: 21px;
    }
  }
}


/* @media screen and (max-width: @transition-threshold-2) {
  .base-content-wrapper{
    .description{
      width: unset;
    }
  }
} */
</style>
