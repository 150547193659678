import XLSX from 'xlsx';
import downloadBLOB from '../project_management/downloadBLOB';

class XlsxTable {
  constructor(columns, data, isWithTotal, totalRow, settings) {
    const defaultSetting = {
      fileName: 'table_export',
      extraLength: 3,
      writeOptions: {
        type: 'array',
      },
    };

    this.columns = columns;
    this.data = data;
    this.isWithTotal = isWithTotal || false;
    this.totalRow = totalRow || [];
    this.settings = {settings, ...defaultSetting};
  }

  createWorkSheet() {
    let headerRow = {};

    this.columns.forEach((column) => {
      headerRow = {...headerRow, [column.id]: column.title};
    });

    const contentRows = this.data.map((row) => {
      let contentRow = {};

      this.columns.forEach((column) => {
        contentRow = {...contentRow, [column.id.toString()]: row[column.id]};
      });

      return contentRow;
    });

    const workSheetTableData = [headerRow, ...contentRows];

    const workSheetHeaderData = this.columns.map((column) => column.id.toString());

    if (this.isWithTotal) {
      workSheetTableData.push(this.totalRow);
    }

    return XLSX.utils.json_to_sheet(workSheetTableData, {header: workSheetHeaderData, skipHeader: true});
  }

  calculateColumnWidth(workSheet, extraLength) {
    let _a;
    const columnRange = XLSX.utils.decode_range((_a = workSheet['!ref']) !== null && _a !== void 0 ? _a : '');
    const columnLetters = [];
    for (let C = columnRange.s.c; C <= columnRange.e.c; C++) {
      const address = XLSX.utils.encode_col(C);
      columnLetters.push(address);
    }
    const ans = columnLetters.map(function(column) {
      const columnCells = Object.keys(workSheet).filter(function(cell) {
        return cell.charAt(0) === column || cell.slice(0, 2) === column;
      });
      const maxWidthCell = columnCells.reduce(function(previousCell, currentCell) {
        // console.log(workSheet[previousCell], workSheet[currentCell]);

        let v1 = workSheet[previousCell].v;
        v1 = v1 ? v1.toString() : '';
        let v2 = workSheet[currentCell].v;
        v2 = v2 ? v2.toString() : '';

        return v1.length > v2.length ? previousCell : currentCell;
      });

      let v3 = workSheet[maxWidthCell].v;
      v3 = v3 ? v3.toString() : '';

      // console.log(v3, v3.length, extraLength);

      return {width: v3.length + extraLength};
    });

    return ans;
  }

  download() {
    const workBook = XLSX.utils.book_new();
    const workSheet = this.createWorkSheet();
    workSheet['!cols'] = this.calculateColumnWidth(workSheet, this.settings.extraLength);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'A1');

    const arrayBuffer = XLSX.write(workBook, this.settings.writeOptions);

    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    });

    const url = URL.createObjectURL(blob);

    downloadBLOB(url, this.settings.fileName + '.xlsx');
  }
}

export default XlsxTable;

