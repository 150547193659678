<template>
  <ButtonComponent :label="this.getTitle"
                   v-bind="this.getComponentSettings"
                   @click_to_button="this.buttonClick"/>
</template>

<script>
import builder from '@/assets/v1/js/builder';
import ButtonComponent from '@/components/unit/Button';

export default builder({
  name: 'FormButton',
  components: {
    ButtonComponent,
  },
  emits: [
    'buttonClick',
  ],
  computed: {
    getTitle() {
      const title = this.fieldInfo.title || this.fieldInfo.system_name;
      return this.loc(title);
    },
    getComponentSettings() {
      return this.fieldInfo.typeSettings;
    },
  },
  methods: {
    buttonClick() {
      this.$emit('buttonClick', this.fieldInfo);
    },
  },
  props: {
    fieldInfo: {
      required: true,
      type: Object,
      default: {},
    },
  },
});
</script>

<style>

</style>
