<template>
  <BasePage :name="this.getPageName">
    <div class="report-wraper">
      <!--<div v-if="Object.keys(this.$route.query).length > 0">
          Обнаружены GET Параметры! {{ this.$route.query }} <br><br>
      </div>-->

      <!--<ReportFiltersForm
        :raw_fields="this.workReport ? (this.workReport.fields ? this.workReport.fields : []) : []"
        @form_changed="this.filterFormChanged"
        style="padding: 10px;"
      />-->
      <DropDownInput v-if="this.page_info.variants && this.page_info.variants.length > 1"
                      :dropdown_items_init="this.variantList"
                      :init_selected_item="[this.active_report_id]"
                      label_text="Варианты отчёта"
                      style_type="default"
                      :input_field_status="this.isStraightBuilding ? 'disabled' : undefined"
                      @input_change="this.variantChanged"/>

      <ChangeObjectForm
                  :raw_fields="this.workReport ? (this.workReport.fields ? this.workReport.fields : []) : []"
                  :raw_actions="this.getActions"
                  actionsAlign="center"
                  @completeFormAction="this.requestReportData"
      />

      <!-- <ButtonComponent class="report-generate-button" :label="'Сформировать'"
                        v-if="!this.isAutoBuild"
                        style="margin: auto;"
                        @click_to_button="this.requestReportData"/> -->

      <div class="uni-report uni-report__padding">
        <component
            v-if="this.views.length > 0"
            ref="firstView"
            :is="this.views[0].component"
            :draw_index="0"
            :draw_info="this.views[0].draw_info"
            :draw_data="this.views[0].draw_data"
            @next_select="this.changeViewSelect"
          >
        </component>
      </div>
    </div>
      <template v-slot:modal>
        <ModalViewConductor ref="modalViewConductor">
          <template v-slot="{level, rev_level}">
            <ModalView
                      :open_level="rev_level"
                      :type_modal="this.views[level].modal_type"
                      :ref="'modal_'+level"
                      :caption="this.views[level].modalCaption"
                      @click_close="this.closed_modal_view_first"
                      @click_closing="this.$refs.modalViewConductor.close_level_shadow();">

              <component
                  :is="this.views[level].component"
                  :draw_index="level"
                  :draw_info="this.views[level].draw_info"
                  :draw_data="this.views[level].draw_data"
                  :before_total="this.views[level].before_total"
                  @next_select="this.changeViewSelect"
                  @close_view="this.closeView">
              </component>
            </ModalView>
          </template>
        </ModalViewConductor>
      </template>
  </BasePage>
</template>

<script>
import ButtonComponent from '@/components/unit/Button';
import BasePage from '@/components/navigation/BasePage';
import getReportInfo from '@/assets/dataSources/reports/getReportInfo';
import lightPageInfo from '@/assets/dataSources/lightPageInfo';
import buildReport from '@/assets/dataSources/reports/buildReport';
import ModalView from '@/components/ModalView';
import ModalViewConductor from '@/components/navigation/ModalViewConductor';
import DropDownInput from '@/components/unit/DropDownInput';

// import ReportFiltersForm from './forms/ReportFiltersForm';
import ChangeObjectForm from '@/views/related_pages/adminLayout/forms/ChangeObjectForm';

import uniTable from './views/uni_table';
import {markRaw} from '@vue/reactivity';

const accordanceViews={
  'table': uniTable,
  // 'pie_chart': uniPieChart,
  // 'sliced_table': uniSlicedTable,
  // 'map': uniMap,
  // 'redirect_link': uniOpenLinkAction,
  // 'download_file': uniDownloadFileAction,
  // 'card_list': uniCardList,
  // 'external_form': uniExternalForm,
  // 'form': uniForm,
};

function normaliseValue(value) {
  if (typeof(value) == typeof(true)) {
    return value ? 'Да' : 'Нет';
  }

  try {
    if (value && 'view' in value) {
      return value.view;
    }
  } catch {}

  if (!value || value == '') {
    return '';
  }

  // function isDate(rawVar) {
  //   rawVar = String(rawVar);
  //   // date samples:

  //   // 0001-01-01T16:15*
  //   // 0001.01.01T16:15*
  //   // 0001/01/01T16:15*
  //   let dateReg = /^\d{4}([./-])\d{2}\1\d{2}T\d{2}:\d{2}.*$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 0001-01-01 16:15*
  //   // 0001.01.01 16:15*
  //   // 0001/01/01 16:15*
  //   dateReg = /^\d{4}([./-])\d{2}\1\d{2} \d{2}:\d{2}.*$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 01-01-0001T16:15*
  //   // 01.01.0001T16:15*
  //   // 01/01/0001T16:15*
  //   dateReg = /^\d{2}([./-])\d{2}\1\d{4}T\d{2}:\d{2}.*$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 01-01-0001 16:15*
  //   // 01.01.0001 16:15*
  //   // 01/01/0001 16:15*
  //   dateReg = /^\d{2}([./-])\d{2}\1\d{4} \d{2}:\d{2}.*$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 16:15
  //   dateReg = /^\d{2}:\d{2}$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 16:15:00
  //   dateReg = /^\d{2}:\d{2}:\d{2}$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 0001-01-01
  //   // 0001.01.01
  //   // 0001/01/01
  //   dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 22.03.1981
  //   // 22-03-1981
  //   // 22/03/1981
  //   dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 20231129T0934
  //   dateReg = /^\d{8}T\d{4}$/;
  //   if (dateReg.test(rawVar)) return true;
  //   // 20231129T093431*
  //   dateReg = /^\d{8}T\d{6}.*$/;
  //   if (dateReg.test(rawVar)) return true;
  //   return false;
  // }

  // if (isDate(value)) {
  //   try {
  //     const checkDate = new Date(value);

  //     if (isNaN(checkDate)) {
  //       const a = {}.var.value;
  //       console.log(a);
  //     }

  //     if (checkDate.getFullYear() < 2000) {
  //       const hours = checkDate.getHours();
  //       const minutes = checkDate.getMinutes();

  //       const timeString = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
  //       return timeString;
  //     } else {
  //       return checkDate.toLocaleDateString('ru-RU');
  //     }
  //   } catch {}
  // }

  return value;
}

function packValueToNormal(values, colorColumn) {
  if (!values) {
    return {};
  }

  const ans = {};
  Object.keys(values).forEach(function(key) {
    if (key == colorColumn) return;

    ans[key] = {
      value: normaliseValue(values[key]),
    };
  });

  return ans;
}

function repackTableToNormalVersion(allColumns, level, rows) {
  if (allColumns.length <= level) {
    return {
      next_level: [],
      next_type_data: null,
    };
  }

  const zeroLevelTypeData = {
    type: 'table',
    columns: {},
  };

  const zeroNextLevel = [];

  let colorColumn = null;

  allColumns[level].forEach(function(elem) {
    if (elem.name == 'line_color') colorColumn = elem.type;
    else {
      zeroLevelTypeData.columns[elem.type] = {
        label: elem.name,
        name: elem.type,
        is_empty: true,
      };
    }
  });

  rows.forEach(function(elem) {
    const res = repackTableToNormalVersion(allColumns, level+1, elem.child);

    if (colorColumn != null && elem.values[colorColumn] && elem.values[colorColumn] != '') {
      res.color = 'other-color-0';
      res.exact_color = elem.values[colorColumn];
    }

    Object.keys(elem.values).forEach((key) => {
      if (elem.values[key] != null && elem.values[key] != '' && zeroLevelTypeData.columns[key]) zeroLevelTypeData.columns[key].is_empty = false;
    });

    res.columns = packValueToNormal(elem.values, colorColumn);

    zeroNextLevel.push(res);
  });

  const oldColumns = zeroLevelTypeData.columns;
  zeroLevelTypeData.columns = {};
  Object.keys(oldColumns).forEach((key) => {
    if (!oldColumns[key].is_empty) {
      zeroLevelTypeData.columns[key] = oldColumns[key];
    }
  });

  const ans = {
    next_level: zeroNextLevel,
    next_type_data: zeroLevelTypeData,
  };

  return ans;
}

export default ({
  name: 'ReportPage',
  components: {
    ButtonComponent,
    BasePage,
    ModalView,
    ModalViewConductor,
    // ReportFiltersForm,
    ChangeObjectForm,
    DropDownInput,
  },
  props: {

  },
  data: () => ({
    workReport: null,
    curPageVersion: 0,
    active_report_id: 0,
    active_report_info: {},
    page_info: {},
    rawReportData: null,
    views: [], // массив объектов с оутпутами для модальных окон
    noData: false,
    additionalPageName: undefined,
    isStraightBuilding: false,
    // filledForm: {},
  }),
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        // this.rebuildViewsFromParams();
        return;
      }

      // this.currentReportId = -1;
      // this.isFirstPreRequest = true;
      // this.reportVersionsText = undefined;
      // при переключении роутера заново инициализируем параметры
      this.resetParams();
    },
    active_report_info() {
      if (!this.active_report_info || Object.keys(this.active_report_info).length < 1) return;

      const curThis = this;
      const requestForVersion = this.curPageVersion;

      curThis.workReport = null;

      if (curThis.page_info.variants && curThis.page_info.variants.length > 1) this.additionalPageName = curThis.active_report_info.view;

      getReportInfo(curThis.active_report_info.report_name, function(reportInfo) {
        if (curThis.curPageVersion != requestForVersion) {
          return;
        }

        curThis.workReport = reportInfo;

        if (curThis.isAutoBuild) {
          curThis.requestReportData(curThis.getDefaultPostData);
        }
      });
    },
  },
  mounted() {
    window.escapeableElements.push(this.close_all_levels);

    this.resetParams();
  },
  methods: {
    variantChanged(data) { // отрабатываем нажатие выбора вариантов отчёта
      this.active_report_id = data[0].return_value;
      this.active_report_info = this.page_info.variants[this.active_report_id];
    },
    // filterFormChanged(newData) {
    //   // this.filledForm = newData;
    //   // console.log('newData', newData);
    // },
    requestReportInfo() {
      this.noData = false;

      const curThis = this;
      const requestForVersion = this.curPageVersion;

      lightPageInfo((data) => {
        if (curThis.curPageVersion != requestForVersion) {
          return;
        }

        // curThis.active_report_info = data;

        curThis.page_info = data;

        this.active_report_id = 0;

        if (curThis.page_info.variants) {
          curThis.page_info.variants.sort((a, b) => {
            const aOrd = (a.priority ? a.priority : 0);
            const bOrd = (b.priority ? b.priority : 0);
            if (aOrd < bOrd) return -1;
            else if (aOrd > bOrd) return 1;
            return 0;
          });

          curThis.active_report_info = curThis.page_info.variants[this.active_report_id];
        } else {
          curThis.active_report_info = curThis.page_info;
        }
      });
    },
    requestReportData(postData) {
      const curThis = this;
      const requestForVersion = this.curPageVersion;
      this.isStraightBuilding = true;

      this.views.length = 0;

      buildReport(curThis.active_report_info.report_name, postData, function(builtReport) {
        if (curThis.curPageVersion != requestForVersion) {
          return;
        }

        curThis.rawReportData = builtReport;
        const packedData = repackTableToNormalVersion(curThis.rawReportData.columns, 0, curThis.rawReportData.rows);

        // console.log('PACKED_DATA', packedData);

        curThis.changeViewSelect(-1, packedData, 0);
        curThis.isStraightBuilding = false;
      });
    },
    close_all_levels() {
      for (let i = 1; i < this.views.length; i++) {
        this.closed_modal_view_first();
      }
      this.$router.push({query: {}});
    },
    closed_modal_view_first() {
      this.$refs.modalViewConductor.close_level();

      this.views.pop(); // удаляем последний объект в массиве

      const newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery['uni_level__' + (this.views.length-1)] = undefined;
      this.$router.push({query: newQuery});
    },
    rebuildViewsFromParams() {
      if (this.views.length < 1) {
        this.$router.push({query: {}});
        return;
      }

      // const firstView = this.views[0].__orig__;
      this.views.length = 1;
      this.$refs.modalViewConductor.clearAll();
    },
    resetParams() {
      this.curPageVersion = this.curPageVersion + 1;
      this.$refs.modalViewConductor.clearAll();
      // this._availableReports = undefined;
      this.additionalPageName = undefined;
      // this.rawInputFields = {};
      // this.hour_update = false;
      // this.show_generate_button = true,
      this.isStraightBuilding = false;
      // this.show_export_to_excel = true,
      this.views.length = 0;
      this.page_info = {};
      this.active_report_info = {};
      // this.filledForm = {};
      // this.cacheDate = null;
      // this.isFormChanged = false;
      // this.auto_first_request = false;
      // this.show_background = true;
      // this.excel_is_primary = false;
      // this.current_standard_version = 0;
      // this.noDataText = 'Нет данных соответствующих, заданным фильтрам';
      this.requestReportInfo(); // первоначально запрашиваем данные
    },
    setView(index, view) {
      this.views.length = index;

      const curView = accordanceViews[view.next_type_data.type];

      if (curView == null) {
        console.warn('View type not found!', view.next_type_data.type, 'Level:', index, 'View:', view);
        return;
      }

      this.views.push({
        component: markRaw(curView),
        modal_type: view.next_type_data.modal_type === 'fly' ? 'internal' : 'bottom',
        modalCaption: view.modalCaption,
        draw_info: view.next_type_data,
        draw_data: view.next_level,
        before_total: [], // view.before_total,
        __orig__: view,
      });

      // console.log('VIEWS_LEN', this.views, index);

      return true;
    },
    closeView(clickIndex) {
      this.$refs['modal_'+clickIndex].close_window();
    },
    changeViewSelect(clickIndex, nextView, selectIdx) {
      // console.log('CALL_changeViewSelect', clickIndex, nextView, selectIdx, new Error().stack);

      const nextIsHard = nextView.next_type_data.is_hard_loading;

      if (!this.__setFullLoad__ && nextIsHard) {
        this.$store.commit('set_full_screen_loading', true);
      }
      this.__setFullLoad__ = true;

      const workFunc = () => {
        const nextViewComponent = accordanceViews[nextView.next_type_data.type];
        let alreadyQuery = nextViewComponent ? nextViewComponent.ignoreHistory === true : false;

        if (clickIndex > -1 && !alreadyQuery) {
          const newQuery = JSON.parse(JSON.stringify(this.$route.query));
          alreadyQuery = newQuery['uni_level__' + clickIndex] === selectIdx;

          if (!alreadyQuery) {
            newQuery['uni_level__' + clickIndex] = selectIdx;
            this.$router.push({query: newQuery});

            alreadyQuery = true;
          }
        } else {
          if ((nextView.next_level ? nextView.next_level : []).length < 1) {
            this.noData = true;
          }
        }

        if (clickIndex < 0 || alreadyQuery) {
          this.__setFullLoad__ = false;
          if (this.setView(clickIndex + 1, nextView)) {
            while (this.$refs.modalViewConductor.last_level < this.views.length - 1) {
              this.$refs.modalViewConductor.up_level();
            }
          } else {
            if (nextIsHard) {
              this.$store.commit('set_full_screen_loading', false);
            }
          }
        }
      };

      if (nextIsHard === true) {
        setTimeout(workFunc, 0);
      } else {
        workFunc();
      }
    },
  },
  computed: {
    variantList() {
      return this.page_info.variants.map((item, id) => {
        return {
          value_show: item.view,
          return_value: id,
        };
      });
    },
    getPageName() {
      let ans = this.$store.state.navigate_settings.current_page_info.name;
      if (this.additionalPageName) {
        ans += ' (' + this.additionalPageName + ')';
      }
      return ans;
    },
    getDefaultPostData() {
      const ans = new FormData();
      ans.append('__action__', 'build');

      return ans;
    },
    getActions() {
      return [
        {
          system_name: 'build',
          view: 'Сформировать',
          is_loading: this.isStraightBuilding,
        },
      ];
    },
    isAutoBuild() {
      return this.workReport != null && (!this.workReport.fields || this.workReport.fields.length < 1);
    },
  },
});
</script>

<style lang="less">
@import url('../../../assets/v1/less/base.less');

.report-wraper {
  padding: 17px;
}

.uni-report{
  // overflow: auto;
  // &__padding{
  //   padding: 10px;
  // }
  &__gen-buttons-row {
    margin: auto;
    .flex(row, flex-start, unset);
  }
  &__generate-button{
    max-width: 250px;
    align-self: center;
  }
  &__download-button{
    align-self: center;
    margin-left: 14px;
  }
  // &__select-report{
  //   max-width: 400px;
  //   align-self: flex-start;
  // }
}
</style>
