<template>
  <svg viewBox="25 25 50 50" class="spinner">
    <circle class="path-back" cx="50" cy="50" r="20" fill="none"
            :stroke-width="this.back_circle_width" stroke-miterlimit="10" />
    <circle class="path" cx="50" cy="50" r="20" fill="none"
            :stroke-width="this.front_circle_width" stroke-miterlimit="10" />
  </svg>
</template>

<script>

export default {
  name: 'spinner',
  props: {
    width: {default: 2},
  },
  data: () => ({
    front_circle_width: 2,
    back_circle_width: 3,
  }),
  mounted() {
    this.front_circle_width = this.width;
    this.back_circle_width = this.width + 1;
  },
};

</script>

<style lang="less">

@import url('../../assets/v1/less/base.less');
@spinner-size: 50px;

@spinner__stroke__background-color: @mint;
@spinner__stroke__color: @light-green;

.spinner {
  height: @spinner-size;
  width: @spinner-size;
  animation: rotate 2s linear infinite;

  & .path-back {
    stroke-linecap: round;
    stroke: @spinner__stroke__background-color;
  }

  & .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: @spinner__stroke__color;
  }
}


</style>
