import dataSourceMixin from './mixins/dataSourceMixin.js';
import translator from './mixins/translator.js';
import loaderAndModalsController from './mixins/loaderAndModalsController';
import simpleValidatorMixin from './mixins/simpleValidatorMixin.js';

export default (settings) => {
  if (settings.mixins === undefined) {
    settings.mixins = [];
  }
  settings.mixins.push(dataSourceMixin);
  settings.mixins.push(translator);
  settings.mixins.push(loaderAndModalsController);
  settings.mixins.push(simpleValidatorMixin);


  settings.mixins.push({
    data: () => ({
      isMobileWall: 0,
      currentWidth: 0,
    }),
    beforeMount() {
      const appElem = document.getElementById('app');
      const appStyles = window.getComputedStyle(appElem, null);
      const mobileTransitionThreshold = appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2);

      this.isMobileWall = parseInt(mobileTransitionThreshold);
      this.currentWidth = window.innerWidth;

      window.addEventListener('resize', () => {
        this.currentWidth = window.innerWidth;
      });
    },
    computed: {
      get_is_mobile_info() {
        return this.currentWidth <= this.isMobileWall;
      },
    },
  });
  return settings;
};
