<template>
  <CheckBox :is_toggle="true"
             :init_value="this.getInitValue"
             :label_text_left="this.loc(this.input_info.name)"
             :check_box_field_status="this.getIsActive ? null : 'disabled'"
             @input_check="this.inputChanged">
  </CheckBox>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import CheckBox from '@/components/unit/CheckBox';

export default builder({
  components: {
    CheckBox,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {
    getIsActive() {
      return this.input_info.is_active != false && this.input_info.is_active != 'false';
    },
    getInitValue() {
      if (typeof(this.input_info.value) == 'string') {
        return this.input_info.value == 'true';
      }

      if (typeof(this.input_info.value) == 'boolean') {
        return this.input_info.value;
      }

      return this.input_info.value.value === 'true';
    },
  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      const ans = {};
      Object.assign(ans, this.input_info);

      if (typeof(ans.value) == 'string' || typeof(ans.value) == 'boolean') {
        ans.value = data.toString();
      } else {
        ans.value.value = data.toString();
      }

      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
