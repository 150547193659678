<template>
  <div class="short-bottom-panel-frame"
       v-show="this.getShortSiteStruct.length > 0">
      <div v-for="(item, key) in this.getShortSiteStruct" :key="key" class="bottom-button">
        <ButtonComponent
            :iconUrl="item.icon_url ? item.icon_url : '/media/icons/empty.svg'"
            :label="item.name"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'23'"
            :elementStatus="(this.get_current_page_info && item.id === this.get_current_page_info.id) ? 'selected' : undefined"
            :labelType="'caption/small/regular 10'"
            :href="item.url"
        />
      </div>

      <div v-if="this.moreThanShort" class="bottom-button">
        <ButtonComponent
            :iconType="'more'"
            :label="this.loc('Ещё')"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'23'"
            :labelType="'caption/small/regular 10'"
            @click_to_button="this.addonOpen = true"
        />
      </div>
  </div>
  <div v-if="this.addonOpen"
       @click="this.closeAddon()"
       class="dark-background">
      <div class="addonContainer" @click="this.addonClick()">
        <div v-for="(item, key) in this.getAddonSiteStruct" :key="key" class="bottom-button">
        <ButtonComponent
            :iconUrl="item.icon_url ? item.icon_url : '/media/icons/empty.svg'"
            :label="item.name"
            :iconPosition="'top'"
            :elementType="'hover'"
            :iconSize="'23'"
            :elementStatus="(this.get_current_page_info && item.id === this.get_current_page_info.id) ? 'selected' : undefined"
            :labelType="'caption/small/regular 10'"
            :href="item.url"
        />
      </div>
      </div>
  </div>
</template>

<script>

import builder from '../../assets/v1/js/builder';
import ButtonComponent from '../unit/Button';

export default builder({
  name: 'BottomPanel',
  components: {
    ButtonComponent,
  },
  data: ()=> ({
    maxButtonCount: 5,
    moreThanShort: false,
    addonOpen: false,
    NoAddonClose: false,
  }),
  computed: {
    getShortSiteStruct() {
      const ans = this.getSiteInfo();
      if (ans.length > this.maxButtonCount) {
        this.moreThanShort = true;
      }

      ans.length = Math.min(ans.length, this.moreThanShort ? this.maxButtonCount-1 : this.maxButtonCount);
      return ans;
    },
    getAddonSiteStruct() {
      const ans = this.getSiteInfo();
      const addon= [];
      for (let i = this.maxButtonCount-1; i < ans.length; i+=1) {
        addon.push(ans[i]);
      }
      return addon;
    },
    getFullSiteLines() {
      const workArray = this.getSiteInfo();
      const ans = [];

      for (let i = 0; i < workArray.length; i += this.maxButtonCount) {
        ans.push(workArray.slice(i, i + this.maxButtonCount));
        // do whatever
      }

      return ans;
    },
    get_current_page_info() {
      return this.$store.state.navigate_settings.current_page_info;
    },
  },
  methods: {
    closeAddon() {
      if (this.NoAddonClose) {
        this.NoAddonClose = false;
        return;
      }
      this.addonOpen = false;
    },
    addonClick() {
      this.NoAddonClose = true;
    },
    getSiteInfo() {
      const ans = this.$store.state.navigate_settings.web_site_struct.filter((item) => item.in_fast_panel === true);
      ans.sort((itemA, itemB) => {
        if (itemA.priority < itemB.priority) return -1;
        else if (itemA.priority > itemB.priority) return 1;
        else return 0;
      });
      return ans;
    },
    burgerClick() {
      this.$emit(
          'left-menu',
          'on',
      );
    },
  },
});


</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

@panel__top_bottom__padding: @margin-horizontal-default;
@fullscreen__background-color: @dark-opacity-15;
@bottom__panel_background-color: @white;

//@panel__top__padding__800: @margin-horizontal-800;
//
//@panel__burger__color: @gray
.bottom-button {
  //flex-grow: 1;
  flex-basis: 20%;
  padding: 7px 3px;

  p{
      width: 100%;
      overflow: hidden;
      position: relative;
      max-height: 24px;
      margin-right: -1em;
      padding-right: 1em;
      line-break: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // &:before {
      //   content: '...';
      //   position: absolute;
      //   right: 0;
      //   bottom: 0;
      // }
      // &:after {
      //   content: '';
      //   position: absolute;
      //   right: 0;
      //   width: 1em;
      //   height: 1em;
      //   margin-top: 0.2em;
      //   background: #ffffffff;
      // }
    }
}

.short-bottom-panel-frame{
  width: 100%;
  // padding-top: 7px;
  // padding-bottom: 9px;
  // height: 100%;
  .flex(row, center, flex-start);
  background-color: @bottom__panel_background-color;
  // padding: 0 @panel__top_bottom__padding;
}

.full-bottom-panel-frame {
  z-index: 102;
  width: 100%;
  // padding-top: 7px;
  // padding-bottom: 9px;
  background-color: @bottom__panel_background-color;

  table {
    width: 100%;
  }

  /* .bottom-buttons-line {
    .flex(row, flex-start, center);
    width: 100%;
  } */
}

.dark-background {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: @fullscreen__background-color;
  z-index: 101;
  .addonContainer{
    .flex(row, flex-start, flex-end);
    flex-wrap: wrap-reverse;
    margin-bottom: 77px;
    color: #777b75ff;
    background-color: #ffffffff;
    box-shadow: 0 6px 40px rgba(46, 50, 45, 0.12);
    border-radius: 7px;
    width: 100%;
    padding: 8px;
}
}

</style>
