<template>
  <DropDownInput :label_text="this.getTitle"
                 v-bind="this.getComponentSettings"
                 @input_change="(checkedItems) => this.changeHandler(checkedItems)"
                 :init_selected_item="this.set_values"/>
</template>

<script type="text/javascript">
import builder from '../../../assets/v1/js/builder';
import DropDownInput from '../../../components/unit/DropDownInput';


export default builder({
  components: {
    DropDownInput,
  },
  emits: [
    'input_change',
    'update:modelValue',
  ],
  data: () => ({
    data_value: '',
  }),
  computed: {
    getTitle() {
      const title = this.fieldInfo.title || this.fieldInfo.system_name;
      return this.loc(title);
    },
    getComponentSettings() {
      const typeSettings = this.fieldInfo.typeSettings;
      typeSettings.is_filterable = typeSettings.is_filterable !== undefined ? typeSettings.is_filterable : true;
      typeSettings.is_multi_select = typeSettings.is_multi_select !== undefined ? typeSettings.is_multi_select : true;
      return this.fieldInfo.typeSettings;
    },
  },
  watch: {
    modelValue(newData) {
      if (newData) this.data_value = newData;
    },
    set_values(newData) {
      // при получении новых данных через props меняем data_values
      this.data_value = newData;
    },
    data_value(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
      this.$emit('update:modelValue', newData);
    },
  },
  methods: {
    changeHandler(checkedItems) {
      this.data_value = checkedItems;
    },
  },
  props: {
    fieldInfo: {
      required: true,
      type: Object,
      default: {},
    },
    modelValue: {},
    set_values: {},
  },
});
</script>

<style lang="less">

</style>
