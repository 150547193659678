export function collectForm(struct) {
  const innerType = ['row', 'column'];

  const form = struct.filter((item) => !innerType.includes(item.type));
  struct.filter((item) => innerType.includes(item.type)).forEach((item) => {
    form.push(...collectForm(item.items));
  });

  return form.filter((item) => !['button'].includes(item.type));
}

export function filterForm(form, isOnlyChange) {
  if (form.inner_form) form.inner_form = filterForm(form.inner_form, isOnlyChange);

  if (form.form) {
    form.form = form.form.filter((item) => {
      const isRequired = !!item.typeSettings ? item.typeSettings.is_required : false;
      return item.value !== item.valueDefault || isRequired;
    });
  }

  return form;
}

export function joinForm(form) {
  if (form.inner_form) {
    const innerForm = joinForm(form.inner_form, false);
    const innerFormSystemNames = innerForm.form.map((item) => item.system_name);

    form.form = form.form.filter((item) => !innerFormSystemNames.includes(item.system_name));
    form.form = [...form.form, ...innerForm.form];
    delete innerForm.form;
    delete innerForm.action;
  }

  return form;
}

export function compressForm(form) {
  if (form.inner_form) form.inner_form = compressForm(form.inner_form);

  if (form.form) {
    const formData = {};
    form.form.forEach((item) => formData[item.system_name] = item.value);
    form.form = formData;
  }

  return form;
}

const formButtonActions = {
  data: () => ({
    activeButton: null,
    activeModalForm: null,
  }),
  emits: [
    'closeModal',
    'sendForm',
  ],
  methods: {
    showModal(buttonField, action) {
      this.activeButton = buttonField;
      this.activeModalForm = action.form;
    },
    closeModal(buttonField, action) {
      const data = buttonField;
      const params = action.type.split('__');

      if (!this.isBase || params.includes('isWithBase')) {
        this.$emit('closeModal');
      }

      if (params.includes('isAll')) {
        if (!this.isBase) {
          this.$emit('buttonClick', data);
        }
      }

      const isWithCount = params.find((item) => item.match(/isWith[0-9]+/g));

      if (isWithCount) {
        const count = +isWithCount.replace(/isWith/g, '');
        if (count - 1 >= 0) {
          const decreasedIsWithCount = `isWith${count - 1}`;
          data.actions.forEach((item) => {
            if (item.type.match(/closeModal/g)) {
              item.type = item.type.replace(/isWith[0-9]+/g, decreasedIsWithCount);
            }
          });
          this.$emit('buttonClick', data);
        }
      }
    },
    sendFormAll(buttonField, action, isButtonClick = true) {
      let data = {};

      if (isButtonClick) {
        data = {
          action: action.type,
          calling_button: buttonField.system_name,
          form: collectForm(this.struct),
        };
      } else {
        data = {
          action: buttonField.action,
          calling_button: this.activeButton.system_name,
          form: collectForm(this.struct),
          inner_form: buttonField,
        };
      }

      if (this.isBase) {
        const params = data.action.split('__');

        if (params.includes('isJoin')) {
          data = joinForm(data);
        }

        if (params.includes('isOnlyChange') || params.includes('isWithRequired')) {
          data = filterForm(data, params.includes('isOnlyChange'), params.includes('isWithRequired'));
        }

        if (params.includes('isCompressed')) {
          data = compressForm(data);
        }
      }

      this.$emit('sendForm', data);
    },
  },
};

export default formButtonActions;
