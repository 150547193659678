<template>
  <BasePage ref="base_page">
    <template v-slot:content>
<!--      <component-->
<!--          :is="this.get_content_template.template_type"-->
<!--          :template_info="this.get_content_template"-->
<!--          :parent_data="this.parent_data"/>-->
    </template>

    <template v-if="modals_list.length !== 0" v-slot:modal>
      <ModalView v-for="(item) in modals_list" :key="item"
                 @click_close="this.close_modal"
                 @click_closing="this.closing_modal"
                 :caption="item.caption_get_rule"
                 :type_modal="item.type_modal"
                 :open_level="get_modal_open_level(item)">

        <component :is="item.template.template_type"
                   :template_info="item.template"
                   :parent_data="this.parent_data"/>
      </ModalView>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '../components/navigation/BasePage';
import ModalView from '../components/ModalView';

export default {
  name: 'TestPage',
  components: {BasePage, ModalView},
  computed: {
    get_base_settings() {
      // return this.get_rule('base_settings');
      return {};
    },
    get_content_template() {
      // return this.get_rule('content_template');
      return {};
    },
    get_menu_settings() {
      // return this.get_base_settings.outer_structure;
      return {};
    },
    get_name() {
      // return this.get_base_settings.page_name;
      return {};
    },
    get_parent_structure() {
      // return this.get_base_settings.parent_structure;
      return {};
    },
    get_user_info() {
      // if (!this.get_base_settings.user_info) return undefined;
      // return this.get_base_settings.user_info.user;
      return {};
    },
  },
  data: ()=> ({
    modals_list: [],
  }),

};
</script>

<style>

</style>
