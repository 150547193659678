export default {
  'calc_dist': function( str1, str2 ) {
    if (str1 === '') {
      return 0;
    }
    // Calculate Levenshtein distance between two strings
    // original by: Carlos R. L. Rodrigues

    // eslint-disable-next-line no-var
    let s; const l = (s = str1.split('')).length; const t = (str2 = str2.split('')).length; var i; let j; let m; let n;
    if (!(l || t)) return Math.max(l, t);
    // eslint-disable-next-line no-var
    for (var a = [], i = l + 1; i; a[--i] = [i]);
    for (i = t + 1; a[0][--i] = i;);
    for (i = -1, m = s.length; ++i < m;) {
      for (j = -1, n = str2.length; ++j < n;) {
        a[(i *= 1) + 1][(j *= 1) + 1] = Math.min(a[i][j + 1] + 1, a[i + 1][j] + 1, a[i][j] + (s[i] != str2[j]));
      }
    }
    return a[l][t];
  },
};
