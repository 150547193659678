import {createStore} from 'vuex';
import {adminModule} from './admin';
import {materialsGuideModule} from './materialsGuide';
import {ROOT_URL} from '../assets/v1/js/project_management/DataRequester';

function recursResort(pages, iterator) {
  pages.sort((itemA, itemB) => {
    if (itemA.priority < itemB.priority) return -1;
    else if (itemA.priority > itemB.priority) return 1;
    else return 0;
  });

  // eslint-disable-next-line guard-for-in
  for (const key in pages) {
    pages[key].priority = iterator;
    iterator++;
    iterator = recursResort(pages[key].children, iterator);
  }

  return iterator;
}

function resortPages(data) {
  const subPages = {};

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    subPages[data[key].id] = data[key];
    if (!data[key].children) {
      data[key].children = [];
    }
  }

  const sortRoot = [];

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    if (!subPages[data[key].parent_page_id]) {
      data[key].parent_page_id = null;
    }

    if (!data[key].parent_page_id) {
      sortRoot.push(data[key]);
      continue;
    }

    subPages[data[key].parent_page_id].children.push(data[key]);
  }

  recursResort(sortRoot, 0);
  return sortRoot;
}

export const store = createStore({
  state: {
    urls: {
      root: ROOT_URL,
      // root: 'http://localhost:8000',
      get_draw_rules_api: '/api/v1',
      get_draw_rules_api_suffix: '/__draw_rules',
    },
    auth_settings: {
      user_obj: null,
      is_user_obj_updated: false,
    },
    navigate_settings: {
      web_site_struct: null,
      web_site_tree_struct: null,
      is_web_site_struct_updated: false,
      current_page_info: undefined,
    },
    localize_settings: {
      cur_lang: '',
      translations: {},
    },
    locals: {},
    full_screen_loading: 0,
  },
  mutations: {
    set_full_screen_loading(state, up) {
      if (up) {
        state.full_screen_loading = state.full_screen_loading + 1;
      } else {
        state.full_screen_loading = Math.max(state.full_screen_loading - 1, 0);
      }
    },
    set_local(state, key) {
      state.locals[key] = '-';
    },
    is_user_login_now(state) {
      state.auth_settings.is_user_obj_updated = false;
      state.navigate_settings.is_web_site_struct_updated = false;
    },
    user_obj_mut(state, data) {
      state.auth_settings.is_user_obj_updated = true;
      state.auth_settings.user_obj = data;

      if (data.is_anonymous != true && window.WORK_IN_APPLICATION) {
        window.applicationCallback.setPushToken = (status, key, value) => {
          if (value != null && value !== '') {
            let appType = null;

            if (window.WORK_IN_IOS_APPLICATION) {
              appType = 'APNS';
            }

            if (window.WORK_IN_ANDROID_APPLICATION) {
              appType = 'FBS';
            }

            if (appType == null) {
              return;
            }

            const body = new FormData();
            body.append('push_system', appType);
            body.append('token', value);

            fetch(
                ROOT_URL+'/push_system/register_token/',
                {
                  method: 'POST',
                  credentials: 'include',
                  body,
                },
            );
          }
        };

        window.sendMessageInApplication({
          type: 'get_saved_string',
          key: '__push_token__',
          callback: 'window.applicationCallback.setPushToken',
        });
      }
    },
    web_site_struct_mut(state, data) {
      state.navigate_settings.is_web_site_struct_updated = true;
      state.navigate_settings.web_site_tree_struct = resortPages(data);
      state.navigate_settings.web_site_struct = data;
    },
    current_page_info_mut(state, data) {
      state.navigate_settings.current_page_info = data;
    },
    translations_mut(state, data) {
      const lang = state.localize_settings.cur_lang;
      state.localize_settings.translations[lang] = data;
    },
    cur_lang_mut(state, lang) {
      state.localize_settings.cur_lang= lang;
    },
  },
  actions: {
  },
  modules: {
    adminModule,
    materialsGuideModule,
  },
  getters: {
    getUserObj: (state) => {
      return state.auth_settings.user_obj;
    },
    isECMlinked: (state, getters) => {
      return getters.getUserObj.is_ecm_auth;
    },
    isERPlinked: (state, getters) => {
      return getters.getUserObj.is_erp_auth;
    },
    isSUPlinked: (state, getters) => {
      return getters.getUserObj.is_sup_auth;
    },
    isLoggedIn: (state, getters) => {
      return getters.isEndLoadAuthInfo &&
          state.auth_settings.user_obj !== null &&
          state.auth_settings.user_obj.is_anonymous == false &&
          state.navigate_settings.web_site_struct !== null;
    },
    isGroup: (state) => (group) => {
      return null != state.auth_settings.user_obj.groups.find((item) => item === group);
    },
    isEndLoadAuthInfo(state) {
      return state.auth_settings.is_user_obj_updated && state.navigate_settings.is_web_site_struct_updated;
    },
    hasPermission: (state) => (perm) => {
      return null != state.auth_settings.user_obj.permissions.find((item) => item === perm);
    },
    getSortedLayer: (state) => (parentPageId) => {
      if (!state.navigate_settings.web_site_struct) return [];
      const layer = state.navigate_settings.web_site_struct.filter((item) => item.parent_page_id === parentPageId);
      layer.sort((itemA, itemB) => {
        if (itemA.priority < itemB.priority) return -1;
        else if (itemA.priority > itemB.priority) return 1;
        else return 0;
      });
      return layer;
    },
    getBreadCrumbsToPage: (state, getters) => (pageId) => {
      const breadCrumbs = [getters.getPageInfo(pageId)];
      while (breadCrumbs[0]['parent_page_id']) {
        breadCrumbs.unshift(getters.getPageInfo(breadCrumbs[0]['parent_page_id']));
      }
      return breadCrumbs;
    },
    getPageInfo: (state) => (pageId) => {
      const layer = state.navigate_settings.web_site_struct.filter((item) => item.id === pageId);
      return (layer.length > 0) ? layer[0] : undefined;
    },
    getLanguage: (state) => (lang) => {
      return state.localize_settings.translations[lang];
    },
  },
});

export default store;
export {ROOT_URL};
