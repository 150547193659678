<template>
  <div>
    <DateField :label_text="this.loc(this.input_info.name)" @input_changed="this.inputChanged"/>
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import DateField from '../../components/unit/DateField';

export default builder({
  components: {
    DateField,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {

  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      // console.log('data', data);
      const ans = {};
      Object.assign(ans, this.input_info);
      const dataFormatted = new Date(data).toISOString();

      if (this.standard_version === 1) {
        ans.value = dataFormatted;
      } else {
        ans.value = {
          value: dataFormatted,
        };
      }
      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
