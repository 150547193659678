<template>
  <slot v-for="idx in this.last_level" :level="idx" :rev_level="this.last_level - this.closing_level - idx + 1"/>
</template>

<script>
export default {
  name: 'ModalViewConductor',
  data: () => ({
    last_level: 0,
    closing_level: 0,
  }),
  watch: {
    last_level: function() {
      if (this.last_level === 0) this.fix_html(false);
      else if (this.last_level === 1) this.fix_html();
    },
  },
  unmounted() {
    this.fix_html(false);
  },
  methods: {
    up_level() {
      this.last_level++;
    },
    close_level_shadow() {
      this.closing_level++;
    },
    close_level() {
      if (this.closing_level) this.closing_level--;
      this.last_level--;
    },
    clearAll() {
      this.last_level = 0;
      this.closing_level = 0;
    },
    fix_html(fix = true) {
      // const elem = document.getElementsByTagName('html')[0];
      // if (fix) {
      //   this.scroll_position_freeze = window.scrollY;
      //   elem.style.top = `-${this.scroll_position_freeze}px`;
      //   // elem.style.position = 'fixed';
      // } else {
      //   elem.removeAttribute('style');
      //   window.scrollTo(0, this.scroll_position_freeze);
      //   this.scroll_position_freeze = undefined;
      // }
    },
  },
};
</script>

<style>

</style>
