<template>
  <BasePage :is_with_background="false" class="materials-guide">
    <ListView
        :is_loading="this.getIsLoading"
        :is_with_search="false"
        :search_hints="this.get_search_hints"
        :search_data="this.get_struct_info"
        @element_observed="this.add_items()"
        @click_by_list_item="this.click_by_card">

      <template v-if="!this.isFiltersLoading" v-slot:additional_settings>
        <FiltersWrapper :selectedItems="this.selectedFilters">
          <FormComponent class="materials-guide-form__wrapper"
                         v-model="this.getMaterialsFilters"
                         @sendForm="this.formHandler"/>
        </FiltersWrapper>
      </template>

      <template v-slot="{item}">
        <div class="card__header">
          <LabelComponent class="card__header__title__type"
                          label_type='button/large/medium 14'
                          label_text="Марка: "/>

          <LabelComponent class="card__header__title"
                          label_type='button/large/regular 14'
                          :label_text="item.mark || ''"/>
        </div>

        <SeparatorComponent/>

        <BarLayout gap="12" direct="vertical">
          <div v-for="cardItem in item.card" :key="cardItem" class="card__row">
            <LabelComponent class="card__row__type"
                            label_type='button/large/medium 14'
                            :label_text="(cardItem.name || '') + ':'"/>

            <LabelComponent class="card__row__text"
                            label_type='button/large/regular 14'
                            :label_text="cardItem.value"/>
          </div>
        </BarLayout>
      </template>
    </ListView>
    <template v-slot:modal>
      <MaterialsModalPainter v-if="this.selected_item"
                             :external_selected_item="this.selected_item"
                             @modalClose="this.close_item_modal()"/>
    </template>
  </BasePage>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import BasePage from '../../components/navigation/BasePage';
import SeparatorComponent from '../../components/unit/Separator';
import BarLayout from '../../components/unit/BarLayout';
import LabelComponent from '../../components/unit/Label';
import ModalView from '../../components/ModalView';
import InputField from '../../components/unit/InputField';
import ButtonComponent from '../../components/unit/Button';
import ModalViewConductor from '../../components/navigation/ModalViewConductor';
import LoadingFrame from '../../components/LoadingFrame';
import TableComponent from '../../components/unit/Table';
import NamedField from '../../components/unit/NamedField';
import DropDownInput from '../../components/unit/DropDownInput';
import ListView from '../../components/unit/ListView';
import FiltersWrapper from '../../components/unit/FiltersWrapper';
import MaterialsModalPainter from '../../components/materials_guide/MaterialsModalPainter';
import FormPainterComponent from '../../uni_page_components/form/FormPainterComponent.vue';
import FormComponent from '../../uni_page_components/form/FormComponent.vue';

export default builder({
  name: 'MaterialsGuide',
  components: {
    BasePage, BarLayout, SeparatorComponent,
    LabelComponent, ModalView, InputField, ButtonComponent,
    ModalViewConductor, LoadingFrame, TableComponent,
    NamedField, DropDownInput, ListView,
    FiltersWrapper, MaterialsModalPainter,
    FormPainterComponent,
    FormComponent,
  },
  data: () => ({
    selected_item: null,
    selectedFilters: [],
    currentFiltersStruct: null,
  }),
  beforeMount() {
    this.$store.dispatch('updateMaterialsGuideData');
    this.$store.dispatch('updateFilters');
  },
  computed: {
    getMaterialsFilters: {
      get: function() {
        return this.currentFiltersStruct || this.$store.getters.getMaterialGuideFilters;
      },
      set: function(value) {
        this.currentFiltersStruct = value;
        console.log('filters', value);
      },
    },
    isFiltersLoading() {
      return this.$store.getters.getMaterialsGuideFiltersIsLoading;
    },
    get_items() {
      return this.$store.getters.getMaterialsGuideData;
    },
    get_struct_info() {
      return this.get_items.map((item) => {
        const struct = {
          id: item.id,
          mark: item.shortcode || '',
          card: [
            {
              name: 'Тип',
              value: item.subtype.type.name,
            },
            {
              name: 'Подтип',
              value: item.subtype.name,
            },
            {
              name: 'Производители',
              value: this.get_joined_string_names(item.plants),
            },
            {
              name: 'ПТР',
              value: item.ptr,
            },
            {
              name: 'Плотность',
              value: item.density,
            },
            {
              name: 'Цвет',
              value: item.color.name,
            },
            {
              name: 'Сополимер',
              value: item.copolymer ? item.copolymer.name : '',
            },
            {
              name: 'Температура ВИКА',
              value: item.t_vika,
            },
          ],
          form: [
            {
              name: 'Марка',
              value: item.shortcode,
            },
            {
              name: 'Тип',
              value: item.subtype.type.name,
            },
            {
              name: 'Подтип',
              value: item.subtype.name,
            },
            {
              name: 'ПТР',
              value: item.ptr,
            },
            {
              name: 'ПТР нагрузка',
              value: item.ptr_load,
            },
            {
              name: 'ПТР температура',
              value: item.ptr_temperature,
            },
            {
              name: 'Плотность',
              value: item.density,
            },
            {
              name: 'Сополимер',
              value: item.copolymer ? item.copolymer.name : '',
            },
            {
              name: 'Цвет',
              value: item.color.name,
            },
            {
              name: 'Температура ВИКА',
              value: item.t_vika,
            },
            {
              name: 'Область применения',
              value: this.get_joined_string_names(item.applications),
            },
            {
              name: 'Производители',
              value: this.get_joined_string_names(item.plants),
            },
            {
              name: 'Методы получения',
              value: this.get_joined_string_names(item.obtaining_methods),
            },
            {
              name: 'Методы переработки',
              value: this.get_joined_string_names(item.processing_methods),
            },
          ],
        };

        struct.card = struct.card.filter((item) => item.value);
        struct.form = struct.form.filter((item) => item.value);

        return struct;
      });
    },
    get_search_hints() {
      return [];
    },
    get_dropdown_status_objects() {
      return [];
    },
    getIsLoading() {
      return this.$store.getters.getMaterialsGuideIsLoading;
    },
    set_button_apply_enabled() {
      const isActive = this.ttn_status != null &&
          (this.ttn_date != null) &&
          (this.ttn_time != null) &&
          (this.car_date != null) &&
          (this.car_time != null);

      return isActive ? undefined : 'disabled';
    },
  },
  methods: {
    formHandler(data) {
      data.form = data.form.filter((item) => item.value);
      this.selectedFilters = data.form.map((item) => {
        const title = item.title_system || item.title;
        const value = Array.isArray(item.value) ? item.value.map((elem) => elem.name).join(', ') : item.value;
        return title + ': ' + value;
      });
      const form = {};
      data.form.forEach((item) => {
        form[item.system_name] = Array.isArray(item.value) ? item.value.map((elem) => elem.id).join(',') : item.value;
      });
      this.$store.dispatch('clearMaterialsGuide');
      this.$store.dispatch('setMaterialsGuideFilters', form);
    },
    set_date_range(date) {
      this.date_range = date;
    },
    click_by_card(item) {
      // заново инициализируем объект формы
      this.selected_item = item;
    },
    get_joined_string_names(items) {
      return items.map((item) => item.name).join(', ');
    },
    close_item_modal() {
      this.selected_item = null;
    },
    add_items() {
      this.$store.dispatch('updateMaterialsGuideData');
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.materials-guide {
  .text_hint {
    color: @gray;
  }

  .search {
    &__hints {
      .flex(row, flex-start, center);
      gap: 5px;
      flex-wrap: wrap;
    }
  }

  .row {
    width: 100%;
    .flex(row, space-between, center);
    gap: 10px;
  }

  .card {
    &__header {
      .flex(row, flex-start, center);
    }

    &__row {
      .flex(row, flex-start, center);

      &__type {
        margin-right: 5px;
      }
    }

    &__show-analogs-button {
      width: 100%;
      align-self: center;
    }
  }

  .button-panel {
    display: flex;
    flex-direction: row;

    & > * {
      margin-right: 6px;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  .error_text {
    color: @error;
  }

  .success_text {
    color: @green;
  }
}

.materials-guide-form__wrapper{
  max-width: calc((@transition-threshold-0 * 0.98) - 70px);
  width: calc(98vw - 70px);
}
</style>
