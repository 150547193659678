import {dataSource, ROOT_URL} from './dataSource.js';

const REQUEST_LINK = '/auth/logout/';

function logoutUser(handler) {
  const logoutUserDataSource=Object.create(dataSource(ROOT_URL + REQUEST_LINK, handler)); // родительский объект

  // method - метод запроса
  // data - тело POST запроса
  // headers  - загловки POST запроса
  // contentRequestType - тип запроса text или json (по умолчанию)

  // метод запроса
  logoutUserDataSource.method = 'POST';
  logoutUserDataSource.data='';
  // content type запроса : json по умолчанию или еу
  logoutUserDataSource.contentRequestType='text';
  return logoutUserDataSource;
}

export default logoutUser;
