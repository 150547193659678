import axios from 'axios';


export default {
  get_sign(value) {
    let sign = Math.sign(value);
    if (sign === 0) sign = Math.sign(1 / value);
    return sign;
  },
  copy_object(obj) {
    const isArray = (check) => check.constructor === Array;
    const isObject = (check) => check.constructor === Object;
    let ans = obj;

    if (isArray(obj)) {
      ans = obj.map((item, index) => item);
    } else if (isObject(obj)) {
      ans = Object.entries(obj);
      ans = ans.map(([key, value]) => [key, value]);
      ans = Object.fromEntries(ans);
    }
    return ans;
  },
  get_split_cookie() {
    const cookies = {};
    document.cookie.split('; ').forEach((x) => {
      const item = x.split('=');
      cookies[item[0]] = item[1];
    });

    return cookies;
  },
  doRequest(type, url, data, params, rT, success, error, dontSetPreloadTimeout, headers) {
    headers = headers || {};
    headers['Session'] = this.get_split_cookie()['passport_session_id'];
    // headers['Cookie'] = document.cookie;

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

    axios({
      url,
      method: type,
      responseType: rT,
      data,
      params,
      headers: headers,
    })
        .then((res) => {
          success(res);
        })
        .catch((res) => {
          if (res === 'Error: Request failed with status code 401') {
            window.console.error(
                '401',
                res,
            );
          } else {
            if (!url.endsWith('/logs')) {
              window.console.error(
                  'request error',
                  res,
              );
            } else {
              window.console.devLog(
                  'LOG response error',
                  res,
              );
            }
            error(res);
          }
        });
  },
};
