<template>
  <ModalView ref = "settings_modal_view_ref"
             :open_level = "1"
             type_modal = "internal"
             position = "fixed"
             :caption = "this.loc('Настройки')"
             @click_close = "this.$emit('closeSettingsModal')">
    <div class = "settingsModal__container">
      <div class = "settingsModal__container__group">
        <LabelComponent label_type = "button/large/medium 14"
                        label_text = "Привязка аккаунтов"
                        class = "settingsModal__label"/>
        <Separator separatorType="horizontal"/>
        <ButtonComponent v-for = "button in this.getLinkButtons" :key = "button"
                         @click="button.Link"
                         :label = "button.label"
                         elementType = "default-bg"
                         class = "settingsModal__button"/>
      </div>
      <div class = "settingsModal__container__group">
        <LabelComponent label_type = "button/large/medium 14"
                        label_text = "Безопасность"
                        class = "settingsModal__label"/>
        <Separator separatorType="horizontal"/>
        <ButtonComponent v-for = "button in this.getSecurityButtons" :key = "button"
                         :label = "button.label"
                         elementType = "default-bg"
                         class = "settingsModal__button"
                         @click = "button.actionFunc"/>
      </div>
    </div>
  </ModalView>
  <ChangePasswordModal v-if = "this.changePasswordModal"
                       @closeChangePasswordModal = "this.closePasswordModal()"/>
  <AccountLinkModal v-if = "this.chosenAccountToLink"
                       @closeAccountLinkModal = "this.closeAccountLinkModal()"/>
</template>

<script>
import ChangePasswordModal from './ChangePasswordModal';
import builder from '../../../assets/v1/js/builder';
import ModalView from '../../ModalView';
import LabelComponent from '../../unit/Label';
import ButtonComponent from '../../unit/Button';
import Separator from '../../unit/Separator';
import AccountLinkModal from './AccountLinkModal';

export default builder({
  name: 'SettingsModal',
  emits: ['closeSettingsModal'],
  data: () => ({
    changePasswordModal: false,
    chosenAccountToLink: undefined,
  }),
  components: {
    ModalView,
    LabelComponent,
    ButtonComponent,
    ChangePasswordModal,
    Separator,
    AccountLinkModal,
  },
  computed: {
    isECMlinked() {
      return this.$store.getters.isECMlinked;
    },
    isERPlinked() {
      return this.$store.getters.isERPlinked;
    },
    isSUPlinked() {
      return this.$store.getters.isSUPlinked;
    },
    getLinkButtons() {
      return [
        {
          label: (this.isERPlinked) ? 'ERP': 'ERP (Привязать)',
          Link: ()=> {
            this.chosenAccountToLink = 'ERP';
          },
        },
        {
          label: (this.isSUPlinked) ? 'SUP': 'SUP (Привязать)',
          Link: ()=> {
            this.chosenAccountToLink = 'SUP';
          },
        },
        {
          label: (this.isECMlinked) ? 'ECM': 'ECM (Привязать)',
          Link: ()=> {
            this.chosenAccountToLink = 'ECM';
          },
        },
      ];
    },
    getSecurityButtons() {
      return [
        {
          label: 'Сменить Пароль',
          actionFunc: () => {
            this.openPasswordModal();
          },
        },
      ];
    },
  },
  methods: {
    closeAccountLinkModal() {
      this.chosenAccountToLink= undefined;
    },
    openPasswordModal() {
      this.changePasswordModal = true;
    },
    closePasswordModal() {
      this.changePasswordModal = false;
    },
  },
});
</script>

<style lang = "less">
@import url('../../../assets/v1/less/constants.less');

.settingsModal {
  &__container {
    min-width: 250px;
    width: 15vw;
    display: grid;
    grid-row-gap: 30px;

    &__group {
      display: grid;
      grid-row-gap: 10px;
    }
  }

  &__button {
    width: 100%;
  }

  &__line {
    height: 1px;
    width: 100%;
    background: @gray;
  }

  &__label {
  }
}
</style>
