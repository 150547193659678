export default class LanguagesAdmin {
  constructor(baseUrl) {
    this.name = 'localization/languages';
    this.url = baseUrl + this.name;
  }

  get ModelName() {
    return this.name;
  }

  get Url() {
    return this.url;
  }

  get TableStruct() {
    return [
      {
        system_name: 'id',
        title: 'id',
        content_align: 'right',
        getValue: (item) => (item.id ? item.id : ''),
      },
      {
        system_name: 'name',
        title: 'Название',
        content_align: 'right',
        getValue: (item) => (item.name ? item.name : ''),
      },
      {
        system_name: 'system_name',
        title: 'Системное имя',
        content_align: 'right',
        getValue: (item) => (item.system_name ? item.system_name : ''),
      },
    ];
  }

  get FormStruct() {
    return [
      {
        system_name: 'system_name',
        typeSettings: {
          is_required: true,
        },
        title: 'Системное имя',
        getValue: (item) => (item.system_name ? item.system_name : ''),
      },
      {
        system_name: 'name',
        title: 'Название',
        getValue: (item) => (item.name ? item.name : ''),
      },
    ];
  }
}
