<template>
  <div class="version-selector-line">
    <DropDownInput v-if="this.dropdownData"
                     class="select-report-dropdown"
                     :dropdown_items_init="this.dropdownData"
                     :init_selected_item="[this.selected_variant]"
                     :label_text="this.drop_down_title"
                     style_type="default"
                     :input_field_status="this.is_disabled ? 'disabled' : undefined"
                     @input_change="this.dropdownSelected"/>

    <ButtonComponent v-for="curButton in this.buttonsData"
                     :key="curButton"
                     class="select-report-button"
                     :label="this.loc(curButton.value_show)"
                     :elementStatusModifier="(curButton.is_version_selected || this.is_disabled) ? 'disabled' : undefined"
                     labelType="button/small/medium 14"
                     @click_to_button="this.dropdownSelected([curButton])"
                     />
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import DropDownInput from '@/components/unit/DropDownInput';
import ButtonComponent from '../../components/unit/Button';

export default builder({
  components: {
    DropDownInput,
    ButtonComponent,
  },
  data: () => ({

  }),
  created() {

  },
  computed: {
    buttonsData() {
      if (!this.available_variants) {
        return this.available_variants;
      }

      let ans = this.available_variants.map((item) => {
        if (!item.is_button) {
          return null;
        }

        return {
          value_show: this.loc(item.performance),
          return_value: item.id,
          is_version_selected: item.id == this.selected_variant,
          priority: item.priority == null ? 0 : item.priority,
        };
      });

      ans = ans.filter((item) => item != null);

      ans.sort((a, b) =>{
        return a.priority-b.priority;
      });

      return ans;
    },
    dropdownData() {
      if (!this.available_variants) {
        return this.available_variants;
      }

      let ans = this.available_variants.map((item) => {
        if (item.is_button) {
          return null;
        }

        return {
          value_show: this.loc(item.performance),
          return_value: item.id,
          priority: item.priority == null ? 0 : item.priority,
        };
      });

      ans = ans.filter((item) => item != null);

      ans.sort((a, b) =>{
        return a.priority-b.priority;
      });

      return ans;
    },
  },
  watch: {

  },
  methods: {
    dropdownSelected(data) { // отрабатываем нажатие выбора вариантов отчёта
      if (data[0].is_version_selected || this.is_disabled) {
        return;
      }

      this.$emit('variant_change', data[0].return_value, data[0].value_show);
    },
  },
  emits: {
    variant_change: null,
  },
  props: {
    drop_down_title: {default: ''},
    available_variants: {default: []},
    selected_variant: {default: -1},
    is_disabled: {default: false},
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.select-report-button {
  margin-left: 7px;
}

.select-report-dropdown{
  max-width: 400px;
  align-self: flex-start;
}

.version-selector-line {
  .flex(row, flex-start, center);
}

</style>
