<template>
  <BarLayout direct="vertical" gap="12" class="list_wrapper">
    <div class="card" v-if="this.is_with_search">
      <InputField type="search" ref="search"
                  :set_values="search_text"
                  @input_change="this.search_input_text_changed"
                  :label_text="this.loc('Поиск')"/>

      <div class="search__hints">
        <LabelComponent v-for="item in this.search_hints" :key="item"
                        v-show="this.search_text !== item.value"
                        label_type="body/large/regular 12"
                        :label_color="item.color ? item.color : 'status-color-2'"
                        :label_text="item.value"
                        @click="this.set_search_input_text(item.value)"/>

        <LabelComponent v-if="this.search_text !== ''"
                        label_type="body/large/regular 12"
                        label_color="status-color-2"
                        label_text="Очистить"
                        @click="this.set_search_input_text('')"/>
      </div>
    </div>

    <slot name="additional_settings"/>

    <LoadingFrame v-if="this.is_loading"/>

    <template v-if="!this.is_loading">
      <div v-for="item in this.current_data_calc" :key="item"
           class="card list_item"
           :class="{'selected_item': item.isSelected}"
           @click="this.click_by_list_item(item)"
           @mousedown="this.startHoldItem(item)"
           @touchstart="this.startHoldItem(item)"
           @mouseup="this.endHoldCurrentItem()"
           @touchend="this.endHoldCurrentItem()">

        <slot :item="item"/>
      </div>

      <div v-if="this.current_data_calc.length === 0" class="card">
        Нет записей
      </div>
    </template>
  </BarLayout>
</template>

<script>
import builder from '../../assets/v1/js/builder';

import BarLayout from '../../components/unit/BarLayout';
import LabelComponent from '../../components/unit/Label';
import LoadingFrame from '../../components/LoadingFrame';
import InputField from '../../components/unit/InputField';

export default builder({
  name: 'Logistic',
  components: {
    BarLayout, LoadingFrame, LabelComponent, InputField,
  },
  emits: ['select_list_item', 'click_by_list_item', 'element_observed'],
  props: {
    is_loading: {
      type: Boolean,
      default: false,
    },
    is_with_search: {
      type: Boolean,
      default: true,
    },
    search_data: {
      type: Array,
      required: false,
    },
    search_hints: {
      type: Array,
      default: [],
    },
    custom_searcher: {
      type: Function,
      default: undefined,
    },
    enable_multiselect: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    current_data_calc: [],
    search_text: '',
    observer: null,
    selectAwaiterId: null,
    selectingItem: null,
  }),
  watch: {
    search_data: function() {
      this.compute_data();
    },
    current_data_calc: function() {
      this.observerTargetAdd();
    },
  },
  beforeUnmount() {
    document.removeEventListener(
        'mouseup',
        this.endHoldCurrentItem,
    );
    document.removeEventListener(
        'touchend',
        this.endHoldCurrentItem,
    );
  },
  mounted() {
    document.addEventListener(
        'mouseup',
        this.endHoldCurrentItem,
    );
    document.addEventListener(
        'touchend',
        this.endHoldCurrentItem,
    );

    this.compute_data();
  },
  computed: {
    get_dropdown_status_objects() {
      return [
        {
          value_show: 'Товар отгружен',
          return_value: 'Товар отгружен',
          is_selected: true,
        },
        {
          value_show: 'Отменено',
          return_value: 'Отменено',
        },
        {
          value_show: 'Товар получен',
          return_value: 'Товар получен',
        },
      ];
    },
  },
  methods: {
    startHoldItem(item) {
      this.endHoldCurrentItem();

      if (!this.enable_multiselect) {
        return;
      }

      this.selectingItem = item;

      this.selectAwaiterId = setTimeout(() => {
        this.$emit('select_list_item', this.selectingItem);

        this.selectingItem = null;
        this.selectAwaiterId = null;
      }, 500);
    },
    endHoldCurrentItem() {
      if (this.selectAwaiterId != null) {
        clearTimeout(this.selectAwaiterId);

        this.selectingItem = null;
        this.selectAwaiterId = null;
      }
    },
    search_input_text_changed(text) {
      this.search_text = text;
      this.compute_data();
    },
    set_search_input_text(text) {
      this.search_text = text;
    },
    compute_data() {
      if (this.search_data) {
        let baseFilter = undefined;

        if (this.custom_searcher != null) {
          baseFilter = this.custom_searcher;
        } else {
          baseFilter = (item, searchText) => Object.values(item).some((itemValue) => {
            return itemValue.toString().toLowerCase().includes(searchText.toLowerCase());
          });
        }

        this.current_data_calc = this.search_data.filter((item) => baseFilter(item, this.search_text));
      }
    },
    click_by_list_item(item) {
      this.$emit('click_by_list_item', item);
    },
    observerTargetAdd() {
      setTimeout(() => {
        const length = document.getElementsByClassName('list_item').length;
        if (length) {
          const target = document.getElementsByClassName('list_item')[length - 1];
          this.observer.observe(target);
        }
      }, 200);
    },
    onElementObserved(entries) {
      entries.forEach(({target, isIntersecting}) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.$emit('element_observed');
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {threshold: 0.5});
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.text_hint {
  color: @gray;
}

.search {
  &__hints {
    .flex(row, flex-start, center);
    gap: 5px;
    flex-wrap: wrap;
  }
}

.card {
  .flex(column, flex-start, stretch);

  & > * {
    margin-bottom: 5px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  background: @white;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
}

.selected_item {
  background-color: @status-color-3;
}

</style>
