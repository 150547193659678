<template>
  <div class="error-window">
    <transition-group name="error-window__item">
      <MessageWindowItem
          v-for="(item) in messageStore"
          @close_error_item="delete_error_item(item)"
          :push_message="item"
          :key="item"/>
    </transition-group>
  </div>
</template>

<script>
import MessageWindowItem from './MessageWindowItem';
import builder from '../../assets/v1/js/builder';

export default builder({
  components: {MessageWindowItem},
  name: 'MessageWindow',
  mounted() {
    window.globalEvents.addListener('new_message', this.messagesHandler);
  },
  data: ()=>({
    // хранилище сообщений
    messageStore: [],
  }),
  methods: {
    messagesHandler(message) {
      if (message.messageType==='error') {
        message.messageData.name=this.typeErrors(message.messageData.name);
      }
      this.messageStore.push(message);
      // console.log(message);
    },
    // удаляем элемент с ошибкой
    delete_error_item(item) {
      this.messageStore=this.messageStore.filter((idx)=>idx!==item);
    },
    // по названию объекта с ошибкой определяем его источник
    typeErrors(name) {
      if (name==='BackEndError') return this.loc('Бэкофис');
      if (name==='Error') return this.loc('Браузер');
      if (name==='OneSError') return this.loc('1C');
      if (name==='ExternalSysError') return this.loc('Внешняя система');
      return this.loc('Нераспознанная ошибка');
    },
  },
});
</script>

<style scoped lang="less">
.error-window{
  position: fixed;
  top: 0;
  right: 5px;
  z-index: 1100;

  &__item{
    &-enter-active,
    &-leave-active {
      transition: all 1s ease;
    }
    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateX(100px);
    }
   }
}
</style>
