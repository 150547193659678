export default {
  methods: {
    objIsValid(obj) {
      for (const key in obj) {
        if ((obj[key]==null)||(obj[key]==='')||(obj[key]['type']==='invalid')) {
          this.alertWarning('Проверяйте поля');
          return false;
        }
      }
      return true;
    },
  },
};
