<template>
  <component :is="this.get_element_type" class="universal-wrapper"
             :to="{path: this.href}"
             :href="this.href"
             :target="(this.get_element_type === 'a') ? '_blank' : undefined">

    <slot>Что-то должно быть обернуто</slot>

    <div class="modal-context-menu-block"
         v-if="get_show_context_menu_status(is_can_open_context_menu, is_show_context_menu, href)">
    </div>
  </component>
</template>

<script>
import builder from '../../assets/v1/js/builder';

export default builder({
  name: 'UniversalWrapper',
  components: {
    // ModalView,
  },
  props: {
    href: undefined,
    is_show_context_menu: false,
    is_can_open_context_menu: false,
  },
  computed: {
    get_element_type() {
      if (!this.href) return 'div';
      else if (this.href[0] === '/') return 'routerLink';
      else return 'a';
    },
  },
  methods: {
    get_show_context_menu_status(isCanOpenContextMenu, isShowContextMenu, href) {
      return isCanOpenContextMenu && isShowContextMenu && href === undefined;
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.universal-wrapper{
  position: relative;
  //.flex(column, flex-start, flex-start);
}

.modal-context-menu-block{
  position: relative;

  .modal-context-menu{
    position: absolute;
    top: 5px;
  }
}


</style>
