<template>
  <DropDownInput :label_text="this.loc(this.input_info.name)"
                 :dropdown_items_init="this.getAllItems"
                 :is_multi_select="this.is_multi_select"
                 :is_filterable="this.is_filterable"
                 :is_extendable="this.is_extendable"
                 :init_selected_item="this.getSelectedItems"
                 style="max-width: 250px;"
                 @input_change="this.inputChanged">
  </DropDownInput>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import DropDownInput from '@/components/unit/DropDownInput';

export default builder({
  components: {
    DropDownInput,
  },
  data: () => ({
    is_multi_select: false,
    is_filterable: false,
    preset_inns: [
      {
        'id': '2624022320',
        'performance': 'LUK: 2624022320',
      },
      {
        'id': '1651000010',
        'performance': 'NK: 1651000010',
      },
      {
        'id': '1658008723',
        'performance': 'KOS: 1658008723',
      },
      {
        'id': '0266048970',
        'performance': 'SLVT: 0266048970',
      },
      {
        'id': '0916000312',
        'performance': 'UOP: 0916000312',
      },
      {
        'id': '6944005786',
        'performance': 'MP: 6944005786',
      },
      {
        'id': '6901024745',
        'performance': 'TP: 6901024745',
      },
      {
        'id': '5261322707',
        'performance': 'TUR: 5261322707',
      },
      {
        'id': '0526-1322-7070-0001',
        'performance': 'TUR: 0526-1322-7070-0001',
      },
      {
        'id': '1655202105',
        'performance': 'KA: 1655202105',
      },
      {
        'id': '0274051582',
        'performance': 'BNEFT: 0274051582',
      },
      {
        'id': '7706107510',
        'performance': 'ROSNEFT: 7706107510',
      },
      {
        'id': '7727547261',
        'performance': 'SIBUR: 7727547261',
      },
      {
        'id': '2624022320',
        'performance': 'STVRLN: 2624022320',
      },
      {
        'id': '5032335560',
        'performance': 'KBGL: 5032335560',
      },
    ],
  }),
  created() {
    this.is_extendable = true;
    this.is_multi_select = false;
    this.is_filterable = true;
  },
  computed: {
    getAllItems() {
      return this.preset_inns.map((item)=> ({
        value_show: item.performance,
        return_value: item.id,
      }));
    },
    getSelectedItems() {
      if (!this.is_multi_select) {
        return [this.input_info.value.value];
      }

      return this.input_info.value.map((item)=>item.value);
    },
  },
  watch: {

  },
  methods: {
    inputChanged(data) {
      let newValue = data.map((item) => ({value: item.return_value}));
      if (!this.is_multi_select) {
        newValue = newValue[0];
      }

      const ans = {};
      Object.assign(ans, this.input_info);

      ans.value = newValue;
      this.$emit('input_data_change', this.input_name, ans);
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">

</style>
