<template>
  <div class="object-list-view">
      <!-- <div v-if="Object.keys(this.$route.query).length > 0">
          Обнаружены GET Параметры! {{ this.$route.query }} <br><br>
      </div> -->

      <div class="actions-wrapper">
        <ButtonComponent
          v-if="this.getCanAddElem"
          :label="'Добавить ' + (this.workObjectTypeInfo ? this.workObjectTypeInfo.entity_view_name : '-')"
          :href="'/object_create_form/' + this.workObjectName"
        />
      </div>

      <div class="entities-wrapper">
        <ButtonComponent
          v-for="(draw_item, index) in this.drawObjects"
          :key="index"
          :elementType="'default'"
          :label="draw_item.view"
          @click_to_button="this.selectObject(draw_item)"
          class="entity"
        />
      </div>
  </div>
</template>

<script>
import ButtonComponent from '@/components/unit/Button';
import getObjectTypeInfo from '@/assets/dataSources/admin/getObjectTypeInfo';
import getObjectList from '@/assets/dataSources/admin/getObjectList';

export default ({
  components: {
    ButtonComponent,
  },
  data: () => ({
    drawObjects: null,
    workObjectTypeInfo: null,
  }),
  emits: [
    'entitySelect',
  ],
  props: {
    work_object_name: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return;
      }

      this.rebuildForm();
    },
    work_object_name(newData) {
      this.rebuildForm();
    },
  },
  computed: {
    workObjectName() {
      return this.work_object_name ? this.work_object_name : '-';
    },
    getCanAddElem() {
      return this.$store.getters.hasPermission(this.workObjectName + '__add');
    },
  },
  methods: {
    selectObject(object) {
      this.$emit('entitySelect', [object]);
    },
    rebuildForm() {
      // const curThis = this;

      const won = this.workObjectName;

      if (this.getCanAddElem) {
        getObjectTypeInfo(won, (objTypeInfo) => {
          if (this.workObjectName != won) return;
          this.workObjectTypeInfo = objTypeInfo;
        });
      }

      getObjectList(won, (dO) => {
        if (this.workObjectName != won) return;
        this.drawObjects = dO;
      });
    },
  },
  mounted() {
    this.rebuildForm();
  },
});
</script>

<style lang="less">
@import url('../../../assets/v1/less/base.less');

.object-list-view {
    padding: 14px;
}

.actions-wrapper {
  display: flex;
  margin-bottom: 14px;
}

.entities-wrapper {
  .flex(column, start, center);

  .entity {
    margin: 12px;

    * {
      text-align: left;
    }
  }
}
</style>
