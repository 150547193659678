<template>
  <div class="filters-wrapper">
    <div class="filters-wrapper__header-container" @click="this.openToggle()">
      <LabelComponent class="filters-wrapper__header"
                      label_type="caption/big/bold 20"
                      :label_text="this.loc('Фильтры')"/>
      <IconComponent :class="this.getIconClasses"
                     name="caret-circle-down"/>
    </div>
    <div v-show="this.isOpened" class="filters-wrapper__filters-container">
      <slot/>
    </div>
    <div v-show="!this.isOpened && this.selectedItems.length" class="filters-wrapper__selected-items-list">
      <LabelComponent v-for="item in this.getSelectedItemsList" :key="item"
                      :label_text="item"/>
    </div>
  </div>
</template>

<script>
import builder from '../../assets/v1/js/builder';
import LabelComponent from '../../components/unit/Label';
import IconComponent from '../../components/unit/Icon';

export default builder({
  name: 'FiltersWrapper',
  components: {
    LabelComponent,
    IconComponent,
  },
  props: {
    selectedItems: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    isOpened: false,
  }),
  computed: {
    getIconClasses() {
      return {
        'filters-wrapper__open-button': true,
        'filters-wrapper__open-button_opened': this.isOpened,
      };
    },
    getSelectedItemsList() {
      return this.selectedItems;
    },
  },
  methods: {
    openToggle() {
      this.isOpened = !this.isOpened;
    },
  },
});
</script>

<style lang="less">
@import "../../assets/v1/less/base";

.filters-wrapper {
  background-color: @white;
  padding: 12px;
  border-radius: 10px;

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
  }

  &__open-button {
    transition: transform 0.2s;
    background: #b3b7b1ff;

    &_opened {
      transform: rotate(180deg);
    }
  }

  &__selected-items-list {
    margin-top: 10px;
  }

  &__filters-container {
    margin-top: 10px;
  }
}
</style>
