// import eventsHandlerBuilder from '../events_handler.js';
import {jsonRequester} from '../HttpRequester.js';
import store from '../../../../../store';

export const ROOT_URL = store.state.urls.root;

export function dataSource(url, callback) {
  // const curEventsHandler = eventsHandlerBuilder();
  const ans ={url};

  // method - метод запроса
  // data - тело POST запроса
  // headers  - загловки POST запроса
  // contentRequestType - тип запроса text или json (по умолчанию)

  ans['makeRequest'] = async function(noShowLoader, idx) {
    if (!noShowLoader) {
      window.globalEvents.callEvent('showLoader');
    }
    let jsonResp;
    try {
      jsonResp = await jsonRequester(this.url, this.method, this.data, this.headers, this.contentRequestType);
    } catch (error) {
      jsonResp = {
        error: true,
        connection_error: error,
      };
    }
    if (!noShowLoader) {
      window.globalEvents.callEvent('closeLoader');
    }
    // console.log('запускаем handler с dataSource=', ans);
    // handler запустится в контексте объекта ans
    // idx пока ниоткуда не берется
    callback.call(ans, jsonResp, idx);
  };
  return ans;
}
