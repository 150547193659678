<template>
  <BasePage>
    <div class="base-test-wrapper">
      <ButtonComponent :label="this.loc('Тест alert')" @click_to_button="this.alertTest"/>
      <ButtonComponent :label="this.loc('Тест confirm: ') + this.lastConfirmResult" @click_to_button="this.confirmTest"/>
      <ButtonComponent :label="this.loc('Тест prompt: ') + this.lastPromptResult" @click_to_button="this.promptTest"/>
      <ButtonComponent :label="this.loc('Тест BLOB download: ')" @click_to_button="this.blobDownload"/>
    </div>
    <div class="base-test-wrapper">
      <InputField type="date"/>
      <InputField type="time"/>
    </div>
    <div class="base-test-wrapper">
      <InputField type="file"
                  :is_all_time_open="true"
                  :label_text="this.loc('Тест прикрепления файлов')"/>
    </div>
    <div v-if="!this.workInApplication"> {{this.loc('Работа в режиме браузера')}} </div>
    <template v-else>
      <div> {{this.loc('Работа в режиме приложения')}} </div>
      <div> {{this.loc('IOS:')}} {{this.isIos}} </div>
      <div> {{this.loc('Android:')}} {{this.isAndroid}} </div>

      <div> {{this.loc('Доступные функции приложения:')}} </div>
      <div v-for="(item, idx) in this.getPermissions" :key="idx"
           class="item">{{item}}</div>

      <template v-if="this.hasPermissionReadWriteString">
        <div> {{this.loc('Проверка сохранения/считывания текстового значения:')}} </div>
        <InputField :label_text="this.loc('Значение')" set_values="test_string" ref="stringInput"/>
        <ButtonComponent :label="this.loc('Удалить, сохранить и считать')" @click_to_button="this.saveLoadString"/>
        <div v-if="this.readTestString"> {{this.loc('Считанное значение:')}} {{this.readTestString}} </div>
      </template>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '../../components/navigation/BasePage';
import builder from '../../assets/v1/js/builder';
import LabelComponent from '../../components/unit/Label';
import ButtonComponent from '../../components/unit/Button';
import InputField from '../../components/unit/InputField';
import downloadBLOB from '../../assets/v1/js/project_management/downloadBLOB';

export default builder({
  components: {
    BasePage,
    LabelComponent,
    ButtonComponent,
    InputField,
  },
  data: ()=>({
    savingKey: '__test_string_saving__',
    workInApplication: false,
    readTestString: null,
    lastConfirmResult: false,
    lastPromptResult: '',
  }),
  created() {
    this.workInApplication = window.WORK_IN_APPLICATION;

    if (!this.hasPermissionReadWriteString) {
      return;
    }

    window.applicationCallback.testLoadFromStorage = (status, key, value) => {
      this.readTestString = value;
    };

    window.sendMessageInApplication({
      type: 'get_saved_string',
      key: this.savingKey,
      callback: 'window.applicationCallback.testLoadFromStorage',
    });
  },
  methods: {
    promptTest() {
      this.lastPromptResult = prompt(this.loc('Введите текст для теста prompt:'));
    },
    confirmTest() {
      this.lastConfirmResult = confirm(this.loc('Тестирование confirm. Да или нет?'));
    },
    alertTest() {
      alert(this.loc('Тестовый alert'));
    },
    blobDownload() {
      const ans = fetch('https://online-back.kartli.ch/media/icons/sphere-white-matte.png', {
        method: 'GET',
      }).then(async function(result) {
        const contentType = result.headers.get('content-type');

        const extention = contentType.split('/')[1];
        // console.info('HTTP обрабатываем как скачиваемый файл');
        const curText = await result.blob();
        const blob = new Blob([curText], {type: `application/${extention}`});
        const url = window.URL.createObjectURL(blob);
        downloadBLOB(url, 'test_file.' + extention);
        window.URL.revokeObjectURL(url);
      });
    },
    saveLoadString() {
      const saveString = this.$refs.stringInput.data_values;

      window.applicationCallback.testLoadFromStorage = (status, key, value) => {
        this.readTestString = value;
      };

      window.applicationCallback.testSaveToStorage = (status, key) => {
        window.sendMessageInApplication({
          type: 'get_saved_string',
          key: this.savingKey,
          callback: 'window.applicationCallback.testLoadFromStorage',
        });
      };

      window.applicationCallback.testDeleteFromStorage = (status, key) => {
        window.sendMessageInApplication({
          type: 'save_string',
          key: this.savingKey,
          value: saveString,
          callback: 'window.applicationCallback.testSaveToStorage',
        });
      };

      window.sendMessageInApplication({
        type: 'delete_saved_string',
        key: this.savingKey,
        callback: 'window.applicationCallback.testDeleteFromStorage',
      });
    },
  },
  computed: {
    isIos() {
      return window.WORK_IN_IOS_APPLICATION === true;
    },
    isAndroid() {
      return window.WORK_IN_ANDROID_APPLICATION === true;
    },
    getPermissions() {
      return window.availableApplicationFunctions;
    },
    hasPermissionReadWriteString() {
      return window.WORK_IN_APPLICATION && window.hasFunctions(['save_string', 'get_saved_string', 'delete_saved_string']);
    },
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.item {
  margin: 7px;
  white-space: pre-wrap;
}

.base-test-wrapper {
  .flex(row, space-around, center);
}
</style>
