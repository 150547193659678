<template>
  <CheckBox :is_toggle="true"
            :init_value="this.set_values"
            @input_check="(value) => this.data_value = value"
            v-bind="this.getComponentSettings"
            :label_text_right="this.getTitle"/>
</template>

<script>
import builder from '@/assets/v1/js/builder';
import CheckBox from '@/components/unit/CheckBox';

export default builder({
  name: 'FormSwitcher',
  components: {
    CheckBox,
  },
  emits: [
    'input_change',
    'update:modelValue',
  ],
  data: () => ({
    data_value: '',
  }),
  computed: {
    getTitle() {
      const title = this.fieldInfo.title || this.fieldInfo.system_name;
      return this.loc(title);
    },
    getComponentSettings() {
      return this.fieldInfo.typeSettings;
    },
  },
  watch: {
    modelValue(newData) {
      if (newData) this.data_value = newData;
    },
    set_values(newData) {
      // при получении новых данных через props меняем data_values
      this.data_value = newData;
    },
    data_value(newData) {
      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
      this.$emit('update:modelValue', newData);
    },
  },
  props: {
    fieldInfo: {
      required: true,
      type: Object,
      default: {},
    },
    modelValue: {},
    set_values: {},
  },
});
</script>

<style>

</style>
