<template>
  <div>
    <NamedField :label="this.loc(this.input_info.name) + ':'">
      <template v-if="!this.input_info.value || this.input_info.value.length < 1">
        <LabelComponent label_type="button/large/regular 14"
                      :label_text="this.loc('Файлов нет')"
      />
      </template>
      <div class="objects-line" v-else>
        <ButtonComponent v-for="interactObj in this.input_info.value" :key="interactObj"
                              :label="this.loc(interactObj.name)"
                              elementType="default"
                              labelType="button/small/medium 14"
                              @click_to_button="this.download_file(interactObj.GUID, interactObj.name)"/>
      </div>
    </NamedField>
  </div>
</template>

<script type="text/javascript">
import builder from '../../assets/v1/js/builder';
import LabelComponent from '../../components/unit/Label';
import ButtonComponent from '../../components/unit/Button';
import {ROOT_URL, dataRequester} from '../../assets/v1/js/project_management/DataRequester';
import downloadBLOB from '../../assets/v1/js/project_management/downloadBLOB';
import NamedField from '../../components/unit/NamedField';

export default builder({
  components: {
    LabelComponent,
    ButtonComponent,
    NamedField,
  },
  data: () => ({
    inputType: 'text',
    is_all_time_open: false,
  }),
  created() {
    this.inputType = 'text';

    if (this.input_info.input_type === 'float') {
      this.inputType = 'number';
    }

    if (this.input_info.input_type === 'string' && this.input_info.multiline === true) {
      this.inputType = 'textarea';
      this.is_all_time_open = true;
    }
  },
  computed: {
    getInitValue() {
      if (this.standard_version === 1) {
        return this.input_info.value;
      }
      return this.input_info.value.value;
    },
  },
  watch: {

  },
  methods: {
    download_file(guid, name) {
      const options={
        url: `${ROOT_URL}/api/v1/src/${this.input_info.download_additional_objects_src}`,
        method: 'POST',
        responseType: 'blob',
        body: {params: {'GUID': guid}},
      };
      this.$store.commit('set_full_screen_loading', true);
      dataRequester(options)
          .then((response) => {
            downloadBLOB(response.result.url, name);
          })
          .catch((errData)=> {
            console.log(errData);
          })
          .finally(()=>{
            this.$store.commit('set_full_screen_loading', false);
          });
    },
  },
  emits: {
    input_data_change: null,
  },
  props: {
    input_name: {default: ''},
    input_info: {default: {}},
    available_objects: {default: {}},
    standard_version: {default: null},
  },
});
</script>

<style lang="less">
@import url('../../assets/v1/less/base.less');

.objects-line {
  .flex(row, flex-start, flex-start);
  gap: 10px;
  flex-wrap: wrap;
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  // row-gap: 5px;
  // column-gap: 10px;
  align-items: center;
}
</style>
